import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Reference from './Reference'

import electron from '../images/svg/nuclear.jpg';
import solar from '../images/svg/solar-panel.svg';
import wind from '../images/svg/wind.jpg';
import gas from '../images/svg/gaz-plant.jpg';
import dam from '../images/svg/dam.jpg';
import smoke from '../images/svg/smoke.jpg';
import coalPlant from '../images/svg/coal-plant.png';
import battery from '../images/svg/battery.jpg';
import hydrogen from '../images/svg/hydrogen.svg';
import STEP from '../images/svg/STEP.jpg';

import { energyAbacus } from '../data/EnergyAbacus';
import { renewableAbacus } from '../data/EnergyAbacus';

export default class CoalGame extends Component {

    state = {
        alternativEnergySum: 0,
        totalAlternativePower: 0,
        totalAlternativeEnergy: 0,
        totalPower: 0,
        powerPercent: 0,
        initialPower: 1000,
        renewableEnergy: 0,
        selectedStockage: {
            label: "STEP",
            loss: 0.8,
            carbonIntensity: "28"
        },
        coal: {
            selection: 100,
            initialSelection: 100,
            initialValue: 10,
            energyByPercent: 8.5,
            energy: 8760,
            initialEnergy: 8760,
            carbonIntensity: 820
        },
        emissions: {
            selection: 100,
            initialValue: 0,
            value: 820
        },
        alternatives: [
            {
                label: "Gaz",
                name: "gas",
                selection: 0,
                energy: 0,
                power: 0,
                min: 0,
                max: 100,
                powerByPercent: 20,
                energyLoss: 0,
                initialValue: 0,
                unitPower: 465,
                loadFactor: 0.85,
                image: gas,
                carbonIntensity: 490,
                handleChange: (event, newValue) => {
                    this.handleEnergyChange(event, newValue, 0);
                },
                getLabel: (power) => {
                    return "Installation de " + (((power / 465).toFixed(1))).toLocaleString('fr') + " turbines à gaz ";
                },
                subtitle: "D'après la moyenne française de 465MW sur la décennie 2005 - 2016"

            },
            {
                label: "Nucléaire",
                name: "nuclear",
                selection: 0,
                power: 0,
                energy: 0,
                min: 0,
                max: 100,
                powerByPercent: 30,
                energyLoss: 0,
                initialValue: 0,
                loadFactor: 0.75,
                unitPower: 1450,
                carbonIntensity: 12,
                image: electron,
                handleChange: (event, newValue) => {
                    this.handleEnergyChange(event, newValue, 1);
                },
                getLabel: (power) => {
                    return "Mise en service de " + (((power / 1450).toFixed(1))).toLocaleString('fr') + " EPR.";
                },
                subtitle: "Le réacteur de l'EPR en construction à Flamanville aura une puissance de 1500MW"

            },
            {
                label: "Barrage",
                name: "hydro",
                selection: 0,
                power: 0,
                energy: 0,
                min: 0,
                max: 100,
                powerByPercent: 35,
                energyLoss: 0,
                initialValue: 0,
                unitPower: 380,
                loadFactor: 0.35,
                image: dam,
                carbonIntensity: 24,
                handleChange: (event, newValue) => {
                    this.handleEnergyChange(event, newValue, 2);
                },
                getLabel: (power) => {
                    return "Construction de " + (((power / 380).toFixed(1))).toLocaleString('fr') + " très grands barrages";
                },
                subtitle: "Le plus grand barrage de France, à Serre-Ponçon a une puissance de 380MW ."
            },
            {
                label: "Solaire",
                name: "solar",
                selection: 0,
                power: 0,
                energy: 0,
                min: 0,
                max: 75,
                powerByPercent: 200,
                energyLoss: 0,
                initialValue: 0,
                unitPower: 0.0003,
                loadFactor: 0.14,
                carbonIntensity: 43,
                image: solar,
                imageLegend: "1000",
                eneryProdAbacus: [1184, 2270, 2865, 3207, 3425, 3579, 3696, 3788, 3864, 3930, 3990, 4045, 4095, 4140, 4182, 4220, 4256, 4289, 4321, 4350],
                handleChange: (event, newValue) => {
                    this.handleEnergyChange(event, newValue, 3);
                },
                getLabel: (power) => {

                    let nbrOfPanels = Math.ceil(power / 0.0003);
                    let result = "";

                    if (nbrOfPanels < 1000000) {
                        result = "Installation de " + nbrOfPanels.toLocaleString('fr') + " panneaux solaires";
                    }
                    else {
                        result = "Installation de " + (nbrOfPanels / 1000000).toFixed(1) + " millions de panneaux solaires";
                    }

                    return result;
                },
                subtitle: "Un panneau solaire standard fait 1,7m2 a une puissance de 0,3kW, soit 3 dix-millième de MW"
            },
            {
                label: "Éolien",
                name: "wind",
                selection: 0,
                energy: 0,
                power: 0,
                min: 0,
                max: 75,
                powerByPercent: 200,
                energyLoss: 0,
                initialValue: 0,
                unitPower: 3,
                loadFactor: 0.235,
                carbonIntensity: 11,
                eneryProdAbacus: [0, 2043, 3905, 5165, 6046, 6705, 7195, 7559, 7834, 8045, 8207, 8331, 8427, 8503, 8561, 8605, 8639, 8666, 8687, 8703, 8716],
                image: wind,
                imageLegend: "100",
                handleChange: (event, newValue) => {
                    this.handleEnergyChange(event, newValue, 4);
                },
                getLabel: (power) => {
                    return "Installation de " + Math.ceil(power / 3).toLocaleString('fr') + " éoliennes terrestres";
                },
                subtitle: "La puissance moyenne d'une éolionne terrestre est de 3 MW, deux fois plus pour les éoliennes offshore"
            }
        ],
        storage: {
            energy: 0,
            power: 0,
            selection: 0,
            handleChange: (event, newValue) => {
                this.handleStorageCapacityChange(event, newValue, 10);
            },
            storageType: [
                {
                    label: "STEP",
                    yield: 0.65,
                    selected: true,
                    carbonIntensity: 28,
                    image: STEP,
                    unitPower: 720,
                    handleStorageChange: (event) => {
                        this.handleStorageChange(0.65, 28, 0);
                    },
                    getLabel: (power) => {
                        return "" + (((power / 720).toFixed(1))).toLocaleString('fr') + " stations de pompage-turbinage";
                    }
                },
                {
                    label: "Batterie",
                    yield: 0.65,
                    selected: false,
                    carbonIntensity: 250,
                    image: battery,
                    unitPower: 1.5,
                    handleStorageChange: (event) => {
                        this.handleStorageChange(0.65, 250, 1);
                    },
                    getLabel: (power) => {

                        let batteryPower = (Math.ceil(power / 0.0015) / 1000000).toFixed(1);

                        return "" + batteryPower + " millions de tonnes de batteries Lithium-Ion";
                    }
                },
                {
                    label: "Hydrogène",
                    yield: 0.23,
                    selected: false,
                    carbonIntensity: 30,
                    unitPower: 4,
                    image: hydrogen,
                    handleStorageChange: (event) => {
                        this.handleStorageChange(0.23, 30, 2);
                    },
                    getLabel: (power) => {
                        return "" + Math.ceil(power / 4).toLocaleString('fr') + " modules d'élecrolyseur de 4 MW.";
                    },
                },
            ]
        }
    }

    easeOutExpo = (x) => {
        return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
    }

    handleStorageChange = (loss, carbonIntensity, i) => {

        let storage = this.state.storage;

        storage.storageType.map((item, index) => {
            if (index == i) {
                item.selected = true;
            }
            else {
                item.selected = false;
            }
        });

        let alternatives = this.state.alternatives.slice();

        this.calculateResults(alternatives, storage);
    }

    calculateResults = (alternatives, storage) => {

        /** 
         *  RESULTS
         */

        let coal = this.state.coal;

        // Sum of alternative energies
        let totalAlternativeEnergies = 0;
        let totalPower = 0;

        alternatives.map((energyItem) => {

            energyItem.power = energyItem.selection * energyItem.powerByPercent;
            totalPower += energyItem.power;

            energyItem.energy = (energyItem.selection * energyItem.powerByPercent * energyItem.loadFactor * 365 * 24) / 1000;

            if (energyItem.name == "hydro" || energyItem.name == "nuclear" || energyItem.name == "gas") {

                totalAlternativeEnergies += energyItem.energy;
            }
            else {

            }
        });

        let solarIndex = alternatives.map((e) => { return e.name; }).indexOf('solar');
        let windIndex = alternatives.map((e) => { return e.name; }).indexOf('wind');

        let solarArray = renewableAbacus[Math.floor(alternatives[windIndex].power / 1000)];
        let renewableEnergy = solarArray[Math.floor(alternatives[solarIndex].power / 1000)];

        totalAlternativeEnergies += renewableEnergy;

        /**
         *  STORAGE
         */
        let storagePower = storage.power;
        totalPower += storagePower;

        let solarRatio = Math.floor((alternatives[solarIndex].power / 1000));

        let storageMatrix = energyAbacus[solarRatio];
        let ratio = storagePower / 1000;
        let windRatio = Math.floor((alternatives[windIndex].power / 1000));

        let floor = Math.floor(windRatio);
        let floorDiff = windRatio - floor;
        let ceiling = (floor * 1) + 1;

        let floorArray = storageMatrix[floor];
        let ceilingArray = storageMatrix[ceiling];

        let floorValue = this.interpolateWithTable(ratio, floorArray);
        let ceilingValue = this.interpolateWithTable(ratio, ceilingArray);

        let selectedStorage = storage.storageType.find(storage => storage.selected);

        storage.energy = (floorValue * (1 - floorDiff) + floorDiff * ceilingValue) * selectedStorage.yield;

        coal.energy = coal.initialEnergy - (totalAlternativeEnergies + storage.energy);

        if (coal.energy < 0) {
            coal.energy = 0;
        }

        coal.selection = Math.ceil((coal.energy / coal.initialEnergy) * 100);

        /**
         *  EMISSIONS
         */

        let emissions = this.state.emissions;
        let nonRenewableEnergy = 0;

        let totalEmissions = 0;
        let renewableEmissions = 0;

        if (renewableEnergy > 0) {

            let wind = this.state.alternatives.find(energy => energy.name == "wind");
            let solar = this.state.alternatives.find(energy => energy.name == "solar");

            let averageRatio = ((wind.power * wind.loadFactor * wind.carbonIntensity) + (solar.power * solar.loadFactor * solar.carbonIntensity)) / (wind.power * wind.loadFactor + solar.power * solar.loadFactor);

            /* totalEmissions += averageRatio * this.state.renewableEnergy; */

            renewableEmissions = (wind.power * wind.loadFactor * wind.carbonIntensity + solar.power * solar.loadFactor * solar.carbonIntensity) * (24 * 365) / 1000;
            totalEmissions += renewableEmissions;

        }

        let i = 0;
        let j = 0;

        let nonRenewableAverageRatio = 0;

        alternatives.map((energyItem) => {


            if (energyItem.name != "wind" && energyItem.name != "solar") {

                totalEmissions += energyItem.carbonIntensity * energyItem.energy;
                nonRenewableEnergy += energyItem.energy;

                if (energyItem.power > 0) {
                    i += energyItem.power * energyItem.carbonIntensity;
                    j += energyItem.power;
                }
            }
        });

        let storageEmissions = 0;

        if (this.state.storage.power > 0) {
            let storage = this.state.storage.storageType.find(storage => storage.selected);
            storageEmissions += (this.state.storage.energy * storage.carbonIntensity);
        }

        if (j > 0) {
            nonRenewableAverageRatio = (i * 1) / (j * 1);
        }


        let nonRenewableRealEnergy = (365 * 24) - (renewableEnergy + storage.energy + coal.energy);
        nonRenewableRealEnergy = nonRenewableRealEnergy < 0 ? 0 : nonRenewableRealEnergy;


        totalEmissions = (coal.energy * coal.carbonIntensity + storageEmissions + renewableEmissions + nonRenewableRealEnergy * nonRenewableAverageRatio) / (365 * 24);

        emissions.value = totalEmissions;

        this.setState({
            coal: coal,
            powerPercent: (totalPower / 1000),
            alternatives: alternatives,
            emissions: emissions
        });

    }

    handleEnergyChange = (event, newValue, index) => {

        let alternatives = this.state.alternatives.slice();
        let thisAltEnery = alternatives[index];
        thisAltEnery.selection = newValue;

        this.setState({
            alternatives: alternatives
        });

        this.calculateResults(alternatives, this.state.storage);

    }

    handleStorageCapacityChange = (event, newValue, index) => {

        let storage = this.state.storage;
        storage.selection = newValue;
        storage.power = newValue * 100;


        this.calculateResults(this.state.alternatives, storage);

    }

    /**
     * From a multiplier, get the corresponding value in a table
     */
    interpolateWithTable = (value, table) => {

        let floor = Math.floor(value);
        let floorDiff = value - floor;
        let ceiling = (floor * 1) + 1;

        return (table[floor] * (1 - floorDiff) + floorDiff * table[ceiling]);
    }

    render() {

        let storageType = this.state.storage.storageType.map((item, index) => {
            return <div onClick={item.handleStorageChange} className={item.selected ? "stockage-selected" : ""}>
                {item.label}
            </div>
        });

        let alternatives = this.state.alternatives.map((item, index) => {

            if (this.props.displayedItems && !this.props.displayedItems.includes(item.name)) {
                return;
            }

            return <>

                <label className="energy-label">
                    <span>{item.label} </span>&nbsp;
                        {item.power > 0 && <span className="diff-text-neutral">{item.power}</span>}
                </label>
                <div>
                    <GreenSlider max={item.max} value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                </div>
            </>

        });

        let intensityColor = '#3E0C08';

        if (this.state.emissions.value < 700) {
            intensityColor = '#9E1F15';
        }

        if (this.state.emissions.value < 700) {
            intensityColor = '#9E1F15';
        }

        if (this.state.emissions.value < 600) {
            intensityColor = '#C95E27';
        }

        if (this.state.emissions.value < 500) {
            intensityColor = '#EFB33C';
        }

        if (this.state.emissions.value < 300) {
            intensityColor = '#DCC85C';
        }

        if (this.state.emissions.value < 200) {
            intensityColor = '#CBCB79';
        }

        if (this.state.emissions.value < 100) {
            intensityColor = '#B9CE8E';
        }

        if (this.state.emissions.value < 40) {
            intensityColor = '#7EAA57';
        }

        let icons = this.state.alternatives.map((item, index) => {

            let progressLabel = item.getLabel(item.power);

            return <>{
                (item.power > 0) && <div >
                    <div>
                        <img className="energy-icon" src={item.image} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <span>{progressLabel} </span><br />
                    </div>

                </div>
            }</>

        });

        let displayStorage = this.props.displayedItems && this.props.displayedItems.includes("storage") ? '' : 'none';

        let storage = this.state.storage.storageType.map((item, index) => {

            let storagePower = this.state.storage.power;
            let progressLabel = item.getLabel(storagePower);

            return <>{

                (storagePower > 0 && item.selected) && <div >
                    <div>
                        <img className="energy-icon" src={item.image} />
                    </div>
                    <div style={{ display: 'block' }}>
                        <span>{progressLabel} </span><br />
                    </div>

                </div>
            }</>
        });

        let coalColor = this.state.coal.energy > 0 ? '' : 'var(--secondColor)';

        let smokeOpacity = (Math.sqrt((this.state.coal.energy / this.state.coal.initialEnergy))).toFixed(1);

        return (

            <>

                <article class="game-container coal-game" >
                    <div class="game">

                        <h3 className="energy-label" style={{ textAlign: 'center' }}>
                            <span >Puissance installée &nbsp;  <span className="diff-text-neutral" >

                                {(this.state.powerPercent) > 0 ? "x " + (this.state.powerPercent).toFixed(1) : " - "}</span>

                            </span> <span className="unit-main">( MW )</span>

                            <span className="c-intensity">{(this.state.powerPercent * 1000).toLocaleString('fr')} </span>
                        </h3>

                        <section>

                            {alternatives}

                            <label className="energy-label" style={{ display: displayStorage }}>
                                <span>Stockage</span>&nbsp;
                        {this.state.storage.power > 0 && <span className="diff-text-neutral">{this.state.storage.power.toLocaleString('fr')} </span>}
                            </label>
                            <div style={{ display: displayStorage }} >
                                <GreenSlider value={this.state.storage.selection} defaultValue={this.state.storage.selection} onChange={this.state.storage.handleChange} aria-labelledby="continuous-slider" />
                            </div>

                            <div className='stockage-type-panel' style={{ display: displayStorage }} >
                                {storageType}
                            </div>

                        </section>
                    </div>

                    <div class="game-icons">

                        <section >
                            <div className="coal-header">

                                <h3 className="energy-label" style={{ textAlign: 'center' }}>
                                    <span >Intensité Carbone &nbsp;  <span className="diff-text-above" style={{ padding: "0.5rem", letterSpacing: "1px", color: "var(--secondColorDark)" }}>

                                        {this.state.emissions.value < 820 ?
                                            "-" + (100 - (this.state.emissions.value / 820) * 100).toFixed(0) + "%" : " - "}</span>

                                    </span> <span className="unit-main">( gCO<sub>2</sub> eq/kWh )</span>

                                    <span className="c-intensity" style={{ color: intensityColor }}>{this.state.emissions.value.toFixed(0)}
                                    </span>

                                </h3>

                                <div className="energy-label align-label">

                                    <div style={{ position: 'relative' }}>

                                        <img className="coal-icon" src={coalPlant} />
                                        <img className="smoke-icon" src={smoke} style={{ opacity: smokeOpacity }} />
                                    </div>

                                    <div>
                                        <span>Charbon {this.state.coal.section}</span>&nbsp;
                                    <span className="diff-text-neutral">
                                            {"" +

                                                (((this.state.coal.energy / this.state.coal.initialEnergy) < 0.01 && (this.state.coal.energy / this.state.coal.initialEnergy) > 0) ?
                                                    (((this.state.coal.energy / this.state.coal.initialEnergy)) * 100).toFixed(2)
                                                    :
                                                    (((this.state.coal.energy / this.state.coal.initialEnergy)) * 100).toFixed(0)

                                                )
                                                + " %"}  </span>

                                        <EmissionSlider value={this.state.coal.selection} defaultValue={this.state.coal.selection} id="coal-id" aria-labelledby="continuous-slider" />
                                    </div>

                                </div>
                            </div>

                            <div className="transition-results">

                                <div className="game-icon-list">
                                    {icons} {storage}
                                </div>
                            </div>

                        </section>
                    </div>
                </article>

                <div className="coal-game-source">
                    {this.props.source}
                </div>

            </>


        )
    }
}

const GreenSlider = withStyles({
    root: {
        color: '#09bc8a',
        height: 8,
        "& . Mui-disabled": {
            color: '#09bc8a',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgba(12, 238, 174, 0.301)',
        },
        '& $active': {
            boxShadow: '0px 0px 0px 14px rgba(12, 238, 174, 0.301)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
        color: '#09bc8a',
    },

})(Slider);


const EmissionSlider = withStyles({

    root: {
        color: '#595A5A',
        height: 8,
        "& . Mui-disabled": {
            color: '#595A5A',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#595A5A',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        marginLeft: '-10px',
        borderRadius: 4,
        color: '#595A5A',
    },

})(Slider);


const DarkSlider = withStyles({
    root: {
        color: '#ccc',
        height: 8,
        "& . Mui-disabled": {
            color: '#09bc8a',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgba(12, 238, 174, 0.301)',
        },
        '& $active': {
            boxShadow: '0px 0px 0px 14px rgba(12, 238, 174, 0.301)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4
    },

})(Slider);
