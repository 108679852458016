import React, { Component } from 'react'
import { curriculums } from '../data/Curriculums'
import { Link } from 'react-router-dom';
import logo_full from '../images/energie-climat-logo.png'
import success from '../images/success.png'


import firebase, { firebaseAuth, firebaseProvider } from '../firebase.js';
import Checked from './Checked';

export default class FinishedQuiz extends Component {

    state = {
        simple: this.props.simple,
        nameDefined: false,
        firstName: "Nicolas",
        lastName: "Palitzyne"

    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
        let totalCompleted = 0;

        if (storedUSerInfo && storedUSerInfo.user) {

            var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/certificate');

            if (overview) {

                overview.on('value', (snapshot) => {

                    let thisCurriculum = this.state.thisCurriculum;

                    const data = snapshot.val();

                    if (data) {
                        this.setState({
                            date: data.date,
                            id: data.id,
                            title: data.id
                        });
                    }
                });
            }
        }
    }

    updateName() {

    }

    render() {

        let currentArticle = curriculums[0].articles[0];
        let articleIndex = 0;

        curriculums.map((item, index) => {
            if (item.articles) {
                item.articles.map((item, index) => {
                    if (this.props.articleId == item.url) {
                        currentArticle = item;
                        articleIndex = index;
                    }
                });
            }
        });

        let nextArticle;
        let nextArticleUrl;

        if (articleIndex < curriculums[0].articles.length - 1) {
            nextArticle = curriculums[0].articles[articleIndex + 1];
            nextArticleUrl = '/' + nextArticle.url;
        }

        return (
            <>

                <div className="modal-overview-container result-display">

                    <img src={success}></img>
                    <img src={logo_full} style={{ width: "22rem", marginTop: "2rem" }}></img>

                    <div className="cert-text" style={{ width: "80%", textAlign: "center", margin: "2rem auto" }}>
                        <p>Félicitations ! </p>
                        <p>Vous avez complété avec succès la formation :</p>
                        <h1>Fondamentaux des enjeux <br /> énergie-climat</h1>
                    </div>
                    {/* 
                        <form onSubmit={this.updateName} className="login-form" >

                            <div className="form__body" style={{ border: '1px solid black' }}>

                                <p className="quizz-top-page-error" style={{ display: 'block', marginBottom: '0rem', marginTop: '1.5rem', }} >{this.state.errorMessage}</p>

                                <input type="text" placeholder="Prénom" />
                                <input type="text" placeholder="Nom" />

                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <a style={{ marginRight: "2rem" }}>Passer</a>
                                    <div className="btn green-btn" style={{ padding: "1rem 3rem" }}>Suivant</div>
                                </div>

                            </div>
                        </form>
                        */}

                    <div className="cert-results" >

                        <div>
                            Validé le 01/01/2021
                        </div>
                        <div  >
                            Id : 10231239
                        </div>

                    </div>
                </div>
                <div style={{ fontSize: "1.3rem", marginTop: "1.3rem" }}>
                    https://energie-climat.info
                </div>

            </>
        )
    }
}
