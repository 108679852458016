export const questions = [{
    id: "la-transition-energetique",
    text: "Au rythme actuel notre « budget carbone », compatible avec un réchauffement inférieur à 2 °C, sera épuisé en : ",
    type: "Single",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "5 ans",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "15 ans",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "30 ans",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "80 ans",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Pour préserver le climat, à quoi mesure-t-on le succès de la transition énergétique ?",
    type: "Single",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [
        {
            text: "L'augmentation de la quantité d'énergie issue de sources renouvelables",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "La baisse de la quantité d'électricité d'origine nucléaire",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "La baisse de la consommation d'énergies fossiles",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Les trois au-dessus",
            isCorrect: false,
            isSelected: false
        },
    ]
},
{
    text: "En 2019, les énergies fossiles couvrent :",
    type: "Single",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "32% des besoins en énergie",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "58% des besoins en énergie",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "70% des besoins en énergie",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "80% des besoins en énergie",
        isCorrect: true,
        isSelected: false
    }
    ]
},
{
    text: "Quel mode de production d'électricité a connu la plus forte croissance entre 2008 et 2018 ?",
    type: "Single",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [
        {
            text: "Le charbon",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "La biomasse",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Les énergies renouvelables",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Le nucléaire",
            isCorrect: false,
            isSelected: false
        }
    ]
}
]