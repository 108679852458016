import React, { Component } from 'react'
import { FcGlobe, FcBarChart, FcApproval, FcIdea, FcCollect, FcShare } from "react-icons/fc";

export default class Benefit extends Component {

    state = {
        benefits: [{
            icon: <FcIdea />,
            title: "Faites-vous votre avis",
            text: "Appropriez-vous les leviers et les contraintes pour construire vos propres approches."
        },
        {
            icon: <FcApproval />,
            title: "Validez vos connaissances",
            text: "Répondez aux quiz relatifs à chaque chapitre pour valider la maîtrise des points clés."
        },
        {
            icon: <FcCollect />,
            title: "Allez à l'essentiel",
            text: "Nous avons lu les rapports, étudié les données et condensé des milliers d'heures de travail en 11 articles allant droit au but."
        },
        {
            icon: <FcShare />,
            title: "Passez le message",
            text: "Partagez vos résultats autour de vous pour créer des communautés de personnes éduquées quant aux enjeux énergie-climat."
        }
        ]
    }

    render() {
        return (
            <div class="benefit-container">
                {
                    this.state.benefits.map((item, index) => {

                        return <section key={index} className="benefit" >
                            <div className="icon u-center" >
                                {item.icon}
                            </div >
                            <div>
                                <div className="benefit-title">
                                    <h3>{item.title}</h3>
                                </div>
                                <div className="text" >
                                    {item.text}
                                </div>
                            </div>


                        </section>

                    })
                }
            </div>
        )
    }
}


