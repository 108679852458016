import React, { Component } from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class cgu extends Component {

    state = {
        isLoading: true,
        themesSection: React.createRef()
    }

    constructor(props) {
        super(props);
    }

    handleScrollToCurriculums = () => {
        // window.scrollTo({ behavior: 'smooth', top: this.state.themesSection.current.offsetTop - 30 });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    render() {

        return <>

            <Helmet>
                <title>Conditions générales d'utilisation</title>
                <meta name="description" content="Conditions générales d'utilisation" />
            </Helmet>


            <Preloader isLoading={this.state.isLoading} />

            <Navbar />

            <article className="article-container" >

                <div class="curriculum-header">

                    <h1>Conditions générales d'utilisation</h1>

                    <div class="article-content">
                        <h2>Définitions</h2>
                        <p>Les Parties conviennent et acceptent que les termes suivants utilisés avec une majuscule, au singulier et/ou au pluriel, auront, dans le cadre des présentes Conditions Générales d'Utilisation, la signification définie ci-après :</p>
                        <ul>
                            <li><strong>« Contrat »</strong> : désigne les présentes Conditions Générales d'Utilisation ainsi que la Politique de protection des données personnelles ;</li>
                            <li><strong>« Membre »</strong> : désigne le membre ayant un compte sur notre Plateforme pour accéder aux fonctionnalités gratuites de notre Plateforme ;</li>
                            <li><strong>« Plateforme »</strong> : plateforme numérique de type site Web et/ou application mobile permettant l'accès au Service ainsi que son utilisation ;</li>
                            <li><strong>« Utilisateur »</strong> : désigne toute personne qui utilise la Plateforme, qu'elle soit un Visiteur ou un Membre ;</li>
                            <li><strong>« Visiteur »</strong> : désigne toute personne, internaute, naviguant sur la Plateforme sans création de compte associé.</li>
                        </ul>

                        <p>Les présentes Conditions Générales d'Utilisation (ci-après les "CGU") régissent nos rapports avec vous, personne accédant à la Plateforme, applicables durant votre utilisation de la Plateforme et, si vous êtes un Membre jusqu'à désactivation de votre compte. Si vous n'êtes pas d'accord avec les termes des CGU il vous est vivement recommandé de ne pas utiliser notre Plateforme et nos services.</p>
                        <p>En naviguant sur la Plateforme, si vous êtes un Visiteur, vous reconnaissez avoir pris connaissance et accepté l'intégralité des présentes CGU et notre Politique de protection des données personnelles.</p>
                        <p>En créant un compte en cliquant sur le bouton « S'inscrire avec Facebook » ou « Inscription avec un email » ou « S'inscrire avec Google » pour devenir Membre, vous êtes invité à lire et accepter les présentes CGU et la Politique de protection des données personnelles.</p>
                        <p>Nous vous encourageons à consulter les « Conditions Générales d'Utilisation et la Politique de protection des données personnelles » avant votre première utilisation de notre Plateforme et régulièrement lors de leurs mises à jour. Nous pouvons en effet être amenés à modifier les présentes CGU. Si des modifications sont apportées, nous vous informerons par email ou via notre Plateforme pour vous permettre d'examiner les modifications avant qu'elles ne prennent effet. Si vous continuez à utiliser notre Plateforme après la publication ou l'envoi d'un avis concernant les modifications apportées aux présentes conditions, cela signifie que vous acceptez les mises à jour. Les CGU qui vous seront opposables seront celles en vigueur lors de votre utilisation de la Plateforme.</p>

                        <h2>Article 1. Les mentions légales</h2>
                        <p>Adresse email : hello@energie-climat.org</p>
                        <p>L'hébergeur du site energie-climat.info est la société française OVH, dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix – France.</p>

                        <h2>Article 2. Inscription au service</h2>
                        <h3>2.1 Conditions d’accès la plateforme</h3>

                        <p>Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.</p>

                        <h3>2.2 Conditions d'inscription à la Plateforme</h3>

                        <p>Certaines fonctionnalités de la Plateforme nécessitent d'être inscrit et d'obtenir un compte. Avant de pouvoir vous inscrire sur la Plateforme vous devez avoir lu et accepté les présentes CGU et <Link to="/data-privacy" target="_blank">la Politique de protection des données personnelles</Link>.</p>

                        <p>Vous déclarez avoir la capacité d'accepter les présentes conditions générales d'utilisation, c'est-à-dire avoir plus de 16 ans et ne pas faire l'objet d'une mesure de protection juridique des majeurs (mise sous sauvegarde de justice, sous tutelle ou sous curatelle).</p>

                        <p>Avant d'accéder à notre Plateforme, le consentement des mineurs de moins de 16 ans doit être donné par le titulaire de l'autorité parentale.</p>

                        <p>Notre Plateforme ne prévoit aucunement l'inscription, la collecte ou le stockage de renseignement relatifs à toute personne âgée de 15 ans ou moins.</p>

                        <h3>2.3 Création de compte</h3>
                        <p>Vous pourrez créer un compte des deux manières suivantes :</p>
                        <ul>
                            <li>Soit remplir manuellement, sur notre Plateforme, les champs obligatoires figurant sur le formulaire d'inscription, à l'aide d'informations complètes et exactes. Pour enregistrer votre compte, il vous faudra soumettre à energie-climat.info certaines informations à caractère personnel telles que votre nom, prénom et votre adresse email. Vous trouverez le descriptif du traitement de vos données dans notre Politique de protection des données personnelles ;</li>
                            <li>Soit en utilisant un compte existant (notamment via Google ou Facebook), en utilisant la fonction prévue à cet effet. En utilisant une telle fonctionnalité, nous aurons accès, publierons sur notre Plateforme et conserverons certaines informations de votre compte Facebook ou Google. Vous pouvez à tout moment supprimer le lien entre votre compte sur la Plateforme energie-climat.info et votre compte Google ou Facebook par l'intermédiaire des réglages de votre profil Google ou Facebook. Si vous souhaitez en savoir plus sur l'utilisation de vos données dans le cadre de votre compte Facebook ou Google, consultez notre Politique de protection des données personnelles et celles de Facebook et Google.</li>
                        </ul>

                        <p>En cas d'inscription par email, vous vous engagez à garder secret le mot de passe choisi lors de la création de votre compte et à ne le communiquer à personne. En cas de perte ou divulgation de votre mot de passe, vous vous engagez à en informer sans délai energie-climat.info. Vous êtes seul responsable de l'utilisation faite de votre compte par un tiers, tant que vous n'avez pas expressément notifié energie-climat.info de la perte, l'utilisation frauduleuse ou la divulgation de votre mot de passe à un tiers.</p>

                        <p>Vous vous engagez à ne pas créer ou utiliser, sous votre propre identité ou celle d'un tiers, d'autres comptes que celui initialement créé. Vous ne pouvez pas autoriser des tiers à utiliser votre compte. Vous ne pouvez pas céder ou, quoi qu'il en soit, transférer votre compte à toute autre personne ou entité.</p>

                        <p>Les informations que vous avez fournies pendant l'inscription peuvent être corrigées pendant le processus d'inscription en revenant aux écrans précédents et en corrigeant les informations erronées. Vous acceptez de respecter les lois applicables lorsque vous utilisez les services, et vous ne pouvez utiliser les services qu'à des fins légales. Le contenu présent sur la Plateforme doit uniquement être utilisé pour un usage privé.</p>

                        <p>Lorsque vous choisissez votre mot de passe, vous ne devez pas utiliser de mot de passe simpliste (par exemple : 123456).
                            L'identifiant et le mot de passe seront strictement personnels et confidentiels et vous devrez les conserver et les utiliser de manière à en préserver la stricte confidentialité.</p>

                        <p>Le Membre sera seul autorisé à accéder au Service à l'aide de son identifiant et son mot de passe. Toute utilisation de la Plateforme au moyen de ses identifiants et mot de passe est réputée avoir été faite par le Membre lui-même. En cas d'utilisation par un tiers de ses identifiant et mot de passe, le Membre devra en avertir immédiatement energie-climat.info en envoyant un message <Link to="/nous-contacter">via le formulaire de contact</Link>.</p>

                        <p>Le Membre qui a perdu son mot de passe devra se connecter sur le site d'energie-climat.info et suivre la procédure en cliquant sur le lien  <Link to="/forgot-password">mot de passe oublié</Link>.</p>

                        <p>Le Membre est responsable de l'utilisation de la Plateforme et de toutes les actions réalisées au sein de la Plateforme avec son identifiant et son mot de passe, sauf si l'utilisation de son compte a été faite après sa désinscription, ou après notification à energie-climat.info d'une utilisation abusive de son compte.</p>

                        <h3>2.4. Conditions d'accès à la Plateforme</h3>
                        <h4>2.4.1 Si vous n'avez pas de compte Membre</h4>

                        <p>Si vous n'avez pas créé de compte Membre sur notre Plateforme, vous pouvez tout de même accéder à la Plateforme mais vous ne pourrez pas bénéficier de toutes les fonctionnalités et vous n'aurez pas de profil personnel. En effet certains accès ne vous seront pas autorisés, comme les quiz de fin d'article. Vous n'aurez pas de compte personnel. Les présentes CGU vous sont opposables car elles régissent les conditions d'utilisation du Service, vous devez en prendre connaissance et les accepter avant de poursuivre votre navigation sur la Plateforme.</p>

                        <h4>2.4.2 Si vous avez déjà un compte membre</h4>

                        <p>Le site ne nécessite pas de paiement.</p>

                        <p>Si vous avez créé un compte Membre vous pouvez accéder à la Plateforme et à toutes les fonctionnalités du site. Suite à la création de votre compte, vous pourrez accéder à votre page de profil où vous renseignez certaines mentions qui sont obligatoires et d'autres qui sont optionnelles.</p>
                        <p>Vous avez également accès aux quiz à la fin des articles. Avant d'utiliser la Plateforme, vous devrez lire attentivement les présentes Conditions Générales d'Utilisation.</p>

                        <h2>Article 3. Liens hypertextes</h2>

                        <p>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site energie-climat.info. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>

                        <h2>Article 4. Cookies</h2>

                        <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.</p>

                        <p>Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site energie-climat.info. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent.</p>

                        <p>L’information contenue dans les cookies est utilisée pour améliorer le site energie-climat.info. En naviguant sur le site, L’Utilisateur les accepte.</p>

                        <p>L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies.
                            A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.</p>

                        <p>L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>

                        <h2>Article 5. Informations fournies par l'Utilisateur</h2>

                        <p>energie-climat.info ne pourra en aucun cas être tenue responsable des erreurs, omissions, imprécisions pouvant être relevées dans les informations que vous nous avez fournies, ni du préjudice pouvant éventuellement en découler pour les autres Utilisateurs ou pour des tiers.</p>

                        <p>Vous êtes responsable de toute l'activité qui se déroule sur votre compte, et vous acceptez de préserver à tout moment la sécurité et le secret de votre identifiant et de votre mot de passe. Vous ne pouvez posséder qu'un seul compte.</p>

                        <h2>Article 6. Propriété intellectuelle</h2>

                        <h3>6.1. Propriété intellectuelle afférente au Service, à notre Plateforme et aux éléments qui les composent</h3>

                        <p>L'ensemble des éléments techniques, graphiques, textuels ou autres constituant le Service et/ou notre Plateforme (textes, graphismes, logiciels, fichiers multimédias, photographies, images, vidéos, sons, plans, charte graphique, technologie(s), codes sources, noms, marques, logos, visuels, bases de données, etc.) ainsi que notre Plateforme et le Service eux-mêmes sont la propriété exclusive d’energie-climat.info ou des ayant-droits dûment référencés dans la section <Link to="/credits">crédits</Link>.</p>

                        <p>Sauf mention contraire, le contenu est soumis à Creative Commons suivantes icence CC BY-NC dont les règles sont détaillées à l'adresse <Link to="https://creativecommons.org/licenses/by-nc/2.0/fr/">suivante</Link>.</p>

                        <p>L'Utilisateur qui souhaite reproduire tout ou partie d'un article doit impérativement vérifier que la licence CC BY-NC le permet.
                            Pas d’Utilisation Commerciale — Sauf accord d’energie-climat.info, L'Utilisateur n’est pas autorisé à faire un usage commercial de cette Oeuvre, tout ou partie du matériel la composant.</p>

                        <h2>Article 7. Protection des données personnelles</h2>
                        <p>Nous portons une attention particulière à la protection de vos données personnelles. Vous trouverez ici le descriptif détaillé des utilisations que nous faisons de vos données personnelles et des cookies et leurs finalités.</p>
                        <p>Energie-climat.org invite expressément l'Utilisateur à consulter sa Politique de protection des données personnelles qui fait partie intégrante des présentes CGU.</p>

                        <h2>Article 8. Obligations des Utilisateurs et Charte de Bonne Conduite</h2>

                        <h3>8.1. Obligations des Utilisateurs</h3>
                        <p>Dans le cadre de l'utilisation de la Plateforme, nous vous demandons de vous engager à :</p>
                        <ul>
                            <li>Garantir le bon usage de la Plateforme ;</li>
                            <li>Ne créer qu'un seul compte au sein de la Plateforme ;</li>
                            <li>Ne pas chercher à altérer ou perturber l'intégrité de la Plateforme et/ou des données qui y sont contenues ;</li>
                            <li>Ne pas tenter d'obtenir un accès non autorisé à la Plateforme ou aux systèmes ou réseaux qui lui sont associés ou d'intercepter des données ;</li>
                            <li>Utiliser la Plateforme dans le respect des législations et réglementations nationales et/ou internationales applicables.</li>
                        </ul>

                        <h2>Article 9. Interruption du service pour maintenance ou amélioration</h2>

                        <p>Nous ne sommes tenus à aucune obligation, ni de moyens ni de résultats, concernant la continuité de l'accès au Service. Nous ne pouvons garantir la pérennité ou les performances de la Plateforme.</p>
                        <p>En cas de mise à jour de la Plateforme pour une évolution technique, notre Plateforme sera inaccessible temporairement.
                            L'accès au Service pourra être interrompu pour des raisons notamment de maintenance, de mise à jour et en cas d'urgence. Plus généralement, l'interruption du Service quel que soit la cause, la durée ou la fréquence, n'engagera pas notre responsabilité.</p>

                        <p>Des "bugs" sur notre Plateforme ou des erreurs de manipulations peuvent vous faire perdre certaines de vos données, ou les altérer, sans que cela nengage notre responsabilité. S’agissant d’un service gratuit administré par des bénévoles, nous ne pouvons prendre aucun engagement pour les restaurer et à vous garantir un accès à la Plateforme.</p>

                        <h2>Article 10. Droits sur le compte Utilisateur</h2>

                        <h3>10.1. Par un Membre</h3>

                        <p>ous les Membres de notre Plateforme disposent d'un droit d'accès, de modification ou de suppression de leur compte.
                            Les Utilisateurs ainsi que les Membres peuvent à tout moment modifier et obtenir un droit d'accès à leur compte personnel en nous contactant via <Link to="/nous-contacter">le formulaire de contact</Link> dédié. </p>

                        <p>Si vous souhaitez vous désinscrire de notre Plateforme, veuillez nous le mentionner via <Link to="/nous-contacter">le formulaire de contact</Link>. Votre demande de suppression de compte entraînera la suppression de vos données personnelles de nos bases de données. La désinscription entraînera la résiliation du Contrat. Cette résiliation prendra effet dans un délai de trente (30) jours ouvrés à compter de la réception de la demande de désinscription par energie-climat.info.
                        </p>

                        <h3>10.2. Par energie-climat.info</h3>

                        <p>energie-climat.info se réserve le droit, à tout moment et sans préavis de désactiver ou supprimer votre compte pour une durée temporaire ou définitive.</p>

                        <p>energie-climat.info se réserve le droit, à tout moment et sans préavis de mettre fin au service fourni par la Plateforme.</p>

                    </div>

                    <div class="article-separator"></div>
                </div>

            </article>

            <Footer />

        </>
    }
}

