import React, { Component } from 'react'
import { curriculums } from '../data/Curriculums'
import { FcClock } from "react-icons/fc";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import CurriculumDetailsThumb from './CurriculumDetailsThumb';

import Navbar from './Navbar'
import Footer from './Footer'
import Preloader from './Preloader'

import firebase, { firebaseAuth, firebaseProvider } from '../firebase.js';
import Checked from './Checked';
import Certification from './Certification';



export default class ArticleContent extends Component {

    state = {
        isLoading: false,
        isModalDisplayed: false,
        navbarClassName: 'navbar-faded-in'
    }

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.articleId != this.props.articleId) {
            this.setState({
                isLoading: true,
            });
        }

        return true;
    }

    goToNextArticle = () => {

        this.props.history.push('/')
    }

    displayModal = () => {

        this.setState(
            {
                isModalDisplayed: true
            }
        )
    }

    closeModal = () => {

        this.setState(
            {
                isModalDisplayed: false
            }
        )
    }

    componentDidMount() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
        let totalCompleted = 0;

        if (storedUSerInfo && storedUSerInfo.user) {

            var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/overview');

            if (overview) {

                overview.on('value', (snapshot) => {

                    let thisCurriculum = this.state.thisCurriculum;

                    const data = snapshot.val();

                    if (data && data.results && data.results.length) {

                        let i;
                        for (i = 0; i < data.results.length; i++) {

                            let articleIndex;

                            let goThroughQuestions = curriculums[0].articles.map((element, j) => {

                                if (data && data.results && data.results[i] && data.results[i].isPassed && element.url === data.results[i].articleId) {
                                    articleIndex = j;
                                }
                            });

                            if (articleIndex >= 0) {
                                totalCompleted += 1;
                            }
                        }
                    }

                    this.setState({
                        completion: ((totalCompleted / 12) * 100).toFixed(0),
                        isAuthenticated: true
                    });
                });
            }
        }

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    componentDidUpdate(prevProps) {

        if (this.props.articleId !== prevProps.articleId) {

            window.scrollTo(0, 0);

            setTimeout(() => {
                this.setState({
                    isLoading: false
                })
            }, 300);
        }
    }

    render() {

        let currentArticle = curriculums[0].articles[0];
        let articleIndex = 0;

        curriculums.map((item, index) => {
            if (item.articles) {
                item.articles.map((item, index) => {
                    if (this.props.articleId == item.url) {
                        currentArticle = item;
                        articleIndex = index;
                    }
                });
            }
        });

        let nextArticle;
        let nextArticleUrl;
        let imageUrl = currentArticle.imageHeader;


        if (articleIndex < curriculums[0].articles.length - 1) {
            nextArticle = curriculums[0].articles[articleIndex + 1];
            nextArticleUrl = '/' + nextArticle.url;
        }

        document.title = currentArticle.title;

        return (
            <>

                <Preloader isLoading={this.state.isLoading} />

                <Navbar />

                <Helmet>
                    <title>{currentArticle.title}</title>
                    <meta name="description" content={currentArticle.description} />
                    <meta name="description" content={currentArticle.description} />
                    <meta property="og:title" content={currentArticle.title} />
                    <meta property="og:type" content="article" />
                    <meta property="og:description" content={currentArticle.description} />
                    <meta property="og:image" content={imageUrl} />

                </Helmet>

                {!this.state.isLoading &&
                    <>
                        <article className="article-container" >

                            <div class="article-header">
                                {currentArticle.imageHeader && <div class="article-header-image">
                                    <img src={currentArticle.imageHeader}></img>
                                </div>}


                                <div className="subtitle">{currentArticle.subtitle}</div>
                                <h1>{currentArticle.title}</h1>
                                <div class="description-footer footer-article">
                                    <FcClock className="cur-description-icon" /> <div class="cur-icon-text">{currentArticle.readTime}</div>
                                </div>

                            </div>

                            <div class="article-content">
                                {currentArticle.content}
                            </div>

                            <div class="article-separator"></div>

                        </article>

                        <Footer />
                    </>
                }

            </>
        )
    }
}
