import React, { Component } from 'react'
import Smokes from '../images/curriculums/smokes.jpg'
import { FcReading, FcClock } from "react-icons/fc";
import CurriculumDetailsThumb from './CurriculumDetailsThumb'
import { simulators } from '../data/Simulators'
import CoalGame from '../components/CoalGame'

import Navbar from './Navbar'
import Footer from './Footer'
import Preloader from './Preloader';

export default class SimulatorContainer extends Component {

    state = {
        isLoading: false,
        navbarClassName: 'navbar-faded-in'
    }

    constructor(props) {
        super(props);
    }


    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.simulatorId != this.props.simulatorId) {
            this.setState({
                isLoading: true,
            });
        }

        return true;
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    render() {

        let currentSimulator = simulators[0];

        simulators.map((item, index) => {

            if (this.props.simulatorId == item.url) {
                currentSimulator = item;
            }

        });

        return (
            <>

                <Preloader isLoading={this.state.isLoading} />

                <Navbar />

                {!this.state.isLoading &&
                    <>
                        <div className="curriculum-header-image">
                            <img src={currentSimulator.image}></img>
                        </div>


                        <section className="curriculum-header">

                            <h1>{currentSimulator.title}</h1>
                            <p>{currentSimulator.description}</p>

                        </section>

                        <section className="simulator-list-section">

                            {currentSimulator.content}


                        </section>

                        <Footer />
                    </>
                }

            </>
        )
    }
}
