import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import CoalGame from '../../components/CoalGame'

import energiesRenouvelablesContreCharbon from './images/energie-renouvelables-contre-charbon.jpg'
import FacteurDeChargeEolien from './images/facteur-de-charge-eolien.jpg'
import FacteurDeChargeEolienMobile from './images/facteur-de-charge-eolien-MOB.jpg'
import FacteurDeChargeSolaire from './images/facteur-de-charge-solaire.jpg'
import FacteurDeChargeSolaireMobile from './images/facteur-de-charge-solaire-MOB.jpg'
import EolienSolaire from './images/fonctionnement-eolien-et-solaire.jpg'
import intensiteRenouvelables from './images/intensite-renouvelables.jpg'
import intensiteRenouvelablesMob from './images/intensite-renouvelables-MOB.jpg'

import Hydro from './images/hydroelectricite-contre-charbon.jpg'
import Intermittence from './images/intermittence-eolien-et-solaire.jpg'
import PuissanceInstallee from './images/puissance-installee.jpg'
import PuissanceInstalleeMobile from './images/puissance-installee-MOB.jpg'

function EnergiesRenouvelables() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/les-energies-renouvelables");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>En 2021, la principale source d’électricité d’origine renouvelable reste de très loin l’hydroélectricité. Ouvrir les vannes d’un barrage permet d’injecter très rapidement, en moins d’une dizaine de minutes, de l’électricité sur le réseau. Cette flexibilité est précieuse pour absorber des pics de demande ou compenser des défaillances brutales.</p>

                    <p>Ce caractère pilotable vaut uniquement pour les installations qui disposent d’un réservoir. Les centrales hydroélectriques dites «&#8239;au fil de l’eau&#8239;» dépendent en effet du débit du cours d'eau sur lesquelles elles sont installées.</p>

                    <p>Certes, les barrages produisent de l'énergie renouvelable, mais ils n’échappent pas pour autant à un bilan écologique et humain parfois très lourd. Le remplissage du lac de retenue peut noyer sous l’eau des vallées entières avec des impacts considérables sur la biodiversité ou les populations locales. La construction du barrage des Trois-Gorges, en Chine, a conduit à déplacer entre 1,2 et 1,8&nbsp;million de personnes, et a engendré des répercussions majeures sur les écosystèmes dans la région.</p>

                    <p>Même faible, le risque d'accident majeur reste toujours présent. Événement tragique méconnu, la rupture du barrage de Banqiao, en Chine, durant le mois d’août 1975, a occasionné la mort de plus de 170&#8239;000 personnes, ce qui en fait la catastrophe industrielle la plus mortelle de l'histoire.<Reference index="1" content="<em>People's Daily Online</em>, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'> After 30 years, secrets, lessons of China's worst dams burst accident surface</a>, octobre 2005." /> Plus récemment, en 2018, l'effondrement d’un barrage en construction au Laos a balayé au moins 7 villages, tuant plus d’un millier de personnes.<Reference index="2" content="<em>France 24</em>, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'> Effondrement d'un barrage au Laos : des centaines de disparus</a>, 24 juillet 2019." /></p>

                    <p>Dans certains cas, et en particulier dans les zones tropicales, le pourrissement de matières organiques piégées dans l’eau stagnante des lacs de retenue peut dégager d'importantes quantités de méthane, un puissant gaz à effet de serre. Ce phénomène est pris en compte dans le calcul de l'impact climatique des barrages qui, malgré les contraintes évoquées, constituent une solution éprouvée pour produire une énergie décarbonée pilotable.</p>

                    <div className="image-source">
                        <img src={intensiteRenouvelables} style={{ maxWidth: '57rem' }} className="img-desktop" />
                        <img src={intensiteRenouvelablesMob} style={{ maxWidth: '38rem' }} className="img-mobile" />
                    </div>

                    <p>Cependant, consécutivement à une forte expansion à partir de la deuxième moitié du XXe siècle, les emplacements susceptibles d'accueillir de nouveaux grands ouvrages commencent à se raréfier. Même en multipliant partout les microcentrales hydrauliques, le potentiel de l’hydroélectricité reste donc topographiquement limité.</p>

                    {/* 
                    <p><CoalGame displayedItems={["hydro"]} source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="3" content="La puissance unitaire de référence est celle d'une turbine hydraulique de taille moyenne, soit 70 MW. Le facteur de charge — la puissance fournie par rapport à la puissance installée — retenu est ici de 35%.<br/><br/>
                        L'intensité carbone provient des données du GIEC. Elle se comprend en « grammes équivalent CO<sub>2</sub>» qui, dans le cas des barrages comprend non seulement les émissions liées à la construction mais également les dégagement de méthane liés à la dégradation de matières organiques dans les lacs de retenue.<br/><br/>   
                        
                        RTE, <a target='_blank' href='https://www.rte-france.com/sites/default/files/be_pdf_2018v3.pdf'>Bilan électrique 2018</a><br/><br/>

                        GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br /> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />
                    </p>
                    */}

                    <h2>Les nouvelles énergies renouvelables</h2>

                    <p>Les technologies de panneaux solaires et d’éoliennes ont connu une accélération à la fin des années 1990, et un vrai déploiement commercial à partir des années&nbsp;2000. Malgré l'engouement pour ces technologies «&nbsp;vertes&nbsp;», répondre à la demande électrique en temps réel avec de l'électricité produite de manière variable reste toujours un défi majeur.</p>

                    <p>Mesurer la production d'une éolienne, en prenant bien en compte ses variations, demande d'étudier un indicateur fondamental pour appréhender les problématiques énergétiques&#8239;: le facteur de charge.</p>

                    <p>Celui-ci donne le taux d'utilisation d'une installation électrique, soit le rapport entre la production effective en conditions réelles et la production théorique maximale. Une centrale électrique qui fonctionnerait à pleine puissance en permanence présenterait un facteur de charge de 100&#8239;%. Dans la réalité, un tel résultat n'est jamais atteint. Toute installation est contrainte de s'arrêter pour effectuer des opérations de maintenance, ou parfois de diminuer sa production, car il n'y a tout smplement pas de demande suffisante&#8239;;&nbsp;autant d'éléments qui vont affecter le facteur de charge à la baisse.</p>

                    <p>Pour les énergies renouvelables, plus que les opérations de maintenance, de rechargement de combustible ou les appels sur le réseau, ce sont les variations du vent et de soleil qui vont être limitantes.</p>

                    <p>Sur une année, une éolienne terrestre présente un facteur de charge moyen de 23&#8239;%, ce qui signifie que celle-ci ne produit en réalité qu'un quart de ce qu'elle pourrait fournir à pleine puissance.
                        <Reference index="3" content="Nous nous basons ici sur les chiffres issus des productions <em>offshore</em> anglaises et danoises.<br/><br/> <em>Energy Numbers</em>, <a target='_blank' href='http://energynumbers.info/uk-offshore-wind-capacity-factors'>UK offshore wind capacity factors</a>, mars 2019 (en). <br/><em>Energy Numbers</em>, <a target='_blank' href='http://energynumbers.info/capacity-factors-at-danish-offshore-wind-farms'>Capacity factors at Danish offshore wind farms</a>, mars 2019 (en)." />
                    </p>

                    <div className="image-source">
                        <img src={FacteurDeChargeEolien} style={{ maxWidth: '58rem' }} className="img-desktop" />
                        <img src={FacteurDeChargeEolienMobile} style={{ maxWidth: '38rem' }} className="img-mobile" />
                    </div>

                    <p>Pour autant, cela ne signifie pas qu'une éolienne ne tourne que 25&#8239;% du temps. Elle peut tourner jusqu'à 80&#8239;% de son temps, mais à des vitesses variables, générant plus ou moins d'électricité. C'est bien le total de l'électricité produite au cours de toutes ces variations qui représente 25&#8239;% de sa capacité maximale de production.  </p>

                    <p>Le facteur de charge le plus élevé provient des éoliennes « offshore », installées en mer. Bénéficiant de conditions de vent particulièrement favorables, elles peuvent atteindre un facteur de charge record de l’ordre de 40%.</p>

                    <p>La même problématique s’applique pour le solaire à cause de l’alternance jour-nuit    et des variations de la couverture nuageuse, la «&#8239;nébulosité&#8239;». Le facteur de charge est ici compris entre 5 et 35&#8239;% avec, selon les pays, une variabilité saisonnière importante.
                        <Reference index="4" content="Le facteur de charge ne dépasse que très rarement les 20% en France. Seules les zones les plus ensoleillées comme l'Arizona atteignent les 35%.<br/><br/><em>RTE</em>, Bilan Électrique 2017, <a target='_blank' href='https://bilan-electrique-2017.rte-france.com/production/le-solaire/242-la-production-solaire/241esp-la-production-solaire-en-detail/244-facteur-de-charge-solaire-mensuel/'>Le facteur de charge solaire mensuel</a>. <br/><br/> <em>U.S. Energy Information Administration </em>, Electric Power Monthly, <a target='_blank' href='https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=epmt_6_07_b'>Capacity Factors for Utility Scale Generators Primarily Using Non-Fossil Fuels</a><span>, Octobre 2019 (en).</span> " />
                    </p>

                    <div className="image-source">
                        <img src={FacteurDeChargeSolaire} style={{ maxWidth: '58rem' }} className="img-desktop" />
                        <img src={FacteurDeChargeSolaireMobile} style={{ maxWidth: '38rem' }} className="img-mobile" />
                    </div>

                    <p>Notons également que, de par leurs procédés de fabrication, les panneaux solaires ont un bilan carbone 4 à 5 fois plus important que celui des éoliennes. En outre, ceux-ci requièrent significativement plus de place au sol.</p>

                    <p>Plus une source est intermittente, plus il est nécessaire d'installer de la puissance pour compenser, de manière à rendre les plages de fonctionnement les plus productives possibles. Ainsi, produire autant avec des éoliennes terrestres qu'une centrale thermique nécessiterait d'implanter 3,5 fois plus de puissance et pour des panneaux solaires, ce serait 6 fois plus.</p>

                    <div className="image-source">
                        <img src={PuissanceInstallee} style={{ maxWidth: '58rem' }} className="img-desktop" />
                        <img src={PuissanceInstalleeMobile} style={{ maxWidth: '38rem' }} className="img-mobile" />
                    </div>

                    <p> Pour autant, la production reste concentrée sur des plages spécifiques, et celle-ci ne correspond pas nécessairement aux variations de la demande. Dès lors, les nouvelles énergies renouvelables se montrent bien incapables de remplacer totalement une source d’énergie pilotable comme une centrale à charbon. Tout au plus permettent-elles de diminuer en partie leur usage, autrement dit&nbsp;: leur facteur de charge.</p>

                    {/* 
                    <p>En fin de compte, de combien peut-on espérer réduire les émissions de CO<sub>2</sub> ? La réponse va fortement dépendre de la puissance installée et de la zone géographique. Mais pour se faire une idée, voici les résultats théoriques obtenus pour les conditions météorologiques équivalentes à celles de la France métropolitaine sur l’année&nbsp;2019.</p>

                    <p>
                        <CoalGame displayedItems={["solar", "wind"]} source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="7" content="Nous prenons ici comme référence des éoliennes de 3MW et des panneaux solaires standards de surface 1,70m², avec une puissance de 300 Watts-Crête, soit 0,3 kWc.  <br/><br/>
                    La production éolienne et solaire provient des données détaillées, heure par heure, fournies par RTE pour l'année 2019. <br/><br/>

                    RTE, <a target='_blank' href='https://www.services-rte.com/fr/telechargez-les-donnees-publiees-par-rte.html?category=generation&type=actual_generations_per_production_type'>Production : Production réalisée aggrégée par filières</a>, 2019
                    <br/><br/>

                    GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br/> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />
                    </p>
                      */}

                    {/* 
                    <p>Dans les faits, même les jours exceptionnellement favorables, où la production éolienne dépasse la demande à l'échelle d'un pays, on n'observe pas de situation où les énergies renouvelables sont les seules à produire de l'électricité. Car les centrales pilotables de backup —&nbsp;gaz ou charbon&nbsp;— ne sont pas totalement arrêtées. Les opérateurs continuent à les faire tourner, même à faible régime, pour être prêt à injecter de l'électricité en cas de variation soudaine, mais surtout pour éviter d’assumer le coût d’un arrêt complet suivi d’un redémarrage.</p>
                    */}
                    <p>L'électricité produite par des éoliennes doit être consommée sur le moment, faute de quoi elle se voit perdue. Idem pour les panneaux solaires ou les barrages au fil de l'eau. On parle à ce titre d’énergie «&#8239;fatale&#8239;». Quand les sources d'énergie fatale commencent à atteindre une part critique dans la production d'électricité, il arrive de plus en plus fréquemment que l'offre dépasse la demande. Faute d'acheteurs en nombre suffisant, l'électricité produite peut dès lors perdre toute valeur économique, au point que les prix peuvent devenir nuls, voire négatifs. Ce sont alors les producteurs qui paient les consommateurs, essentiellement les sites de stockage, les pays voisins ou l'industrie lourde, capables d'évacuer une production excessive.</p>

                    <p>Toute utilisation à grande échelle d'énergies intermittentes doit ainsi s'accompagner d'un effort important dans la flexibilisation du réseau, que ce soit dans la régulation de la production ou celle de la demande.</p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                            <a href="#0">Renouvelables</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="energies-renouvelables" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="4. Stocker l'énergie" url="/stocker-energie"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default EnergiesRenouvelables

