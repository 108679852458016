export const questions = [
    {
        id: "apres-petrole",
        text: "Le véhicule électrique :",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 2,
        answers: [
            {
                text: "Est zéro émission",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Permet une diminution des émissions de CO2 de 10 à 75% selon les contextes de production et d'usage",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est plus polluant qu'un véhicule thermique si l'on prend en compte sa construction",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Permet de réduire la pollution atmosphérique en ville",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "La compensation carbone :",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 2,
        answers: [{
            text: "Permet d'annuler l'impact de ses déplacements",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Est un outil qui doit être utilisé en complément des efforts de réduction des émissions",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Est une pure invention marketing",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "A des effets dans le temps très variables selon le type de projet financé",
            isCorrect: true,
            isSelected: false
        }
        ]
    },
    {
        text: "Les agrocarburants :",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 3,
        answers: [
            {
                text: "Peuvent réduire les émissions nettes de CO2 par rapport au kérosène",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Sont très consommateurs de terres agricoles",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Sont « drop-in » et peuvent être utilisés par les avions actuels",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Sont beaucoup moins chers que le kérosène",
                isCorrect: false,
                isSelected: false
            },

        ]
    },
    {
        text: "Quelle contrainte physique limite fortement le déploiement des solutions basées sur l'hydrogène « vert » ?",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Il occupe un volume trop importante",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Les bouteilles pèsent trop lourd",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "sa production consomme beaucoup d'énergie",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Son utilisation est trop dangereuse",
                isCorrect: false,
                isSelected: false
            }
        ]
    }
]