import React, { Component } from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class Credits extends Component {

    state = {
        isLoading: true,
        themesSection: React.createRef()
    }

    constructor(props) {
        super(props);
    }

    handleScrollToCurriculums = () => {
        // window.scrollTo({ behavior: 'smooth', top: this.state.themesSection.current.offsetTop - 30 });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    render() {

        return <>

            <Helmet>
                <title>Crédits</title>
                <meta name="description" content="Crédits" />
            </Helmet>


            <Preloader isLoading={this.state.isLoading} />

            <Navbar />

            <article className="article-container" >

                <div class="curriculum-header">

                    <h1>Crédits</h1>
                    <div class="article-content">
                        <h4>Accueil</h4>
                        <p>Photo by <a href="https://unsplash.com/@tobiastu?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tobias Tullius</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <p>Image credit Paul-Gilmore on Wunderstock (license)</p>
                        <h4>La double contrainte énergie-climat</h4>
                        <p>Photo by <a href="https://unsplash.com/@fraumuksch?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jennifer Latuperisa-Andresen</a> on <a href="https://unsplash.com/s/photos/greenland?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <p>Photo by <a href="https://unsplash.com/@redcharlie?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">redcharlie</a> on <a href="https://unsplash.com/@redcharlie?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <h4>La transition énergétique</h4>
                        <p>Photo by <a href="https://unsplash.com/@yarmaltsev?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Yaroslav Maltsev</a> on <a href="https://unsplash.com/s/photos/bishkek?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>

                        <h4>Capturer le carbone</h4>
                        <p>Photo by <a href="https://unsplash.com/@martinadams?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Martin Adams</a> on <a href="https://unsplash.com/@martinadams?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <h4>Les énergies renouvelables</h4>
                        <p><em>Photo by <a href="https://unsplash.com/@lucabravo?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank">Luca Bravo</a> on </em><a href="https://unsplash.com/s/photos/climate-change?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText" target="_blank"><em>Unsplash</em></a>
                        </p>
                        <h4>Stocker l'énergie</h4>
                        <p>Photo by <a href="https://unsplash.com/@kees_streefkerk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kees Streefkerk</a> on <a href="https://unsplash.com/@kees_streefkerk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>

                        <h4>Décarboner l'électricité</h4>
                        <p>Photo by <a href="https://unsplash.com/@thecreativv?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">The Creativv</a> on <a href="https://unsplash.com/s/photos/bulb?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <h4>L'après-pétrole</h4>
                        <p>Photo by <a href="https://unsplash.com/@ryanancill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ryan Ancill</a> on <a href="https://unsplash.com/@ryanancill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                        </p>
                        <h4>Les métaux</h4>
                        <p>West O'okiep Mine - extraction de 284,000 tonnes de cuivre © Dillon Marsh</p>
                        <h4>La descente énergétique</h4>
                        <p>Photo by <a href="https://unsplash.com/@myr0326?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">natsuki</a> on <a href="https://unsplash.com/@myr0326?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
                        <h4>Énergie et PIB</h4>
                        <p>Photo by <a href="https://unsplash.com/@dnevozhai?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Denys Nevozhai</a> on <a href="https://unsplash.com/@dnevozhai?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>

                    </div>
                    <div class="article-separator"></div>
                </div>

            </article>

            <Footer />

        </>
    }
}

