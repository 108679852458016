export const questions = [
    {
        id: "decarboner-electricite",
        text: "Comment certains États ou régions parviennent-ils aujourd'hui à produire une électricité 100% renouvelable ? ",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Grâce à leurs parcs éoliens",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Grâce à des centrales nucléaires",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Essentiellement grâce à leurs barrages hydroélectriques",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Grâce à une combinaison entre le solaire et l'éolien",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        text: "Aujourd'hui, comment l'intermittence des énergies solaires et éoliennes est-elle essentiellement compensée ?",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 2,
        answers: [
            {
                text: "Par l'importation d'électricité depuis les pays voisins",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Par du stockage d'énergie à grande échelle",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Par de l'effacement de consommation électrique",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Par le recours à des centrales à gaz ou à charbon",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "Quelle approche fut historiquement la plus rapide pour décarboner massivement la production électrique ?",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Le déploiement massif d'énergies renouvelables",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La construction de nouvelles centrales nucléaires",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Le lissage de la production électrique variable",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La flexibilisation de la demande en électricité",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        text: "Quels leviers doivent être utilisés conjointement pour accélérer autant que possible la transition énergétique ?",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 4,
        answers: [
            {
                text: "Le déploiement massif d'énergies renouvelables",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "La construction de nouvelles centrales nucléaires",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "La construction de nouvelles centrales électriques au gaz naturel",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Le lissage de la production électrique",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "La flexibilisation de la demande en électricité",
                isCorrect: true,
                isSelected: false
            }
        ]
    }
]