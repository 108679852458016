import React, { Component } from 'react'
import { GrLinkNext } from "react-icons/gr"
import { IconContext } from "react-icons"

import { Link } from 'react-router-dom'
import '../css/arrows.css'


export default class Checked extends Component {

    render() {
        return (

            <div className="arrow-icon" style={{}}>
                <span class="check"></span>
            </div>
        )
    }
}
