import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import gazContreCharbon from './images/gaz-contre-charbon.jpg'
import gazIntensiteCarbone from './images/intensite-gaz.jpg'
import gazIntensiteCarboneMob from './images/intensite-gaz-MOB.jpg'
import ThermiqueAFlamme from './images/fonctionnement-centrale-thermique.jpg'
import ThermiqueAFlammeMobile from './images/fonctionnement-centrale-thermique-MOB.jpg'
import CoalGame from '../../components/CoalGame'

function GazNaturel() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/gaz-naturel");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Au XIX<sup>e</sup> siècle, le «&#8239;gaz de ville&#8239;» alimentait les réseaux de chauffage et d'éclairage urbains. Le produire à partir de charbon reposait sur des «&#8239;usines à gaz&#8239;», généralement situées en banlieue, puis sa distribution était assurée par un réseau spécifique. Sa constitution s’appuyait en grande partie sur un mélange d'hydrogène et de monoxyde de carbone, un gaz inodore et particulièrement toxique.</p>

                    <p>C’est par opposition au gaz de ville, produit artificiellement, que l’on a défini le gaz d'origine fossile comme «&#8239;naturel&#8239;». Initialement considéré comme un coproduit inutile et dangereux de l'extraction pétrolière, ce n'est qu'au XX<sup>e</sup> siècle qu’il a commencé à être réellement valorisé. Son pouvoir énergétique se révèle plus élevé que celui du gaz de ville et, même s’il reste explosif, il ne présente pas de toxicité particulière.</p>

                    <p>Il ne doit surtout pas être confondu avec le biogaz, ou biométhane, qui est lui issu de la fermentation de matières organiques contenues dans les biodéchets, les résidus de récoltes ou les boues d'épuration d'eaux usées. Ce dernier appartient aux énergies de la biomasse, au bilan carbone incomparablement plus avantageux, mais dont l'usage, comparé à son équivalent fossile, reste tout à fait confidentiel.</p>

                    <p>Pour la production d'électricité, gaz et charbon partagent un mode de production d'énergie commun&#8239;: le thermique à flamme. Le combustible est brûlé dans une chaudière pour produire de la vapeur d'eau qui passe ensuite dans une turbine pour générer de l'électricité. L'intensité de ces deux sources de chaleur peut aisément être ajustée, ce qui les définit comme sources d'énergie pilotables, une caractéristique précieuse pour suivre la demande sur le réseau.</p>

                    <div className="image-source">
                        <img src={ThermiqueAFlamme} alt="Thermique à flamme" style={{ maxWidth: '55rem' }} className="img-desktop"></img>
                        <img src={ThermiqueAFlammeMobile} alt="Thermique à flamme" style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                    </div>

                    <p>Ce qui les distingue se situe essentiellement à trois niveaux&#8239;:</p>

                    <ul>
                        <li>Le charbon est un minerai abondant, solide et donc facile à transporter</li>
                        <li>Le gaz n’émet pas de suies et très peu de particules fines, il réduit donc très largement la pollution de l'air</li>
                        <li>Le gaz émet théoriquement 40% de CO<sub>2</sub> en moins, ce qui constitue un avantage loin d'être négligeable</li>
                    </ul>


                    <div className="image-source">

                        <img src={gazIntensiteCarboneMob} alt="Thermique à flamme" style={{ maxWidth: '37rem' }} ></img>
                        <div >GIEC<Reference index="1" content=" <em>GIEC</em>,  <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'> 2014: Climate Change 2014: Synthesis Report</a>, <br> Table A.III.2 | Emissions of selected electricity supply technologies (en). " /></div>
                    </div>

                    <p>Mais sa nature même pose un autre problème de taille. En effet, le gaz naturel est composé à 90&#8239;% de méthane, un gaz à effet de serre 25&nbsp;fois plus puissant que le CO<sub>2</sub> sur les 100 prochaines années. Or, au cours de son extraction ou de son transport, une partie peut fuir, auquel cas du méthane est relâché directement dans l'atmosphère.</p>

                    <p>Basées sur des mesures réalisées par satellite, plusieurs études récentes convergent sur le fait que les quantités rejetées dans l'atmosphère s’avèrent en réalité 25 à 40&#8239;% plus importantes que ce qui ressortait des estimations données par les exploitants gaziers. En attendant que des parades efficaces soient mises en place, l’ampleur de ce phénomène pourrait remettre en cause une grande partie des bénéfices escomptés pour le gaz naturel.
                        <Reference index="2" content="Hmiel, B., Petrenko, V.V., Dyonisius, M.N. et al. <em>Nature</em>, <a target='_blank' href='https://www.nature.com/articles/s41586-020-1991-8'>Preindustrial CH<sub>4</sub> indicates greater anthropogenic fossil CH<sub>4</sub> emissions.</a>, Nature 578, 409–412 (2020) (en)" />
                    </p>
                    {/* 
                    <p>En pratique, prenons une consommation continue de 1000 mégawatts (MW) d'électricité, correspondant à la consommation d'environ 600&#8239;000 foyers français, initialement couverte intégralement par des centrales à charbon. Dans quelle mesure le gaz naturel peut-il aider à faire baiser l'intensité carbone, voire à fermer complètement les centrales en place&#8239;? À vous de jouer ! </p>
                    */}

                    {/* 
                    <p>
                        <CoalGame displayedItems={["gas"]} source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="3" content="La référence pour les centrales à gaz est une tranche de gaz à cycle combiné de 465 MW, semblable à celles mises en service à la centrale de Martigues par EDF en 2012 et 2013.<br/><br/>
                        L'intensité carbone provient des données du GIEC.<br/><br/>
                        EDF, <a  target='_blank' href=' https://www.edf.fr/groupe-edf/producteur-industriel/carte-des-implantations/centrale-thermique-de-martigues/presentation'>La centrale thermique de Martigues</a>, 2019<br/><br/>
                        
                        GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br/> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />
                    </p>
                    */}

                    {/* 
                    <p>On remarquera que couvrir une demande continue de 1000 MW requiert de déployer au minimum 1200 MW de puissance installée. Ce rapport entre puissance installée et production réelle s'explique ici par le fait qu'une turbine à gaz ne fonctionne pas 100% du temps&#8239;; installer un surplus de puissance capable de prendre le relais en cas de maintenance ou de panne se révèle donc indispensable.</p>
                    */}

                    <p>Concrètement, vouloir passer du charbon au gaz implique de bâtir de nouvelles centrales, mais aussi de construire une chaîne d'approvisionnement spécifique. Se pose notamment la question d'investir dans le développement d'un réseau de gazoducs, de navires méthaniers et de terminaux portuaires adaptés. Ces infrastructures de transport demandent des milliards d'euros en investissement, et des années de travaux.</p>

                    <p>L'autre obstacle relève de la géopolitique. Car tout projet gazier repose sur quelques pays producteurs, en premier lieu la Russie, les États-Unis ou le Qatar, et, par la dépendance induite, soulève divers problèmes relatifs à la sécurité d’approvisionnement énergétique. La construction de gazoducs nécessite également de traverser des pays voisins, ce qui peut exacerber des tensions déjà existantes dans certaines régions. En pratique, il reste très difficile de renoncer à la souveraineté énergétique qu’accorde la consommation du charbon issu de son propre sous-sol.</p>

                    <p>Ouvertement négationniste sur la question climatique, et soutien indéfectible de l'industrie du charbon, le président Trump a vu, bien malgré lui, les émissions de CO<sub>2</sub> diminuer sous son mandat. Rien qu'en 2019, la consommation de charbon a plongé de 18&#8239;% aux États-Unis et les émissions du pays ont baissé de plus de 2&#8239;%. Cette performance s'explique essentiellement par la chute des prix du gaz, portée en particulier par l'exploitation de nouveaux gisements de schiste qui étaient autrefois interdits en raison des dégâts causés à l’environnement. Pour les pays qui disposent déjà d'infrastructures en place, consommer du gaz plutôt que du charbon reste une opération positive sur le plan climatique, en tout cas à court terme.</p>

                    <p>En revanche, investir aujourd’hui à long terme dans de nouvelles centrales à gaz, des infrastructures qui s'amortissent sur 40 ou 50&#8239;ans, et annoncer en parallèle des objectifs climatiques qui impliquent d’en mettre une grande partie à l’arrêt d’ici 10 à 20&nbsp;ans, pose un évident problème de cohérence.</p>

                    <p>Derrière son apparence moins nocive, et malgré la mise en avant de son aspect «&#8239;naturel&#8239;», le gaz n'en demeure pas moins une source très importante d'émissions de CO<sub>2</sub>. Cette nocivité déguisée lui vaut d'ailleurs d'être considéré  comme «&#8239;la cigarette light&#8239;» du climat.</p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="gaz-naturel" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="6. Capturer le carbone" url="/csc"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default GazNaturel

