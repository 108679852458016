import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'


import ResultModal from '../../components/ResultModal'

import ObjectifDeuxDegres from './images/objectif-2-degres-MOB.jpg'
import Carbone from './images/Carbone.jpg'
import IntensiteCarbone from './images/intensite-carbone.jpg'
import IntensiteCarboneMobile from './images/intensite-carbone-MOB.jpg'
import ConsommationEnergie from './images/consommation-energetique-actuelle.jpg'
import ConsommationEnergieMobile from './images/consommation-energetique-actuelle-MOB.jpg'
import PuissanceProduction from './images/Puissance-Production.jpg'



function TransitionStrategy() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/la-transition-energetique");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>La communauté scientifique appelle à ne pas dépasser les 1,5&#8239;°C de réchauffement, un seuil à partir duquel les effets sont considérés comme particulièrement dangereux, notamment par le franchissement de points de bascule irréversibles.</p>

                    <p>Ce seuil des 1,5&#8239;°C sera probablement franchi  avant 2030, plus tôt qu'anticipé par les prévisions, plutôt prudentes, des scientifiques du GIEC. Malgré ce probable échec, maintenir le réchauffement bien en deçà des 2&#8239;°C doit rester une priorité. Le GIEC estime que les émissions supplémentaires ne doivent pas dépasser 700&nbsp;milliards de tonnes de CO<sub>2</sub> si l’on souhaite conserver des chances raisonnables d'y parvenir. Au rythme actuel, d'environ 35 milliards de tonnes par an, ce budget carbone se verrait épuisé en une quinzaine d'années.</p>

                    <p>Pour atténuer le réchauffement climatique, nous disposons de deux approches complémentaires&#8239;:</p>

                    <ul >
                        <li>Pour l’essentiel, réduire purement et simplement les émissions. Cela constitue, de très loin, le gros de l’effort à fournir.</li>
                        <li>Pour le reste, compenser les émissions incompressibles en développant les «&#8239;puits de carbone&#8239;» : forêts, mangroves, terres cultivables ou autres écosystèmes naturels qui capturent du CO<sub>2</sub> pour leur développement.</li>
                    </ul>

                    <p>Alors que les niveaux d’émissions déclinent et que les mécanismes de capture augmentent, arrive le point où ces deux phénomènes s’équilibrent : la concentration en CO<sub>2</sub> de l’atmosphère se stabilise. Cet équilibre correspond à ce que l’on appelle la neutralité carbone. </p>

                    <div className="image-source">
                        <img src={ObjectifDeuxDegres} style={{ width: "40rem" }} ></img>
                    </div>

                    <p> Atténuer le réchauffement climatique, pour que nous puissions encore être en capacité de nous y adapter, requiert d'atteindre la neutralité carbone au milieu du XXIe siècle, soit une baisse des émissions d'au moins 90&#8239;% d’ici 2050.<Reference index="1" content="Calculs effectués à partir des résultats du GIEC et des émissions observées sur les dernières années.<br><br><em>GIEC</em>, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/SYR_AR5_FINAL_full_fr.pdf#page=27'>Cinquième rapport d'évaluation (AR5)</a>,<br> RID 2.1 Principaux facteurs déterminants du climat futur, 2014." />
                    </p>

                    <p>Il est important de préciser que la neutralité carbone est une notion qui ne peut être rigoureusement définie qu’à l’échelle de la planète ou au niveau d'un État si les émissions importées s'avèrent correctement comptabilisées. Mais dans un monde profondément interconnecté, entreprises, villes ou collectivités locales ne peuvent physiquement devenir neutres en carbone à leur seule échelle.</p>

                    <h2>Planter des arbres ne suffira pas à sauver le climat</h2>

                    <p>À l’heure actuelle, la tendance reste toujours nettement à la déforestation. Qu’ils soient causés ou non par l’homme, les feux de forêt libèrent du carbone stocké dans les arbres. Il s'agit d'un facteur significativement aggravant du réchauffement climatique qui représente environ 12&#8239;% des émissions de CO<sub>2</sub>.

                        <Reference index="2" content="Ces contributions des écosystèmes à la capture du carbone sont comptabilisées au sein d'un secteur appelé AFOLU <em>(Agriculture, Forestry and Other Land Use)</em>.<br/><br/><em>GIEC</em>, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/sites/2/2019/06/SR15_Full_Report_High_Res.pdf#page=28'>Special Report Global Warming of 1.5°C (SR1.5.)</a>, 2018." />

                    </p>

                    <p>L’effort porté sur la reforestation, l’afforestation, soit planter de nouvelles forêts, et généralement l’utilisation des sols constitue une part incontournable de la solution au problème climatique. Pour autant, faute de surface disponible, les capacités demeurent limitées. Pour se faire une idée, la masse de carbone relâchée depuis 1850 par les activités humaines dépasse déjà celle contenue dans toutes les plantes existant aujourd'hui sur Terre.<Reference index="3" content="<em>Yinon M. Bar-On, Rob Phillips, and Ron Milo</em>. <a target='_blank' href='https://www.pnas.org/content/115/25/6506'>The biomass distribution on Earth</a>, juin 2018.<br><br> <em>GIEC</em>,  <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/05/ar4-wg1-ts-fr.pdf#'>Changements climatiques 2014, rapport de synthèse</a> " /></p>


                    <div className="image-source">
                        <img src={Carbone} style={{ width: "40rem" }} ></img>
                    </div>

                    <p>Par conséquent, si planter des arbres, et plus généralement développer les puits de carbone naturels, est une excellente chose pour le climat, la qualité de l'air et la biodiversité, cette démarche ne saurait compter que pour une petite part de l'effort demandé, peut-être 10% dans les cas les plus optimistes. Celle-ci doit donc être entreprise en complément, et non à la place, des efforts portant sur la réduction des émissions.</p>

                    <h2>Panorama de notre consommation énergétique</h2>

                    <p>La quantité de CO<sub>2</sub> rejetée dans l’atmosphère pour produire une quantité d’énergie de référence, ici, un kilowatt-heure, définit l’intensité carbone d’une source d’énergie. Cette valeur prend en compte tout le cycle de vie&#8239;: le fonctionnement courant, mais aussi la construction, la maintenance, le démantèlement et le recyclage des systèmes de production.</p>

                    <div className="image-source">
                        <img src={IntensiteCarbone} style={{ maxWidth: '55rem' }} className="img-desktop" ></img>
                        <img src={IntensiteCarboneMobile} style={{ maxWidth: '38rem' }} className="img-mobile" ></img>
                        <div >GIEC<Reference index="4" content=" <em>GIEC</em>,  <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'> 2014: Climate Change 2014: Synthesis Report</a>, <br> Table A.III.2 | Emissions of selected electricity supply technologies (en). " /></div>
                    </div>

                    <p>Aucune énergie ne peut se revendiquer comme totalement «&#8239;verte&#8239;» ou «&#8239;propre&#8239;». D’autant que les émissions de CO<sub>2</sub> n’épuisent pas à elles seules la question des impacts environnementaux. Néanmoins, si l’on s’en tient à la problématique climatique, une classification pertinente consiste à séparer les énergies fossiles des énergies «&#8239;bas carbone&#8239;»,  qui n’émettent que très peu de CO<sub>2</sub>.</p>

                    <p>La biomasse se situe à la frontière entre les deux. Elle recouvre l’ensemble des matières organiques, comme le bois, les résidus de récolte ou le biogaz, qui peuvent être brûlées pour produire de la chaleur. Aussi ancienne que la découverte du feu, elle couvre toujours aujourd’hui 11&#8239;% des besoins énergétiques mondiaux.</p>

                    <p>Mais la nature «&#8239;bas carbone&#8239;», comme le caractère «&#8239;renouvelable&#8239;» des ressources naturelles consommées, dépend fortement du mode d’exploitation. Car «&#8239;renouvelable&#8239;» ne signifie pas systématiquement «&#8239;renouvelé&#8239;». Si valoriser les résidus de récolte ou traiter les boues de station d'épuration pour produire du biogaz répond à cette définition, accélérer la déforestation pour brûler plus de bois se montrerait tout à fait contre-productif.</p>

                    <p>Quoi qu’il en soit, au regard des ordres de grandeur impliqués, les possibilités de croissance durables de la biomasse demeurent très limitées, voire inexistantes, ce qui restreint ses applications à des marchés de niche.</p>

                    {/* 
                    <p>D’autres formes d’énergies renouvelables existent. La géothermie qui utilise la chaleur interne de la Terre, les usines marémotrices, qui génèrent de l’électricité avec la force des marées. Dans les deux cas les emplacements potentiels sont limités, ce qui ne permet pas d'envisager un recours à grande échelle. Les hydroliennes utilisent les courants marins pour produire de l'électricité&#8239;; elles restent, par rapport aux éoliennes en mer avec lesquelles elles partagent des caractéristiques communes, toujours handicapées par des coûts d'exploitation excessivement importants.</p>
                    */}

                    <p>Pour finir, le nucléaire produit de l’électricité à partir de la fission d’atomes d’uranium, une réaction atomique, et non chimique, qui n’émet pas de CO<sub>2</sub>. Le nucléaire est bien une source d’énergie bas carbone, même si elle n’appartient pas à la catégorie des énergies renouvelables, car elle consomme de l’uranium, un métal lourd radioactif dont les quantités sur Terre restent limitées.</p>

                    <h2>Panorama de la consommation mondiale d'énergie</h2>

                    <p>Commençons par rappeler la distinction entre la puissance, qui est un flux d'énergie, comme le débit d'un robinet, et l'énergie, qui est le résultat de la production sur un temps donné, par analogie, ce serait la quantité d'eau déversée dans un récipient. La puissance se mesure usuellement en watts, et l'énergie produite en watt-heure (Wh), soit la production d'un système d'un watt pendant une heure. Ainsi, une ampoule de 100 watts consommera en une heure une énergie de 100 watt-heure. </p>

                    <div className="image-source">
                        <img src={PuissanceProduction} style={{ maxWidth: '38rem' }}></img>
                    </div>

                    <p>Dans une vue d’ensemble, l’énergie finale consommée chaque année par l’économie mondiale représente 11&#8239;630 TWh et repose à 80&#8239;% sur les énergies fossiles. Cette répartition n’a pas évolué, ne serait-ce que d’un pour cent, depuis 30&nbsp;ans.</p>

                    <div className="image-source">
                        <img src={ConsommationEnergie} style={{ maxWidth: '55rem' }} className="img-desktop"></img>
                        <img src={ConsommationEnergieMobile} style={{ maxWidth: '40rem' }} className="img-mobile"></img>
                        <div >AIE<Reference index="5" content="<em>Agence Internationale de l'énergie</em>, World total final consumption by source, <a target='_blank' href='https://www.iea.org/reports/key-world-energy-statistics-2020/final-consumption'>World total final consumption by source, 1973-2018</a>. <br/><br/>
                         
                        BP, <a href='https://www.bp.com/en/global/corporate/energy-economics/statistical-review-of-world-energy.html'>Statistical Review of World Energy</a>, 2021 (en)" /></div>
                    </div>

                    <p>Environ 20% de l’énergie est consommée après production d’électricité, le reste par la consommation directe de pétrole (voitures), de bois (cheminées), de gaz (gazinières), de charbon (poêles), etc. Le pétrole demeure toujours la principale source d’énergie, mais c’est bien le charbon qui, avec pas loin de la moitié des émissions de CO<sub>2</sub>, est de loin le plus polluant.</p>

                    <p>Le charbon est beaucoup utilisé dans des industries lourdes, notamment la production d'acier ou de ciment, mais son usage le plus prédominant reste la production d'électricité. Il couvre près de 40% de la demande mondiale d'électricité et son utilisation connaît une forte expansion, particulièrement en Inde et en Chine. Le charbon est l’énergie qui affiche la progression la plus importante de la dernière décennie, devant les énergies renouvelables.</p>

                    <p>Pourquoi un tel engouement pour une énergie aussi néfaste, pour la santé et pour le climat&#8239;?</p>

                    <ul>
                        <li>Il présente l’avantage de se montrer abondant et relativement facile à extraire.</li>
                        <li>Les gisements se situent souvent à portée de main. La majorité des pays consommateurs de charbon, dont nos voisins allemands, utilisent tout simplement les stocks qu’ils possèdent dans leur propre sous-sol.</li>
                        <li>Enfin, et surtout, parce que c’est une technologie éprouvée, efficace et robuste.</li>
                    </ul>

                    <p>Malgré la signature des accords de Paris, l’heure est toujours au financement massif de nouvelles infrastructures. Les subventions pour les centrales à charbon versées par les pays du G20 ont presque triplé de 2014 à 2017, passant de 17 à 47&nbsp;milliards de dollars par an.<Reference index="6" content="<em>Overseas Development Institue, ODI</em>, <a target='_blank' href='https://odi.org/en/publications/g20-coal-subsidies-tracking-government-support-to-a-fading-industry/'>G20 coal subsidies: tracking government support to a fading industry</a>, Juin 2019 (en)" />

                    </p>

                    <p>En réaction, lutter contre le réchauffement climatique impose donc les actions ci-dessous, par ordre de priorité&#8239;:</p>

                    <ul>
                        <li>Sortir au plus vite du charbon.</li>
                        <li>Réduire drastiquement notre dépendance au pétrole.</li>
                        <li>Réduire notre consommation de gaz naturel.</li>
                    </ul>

                    <p>Les deux tiers de la consommation de charbon servant à produire de l'électricité, les chapitres suivants seront consacrés à passer en revue les alternatives aux centrales à charbon que sont <Link to="/les-energies-renouvelables">les énergies renouvelables</Link> et <Link to="/nucleaire">le nucléaire</Link>. Nous traiterons également de la question du <Link to="/gaz-naturel">gaz</Link> dans un chapitre dédié. La problématique du <Link to="/apres-petrole">pétrole</Link>, associée essentiellement à la mobilité sera abordée dans un second temps. </p>


                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="la-transition-energetique" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="3. Les énergies renouvelables" url="/energies-renouvelables"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default TransitionStrategy

