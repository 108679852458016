import React, { Component } from 'react'
import '../css/factory.css'
import box from '../images/svg/factory/box.svg'
import arm from '../images/svg/factory/arm.svg'
import oil from '../images/svg/factory/fuel.svg'
import wood from '../images/svg/factory/firewood.svg'
import coal from '../images/svg/factory/coal.svg'
import { FaCreativeCommonsPdAlt } from 'react-icons/fa'



export default class Factory extends Component {

    state = {
        currentkWh: 2,
        energies: [
            {
                label: "10h d'huile de coude",
                image: arm,
                kwh: 2,
                color: '#F3D1C2',
                cass: 'arm',
                handleChange: (e,) => {
                    this.changeSpeed(2, 'arm');
                }
            },
            {
                label: "1kg de bois",
                image: wood,
                kwh: 4,
                color: '#8C5A40',
                css: "wood",
                handleChange: (e) => {
                    this.changeSpeed(4, 'wood');
                }
            },
            {
                label: "1kg de charbon",
                image: coal,
                kwh: 9,
                color: '#333538',
                css: 'coal',
                handleChange: (e) => {
                    this.changeSpeed(9, 'coal');
                }
            },
            {
                label: "1 kg pétrole",
                image: oil,
                kwh: 12,
                color: '#405D7A',
                css: 'oil',
                handleChange: (e) => {
                    this.changeSpeed(12, 'oil');
                }
            }
        ]
    }

    changeSpeed = (speed, color) => {
        let boxes = document.querySelectorAll('li');
        let newSpeed = (12 / speed).toFixed(3) * 1000;

        if (boxes) {

            boxes.forEach((box, index) => {

                box.style.animationDuration = newSpeed + 'ms';
                let thisDelay = index * (2 / speed) * 1000;

                box.style.animationDelay = thisDelay + 'ms';

                var newBox = box.cloneNode(true);
                box.parentNode.replaceChild(newBox, box);

            });
        }

        let loaders = document.querySelectorAll('.magic');

        if (loaders) {

            loaders.forEach((loader) => {

                loader.classList.remove('arm');
                loader.classList.remove('wood');
                loader.classList.remove('coal');
                loader.classList.remove('oil');

                loader.classList.add(color);

                var newLoader = loader.cloneNode(true);
                loader.parentNode.replaceChild(newLoader, loader);
            });
        }

        this.setState({
            currentkWh: speed
        })
    }

    render() {

        let energies = this.state.energies.map((energy, index) => {
            return <div className="factory-selector-btn quizz-checkbox">
                <img src={energy.image}></img>
                <label for={energy.label}>{energy.label}</label>
                <input name="energy" id={energy.label} className="rounded-checkbox" type="radio" onChange={energy.handleChange} />
            </div>
        });

        return (

            <div >

                <div className="factoryContainer">

                    <div className="factory-selector">
                        {energies}
                    </div>

                    <div className="stage">

                        <ul>
                            <li><img src={box} /></li>
                            <li><img src={box} /></li>
                            <li><img src={box} /></li>
                            <li><img src={box} /></li>

                        </ul>
                        <div className='band'></div>
                        <div className='factory'>
                            <div className='magic' id="magic-id">
                                <spans></spans>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
