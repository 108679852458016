import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Reference from './Reference'

import electron from '../images/svg/nuclear.jpg';
import solar from '../images/svg/solar-panel.svg';
import wind from '../images/svg/wind.jpg';
import gas from '../images/svg/gaz-plant.jpg';
import dam from '../images/svg/dam.jpg';
import smoke from '../images/svg/smoke.svg';
import battery from '../images/svg/battery.svg';
import hydrogen from '../images/svg/hydrogen.svg';
import valve from '../images/svg/valve.svg';
import energyEfficiency from '../images/svg/energy-efficiency.jpg';
import STEP from '../images/svg/STEP.jpg';
import warn from '../images/svg/warn.svg';


export default class TransitionGame extends Component {

    state = {
        lowCarbonVariation: 0,
        fossilVariation: 0,
        initialEmissions: 33247.409,
        initialEnergy: 9846.21,
        totalControllable: 0,
        totalNotControllable: 0,
        initialLowCarbonEnergy: 0,
        actualLowCarbonEnergy: 0,
        initialFossils: 0,
        actualFossilsEnergy: 0,
        totalEmissions: 33247.409,
        totalEnergy: 9846.21,
        otherEnergies: 1053,
        emissionsPourcent: 100,
        emissionsTarget: 20,
        emissionslabel: "",
        energyPourcent: 100,
        energylabel: "",
        energyStock: 0,
        controllableEnergyRequirement: 0,
        pib: {
            PIB: 86357,
            initialPIB: 86357,
            initialIntensity: 0.1140175087,
            intensity: 0.1140175087,
            nonFossilEnergy: 0,
            emissionsPourcent: 100,
            pibPourcent: 100,
            intensityPourcent: 100,
            selection: [50, 50],
            selectedValue: 50,
            todayValue: 50,
            todayTrend: 32,
            pibIntensityLabel: " -",
            pibProgressLabel: "",
            pibLabel: " -",
            getTrend: function (selectedValue, todayValue) {
                return (((todayValue - selectedValue) / 30) / 0.9) + "x la tendance actuelle";
            },
            actualTrend: "Tendance actuelle : 0,9% par an",
            getProgressionLabel: function (selectedValue, todayValue) {
                return "Amélioration de l'efficacité énergétique de " + (todayValue - selectedValue) / 30 + "% par an.";
            }
        },
        energies: [{
            name: "coal",
            label: "Charbon",
            type: "fossil",
            image: gas,
            selection: [50, 50],
            initialSelection: 50,
            selectedValue: 50,
            todayValue: 50,
            todayTrend: 59.5,
            min: 0,
            max: 100,
            initialEmissions: 14745.72,
            emissions: 14745.72,
            initialEnergy: 1726.07,
            energy: 1726.07,
            percentPerTick: 50,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 8.542963782,
            reductionLabel: " -",
            trendPercent: 0,
            controllable: true,
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 0);
            }
        },
        {
            name: "oil",
            label: "Pétrole",
            type: "fossil",
            selection: [50, 50],
            initialSelection: 50,
            selectedValue: 50,
            todayValue: 50,
            todayTrend: 85,
            min: 0,
            max: 100,
            initialEmissions: 11427.933,
            emissions: 11427.933,
            initialEnergy: 4111.19,
            energy: 4111.19,
            percentPerTick: 50,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 2.779714056,
            reductionLabel: " -",
            trendPercent: 0,
            controllable: false,
            stockFactor: 0,
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 1);
            }
        },
        {
            name: "gaz",
            label: "Gaz naturel",
            type: "fossil",
            selection: [50, 50],
            initialSelection: 50,
            selectedValue: 50,
            todayValue: 50,
            todayTrend: 100,
            min: 0,
            max: 100,
            initialEmissions: 7104.756,
            emissions: 7104.756,
            initialEnergy: 2053.24,
            energy: 2053.24,
            percentPerTick: 50,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 3.460269986,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 0,
            controllable: true,
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 2);
            }
        },
        {
            name: "nuclear",
            label: "Nucléaire",
            type: "lowCarbon",
            image: electron,
            selection: [20, 20],
            initialSelection: 20,
            selectedValue: 20,
            todayValue: 20,
            todayTrend: 5,
            min: 0,
            max: 100,
            initialEmissions: 0,
            emissions: 0,
            initialEnergy: 195.36,
            energy: 195.36,
            percentPerTick: 15,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            progressionLabel: "",
            trendPercent: "",
            trendPercent: 0,
            actualEnergyTrend: 270,
            controllable: true,
            actualTrend: "Tendance actuelle : un nouveau réacteur ≈ EPR tous les 2 mois et demi",
            getTrend: function (selectedValue, todayValue) {
                return "";
            },
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 3);
            },
            getProgressionLabel: function (selectedValue) {
                let nbrOfDays = 40 / ((selectedValue - 5) / 15);
                return "Un nouvel EPR tous les " + nbrOfDays.toFixed(0) + " jours.";
            }
        },
        {
            name: "Hydro",
            label: "Hydro",
            type: "lowCarbon",
            image: dam,
            selection: [0, 0],
            initialSelection: 0,
            selectedValue: 0,
            todayValue: 0,
            todayTrend: 8,
            min: 0,
            max: 100,
            initialEmissions: 0,
            emissions: 0,
            initialEnergy: 311.74,
            energy: 311.74,
            percentPerTick: 100,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 702,
            controllable: true,
            actualTrend: "Tendance actuelle : l'équivalent d'un grand barrage tous les 6 jours",
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 4);
            },
            getProgressionLabel: function (selectedValue) {
                let nbrOfDays = 3.2 / ((selectedValue) / 100);
                return "L'équivalent du plus grand barrage de France mis en service tous les " + nbrOfDays.toFixed(0) + " jours.";
            }
        },
        {
            name: "wind",
            label: "Éolien",
            type: "lowCarbon",
            image: wind,
            selection: [0, 0],
            initialSelection: 0,
            selectedValue: 0,
            todayValue: 0,
            todayTrend: 17.3,
            min: 0,
            max: 100,
            initialEmissions: 0,
            emissions: 0,
            initialEnergy: 271.44,
            energy: 271.44,
            percentPerTick: 10,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 1204,
            controllable: false,
            stockRequirement: 0,
            stockFactor: 0.45,
            actualTrend: "Tendance actuelle : ~ 70 éoliennes par jour",
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 5, 10);
            },
            getProgressionLabel: function (selectedValue) {
                // let machinePerDay = ((selectedValue) / 10) * 21.16;
                let machinePerDay = (((selectedValue) / 10) * 21.16 * 1.2) / (0.5 + 0.5 * 0.8);
                return "" + machinePerDay.toFixed(0) + " éoliennes par jour";
            }
        },
        {
            name: "solar",
            label: "Solaire",
            type: "lowCarbon",
            image: solar,
            selection: [0, 0],
            initialSelection: 0,
            selectedValue: 0,
            todayValue: 0,
            todayTrend: 67,
            min: 5,
            max: 100,
            initialEmissions: 0,
            emissions: 0,
            initialEnergy: 124.17,
            energy: 124.17,
            percentPerTick: 5,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 995,
            controllable: false,
            stockRequirement: 0,
            stockFactor: 0.7,
            actualTrend: "Tendance actuelle : ~ 900 000 panneaux solaires par jour",
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 6);
            },
            getProgressionLabel: function (selectedValue) {
                // let nbrOfDays = 504.0832501 / ((selectedValue) / 10);
                let nbrOfPanels = (((selectedValue) / 5) * 0.17 * 1.2) / (0.2 + 0.8 * 0.8);
                //let nbrOfPanels = (((selectedValue) / 10) * 0.17);

                if (nbrOfPanels < 1) {
                    return (nbrOfPanels * 1000).toFixed(0) + " 000 panneaux solaire installés chaque jour ";
                }
                else {
                    return nbrOfPanels.toFixed(1) + " millions de panneaux solaires installés chaque jour ";
                }
                // Soit la surface de Paris recouverte tous les " + nbrOfDays.toFixed(0) + " jours.";
            }
        },
        {
            name: "STEP",
            label: "STEP",
            type: "stock",
            image: dam,
            selection: [0, 0],
            initialSelection: 0,
            selectedValue: 0,
            todayValue: 0,
            todayTrend: 13.41,
            min: 5,
            max: 100,
            initialEmissions: 0,
            emissions: 0,
            initialEnergy: 40,
            energy: 40,
            percentPerTick: 5,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 81.46,
            controllable: true,
            stockRequirement: 0,
            stockFactor: 0.8,
            actualTrend: "Tendance actuelle : ~ 900 000 panneaux solaires par jour",
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 7);
            },
            getProgressionLabel: function (selectedValue) {
                // let nbrOfDays = 504.0832501 / ((selectedValue) / 10);
                let nbrOfSteps = 360 / ((((selectedValue) / 5) * 40) / 0.443680137);

                return "Une nouvelle STEP tous les " + nbrOfSteps.toFixed(1) + " mois";

            }
        },
        {
            name: "CSC",
            label: "CSC",
            type: "csc",
            image: valve,
            selection: [0, 0],
            initialSelection: 0,
            selectedValue: 0,
            todayValue: 0,
            todayTrend: 67,
            min: 5,
            max: 100,
            initialEmissions: -31,
            emissions: -31,
            initialEnergy: 0,
            energy: 0,
            percentPerTick: 1,
            reductionPourcent: 0,
            reductionValue: 0,
            CarbonIntensity: 0,
            reductionLabel: " -",
            trendPercent: 0,
            actualEnergyTrend: 995,
            actualEmissionTrend: 93,
            controllable: true,
            stockRequirement: 0,
            stockFactor: 0.8,
            actualTrend: "Tendance actuelle : ~ 900 000 panneaux solaires par jour",
            handleChange: (event, newValue) => {
                this.handleEnergyChange(event, newValue, 8);
            },
            getProgressionLabel: function (selectedValue) {

                let ratio = ((selectedValue * 50 * 31) / 16);
                return "Déploiement à grande échelle des projets de CSC ";

                // Soit la surface de Paris recouverte tous les " + nbrOfDays.toFixed(0) + " jours.";
            }
        }
        ],
        resultGraphs: [
            {
                label: "Émissions de CO<sub>2</sub>",
                selection: [50, 50],
                valueToday: 50,
                selectedValue: 50,
                id: "emissions-co2"
            },
            {
                label: "Production d'énergie",
                selection: [50, 50],
                valueToday: 50,
                selectedValue: 50,
                id: "energy-total"

            },
            {
                label: "PIB",
                selection: [50, 50],
                valueToday: 50,
                selectedValue: 50,
                id: "pib-total"
            }]

    }

    getIntensityLabel = (selectedValue, todayValue) => {

        let ratio = (1 - selectedValue / todayValue);
        let displayedLabel = "";

        if (ratio < 0.1) {
            displayedLabel = "Très en dessous des dernières décennies";
        }
        else if (ratio < 0.2) {
            displayedLabel = "En dessous des dernières décennies";
        }
        else if (ratio < 0.3) {
            displayedLabel = "Èquivalent aux dernières décennies";
        }
        else if (ratio < 0.4) {
            displayedLabel = "Bien mieux que les dernières décennies";
        }
        else if (ratio < 0.5) {
            displayedLabel = "Deux fois mieux que les dernières décennies";
        }
        else if (ratio < 0.6) {
            displayedLabel = "Intensité énergétique divisée par deux";
        }

        return "Baisse de l'intensité énergétique de " + (2 * (todayValue - selectedValue) / 30).toFixed(1) + "% par an.";
    }

    /*-----------------------------
    * INIT
    *-----------------------------*/

    componentDidMount() {

        let tabs = document.querySelectorAll('.transitionTabs  > div');

        let initialLowCarbonEnergyEnergy = 0;
        let initialFossilEnergy = 0;

        this.state.energies.map(item => {
            if (item.type == "lowCarbon") {
                initialLowCarbonEnergyEnergy += item.initialEnergy;
            }
            if (item.type == "fossil") {
                initialFossilEnergy += item.initialEnergy;
            }
        });

        tabs.forEach(tab => {

            tab.addEventListener('click', (event) => {

                let tabs = document.querySelectorAll('.transitionTabs  > div');
                tabs.forEach(tab => {
                    tab.classList.remove('active');
                });

                let sections = document.querySelectorAll('.game > section:not(:first-child)');
                sections.forEach(section => {
                    section.style.display = 'none';
                });

                let targetId = event.target.id;

                let clickedTab = document.querySelector('#' + targetId);
                clickedTab.classList.add("active");

                let sectionId = targetId.replace("tab-", "section-");

                let correspondingTab = document.querySelector('#' + sectionId);
                correspondingTab.style.display = 'block';
            })
        });

        this.setState({
            initialLowCarbonEnergy: initialLowCarbonEnergyEnergy,
            initialFossils: initialFossilEnergy
        })
    }

    /*-----------------------------
    * FOSSILS
    *-----------------------------*/

    calculateNewPib = (pib, pibIntensity, totalEnergy) => {

        pib.PIB = totalEnergy / pib.intensity;

        pib.pibPourcent = (((pib.PIB / pib.initialPIB)) * 100).toFixed(0);

        let pibDiff = ((pib.PIB / pib.initialPIB) - 1) * 100;
        pib.pibLabel = (pibDiff > 0 ? "+" : "") + pibDiff.toFixed(0) + "%";

        let resultGraphs = this.state.resultGraphs;
        let pibGraph = resultGraphs[2];

        pibGraph.selection = this.calculateSelection(pib.pibPourcent, pibGraph.valueToday);
        pibGraph.selectedValue = this.getSelectedValue(pibGraph.selection, pibGraph.valueToday);

        this.setState({
            pib: pib,
            resultGraphs: resultGraphs
        });
    }

    handlePibIntensityChange = (event, newValue) => {

        let thumbs = document.getElementById("intensity");

        this.setSelector(thumbs, 50);

        let pib = this.state.pib;
        let selectedValue;

        if (newValue[0] == pib.todayValue || newValue[1] == pib.todayValue) {
            pib.selection = newValue;

            selectedValue = this.getSelectedValue(pib.selection, pib.todayValue);
            let ratio = selectedValue / pib.todayValue;
            pib.intensity = ratio * pib.initialIntensity;

            if (ratio != null) {
                let diff = ratio - 1;
                pib.pibIntensityLabel = (diff * 100).toFixed(0) + "%";

                pib.pibProgressLabel = this.getIntensityLabel(selectedValue, pib.todayValue);
            }

            this.calculateNewPib(pib, pib.intensity, this.state.totalEnergy);
        }
    }

    setSelector(thumbs, initialValue) {

        let children = thumbs.children;
        let thumbStart;
        let thumbSelect;

        let energies = this.state.energies;

        for (let i = 0; i < children.length; i++) {

            if (children[i].classList.contains("MuiSlider-thumb")) {

                if (children[i].ariaValueNow === initialValue + "") {
                    thumbStart = children[i];
                }
                else {
                    thumbSelect = children[i];
                }
            }
        }

        if (thumbStart) {
            thumbStart.style.display = 'none';
        }

        if (thumbStart && !thumbSelect) {
            thumbStart.style.display = 'block';
        }

        if (thumbSelect) {
            thumbSelect.style.display = 'block';
        }
    }

    handleResultChange(resultGraph) {

        for (let i = 0; i < 3; i++) {

            let graphResult = resultGraph[i];
            let thumbs = document.getElementById(graphResult.id);
            if (thumbs != null) {
                this.setSelector(thumbs, graphResult.valueToday);
            }

        }
    }

    handleValueChange = (index) => {

        let thumbs = document.getElementById("energy-id-" + index);

        let children = thumbs.children;
        let thumbStart;
        let thumbSelect;

        let energies = this.state.energies;

        for (let i = 0; i < children.length; i++) {

            if (children[i].classList.contains("MuiSlider-thumb")) {

                if (children[i].ariaValueNow === energies[index].initialSelection + "") {
                    thumbStart = children[i];
                }
                else {
                    thumbSelect = children[i];
                }
            }
        }

        if (thumbStart) {
            thumbStart.style.display = 'none';
        }

        if (thumbStart && !thumbSelect) {
            thumbStart.style.display = 'block';
        }

        if (thumbSelect) {
            thumbSelect.style.display = 'block';
        }
    }

    handleEnergyChange = (event, newValue, index) => {

        this.handleValueChange(index);

        let energies = this.state.energies;

        // Get energy information based on given index
        let energy = energies[index];
        let selectedValue;

        if (newValue[0] == energy.initialSelection || newValue[1] == energy.initialSelection) {

            energy.selection = newValue;

            selectedValue = this.getSelectedValue(energy.selection, energy.initialSelection);

            energy.selectedValue = selectedValue;

            let ratio = 1 + (selectedValue - energy.initialSelection) / energy.percentPerTick;

            if (selectedValue < energy.initialSelection && energy.name == "nuclear") {
                ratio = 1 + (selectedValue - energy.initialSelection) / 20;
            }

            energy.energy = energy.initialEnergy * ratio;
            energy.trendPercent = (energy.energy / energy.actualEnergyTrend).toFixed(1).replace('.', ',') + "x la tendance actuelle";
            energy.emissions = energy.initialEmissions * ratio;

            if (energy.name == "CSC") {
                energy.trendPercent = (energy.emissions / energy.initialEmissions).toFixed(1).replace('.', ',') + "x la tendance actuelle";
            }

            energy.stockRequirement = energy.stockFactor * energy.energy;

            let totalEmissions = 0;
            let totalEnergy = 0;
            let totalControllable = 86.3; // Share of other energies (Biomass) dedicated to Electricity
            let totalNotControllable = 0;
            let totalStock = 0;
            let controllableEnergyRequirement = 0;

            this.state.energies.map((item, fuelIndex) => {

                if (fuelIndex != index) {

                    if (item.type != "stock") {
                        totalEmissions += item.emissions;
                        totalEnergy += item.energy;

                        if (item.controllable) {

                            if (item.name != "oil" && item.name != "nuclear") {
                                totalControllable += item.energy;
                            }
                        }
                        else {
                            totalNotControllable += item.energy;
                            controllableEnergyRequirement += item.energy * item.stockFactor;
                        }
                    }
                    else {
                        totalStock += item.energy;
                        totalControllable += item.energy;
                    }
                }


            });

            let reductionValue = (ratio - 1) * 100;

            if (reductionValue > 0) {
                energy.reductionLabel = "+" + reductionValue.toFixed(0) + "%";
            }
            else {
                energy.reductionLabel = "-" + (0 - reductionValue.toFixed(0)) + "%";
            }

            if (energy.type != "stock") {
                totalEmissions += energy.emissions;
                totalEnergy += energy.energy;
            }

            totalEnergy += this.state.otherEnergies;

            if (energy.controllable) {
                totalControllable += energy.energy;
            }
            else {
                totalNotControllable += energy.energy;
                controllableEnergyRequirement += energy.energy * energy.stockFactor;
            }

            let emissionsPourcent = (totalEmissions / this.state.initialEmissions) * 100;


            if (emissionsPourcent < 0) {
                emissionsPourcent = 0;
            }

            let emissionsLabel = ((emissionsPourcent - 100) > 0 ? "+" : "") + (emissionsPourcent - 100).toFixed(0) + "%";

            let energyPourcent = (totalEnergy / this.state.initialEnergy) * 100;
            let energyLabel = ((energyPourcent - 100) > 0 ? "+" : "") + (energyPourcent - 100).toFixed(0) + "%";

            energies[index] = energy;

            let resultGraphs = this.state.resultGraphs;
            let percentArray = [emissionsPourcent, energyPourcent];

            for (let i = 0; i < 2; i++) {

                resultGraphs[i].selection = this.calculateSelection(percentArray[i], resultGraphs[i].valueToday);
                resultGraphs[i].selectedValue = this.getSelectedValue(resultGraphs[i].selection, resultGraphs[i].valueToday);

            }

            this.handleResultChange(resultGraphs);

            // Calculate the % of increase / decrease by energy type
            let lowCarbonEnergy = 0;
            let fossilEnergy = 0;

            energies.map(item => {
                if (item.type == "lowCarbon") {
                    lowCarbonEnergy += item.energy;
                }
                if (item.type == "fossil") {
                    fossilEnergy += item.energy;
                }
            });

            let lowCarbonVariation = ((lowCarbonEnergy / this.state.initialLowCarbonEnergy - 1) * 100).toFixed(0);
            let fossilVariation = ((fossilEnergy / this.state.initialFossils - 1) * 100).toFixed(0);

            this.setState({
                actualLowCarbonEnergy: lowCarbonEnergy,
                lowCarbonVariation: lowCarbonVariation,
                actualFossilsEnergy: fossilEnergy,
                fossilVariation: fossilVariation,
                totalEmissions: totalEmissions,
                totalEnergy: totalEnergy,
                emissionsPourcent: emissionsPourcent,
                energyPourcent: energyPourcent,
                energies: energies,
                emissionsLabel: emissionsLabel,
                totalControllable: totalControllable,
                totalNotControllable: totalNotControllable,
                energyLabel: energyLabel,
                controllableEnergyRequirement: controllableEnergyRequirement,
                energyStock: totalStock,
                resultGraphs: resultGraphs
            });

            this.calculateNewPib(this.state.pib, null, totalEnergy);
        }
    }

    getSelectedValue(newValue, initialValue) {

        if (newValue[0] == newValue[1]) {
            return newValue[0];
        }
        else if (newValue[0] != initialValue) {
            return newValue[0];
        }
        else if (newValue[1] != initialValue) {
            return newValue[1];
        }
    }

    calculateSelection(newPercent, initialValue) {

        if (newPercent > 100) {
            return [initialValue, (newPercent / 100) * initialValue];
        }
        else {
            return [(newPercent / 100) * initialValue, initialValue];
        }
    }

    render() {

        let pibIntenseMax = 50;
        let pibIntenseMin = 30;

        let fossils = this.state.energies.map((item, index) => {

            if (item.type == 'fossil') {
                return <>

                    <label className="energy-label">
                        <span>{item.label} </span>&nbsp;
                        <span className="diff-text-neutral">{item.reductionLabel}</span>
                    </label>
                    <div>
                        <DarkSlider max={item.max} value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                    </div>

                </>
            }
        });


        let csc = this.state.energies.map((item, index) => {

            if (item.type == 'csc') {
                return <>

                    <label className="energy-label">
                        <span>{item.label} </span>&nbsp;
                        <span className="diff-text-neutral">{item.reductionLabel}</span>
                    </label>
                    <div>
                        <DarkSlider max={item.max} value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                    </div>

                </>
            }
        });

        let lowCarbon = this.state.energies.map((item, index) => {

            if (item.type == 'lowCarbon') {

                let progressLabel;
                if (item.getProgressionLabel) {
                    progressLabel = item.getProgressionLabel(item.selectedValue, item.initialEnergy, item.percentPerTick);
                }
                return <>

                    <label className="energy-label">
                        <span>{item.label} </span>&nbsp;
                        <span className="diff-text-neutral">{item.reductionLabel}</span>
                    </label>
                    <div>
                        <GreenSlider value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                    </div>

                </>
            }
        });

        let energyStock = this.state.energies.map((item, index) => {

            if (item.type == 'stock') {

                let progressLabel;
                if (item.getProgressionLabel) {
                    progressLabel = item.getProgressionLabel(item.selectedValue, item.initialEnergy, item.percentPerTick);
                }
                return <>

                    <label className="energy-label">
                        <span>{item.label}</span>&nbsp;
                        <span className="diff-text-neutral">{item.reductionLabel}</span>
                    </label>
                    <div>
                        <GreenSlider value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                    </div>

                </>
            }
        });

        let Csc = this.state.energies.map((item, index) => {

            if (item.type == 'csc') {
                return <>

                    <label className="energy-label">
                        <span>{item.label} </span>&nbsp;
                        <span className="diff-text-neutral">{item.reductionLabel}</span>
                    </label>
                    <div>
                        <DarkSlider max={item.max} value={item.selection} defaultValue={item.selection} id={'energy-id-' + index} onChange={item.handleChange} aria-labelledby="continuous-slider" />
                    </div>

                </>
            }
        });


        let blackOutRisk = "";

        if ((this.state.controllableEnergyRequirement / this.state.totalControllable) > 1) {

            let daysOfBlackout = (this.state.controllableEnergyRequirement - this.state.totalControllable) / 27.2;
            blackOutRisk = <div >
                <div>
                    <img className="energy-icon" src={warn} />
                </div>
                <div style={{ display: 'block', margin: '0.5rem 0' }}>
                    <span style={{
                        color: "#EB592A", fontSize: '1.4rem'
                    }}> Ruptures d'approvisionnement en électricité. Ajoutez des sources pilotables.</span><br />
                </div>

            </div>;
        }
        else if ((this.state.controllableEnergyRequirement / this.state.totalControllable) > 0.9) {
            blackOutRisk = <div >
                <div>
                    <img className="energy-icon" src={warn} />
                </div>
                <div style={{ display: 'block', margin: '0.5rem 0' }}>
                    <span style={{
                        color: "#EB592A", fontSize: '1.4rem'
                    }}> Risques de pénurie de courant.</span><br />
                </div>

            </div>;
        }

        let pibIntensity = <>

            <label className="energy-label">
                <span>Énergie utilisée &nbsp;<span className="diff-text-neutral">{(((this.state.pib.intensity / this.state.pib.initialIntensity) - 1) * 100).toFixed(0) + "%"} </span>
                </span>

            </label>
            <div style={{ marginRight: '1rem' }}>
                <GreenSlider max={pibIntenseMax} min={pibIntenseMin} value={this.state.pib.selection} defaultValue={this.state.pib.selection} id="intensity" onChange={this.handlePibIntensityChange} aria-labelledby="continuous-slider" />
            </div>
            <div>
                <span className="unit-main">Consommation d'énergie à PIB constant</span>
            </div>

        </>

        let icons = this.state.energies.map((item, index) => {

            if (item.type == "lowCarbon" || item.type == "csc" || item.type == "stock") {

                if (item.selectedValue > item.initialSelection) {

                    let progressLabel;
                    if (item.getProgressionLabel) {
                        progressLabel = item.getProgressionLabel(item.selectedValue, item.initialEnergy, item.percentPerTick);
                    }

                    return <>{
                        (item.selection != item.initialSelection) && <div >
                            <div>
                                <img className="energy-icon" src={item.image} />
                            </div>
                            <div style={{ display: 'block', margin: '0.5rem 0' }}>
                                <span>{progressLabel}</span><br />
                                {<span className="unit-main">{item.trendPercent}</span>}
                            </div>

                        </div>
                    }</>
                }
            }

        });

        let pibIntensityIcon = <div>
            <div>
                <img className="energy-icon" src={energyEfficiency} />
            </div>
            <div style={{ display: 'block' }}>
                <span>{this.state.pib.pibProgressLabel}</span><br />
                <span className="unit-main">{this.state.pib.actualTrend}</span>
            </div>

        </div>;

        let emissionsGraph = this.state.resultGraphs[0];
        let energyGraph = this.state.resultGraphs[1];
        let pibGraph = this.state.resultGraphs[2];

        let emissions = this.state.emissionsPourcent;
        let emissionsColor = '#3E0C08';

        if (emissions < 80) {
            emissionsColor = '#9E1F15';
        }

        if (emissions < 60) {
            emissionsColor = '#C95E27';
        }

        if (emissions < 50) {
            emissionsColor = '#EFB33C';
        }

        if (emissions < 40) {
            emissionsColor = '#DCC85C';
        }

        if (emissions < 30) {
            emissionsColor = '#CBCB79';
        }

        if (emissions < 20) {
            emissionsColor = '#B9CE8E';
        }

        if (emissions < 15) {
            emissionsColor = '#7EAA57';
        }

        let transitionContainerClass = "transition-container";

        if (this.props.isFullScreen == true) {
            transitionContainerClass = "transition-container-full-screen";
        }

        return (
            <>

                <div className={transitionContainerClass}>

                    <div className="game-container coal-game slider-section-borders" >

                        <div className="game transition-version" >
                            <h3 className="energy-label energy-label-header" style={{ textAlign: 'center' }}>Énergies fossiles
                                <span className="diff-text-neutral" >

                                    {this.state.fossilVariation > 0 && "+"}{(this.state.fossilVariation) + "%"}

                                </span>


                                {/*   <span className="unit-main">Production annuelle</span>
                            <span className="c-intensity">{this.state.fossilVariation > 0 && "+"}{(this.state.fossilVariation) + "%"} </span>
                            */}
                            </h3>

                            <section>{fossils} <br /> {csc} </section>
                        </div>

                        <div className="game transition-version" >

                            <h3 className="energy-label energy-label-header" style={{ textAlign: 'center' }}>Énergies bas carbone
                                <span className="diff-text-neutral" >

                                    {this.state.lowCarbonVariation > 0 && "+"}{(this.state.lowCarbonVariation) + "%"}

                                </span>

                                {/*  <span className="unit-main">Production annuelle</span>
                            <span className="c-intensity">{this.state.lowCarbonVariation > 0 && "+"}{(this.state.lowCarbonVariation) + "%"} </span>
                            */}
                            </h3>


                            <section>{lowCarbon}  </section>

                        </div>

                        {this.props.withPib === "true" && <div className="game transition-version" >

                            <h3 className="energy-label energy-label-header" style={{ textAlign: 'center' }}>Efficacité énergétique &nbsp;
                                <span className="diff-text-neutral" >

                                    {this.state.pib.intensity < this.state.pib.initialIntensity && "+" + (((1 - (this.state.pib.intensity / this.state.pib.initialIntensity))) * 100).toFixed(0) + "%"}

                                </span>

                                {/*  <span className="unit-main">Utilisation d'énergie par € de PIB</span>
                            <span className="c-intensity">{this.state.lowCarbonVariation > 0 && "+"}{(this.state.lowCarbonVariation) + "%"} </span>
                            */}
                            </h3>

                            <section>{pibIntensity} </section>

                        </div>}

                    </div>

                    <div className="transition-results-container" style={{ marginBottom: '2rem' }}>

                        <div className="transition-results">

                            {/* ÉMISSIONS CO2 */}
                            <div style={{ marginBottom: '5rem' }}>
                                <label className="energy-label tr-results" style={{ color: '#595A5A' }}>
                                    <span className="c-intensity tr-results" style={{ color: '#595A5A', width: '50px' }}>{(this.state.emissionsPourcent - 100) > 0 && "+"}{(this.state.emissionsPourcent - 100).toFixed(0) + "%  "} </span>
                                    <span className="tr-results-label">Émissions de CO<sub>2</sub></span>
                                </label>
                                <div className="tr-results">
                                    <span className="result-blocker"></span>
                                    <span className="emissions-objective-slider">
                                        <span>
                                            <span className="diff-text-above" style={{ padding: '0 0.rem' }}>2 °C</span>
                                        </span>

                                    </span>
                                    <EmissionSlider value={emissionsGraph.selection} defaultValue={emissionsGraph.selection} id="emissions-co2" aria-labelledby="continuous-slider" onChange={this.handleEmissionsChange} />
                                    <div className={this.state.emissionsPourcent <= this.state.emissionsTarget ? "todays-trend co2-objectif co2-objectif-passed" : "todays-trend co2-objectif"} ></div>
                                </div>
                            </div>


                            {/* ENERGIE */}
                            <div>
                                <label className="energy-label tr-results" style={{ color: '#3373BA' }}>
                                    <span className="c-intensity tr-results" style={{ color: '#3373BA', width: '50px' }}>{(this.state.energyPourcent - 100) > 0 && "+"}{(this.state.energyPourcent - 100).toFixed(0) + "%"} </span>
                                    <span className="tr-results-label">Énergie </span>
                                </label>

                                <div className="tr-results">
                                    <span className="result-blocker"></span>
                                    <EnergySlider value={energyGraph.selection} defaultValue={energyGraph.selection} id="energy-total" aria-labelledby="continuous-slider" />
                                </div>
                            </div>

                            {/* PIB MONDIAL */}
                            {this.props.withPib === "true" && <div>

                                <label className="energy-label tr-results" style={{ color: '#F3C240' }}>
                                    <span className="c-intensity tr-results" style={{ color: '#F3C240', width: '50px' }}>{((this.state.pib.PIB / this.state.pib.initialPIB - 1) * 100).toFixed(0) > 0 && "+"}{((this.state.pib.PIB / this.state.pib.initialPIB - 1) * 100).toFixed(0) + "%"} </span>
                                    <span className="tr-results-label">PIB Max.</span>
                                </label>

                                <div className="tr-results">
                                    <span className="result-blocker"></span>
                                    <PibSlider value={pibGraph.selection} defaultValue={this.state.pib.selection} id="pib-total" onChange={this.handlePibIntensityChange} aria-labelledby="continuous-slider" />

                                </div>
                                <div className="unit-main" style={{ color: "rgb(243, 194, 64)", fontSize: "1.6rem" }}>{((Math.exp(Math.log((this.state.pib.PIB / this.state.pib.initialPIB)) / 32) - 1) * 100).toFixed(1)} % de croissance par an</div>
                            </div>}


                        </div>
                        <div className="transition-results">

                            <div className="game-icon-list">

                                {blackOutRisk}

                                {icons}

                                {this.state.pib.intensity != this.state.pib.initialIntensity && pibIntensityIcon}
                            </div>
                        </div>
                    </div>


                </div>
                <div className="image-source" style={{ zIndex: 10, marginBottom: '4rem' }}>
                    <div >
                        BP Statistical Review, Banque Mondiale
                        <Reference index="i" content="<em>BP Statistical Review,</em> <a href='https://knoema.com/BPWES2017/bp-statistical-review-of-world-energy-main-indicators' target='_blank'>BP Statistical Review of World Energy - Main Indicators</a>, 2019 (en)<br/><br/><em>La banque mondiale,</em> <a href='https://donnees.banquemondiale.org/indicateur/EG.USE.COMM.GD.PP.KD' target='_blank'>Utilisation d’énergie (en kg d’équivalent pétrole) pour 1 000 $ de PIB (PPA constants de 2011)</a>.<br/><br/>Pour le solaire et l'éolien : les pertes dues au stockage ne sont pas ici prises en compte, ce qui tend plutôt à donner une fourchette basse. La durée de vie des panneaux solaires ou des éoliennes est supposée être de 25 ans — les résultats étant sur 30 ans, un facteur de 1,2 s'applique." />
                    </div>
                </div>
            </>
        )
    }
}

const GreenSlider = withStyles({
    root: {
        color: '#09bc8a',
        height: 8,
        "& . Mui-disabled": {
            color: '#09bc8a',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgba(12, 238, 174, 0.301)',
        },
        '& $active': {
            boxShadow: '0px 0px 0px 14px rgba(12, 238, 174, 0.301)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
        color: '#09bc8a',
        marginLeft: '-10px',
    },

})(Slider);


const DarkSlider = withStyles({

    root: {
        color: '#595A5A',
        height: 8,
        "& . Mui-disabled": {
            color: '#595A5A',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0.3)',
        },
        '& $active': {
            color: '#595A5A',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0.3)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
        color: '#595A5A',
        marginLeft: '-10px',
    },

})(Slider);

const EmissionSlider = withStyles({

    root: {
        color: '#595A5A',
        height: 8,
        "& . Mui-disabled": {
            color: '#595A5A',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#595A5A',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        marginLeft: '-10px',
        borderRadius: 4,
        color: '#595A5A',
    },

})(Slider);


const EnergySlider = withStyles({

    root: {
        color: '#3373BA',
        height: 8,
        "& . Mui-disabled": {
            color: '#3373BA',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#3373BA',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        marginLeft: '-10px',
        borderRadius: 4,
        color: '#3373BA',
    },

})(Slider);


const PibSlider = withStyles({
    root: {
        color: '#F3C240',
        height: 8,
        cursor: 'pointer',
        "&.Mui-disabled": {
            color: '#F3C240',
        },
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#595A5A',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
        color: '#F3C240',
        marginLeft: '-10px',
    },

})(Slider);

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

const easeInOutCubic = (t) => {
    return t * (2 - t);
}

const profileWeight = [1, 1.022, 1.037, 1.045, 1.045, 1.038, 1.031, 1.024, 1.01, 0.996, 0.982, 0.97, 0.957, 0.945, 0.934, 0.924, 0.914, 0.905, 0.897, 0.89, 0.884, 0.879, 0.876, 0.875, 0.875, 0.879, 0.886, 0.899, 0.919, 0.95, 1];

