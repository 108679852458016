import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SimulatorList from '../components/SimulatorList'
import { useParams } from "react-router-dom";


const Simulator = () => {

    const { id } = useParams();

    return (
        <p>
            <Navbar />
            <SimulatorList />
        </p>
    )
}

export default Simulator
