import React, { Component } from 'react'

import lightBulb from '../images/curriculums/light-bulb.jpg'
import CoalGame from '../components/CoalGame'
import TransitionGame from '../components/TransitionGame'
import Reference from '../components/Reference'
import transition from '../images/simulators/transition-2050.jpg'
import charbon from '../images/simulators/ decarboner-elec.jpg'


export const simulators = [{
    title: "Décarboner l'électricité",
    url: "decarboner-l-electricite",
    image: charbon,
    content:
        <CoalGame displayedItems={["gas", "nuclear", "hydro", "wind", "solar", "storage"]} index="1" source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="7" content="
    
        <li><strong>Gaz</strong> - D'après la moyenne française de 465MW, sur la décennie 2005 - 2016.</li>
        <li><strong>Nucléaire</strong> - Sur la bae d'un réacteur EPR, tel que celui en construction à Flamanville, avec une puissance de 1500MW.</li>
        <li><strong>Hydroélectricité</strong> - D'après Le plus grand barrage de France, à Serre-Ponçon qui a une puissance de 380MW</li>
        <li><strong>Éolien</strong> - Basé sur la moyenne de 3MW pour les éoliennes terrestres en France.</li>
        <li><strong>Solaire</strong> - Un panneau solaire standard a une surface de 1,70m², avec une puissance de 300 Watts-Crête, soit 0,0003 MW. </li><br/>

RTE, <a target='_blank' href='https://www.services-rte.com/fr/telechargez-les-donnees-publiees-par-rte.html?category=generation&type=actual_generations_per_production_type'>Production : Production réalisée aggrégée par filières</a>, 2019
<br/><br/>

GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br/> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />,
    description: "Débarassons-nous du charbon ! ",
},
{
    title: "Transition globale 2050",
    url: "objectif-2C",
    image: transition,
    content: <TransitionGame withPib="true" isFullScreen={true} />,
    description: "Quels scénarios à horizon 2050 permettent de garder le réchauffement climatique sous les 2 °C ?  ",
}
]