import React, { Component } from 'react'
import QuizzModal from './QuizzModal.js'
import { Link } from 'react-router-dom'


import { backroundImage } from '../images/hero/background-hero.jpg'


import { TransitionGroup, CSSTransition } from "react-transition-group";

export default class Hero extends Component {

    constructor(props) {
        super(props);
    }

    quizzCallback = (dataFromChild) => {

        this.setState(
            {
                isModalDisplayed: false,
                modalClassDisplay: dataFromChild
            }
        )
    }

    handleQuizzOnClick = () => {

        this.setState(
            {
                isModalDisplayed: true,
                modalClassDisplay: "modal"
            }
        )
    }

    handleOnScroll = () => {

        window.scrollTo({
            top: document.documentElement.clientHeight,
            behavior: 'smooth',
        });
    }

    handleLearnOnClick = () => {
        this.props.callbackFromHome();
    }

    render() {

        return <div className="hero" style={{ background: 'background: linear-gradient(to right top, rgba(3, 100, 141, 0.7), rgb(9, 190, 139, 0.5)), url("https://images.unsplash.com/photo-1490100667990-4fced8021649?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80");' }}>

            <div className="hero-title">
                <div className="hero-text-container">
                    <h2>Formez-vous<br />aux enjeux climatiques&nbsp;</h2>

                    <div className="hero-btn-container">
                        <Link to="/la-double-contrainte-carbone">
                            <div className="btn green-btn" >Commencer maintenant</div>
                        </Link>
                    </div>
                </div>
            </div>
            {this.state.isModalDisplayed && <QuizzModal callbackFromHero={this.quizzCallback} />}
            <div class="fog__container">
                <div class="fog__img fog__img--first"></div>
                <div class="fog__img fog__img--second"></div>
            </div>

            <a href="#" class="scroll-down" address="true" onClick={this.handleOnScroll}></a>

        </div >
    }

    state = {
        modalClassDisplay: "modal u-hide",
        isModalDisplayed: false
    }
}
