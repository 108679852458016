import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'
import EmpilementSim from '../../components/Empilement'

import TransitionGame from '../../components/TransitionGame'

import DescenteEnergetiqueImg from './images/descente-energetique.jpg'
import DescenteEnergetiqueImgMobile from './images/descente-energetique-MOB.jpg'
import EffetRebond from './images/effet-rebond.jpg'
import Empilement from './images/empilement.jpg'
import EmpilementMobile from './images/empilement-MOB.jpg'
import EfficaciteENR from './images/efficacite-thermique-enr.jpg'
import EfficaciteVehicule from './images/efficacite-vehicule.jpg'


function DescenteEnergetique() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/la-descente-energetique");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Le nucléaire et les barrages hydroélectriques ne peuvent être déployés partout en même temps, les énergies éolienne et solaire sont très diffuses et se heurtent toujours au problème de l’intermittence, et l’avenir des technologies de capture du carbone reste toujours aussi incertain. En définitive, remplacer les énergies fossiles dans leur quasi-intégralité, sur une période de 30&nbsp;ans, reste à ce jour totalement hors de portée.</p>

                    <p>Dès lors, diminuer nos émissions de CO<sub>2</sub> dans les proportions attendues demande de substantielles économies d’énergie. </p>

                    <p>Réduire la consommation d’énergie ne laisse guère que deux options&#8239;:</p>
                    <ul>
                        <li>Améliorer l'efficacité énergétique pour conserver les mêmes usages avec moins d'énergie&#8239;;</li>
                        <li>Diminuer les usages pour diminuer la consommation d'énergie.</li>
                    </ul>

                    <div className="image-source">
                        <img src={DescenteEnergetiqueImg} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={DescenteEnergetiqueImgMobile} style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                    </div>

                    <p>L'efficacité énergétique joue souvent le rôle de joker permettant de rendre «&#8239;faisable&#8239;» ou «&#8239;possible&#8239;» à peu près n'importe quel scénario. Malheureusement, en la matière la prudence doit rester de mise car les résultats se montrent souvent décevants. À cause de contraintes technologiques mais surtout d'un phénomène socio-économique connu sous le nom «&#8239;d'effet rebond&#8239;». </p>

                    <h2>L’effet rebond</h2>

                    <p>Dans les pays développés, la rénovation énergétique des bâtiments arrive systématiquement en tête des propositions écologiques faisant consensus. Une telle mesure s’avère d'autant plus pertinente que les énergies fossiles —&nbsp;fioul, gaz&nbsp;— restent prédominantes dans le chauffage des habitations.</p>

                    <p>En la matière, l’Allemagne fait figure de bon élève. Remplacement des fenêtres, installation de nouveaux systèmes de chauffage, isolement des façades… Depuis&nbsp;2010, le pays a investi plus de 340&nbsp;milliards d’euros dans des travaux colossaux.</p>

                    <p>Résultat des courses&#8239;: la consommation d’énergie des foyers est restée stable. Mais les ménages allemands qui avant se chauffaient en moyenne à 20°C profitent désormais d’un intérieur à 22 °C.

                        <Reference index="2" content="<em>GdW</em>, <a target='_blank' href='https://www.gdw.de/media/2020/07/jpk2020-praesentation-1.6-mit-kmt-o-bs-.pdf#page=32'>Die Wohnungswirtschaft in Deutschland</a>, July 2020 (de)" />
                    </p>

                    <p>On observe ce phénomène, de manière un peu moins marquée, partout. En règle générale, les économies d’énergie observées après rénovation ne dépassent pas 70% de celles initialement escomptées.
                        <Reference index="3" content="UK Energy Research Centre, <em>UKERC</em>,
                                <a target='_blank' href='http://www.ukerc.ac.uk/asset/3B43125E-EEBD-4AB3-B06EA914C30F7B3E/'>The Rebound Effect: An Assessment of the Evidence for Economy-wide Energy Savings from Improved Energy Efficiency</a>, Page 36, Table 3.1 «&nbsp;Estimates of the long-run direct rebound effect for consumer energy services in the OECD&nbsp;», October 2007 (en)" />
                    </p>

                    <p>Car l’efficacité énergétique entraine un effet pervers : celui de rendre l’usage visé moins cher, ou moins culpabilisant, et donc plus attractif. Le gain d’efficacité peut ainsi se voir annulé par des changements de comportements qui mènent à une augmentation des usages concernés&#8239;: c’est l’effet rebond.</p>

                    <div className="image-source">
                        <img src={EffetRebond} style={{ maxWidth: '35rem' }}></img>
                    </div>

                    <p>Sur le sujet de l’efficacité énergétique des bâtiments, précisons qu’une très bonne option pour diminuer l’empreinte énergétique du chauffage s’appuie sur un déploiement massif de pompes à chaleur. Elles permettent de puiser de la chaleur —&nbsp;ou en été, de la fraîcheur&nbsp;— présente naturellement dans le sol, ce qui réduit très fortement la facture d’électricité par rapport à un chauffage ou une climatisation classique. Souvent oubliées, elles font pourtant bien partie des énergies renouvelables et permettent des économies d’électricité tout à fait significatives.</p>

                    <p>Dans l’automobile, la baisse continue de la consommation des moteurs ne s’est pas traduite par une réduction des émissions, bien au contraire. Partout dans le monde, la mode est désormais aux SUV : des véhicules plus hauts, plus larges et plus lourds. Sur la dernière décennie, la mise en circulation de ces 4x4 urbains constitue le deuxième poste d’augmentation des émissions, derrière le charbon.
                        <Reference index="4" content="Agence Internationale de l'énergie (AIE) <a target='_blank' href='https://www.iea.org/commentaries/growing-preference-for-suvs-challenges-emissions-reductions-in-passenger-car-market'>Growing preference for SUVs challenges emissions reductions in passenger car market</a>, Octobre 2019 (en)." />

                    </p>

                    <p>En outre, lorsqu’il s’agit d’argent, «&nbsp;économiser&nbsp;» permet d’éviter une dépense à un instant donné pour mettre de côté, et donc dépenser plus tard. Mais dans le cas de l’énergie, cela ne fonctionne pas de cette manière. Si les économies réalisées sur des postes de dépense énergétique, par exemple de chauffage, se reportent après coup sur d’autres secteurs encore plus énergivores, comme acheter un second véhicule, ou voyager en avion, alors le gain initial se voit annulé. Ce cas de figure relève aussi d’une forme d’effet rebond, cette fois-ci indirect.</p>

                    <p>Pour finir, diminuer légèrement l’impact écologique d’une pratique, en recourant par exemple à des mécanismes dits de « compensation », a tendance à générer un «&#8239;effet indulgence&#8239;». Déculpabiliser le consommateur revient de fait à favoriser l’essor du problème contre lequel on prétend lutter.</p>


                    <h2>L’empilement</h2>

                    <p>La 5G annonce une vraie rupture technologique. Son réseau fonctionne grâce à des antennes miniatures capables de focaliser le signal vers son lieu d’émission ou de réception. Grâce à de l’intelligence artificielle, elles pourraient apprendre pour s’adapter encore plus finement aux usages. En outre, de tels équipements bénéficient de fonctionnalités leur permettant de se mettre en veille automatiquement en cas de baisse du trafic.</p>

                    <p>Pour transmettre une certaine quantité de données, cette technologie permet assurément une amélioration de l’efficacité énergétique. Mais avec un débit beaucoup plus élevé, cette nouvelle technologie va aussi pousser les utilisateurs à consommer encore plus de données : encore un exemple d’effet rebond.</p>

                    <p>Surtout, la 5G va venir se greffer aux réseaux 4G qui, jusqu’à preuve du contraire, resteront en place. De même qu’en son temps la 4G est venue s’ajouter aux réseaux 3G et 2G, toujours en service aujourd’hui.</p>

                    <p>De la même manière, s’ils ne font que se rajouter au parc existant, des avions, des navires, des véhicules plus économes en carburant contribueront à augmenter les émissions de CO<sub>2</sub>.</p>

                    <div className="image-source">
                        <img src={Empilement} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={EmpilementMobile} style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                    </div>


                    <p> Continuer d’empiler, plutôt que d’enlever ou de substituer, se traduira toujours par une augmentation de la consommation énergétique. En fin de compte, la meilleure économie restera toujours la dépense énergétique qui n’est jamais effectuée.</p>

                    <EmpilementSim />

                    <p>En conclusion, des évolutions technologiques demeurent incontournables, mais de profonds changements de comportement chez tous les acteurs — consommateurs, citoyens, entreprises, administrations publiques — restent absolument fondamentaux.</p>


                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="la-descente-energetique" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="12. Énergie et PIB"
                        url="/croissance-verte"></RelatedArticles>


                </div>

            </div>

        </>
    );
}

export default DescenteEnergetique

