import React from 'react'

export const ReadingProgress = ({ target }) => {

    const [readingProgress, setReadingProgress] = React.useState(0);
    const scrollListener = () => {
        if (!target.current) {
            return;
        }

        const element = target.current;
        const offset = 650;

        let totalHeight = element.clientHeight;
        let windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        totalHeight = totalHeight - offset;

        if (windowScrollTop > offset) {
            windowScrollTop = windowScrollTop - offset;
        }
        else {
            windowScrollTop = 0;
        }

        if (windowScrollTop === 0) {
            return setReadingProgress(0);
        }

        if (windowScrollTop > totalHeight) {
            return setReadingProgress(100);
        }

        setReadingProgress((windowScrollTop / totalHeight) * 100);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", scrollListener);
        return () => window.removeEventListener("scroll", scrollListener);
    });

    return <div className={`reading-progress-bar`} style={{ width: `${readingProgress}%` }} />;
};

export default ReadingProgress