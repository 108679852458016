export const energyAbacus = [
    [
        // 0 SOLAIRE
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182],
        [0, 956, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964],
        [0, 1764, 2125, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127],
        [0, 2994, 3382, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511],
        [0, 3135, 4519, 5027, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064],
        [0, 3718, 5592, 6458, 6730, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742],
        [0, 4257, 6567, 7784, 8382, 8507, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510],
        [0, 4753, 7450, 9035, 9891, 10287, 10342, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343, 10343],
        [0, 5203, 8261, 10191, 11316, 11966, 12196, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224],
        [0, 5604, 9012, 11254, 12688, 13519, 14005, 14130, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143],
        [0, 5962, 9709, 12232, 13932, 15001, 15681, 16015, 16085, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090],
        [0, 6277, 10356, 13143, 15107, 16410, 17258, 17805, 18013, 18056, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057],
        [0, 6557, 10598, 13993, 16200, 17441, 18771, 19473, 19891, 20016, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042],
        [0, 6807, 11509, 14973, 17219, 18991, 20219, 21064, 21654, 21948, 22027, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041],
        [0, 7030, 12013, 15543, 18180, 20165, 21596, 22598, 23315, 23793, 23989, 24044, 24050, 24050, 24050, 24050, 24050, 24050, 24050, 24050, 24050],
        [0, 7228, 12475, 16427, 19083, 21267, 22895, 24068, 24912, 25532, 25901, 26027, 26065, 26067, 26067, 26067, 26067, 26067, 26067, 26067, 26067],
        [0, 7404, 12897, 16909, 19938, 22303, 24126, 25473, 26455, 27185, 27709, 27978, 28063, 28088, 28089, 28089, 28089, 28089, 28089, 28089, 28089],
        [0, 7560, 13281, 17530, 20746, 23286, 25287, 26810, 27939, 28782, 29423, 29850, 30037, 30100, 30115, 30115, 30115, 30115, 30115, 30115, 30115],
        [0, 7698, 13631, 18103, 21511, 24219, 26385, 28079, 29364, 30330, 31070, 31630, 31963, 32091, 32137, 32145, 32145, 32145, 32145, 32145, 32145]
    ],
    [
        // 1 SOLAIRE
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182, 182],
        [0, 946, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964, 964],
        [0, 1764, 2125, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127, 2127],
        [0, 2494, 3382, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511, 3511],
        [0, 3135, 4519, 5027, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064, 5064],
        [0, 3718, 5592, 6458, 6730, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742, 6742],
        [0, 4257, 6567, 7784, 8382, 8507, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510, 8510],
        [0, 4753, 7450, 9035, 9891, 10287, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342, 10342],
        [0, 5203, 8261, 10191, 11316, 11966, 12196, 12196, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224, 12224],
        [0, 5604, 9012, 11254, 12668, 13519, 14005, 14005, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143, 14143],
        [0, 5962, 9709, 12232, 13932, 15001, 15681, 15681, 16085, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090, 16090],
        [0, 6277, 10356, 13143, 15107, 16410, 17258, 17258, 18013, 18056, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057, 18057],
        [0, 6557, 10958, 13993, 16200, 17741, 18771, 18771, 19891, 20016, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042, 20042],
        [0, 6807, 11509, 14793, 17219, 18991, 20219, 20219, 21654, 21948, 22027, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041, 22041],
        [0, 7030, 12013, 15543, 18180, 20165, 21596, 21596, 23315, 23793, 23989, 24044, 24050, 24050, 24050, 24050, 24050, 24050, 24050, 24050, 24050],
        [0, 7228, 12475, 16247, 19083, 21267, 22895, 22895, 24912, 25532, 25901, 26027, 26065, 26067, 26067, 26067, 26067, 26067, 26067, 26067, 26067],
        [0, 7404, 12897, 16909, 19938, 22303, 24126, 24126, 26455, 27185, 27709, 27978, 28063, 28088, 28089, 28089, 28089, 28089, 28089, 28089, 28089],
        [0, 7560, 13281, 17530, 20746, 23286, 25287, 25287, 27939, 28782, 29423, 29850, 30037, 30100, 30115, 30115, 30115, 30115, 30115, 30115, 30115],
        [0, 7228, 13631, 18103, 21511, 24219, 26385, 26385, 29364, 30330, 31070, 31630, 31963, 32091, 32137, 32145, 32145, 32145, 32145, 32145, 32145]
    ],
    [
        // 2 SOLAIRE
        [0, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99],
        [0, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99],
        [0, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280, 280],
        [0, 1044, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063, 1063],
        [0, 1861, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224, 2224],
        [0, 2587, 3480, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609, 3609],
        [0, 3226, 4617, 5125, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162, 5162],
        [0, 3805, 5686, 6556, 6828, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841, 6841],
        [0, 4341, 6660, 7882, 8480, 8605, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609, 8609],
        [0, 4834, 7540, 9130, 9989, 10385, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441, 10441],
        [0, 5279, 8349, 10284, 11414, 12064, 12295, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322, 12322],
        [0, 5676, 9098, 11346, 12764, 13617, 14103, 14228, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241, 14241],
        [0, 6031, 9794, 12323, 14026, 15098, 15780, 16114, 16183, 16188, 16188, 16188, 16188, 16188, 16188, 16188, 16188, 16188, 16188, 16188, 16188],
        [0, 6344, 10440, 13233, 15200, 16507, 17356, 17903, 18112, 18155, 18156, 18156, 18156, 18156, 18156, 18156, 18156, 18156, 18156, 18156, 18156],
        [0, 6621, 11038, 14081, 16292, 17836, 18869, 19571, 19990, 20115, 20140, 20140, 20140, 20140, 20140, 20140, 20140, 20140, 20140, 20140, 20140],
        [0, 6868, 11586, 14879, 17310, 19084, 20316, 21162, 21752, 22047, 22125, 22139, 22139, 22139, 22139, 22139, 22139, 22139, 22139, 22139, 22139],
        [0, 7088, 12088, 15628, 18270, 20257, 21691, 22696, 23413, 23892, 24088, 24142, 24149, 24149, 24149, 24149, 24149, 24149, 24149, 24149, 24149],
        [0, 7283, 12547, 16331, 19172, 21358, 22989, 24165, 25010, 25630, 26000, 26126, 26163, 26163, 26163, 26163, 26163, 26163, 26163, 26163, 26163],
        [0, 7456, 12967, 16992, 20025, 22394, 24219, 25569, 26553, 27283, 27808, 28076, 28162, 28162, 28162, 28162, 28162, 28162, 28162, 28162, 28162],
        [0, 7609, 13349, 17609, 20832, 23376, 25379, 26904, 28036, 28880, 29522, 29949, 30135, 30135, 30135, 30135, 30135, 30135, 30135, 30135, 30135],
        [0, 7744, 13697, 18180, 21597, 24308, 26477, 28172, 29460, 30428, 31169, 31728, 32062, 32062, 32062, 32062, 32062, 32062, 32062, 32062, 32062]
    ],
    [
        // 3 SOLAIRE
        [0, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688],
        [0, 687, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688, 688],
        [0, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869, 869],
        [0, 1619, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652, 1652],
        [0, 2408, 2812, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814, 2814],
        [0, 3107, 4050, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198, 4198],
        [0, 3718, 5170, 5709, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751, 5751],
        [0, 4270, 6224, 7124, 7417, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430, 7430],
        [0, 4781, 7181, 8438, 9059, 9194, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198, 9198],
        [0, 5251, 8044, 9674, 10556, 10971, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030, 11030],
        [0, 5673, 8836, 10817, 11973, 12639, 12884, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911, 12911],
        [0, 6045, 9571, 11868, 13312, 14183, 14685, 14818, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830, 14830],
        [0, 6374, 10250, 12832, 14565, 15658, 16352, 16700, 16772, 16777, 16777, 16777, 16777, 16777, 16777, 16777, 16777, 16777, 16777, 16777, 16777],
        [0, 6663, 10879, 13727, 15730, 17058, 17922, 18482, 18700, 18744, 18745, 18745, 18745, 18745, 18745, 18745, 18745, 18745, 18745, 18745, 18745],
        [0, 6921, 11462, 14565, 16813, 18380, 19430, 20142, 20572, 20704, 20729, 20729, 20729, 20729, 20729, 20729, 20729, 20729, 20729, 20729, 20729],
        [0, 7150, 11996, 15354, 17823, 19620, 20869, 21728, 22328, 22633, 22714, 22728, 22728, 22728, 22728, 22728, 22728, 22728, 22728, 22728, 22728],
        [0, 7355, 12484, 16092, 18770, 20786, 22238, 23258, 23984, 24473, 24676, 24731, 24738, 24738, 24738, 24738, 24738, 24738, 24738, 24738, 24738],
        [0, 7534, 12928, 16782, 19662, 21880, 23529, 24721, 25576, 26204, 26583, 26715, 26752, 26754, 26754, 26754, 26754, 26754, 26754, 26754, 26754],
        [0, 7691, 13332, 17431, 20507, 22908, 24752, 26117, 27115, 27853, 28387, 28663, 28751, 28776, 28776, 28776, 28776, 28776, 28776, 28776, 28776],
        [0, 7828, 13698, 18037, 21307, 23881, 25906, 27447, 28593, 29446, 30095, 30531, 30724, 30788, 30803, 30803, 30803, 30803, 30803, 30803, 30803],
        [0, 7949, 14030, 18598, 22065, 24803, 26999, 28710, 30010, 30991, 31737, 32305, 32646, 32778, 32825, 32833, 32833, 32833, 32833, 32833, 32833]
    ],
    [
        // 4 SOLAIRE
        [0, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332, 1332],
        [0, 1332, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529, 1529],
        [0, 1510, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710, 1710],
        [0, 2222, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493, 2493],
        [0, 2964, 3634, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656, 3656],
        [0, 3620, 4837, 5037, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039, 5039],
        [0, 4194, 5925, 6522, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593, 6593],
        [0, 4712, 6944, 7913, 8248, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271, 8271],
        [0, 5192, 7867, 9206, 9868, 10034, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039, 10039],
        [0, 5629, 8701, 10419, 11347, 11794, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871, 11871],
        [0, 6020, 9462, 11537, 12746, 13445, 13717, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753, 13753],
        [0, 6367, 10170, 12563, 14069, 14975, 15503, 15656, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672, 15672],
        [0, 6672, 10828, 13508, 15304, 16436, 17156, 17527, 17613, 17618, 17618, 17618, 17618, 17618, 17618, 17618, 17618, 17618, 17618, 17618, 17618],
        [0, 6941, 11434, 14386, 16450, 17821, 18715, 19297, 19535, 19585, 19586, 19586, 19586, 19586, 19586, 19586, 19586, 19586, 19586, 19586, 19586],
        [0, 7177, 11992, 15201, 17514, 19129, 20210, 20944, 21395, 21542, 21571, 21571, 21571, 21571, 21571, 21571, 21571, 21571, 21571, 21571, 21571],
        [0, 7386, 12498, 15970, 18506, 20354, 21637, 22522, 23140, 23462, 23555, 23570, 23570, 23570, 23570, 23570, 23570, 23570, 23570, 23570, 23570],
        [0, 7569, 12958, 16690, 19442, 21505, 22994, 24040, 24785, 25292, 25511, 25572, 25579, 25579, 25579, 25579, 25579, 25579, 25579, 25579, 25579],
        [0, 7728, 13377, 17366, 20320, 22585, 24273, 25491, 26371, 27013, 27409, 27553, 27593, 27596, 27596, 27596, 27596, 27596, 27596, 27596, 27596],
        [0, 7867, 13758, 17999, 21149, 23598, 25483, 26879, 27900, 28653, 29203, 29493, 29591, 29617, 29618, 29618, 29618, 29618, 29618, 29618, 29618],
        [0, 7986, 14105, 18588, 21933, 24559, 26626, 28197, 29366, 30241, 30901, 31353, 31559, 31628, 31644, 31644, 31644, 31644, 31644, 31644, 31644],
        [0, 8090, 14421, 19131, 22676, 25471, 27706, 29449, 30775, 31776, 32537, 33118, 33474, 33616, 33665, 33674, 33674, 33674, 33674, 33674, 33674]
    ],
    [
        // 5 SOLAIRE
        [0, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805, 1805],
        [0, 2461, 2461, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495, 2495],
        [0, 5469, 2642, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677, 2677],
        [0, 6221, 3412, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460, 3460],
        [0, 7304, 4515, 4621, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622, 4622],
        [0, 8548, 5669, 5980, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006, 6006],
        [0, 9864, 6707, 7433, 7554, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559, 7559],
        [0, 11091, 7680, 8793, 9183, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238, 9238],
        [0, 12226, 8559, 10053, 10777, 10986, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005, 11005],
        [0, 13287, 9356, 11232, 12235, 12724, 12833, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838, 12838],
        [0, 14265, 10085, 12317, 13610, 14354, 14661, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719, 14719],
        [0, 15168, 10759, 13314, 14908, 15867, 16429, 16611, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638, 16638],
        [0, 15999, 11381, 14232, 16117, 17308, 18065, 18466, 18575, 18585, 18585, 18585, 18585, 18585, 18585, 18585, 18585, 18585, 18585, 18585, 18585],
        [0, 16768, 11954, 15081, 17238, 18674, 19609, 20218, 20483, 20550, 20553, 20553, 20553, 20553, 20553, 20553, 20553, 20553, 20553, 20553, 20553],
        [0, 17483, 12481, 15876, 18281, 19962, 21087, 21854, 22330, 22499, 22536, 22537, 22537, 22537, 22537, 22537, 22537, 22537, 22537, 22537, 22537],
        [0, 18155, 12960, 16626, 19257, 21168, 22499, 23418, 24059, 24406, 24516, 24536, 24536, 24536, 24536, 24536, 24536, 24536, 24536, 24536, 24536],
        [0, 18781, 13394, 17326, 20170, 22297, 23840, 24921, 25695, 26223, 26461, 26536, 26546, 26546, 26546, 26546, 26546, 26546, 26546, 26546, 26546],
        [0, 19362, 13788, 17979, 21026, 23359, 25103, 26359, 27268, 27931, 28348, 28510, 28558, 28562, 28562, 28562, 28562, 28562, 28562, 28562, 28562],
        [0, 19901, 14148, 18587, 21839, 24360, 26296, 27733, 28783, 29563, 30130, 30440, 30552, 30583, 30584, 30584, 30584, 30584, 30584, 30584, 30584],
        [0, 20399, 14473, 19151, 22608, 25308, 27420, 29038, 30239, 31140, 31818, 32289, 32511, 32592, 32611, 32611, 32611, 32611, 32611, 32611, 32611],
        [0, 20853, 14767, 19669, 23337, 26202, 28485, 30276, 31636, 32662, 33447, 34042, 34416, 34573, 34630, 34641, 34641, 34641, 34641, 34641, 34641]
    ],
    [
        // 6 SOLAIRE
        [0, 0, 3230, 3524, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525],
        [0, 2150, 3230, 3524, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525],
        [0, 2312, 3409, 3706, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3707],
        [0, 2949, 4153, 4486, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490, 4490],
        [0, 3614, 5209, 5630, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652, 5652],
        [0, 4207, 6315, 6957, 7032, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036, 7036],
        [0, 4735, 7307, 8367, 8558, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589, 8589],
        [0, 5212, 8238, 9684, 10158, 10259, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268, 10268],
        [0, 5652, 9079, 10899, 11723, 11983, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035, 12035],
        [0, 6053, 9843, 12040, 13152, 13695, 13847, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868, 13868],
        [0, 6407, 10542, 13086, 14496, 15304, 15652, 15742, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749, 15749],
        [0, 6717, 11190, 14050, 15763, 16795, 17398, 17618, 17667, 17668, 17668, 17668, 17668, 17668, 17668, 17668, 17668, 17668, 17668, 17668, 17668],
        [0, 6988, 11788, 14938, 16943, 18212, 19017, 19452, 19591, 19615, 19615, 19615, 19615, 19615, 19615, 19615, 19615, 19615, 19615, 19615, 19615],
        [0, 7224, 12336, 15761, 18037, 19555, 20543, 21186, 21482, 21573, 21583, 21583, 21583, 21583, 21583, 21583, 21583, 21583, 21583, 21583, 21583],
        [0, 7430, 12838, 16526, 19055, 20819, 22003, 22808, 23311, 23508, 23564, 23567, 23567, 23567, 23567, 23567, 23567, 23567, 23567, 23567, 23567],
        [0, 7610, 13294, 17245, 20005, 22001, 23395, 24356, 25027, 25400, 25533, 25565, 25566, 25566, 25566, 25566, 25566, 25566, 25566, 25566, 25566],
        [0, 7769, 13707, 17915, 20897, 23111, 24717, 25843, 26650, 27201, 27465, 27559, 27575, 27576, 27576, 27576, 27576, 27576, 27576, 27576, 27576],
        [0, 7906, 14080, 18541, 21737, 24156, 25960, 27266, 28208, 28899, 29338, 29521, 29585, 29592, 29592, 29592, 29592, 29592, 29592, 29592, 29592],
        [0, 8024, 14416, 19124, 22533, 25138, 27132, 28624, 29710, 30519, 31106, 31438, 31570, 31611, 31614, 31614, 31614, 31614, 31614, 31614, 31614],
        [0, 8127, 14720, 19663, 23284, 26063, 28240, 29912, 31152, 32082, 32786, 33275, 33517, 33615, 33639, 33641, 33641, 33641, 33641, 33641, 33641],
        [0, 8216, 14993, 20160, 23991, 26940, 29293, 31133, 32536, 33593, 34404, 35017, 35411, 35585, 35656, 35670, 35671, 35671, 35671, 35671, 35671]

    ],
    [
        // 7 SOLAIRE
        [0, 2418, 3840, 4489, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592],
        [0, 2418, 3840, 4489, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592, 4592],
        [0, 2573, 4012, 4670, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774, 4774],
        [0, 3179, 4729, 5440, 5556, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557, 5557],
        [0, 3817, 5743, 6555, 6714, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719, 6719],
        [0, 4392, 6804, 7840, 8077, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103, 8103],
        [0, 4906, 7758, 9200, 9575, 9650, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656, 9656],
        [0, 5370, 8654, 10470, 11141, 11299, 11334, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335, 11335],
        [0, 5797, 9466, 11643, 12668, 12998, 13090, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102, 13102],
        [0, 6182, 10208, 12742, 14059, 14683, 14879, 14933, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935, 14935],
        [0, 6522, 10885, 13750, 15366, 16266, 16662, 16790, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816, 16816],
        [0, 6818, 11512, 14680, 16601, 17729, 18386, 18645, 18726, 18735, 18735, 18735, 18735, 18735, 18735, 18735, 18735, 18735, 18735, 18735, 18735],
        [0, 7077, 12092, 15537, 17748, 19119, 19987, 20458, 20633, 20679, 20682, 20682, 20682, 20682, 20682, 20682, 20682, 20682, 20682, 20682, 20682],
        [0, 7302, 12621, 16327, 18813, 20435, 21491, 22176, 22505, 22625, 22649, 22650, 22650, 22650, 22650, 22650, 22650, 22650, 22650, 22650, 22650],
        [0, 7499, 13102, 17064, 19806, 21673, 22929, 23781, 24315, 24544, 24623, 24634, 24634, 24634, 24634, 24634, 24634, 24634, 24634, 24634, 24634],
        [0, 7672, 13537, 17757, 20733, 22831, 24299, 25311, 26018, 26418, 26579, 26629, 26633, 26633, 26633, 26633, 26633, 26633, 26633, 26633, 26633],
        [0, 7824, 13929, 18405, 21603, 23917, 25599, 26781, 27626, 28204, 28496, 28612, 28641, 28642, 28642, 28642, 28642, 28642, 28642, 28642, 28642],
        [0, 7955, 14283, 19006, 22417, 24940, 26819, 28186, 29168, 29890, 30352, 30560, 30643, 30659, 30659, 30659, 30659, 30659, 30659, 30659, 30659],
        [0, 8067, 14601, 19564, 23186, 25900, 27974, 29526, 30657, 31497, 32109, 32465, 32618, 32673, 32681, 32681, 32681, 32681, 32681, 32681, 32681],
        [0, 8164, 14889, 20081, 23911, 26810, 29067, 30795, 32084, 33046, 33778, 34287, 34551, 34668, 34704, 34708, 34708, 34708, 34708, 34708, 34708],
        [0, 8249, 15149, 20556, 24594, 27672, 30102, 31996, 33452, 34545, 35384, 36020, 36433, 36627, 36715, 36736, 36738, 36738, 36738, 36738, 36738]

    ],
    [
        // 8 SOLAIRE
        [0, 2627, 4330, 5290, 5664, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684],
        [0, 2627, 4330, 5290, 5664, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684, 5684],
        [0, 2774, 4498, 5469, 5845, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866, 5866],
        [0, 3356, 5190, 6221, 6624, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649, 6649],
        [0, 3975, 6166, 7304, 7768, 7810, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811, 7811],
        [0, 4535, 7188, 8548, 9103, 9188, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195, 9195],
        [0, 5037, 8114, 9864, 10565, 10718, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748, 10748],
        [0, 5488, 8983, 11091, 12083, 12343, 12417, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427, 12427],
        [0, 5903, 9776, 12226, 13567, 14014, 14153, 14193, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195, 14195],
        [0, 6277, 10497, 13287, 14917, 15666, 15921, 16010, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027, 16027],
        [0, 6605, 11157, 14265, 16188, 17217, 17679, 17848, 17904, 17908, 17908, 17908, 17908, 17908, 17908, 17908, 17908, 17908, 17908, 17908, 17908],
        [0, 6891, 11766, 15168, 17384, 18647, 19380, 19682, 19797, 19827, 19827, 19827, 19827, 19827, 19827, 19827, 19827, 19827, 19827, 19827, 19827],
        [0, 7142, 12326, 15999, 18495, 20006, 20956, 21476, 21685, 21762, 21774, 21774, 21774, 21774, 21774, 21774, 21774, 21774, 21774, 21774, 21774],
        [0, 7361, 12837, 16768, 19528, 21294, 22435, 23175, 23538, 23690, 23738, 23742, 23742, 23742, 23742, 23742, 23742, 23742, 23742, 23742, 23742],
        [0, 7552, 13300, 17483, 20491, 22502, 23848, 24760, 25332, 25592, 25698, 25726, 25726, 25726, 25726, 25726, 25726, 25726, 25726, 25726, 25726],
        [0, 7719, 13718, 18155, 21390, 23633, 25195, 26270, 27019, 27449, 27639, 27711, 27725, 27725, 27725, 27725, 27725, 27725, 27725, 27725, 27725],
        [0, 7865, 14093, 18781, 22230, 24698, 26471, 27720, 28611, 29221, 29539, 29682, 29729, 29735, 29735, 29735, 29735, 29735, 29735, 29735, 29735],
        [0, 7990, 14433, 19362, 23017, 25700, 27670, 29104, 30138, 30894, 31381, 31616, 31720, 31749, 31751, 31751, 31751, 31751, 31751, 31751, 31751],
        [0, 8098, 14739, 19901, 23762, 26642, 28802, 30423, 31608, 32486, 33126, 33505, 33682, 33756, 33773, 33773, 33773, 33773, 33773, 33773, 33773],
        [0, 8192, 15016, 20399, 24466, 27531, 29874, 31672, 33018, 34021, 34783, 35314, 35603, 35740, 35791, 35800, 35800, 35800, 35800, 35800, 35800],
        [0, 8273, 15265, 20853, 25129, 28372, 30890, 32856, 34368, 35505, 36375, 37037, 37470, 37687, 37792, 37826, 37830, 37830, 37830, 37830, 37830]
    ],
    [
        // 9 SOLAIRE
        [0, 2789, 4730, 5962, 6608, 6791, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792],
        [0, 2789, 4730, 5962, 6608, 6791, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792, 6792],
        [0, 2929, 4892, 6136, 6789, 6972, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974, 6974],
        [0, 3492, 5563, 6867, 7559, 7753, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757, 7757],
        [0, 4097, 6504, 7919, 8679, 8909, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919, 8919],
        [0, 4644, 7498, 9123, 9983, 10272, 10301, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303, 10303],
        [0, 5136, 8399, 10398, 11405, 11777, 11847, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856, 11856],
        [0, 5578, 9248, 11590, 12882, 13373, 13499, 13533, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535, 13535],
        [0, 5983, 10023, 12691, 14324, 15002, 15215, 15289, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303, 15303],
        [0, 6347, 10729, 13723, 15635, 16618, 16959, 17089, 17132, 17135, 17135, 17135, 17135, 17135, 17135, 17135, 17135, 17135, 17135, 17135, 17135],
        [0, 6667, 11373, 14678, 16870, 18131, 18688, 18908, 18995, 19016, 19016, 19016, 19016, 19016, 19016, 19016, 19016, 19016, 19016, 19016, 19016],
        [0, 6947, 11966, 15561, 18031, 19528, 20361, 20720, 20871, 20929, 20935, 20935, 20935, 20935, 20935, 20935, 20935, 20935, 20935, 20935, 20935],
        [0, 7192, 12510, 16372, 19109, 20857, 21908, 22492, 22741, 22847, 22881, 22882, 22882, 22882, 22882, 22882, 22882, 22882, 22882, 22882, 22882],
        [0, 7406, 13004, 17122, 20114, 22110, 23358, 24169, 24574, 24760, 24834, 24850, 24850, 24850, 24850, 24850, 24850, 24850, 24850, 24850, 24850],
        [0, 7591, 13454, 17818, 21050, 23288, 24746, 25732, 26351, 26643, 26779, 26828, 26834, 26834, 26834, 26834, 26834, 26834, 26834, 26834, 26834],
        [0, 7754, 13857, 18472, 21923, 24391, 26067, 27219, 28021, 28484, 28704, 28802, 28832, 28833, 28833, 28833, 28833, 28833, 28833, 28833, 28833],
        [0, 7895, 14220, 19080, 22742, 25429, 27316, 28646, 29593, 30241, 30587, 30758, 30826, 30843, 30843, 30843, 30843, 30843, 30843, 30843, 30843],
        [0, 8017, 14548, 19642, 23508, 26406, 28490, 30010, 31101, 31900, 32415, 32676, 32806, 32852, 32859, 32859, 32859, 32859, 32859, 32859, 32859],
        [0, 8122, 14844, 20163, 24232, 27324, 29604, 31307, 32553, 33476, 34147, 34551, 34754, 34848, 34879, 34881, 34881, 34881, 34881, 34881, 34881],
        [0, 8213, 15112, 20642, 24915, 28188, 30657, 32535, 33944, 34995, 35792, 36348, 36660, 36821, 36888, 36907, 36908, 36908, 36908, 36908, 36908],
        [0, 8292, 15353, 21079, 25557, 29004, 31655, 33699, 35275, 36463, 37369, 38060, 38514, 38755, 38880, 38928, 38938, 38938, 38938, 38938, 38938]

    ],
    [
        // 10 SOLAIRE
        [0, 2919, 5064, 6528, 7417, 7841, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910],
        [0, 2919, 5064, 6528, 7417, 7841, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910, 7910],
        [0, 3054, 5222, 6698, 7597, 8023, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092, 8092],
        [0, 3603, 5870, 7409, 8352, 8800, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874, 8874],
        [0, 4194, 6782, 8434, 9448, 9943, 10033, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037, 10037],
        [0, 4733, 7753, 9602, 10721, 11282, 11409, 11420, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421, 11421],
        [0, 5218, 8636, 10841, 12105, 12757, 12939, 12971, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974, 12974],
        [0, 5652, 9468, 12004, 13543, 14313, 14571, 14640, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652, 14652],
        [0, 6048, 10227, 13078, 14947, 15904, 16263, 16380, 16417, 16420, 16420, 16420, 16420, 16420, 16420, 16420, 16420, 16420, 16420, 16420, 16420],
        [0, 6404, 10920, 14087, 16224, 17481, 17973, 18161, 18235, 18253, 18253, 18253, 18253, 18253, 18253, 18253, 18253, 18253, 18253, 18253, 18253],
        [0, 6719, 11549, 15023, 17425, 18956, 19670, 19959, 20082, 20129, 20134, 20134, 20134, 20134, 20134, 20134, 20134, 20134, 20134, 20134, 20134],
        [0, 6992, 12127, 15886, 18556, 20318, 21310, 21746, 21941, 22027, 22052, 22053, 22053, 22053, 22053, 22053, 22053, 22053, 22053, 22053, 22053],
        [0, 7232, 12657, 16681, 19610, 21610, 22824, 23493, 23792, 23930, 23990, 24000, 24000, 24000, 24000, 24000, 24000, 24000, 24000, 24000, 24000],
        [0, 7441, 13138, 17413, 20591, 22831, 24247, 25146, 25606, 25827, 25928, 25965, 25967, 25967, 25967, 25967, 25967, 25967, 25967, 25967, 25967],
        [0, 7622, 13574, 18093, 21507, 23977, 25609, 26681, 27362, 27691, 27858, 27933, 27952, 27952, 27952, 27952, 27952, 27952, 27952, 27952, 27952],
        [0, 7781, 13965, 18729, 22360, 25053, 26900, 28144, 29011, 29516, 29767, 29891, 29942, 29951, 29951, 29951, 29951, 29951, 29951, 29951, 29951],
        [0, 7919, 14318, 19318, 23159, 26066, 28123, 29549, 30562, 31258, 31635, 31833, 31925, 31957, 31960, 31960, 31960, 31960, 31960, 31960, 31960],
        [0, 8038, 14638, 19864, 23907, 27017, 29274, 30889, 32050, 32899, 33448, 33736, 33891, 33957, 33976, 33977, 33977, 33977, 33977, 33977, 33977],
        [0, 8140, 14927, 20369, 24614, 27909, 30364, 32162, 33481, 34457, 35167, 35597, 35824, 35943, 35990, 35999, 35999, 35999, 35999, 35999, 35999],
        [0, 8229, 15188, 20833, 25279, 28753, 31394, 33368, 34854, 35960, 36797, 37382, 37717, 37902, 37990, 38022, 38025, 38025, 38025, 38025, 38025],
        [0, 8306, 15423, 21257, 25903, 29550, 32372, 34514, 36165, 37410, 38359, 39082, 39559, 39822, 39970, 40034, 40054, 40055, 40055, 40055, 40055]

    ],
    [
        // 11 SOLAIRE
        [0, 3025, 5343, 7003, 8117, 8762, 9019, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034],
        [0, 3025, 5343, 7003, 8117, 8762, 9019, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034, 9034],
        [0, 3156, 5498, 7170, 8293, 8943, 9200, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216, 9216],
        [0, 3694, 6124, 7864, 9032, 9713, 9980, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999, 9999],
        [0, 4274, 7013, 8862, 10102, 10837, 11134, 11160, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161, 11161],
        [0, 4805, 7964, 9998, 11345, 12151, 12494, 12540, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545, 12545],
        [0, 5284, 8830, 11209, 12695, 13595, 14001, 14084, 14097, 14098, 14098, 14098, 14098, 14098, 14098, 14098, 14098, 14098, 14098, 14098, 14098],
        [0, 5710, 9648, 12346, 14096, 15117, 15606, 15738, 15772, 15777, 15777, 15777, 15777, 15777, 15777, 15777, 15777, 15777, 15777, 15777, 15777],
        [0, 6100, 10393, 13399, 15465, 16671, 17263, 17460, 17528, 17544, 17544, 17544, 17544, 17544, 17544, 17544, 17544, 17544, 17544, 17544, 17544],
        [0, 6451, 11072, 14391, 16713, 18209, 18937, 19220, 19332, 19372, 19377, 19377, 19377, 19377, 19377, 19377, 19377, 19377, 19377, 19377, 19377],
        [0, 6760, 11689, 15310, 17888, 19650, 20599, 20992, 21163, 21236, 21258, 21258, 21258, 21258, 21258, 21258, 21258, 21258, 21258, 21258, 21258],
        [0, 7029, 12255, 16156, 18996, 20979, 22205, 22748, 23003, 23121, 23170, 23177, 23177, 23177, 23177, 23177, 23177, 23177, 23177, 23177, 23177],
        [0, 7264, 12772, 16933, 20030, 22241, 23686, 24467, 24830, 25008, 25093, 25123, 25124, 25124, 25124, 25124, 25124, 25124, 25124, 25124, 25124],
        [0, 7470, 13241, 17651, 20994, 23431, 25077, 26091, 26623, 26887, 27017, 27079, 27092, 27092, 27092, 27092, 27092, 27092, 27092, 27092, 27092],
        [0, 7648, 13667, 18315, 21890, 24551, 26405, 27598, 28357, 28734, 28933, 29032, 29072, 29076, 29076, 29076, 29076, 29076, 29076, 29076, 29076],
        [0, 7803, 14051, 18936, 22725, 25604, 27669, 29037, 29983, 30541, 30824, 30977, 31052, 31074, 31075, 31075, 31075, 31075, 31075, 31075, 31075],
        [0, 7938, 14398, 19511, 23508, 26594, 28862, 30420, 31510, 32263, 32678, 32905, 33020, 33074, 33085, 33085, 33085, 33085, 33085, 33085, 33085],
        [0, 8055, 14711, 20044, 24240, 27524, 29987, 31734, 32975, 33885, 34477, 34792, 34973, 35062, 35097, 35101, 35101, 35101, 35101, 35101, 35101],
        [0, 8156, 14994, 20536, 24930, 28398, 31054, 32983, 34387, 35424, 36180, 36640, 36892, 37034, 37101, 37122, 37123, 37123, 37123, 37123, 37123],
        [0, 8242, 15249, 20988, 25579, 29222, 32063, 34169, 35739, 36908, 37792, 38413, 38773, 38980, 39091, 39139, 39150, 39150, 39150, 39150, 39150],
        [0, 8317, 15479, 21401, 26187, 30001, 33017, 35296, 37029, 38339, 39338, 40100, 40603, 40888, 41058, 41141, 41175, 41180, 41180, 41180, 41180]

    ],
    [
        // 12 SOLAIRE
        [0, 3113, 5577, 7413, 8727, 9572, 10030, 10161, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164],
        [0, 3113, 5577, 7413, 8727, 9572, 10030, 10161, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164, 10164],
        [0, 3240, 5727, 7577, 8899, 9752, 10212, 10343, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345, 10345],
        [0, 3768, 6334, 8256, 9621, 10510, 10988, 11124, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128, 11128],
        [0, 4340, 7204, 9226, 10668, 11613, 12130, 12282, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290, 12290],
        [0, 4864, 8140, 10335, 11885, 12902, 13470, 13656, 13672, 13674, 13674, 13674, 13674, 13674, 13674, 13674, 13674, 13674, 13674, 13674, 13674],
        [0, 5337, 8991, 11524, 13200, 14317, 14954, 15185, 15221, 15227, 15227, 15227, 15227, 15227, 15227, 15227, 15227, 15227, 15227, 15227, 15227],
        [0, 5758, 9795, 12639, 14568, 15804, 16527, 16818, 16889, 16904, 16906, 16906, 16906, 16906, 16906, 16906, 16906, 16906, 16906, 16906, 16906],
        [0, 6142, 10529, 13673, 15910, 17323, 18150, 18516, 18631, 18667, 18674, 18674, 18674, 18674, 18674, 18674, 18674, 18674, 18674, 18674, 18674],
        [0, 6489, 11197, 14649, 17133, 18827, 19789, 20249, 20418, 20486, 20505, 20506, 20506, 20506, 20506, 20506, 20506, 20506, 20506, 20506, 20506],
        [0, 6793, 11804, 15552, 18285, 20238, 21417, 21986, 22230, 22337, 22380, 22387, 22387, 22387, 22387, 22387, 22387, 22387, 22387, 22387, 22387],
        [0, 7058, 12358, 16384, 19372, 21537, 22987, 23713, 24048, 24206, 24280, 24305, 24306, 24306, 24306, 24306, 24306, 24306, 24306, 24306, 24306],
        [0, 7291, 12865, 17148, 20390, 22772, 24436, 25400, 25849, 26078, 26191, 26243, 26253, 26253, 26253, 26253, 26253, 26253, 26253, 26253, 26253],
        [0, 7493, 13327, 17852, 21335, 23938, 25797, 26993, 27616, 27936, 28101, 28185, 28218, 28221, 28221, 28221, 28221, 28221, 28221, 28221, 28221],
        [0, 7668, 13746, 18502, 22216, 25037, 27096, 28475, 29324, 29763, 30001, 30126, 30189, 30205, 30205, 30205, 30205, 30205, 30205, 30205, 30205],
        [0, 7821, 14124, 19110, 23037, 26070, 28329, 29887, 30923, 31549, 31875, 32058, 32155, 32198, 32204, 32204, 32204, 32204, 32204, 32204, 32204],
        [0, 7954, 14464, 19673, 23803, 27042, 29497, 31239, 32427, 33252, 33713, 33969, 34112, 34187, 34212, 34214, 34214, 34214, 34214, 34214, 34214],
        [0, 8069, 14771, 20194, 24520, 27955, 30600, 32527, 33872, 34850, 35493, 35844, 36051, 36162, 36217, 36230, 36230, 36230, 36230, 36230, 36230],
        [0, 8167, 15049, 20674, 25194, 28812, 31645, 33752, 35263, 36369, 37180, 37677, 37955, 38122, 38210, 38246, 38252, 38252, 38252, 38252, 38252],
        [0, 8253, 15298, 21115, 25827, 29620, 32631, 34915, 36592, 37833, 38773, 39436, 39824, 40054, 40186, 40254, 40277, 40279, 40279, 40279, 40279],
        [0, 8326, 15523, 21517, 26420, 30383, 33565, 36019, 37861, 39248, 40303, 41106, 41641, 41950, 42142, 42246, 42295, 42309, 42309, 42309, 42309]
    ],
    [
        // 13 SOLAIRE
        [0, 3188, 5775, 7772, 9259, 10290, 10935, 11246, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298],
        [0, 3188, 5775, 7772, 9259, 10290, 10935, 11246, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298, 11298],
        [0, 3312, 5919, 7932, 9427, 10467, 11116, 11428, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479, 11479],
        [0, 3831, 6512, 8597, 10136, 11211, 11885, 12208, 12261, 12262, 12262, 12262, 12262, 12262, 12262, 12262, 12262, 12262, 12262, 12262, 12262],
        [0, 4394, 7366, 9541, 11164, 12294, 13011, 13359, 13420, 13424, 13424, 13424, 13424, 13424, 13424, 13424, 13424, 13424, 13424, 13424, 13424],
        [0, 4914, 8287, 10628, 12350, 13557, 14331, 14718, 14800, 14808, 14808, 14808, 14808, 14808, 14808, 14808, 14808, 14808, 14808, 14808, 14808],
        [0, 5381, 9125, 11795, 13636, 14943, 15787, 16225, 16341, 16358, 16361, 16361, 16361, 16361, 16361, 16361, 16361, 16361, 16361, 16361, 16361],
        [0, 5797, 9918, 12894, 14978, 16400, 17334, 17836, 17995, 18032, 18040, 18040, 18040, 18040, 18040, 18040, 18040, 18040, 18040, 18040, 18040],
        [0, 6178, 10642, 13913, 16297, 17886, 18924, 19505, 19719, 19788, 19805, 19808, 19808, 19808, 19808, 19808, 19808, 19808, 19808, 19808, 19808],
        [0, 6520, 11300, 14874, 17496, 19358, 20530, 21204, 21486, 21593, 21632, 21640, 21640, 21640, 21640, 21640, 21640, 21640, 21640, 21640, 21640],
        [0, 6822, 11897, 15762, 18629, 20742, 22123, 22909, 23275, 23430, 23498, 23520, 23521, 23521, 23521, 23521, 23521, 23521, 23521, 23521, 23521],
        [0, 7084, 12444, 16581, 19700, 22017, 23662, 24604, 25062, 25281, 25385, 25431, 25440, 25440, 25440, 25440, 25440, 25440, 25440, 25440, 25440],
        [0, 7313, 12944, 17331, 20701, 23230, 25083, 26258, 26837, 27134, 27283, 27357, 27385, 27387, 27387, 27387, 27387, 27387, 27387, 27387, 27387],
        [0, 7512, 13399, 18022, 21631, 24377, 26415, 27819, 28576, 28968, 29177, 29288, 29341, 29355, 29355, 29355, 29355, 29355, 29355, 29355, 29355],
        [0, 7685, 13812, 18660, 22495, 25458, 27687, 29271, 30257, 30773, 31059, 31215, 31299, 31335, 31339, 31339, 31339, 31339, 31339, 31339, 31339],
        [0, 7836, 14184, 19256, 23300, 26474, 28896, 30654, 31830, 32536, 32915, 33133, 33254, 33317, 33337, 33338, 33338, 33338, 33338, 33338, 33338],
        [0, 7967, 14518, 19808, 24053, 27428, 30043, 31980, 33312, 34214, 34733, 35029, 35199, 35293, 35339, 35348, 35348, 35348, 35348, 35348, 35348],
        [0, 8080, 14821, 20316, 24756, 28325, 31125, 33241, 34734, 35790, 36496, 36888, 37123, 37258, 37333, 37361, 37364, 37364, 37364, 37364, 37364],
        [0, 8177, 15094, 20786, 25416, 29167, 32151, 34441, 36098, 37288, 38163, 38705, 39015, 39206, 39313, 39370, 39386, 39386, 39386, 39386, 39386],
        [0, 8261, 15340, 21219, 26037, 29961, 33119, 35581, 37403, 38733, 39736, 40447, 40869, 41124, 41279, 41366, 41405, 41413, 41413, 41413, 41413],
        [0, 8333, 15561, 21615, 26617, 30710, 34036, 36665, 38650, 40128, 41246, 42101, 42674, 43009, 43222, 43346, 43414, 43440, 43443, 43443, 43443]
    ],
    [
        // 14 SOLAIRE
        [0, 3252, 5943, 8087, 9724, 10932, 11747, 12229, 12423, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436],
        [0, 3252, 5943, 8087, 9724, 10932, 11747, 12229, 12423, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436, 12436],
        [0, 3373, 6082, 8244, 9891, 11105, 11926, 12410, 12605, 12618, 12618, 12618, 12618, 12618, 12618, 12618, 12618, 12618, 12618, 12618, 12618],
        [0, 3884, 6664, 8892, 10586, 11835, 12687, 13185, 13385, 13401, 13401, 13401, 13401, 13401, 13401, 13401, 13401, 13401, 13401, 13401, 13401],
        [0, 4442, 7505, 9816, 11594, 12898, 13794, 14327, 14542, 14561, 14563, 14563, 14563, 14563, 14563, 14563, 14563, 14563, 14563, 14563, 14563],
        [0, 4957, 8412, 10883, 12754, 14138, 15094, 15669, 15912, 15942, 15947, 15947, 15947, 15947, 15947, 15947, 15947, 15947, 15947, 15947, 15947],
        [0, 5419, 9241, 12033, 14015, 15500, 16525, 17156, 17438, 17490, 17499, 17500, 17500, 17500, 17500, 17500, 17500, 17500, 17500, 17500, 17500],
        [0, 5830, 10025, 13116, 15333, 16923, 18043, 18738, 19071, 19156, 19175, 19179, 19179, 19179, 19179, 19179, 19179, 19179, 19179, 19179, 19179],
        [0, 6207, 10740, 14120, 16630, 18378, 19602, 20382, 20774, 20899, 20937, 20946, 20946, 20946, 20946, 20946, 20946, 20946, 20946, 20946, 20946],
        [0, 6547, 11389, 15066, 17813, 19825, 21177, 22051, 22516, 22688, 22756, 22776, 22779, 22779, 22779, 22779, 22779, 22779, 22779, 22779, 22779],
        [0, 6845, 11978, 15941, 18929, 21186, 22740, 23724, 24274, 24507, 24608, 24650, 24660, 24660, 24660, 24660, 24660, 24660, 24660, 24660, 24660],
        [0, 7105, 12517, 16747, 19984, 22441, 24251, 25388, 26032, 26340, 26483, 26551, 26576, 26579, 26579, 26579, 26579, 26579, 26579, 26579, 26579],
        [0, 7332, 13012, 17485, 20969, 23633, 25645, 27010, 27776, 28165, 28364, 28466, 28513, 28526, 28526, 28526, 28526, 28526, 28526, 28526, 28526],
        [0, 7529, 13461, 18165, 21883, 24761, 26952, 28542, 29486, 29973, 30243, 30384, 30458, 30490, 30494, 30494, 30494, 30494, 30494, 30494, 30494],
        [0, 7700, 13869, 18792, 22735, 25829, 28202, 29966, 31137, 31755, 32103, 32298, 32406, 32461, 32478, 32478, 32478, 32478, 32478, 32478, 32478],
        [0, 7849, 14235, 19377, 23529, 26828, 29389, 31323, 32684, 33492, 33939, 34200, 34349, 34431, 34471, 34477, 34477, 34477, 34477, 34477, 34477],
        [0, 7978, 14565, 19917, 24268, 27767, 30518, 32623, 34138, 35146, 35736, 36077, 36280, 36397, 36461, 36485, 36487, 36487, 36487, 36487, 36487],
        [0, 8089, 14864, 20418, 24958, 28651, 31584, 33860, 35533, 36700, 37478, 37920, 38190, 38350, 38444, 38491, 38503, 38503, 38503, 38503, 38503],
        [0, 8185, 15133, 20881, 25607, 29479, 32594, 35039, 36874, 38179, 39121, 39719, 40067, 40285, 40414, 40489, 40521, 40525, 40525, 40525, 40525],
        [0, 8268, 15375, 21309, 26216, 30257, 33546, 36160, 38155, 39604, 40675, 41446, 41907, 42190, 42369, 42474, 42532, 42551, 42552, 42552, 42552],
        [0, 8340, 15592, 21699, 26786, 30993, 34447, 37224, 39378, 40975, 42167, 43079, 43695, 44062, 44299, 44445, 44530, 44571, 44582, 44582, 44582]
    ],
    [
        // 15 SOLAIRE 
        [0, 3307, 6093, 8364, 10141, 11506, 12479, 13123, 13475, 13576, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579],
        [0, 3307, 6093, 8364, 10141, 11506, 12479, 13123, 13475, 13576, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579, 13579],
        [0, 3427, 6228, 8519, 10305, 11676, 12656, 13304, 13657, 13758, 13760, 13760, 13760, 13760, 13760, 13760, 13760, 13760, 13760, 13760, 13760],
        [0, 3931, 6799, 9151, 10988, 12394, 13404, 14074, 14436, 14538, 14543, 14543, 14543, 14543, 14543, 14543, 14543, 14543, 14543, 14543, 14543],
        [0, 4484, 7629, 10055, 11976, 13438, 14496, 15201, 15585, 15697, 15705, 15705, 15705, 15705, 15705, 15705, 15705, 15705, 15705, 15705, 15705],
        [0, 4994, 8524, 11105, 13112, 14659, 15772, 16525, 16943, 17074, 17087, 17089, 17089, 17089, 17089, 17089, 17089, 17089, 17089, 17089, 17089],
        [0, 5452, 9345, 12240, 14351, 15990, 17180, 17990, 18452, 18614, 18637, 18643, 18643, 18643, 18643, 18643, 18643, 18643, 18643, 18643, 18643],
        [0, 5860, 10121, 13308, 15650, 17387, 18672, 19549, 20064, 20266, 20309, 20319, 20321, 20321, 20321, 20321, 20321, 20321, 20321, 20321, 20321],
        [0, 6234, 10827, 14299, 16926, 18818, 20202, 21165, 21741, 21989, 22064, 22084, 22089, 22089, 22089, 22089, 22089, 22089, 22089, 22089, 22089],
        [0, 6570, 11469, 15232, 18092, 20243, 21747, 22802, 23456, 23760, 23870, 23909, 23920, 23921, 23921, 23921, 23921, 23921, 23921, 23921, 23921],
        [0, 6866, 12051, 16096, 19194, 21581, 23281, 24447, 25185, 25557, 25708, 25775, 25798, 25803, 25803, 25803, 25803, 25803, 25803, 25803, 25803],
        [0, 7123, 12584, 16890, 20236, 22816, 24767, 26079, 26912, 27363, 27567, 27665, 27709, 27721, 27722, 27722, 27722, 27722, 27722, 27722, 27722],
        [0, 7348, 13072, 17619, 21206, 23991, 26139, 27673, 28629, 29160, 29432, 29568, 29636, 29663, 29668, 29668, 29668, 29668, 29668, 29668, 29668],
        [0, 7543, 13516, 18288, 22109, 25106, 27427, 29180, 30307, 30944, 31287, 31471, 31571, 31620, 31636, 31636, 31636, 31636, 31636, 31636, 31636],
        [0, 7712, 13919, 18904, 22949, 26157, 28656, 30579, 31929, 32698, 33122, 33371, 33507, 33580, 33615, 33621, 33621, 33621, 33621, 33621, 33621],
        [0, 7859, 14281, 19480, 23729, 27141, 29827, 31911, 33448, 34410, 34938, 35254, 35437, 35542, 35598, 35618, 35619, 35619, 35619, 35619, 35619],
        [0, 7987, 14606, 20014, 24457, 28065, 30940, 33187, 34878, 36038, 36712, 37112, 37354, 37496, 37578, 37620, 37629, 37629, 37629, 37629, 37629],
        [0, 8097, 14901, 20509, 25136, 28934, 31990, 34404, 36248, 37569, 38431, 38935, 39246, 39437, 39551, 39616, 39643, 39645, 39645, 39645, 39645],
        [0, 8192, 15166, 20967, 25773, 29748, 32982, 35564, 37564, 39022, 40052, 40716, 41109, 41358, 41511, 41603, 41653, 41667, 41667, 41667, 41667],
        [0, 8274, 15405, 21388, 26372, 30515, 33920, 36666, 38820, 40423, 41587, 42421, 42931, 43250, 43453, 43579, 43654, 43688, 43694, 43694, 43694],
        [0, 8345, 15620, 21772, 26932, 31238, 34808, 37715, 40024, 41772, 43062, 44035, 44705, 45108, 45371, 45539, 45641, 45701, 45722, 45722, 45722]

    ],
    [
        // 16 SOLAIRE 
        [0, 3355, 6224, 8609, 10516, 12015, 13144, 13936, 14437, 14683, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724],
        [0, 3355, 6224, 8609, 10516, 12015, 13144, 13936, 14437, 14683, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724, 14724],
        [0, 3473, 6357, 8760, 10677, 12184, 13318, 14115, 14619, 14864, 14905, 14906, 14906, 14906, 14906, 14906, 14906, 14906, 14906, 14906, 14906],
        [0, 3972, 6919, 9376, 11349, 12891, 14055, 14877, 15393, 15644, 15687, 15688, 15688, 15688, 15688, 15688, 15688, 15688, 15688, 15688, 15688],
        [0, 4520, 7738, 10265, 12316, 13920, 15128, 15988, 16534, 16799, 16846, 16851, 16851, 16851, 16851, 16851, 16851, 16851, 16851, 16851, 16851],
        [0, 5026, 8623, 11302, 13432, 15117, 16384, 17296, 17878, 18167, 18226, 18234, 18235, 18235, 18235, 18235, 18235, 18235, 18235, 18235, 18235],
        [0, 5481, 9436, 12423, 14652, 16424, 17771, 18739, 19366, 19691, 19773, 19784, 19788, 19788, 19788, 19788, 19788, 19788, 19788, 19788, 19788],
        [0, 5886, 10204, 13476, 15932, 17797, 19236, 20274, 20957, 21324, 21437, 21459, 21466, 21466, 21466, 21466, 21466, 21466, 21466, 21466, 21466],
        [0, 6257, 10903, 14454, 17193, 19206, 20736, 21862, 22611, 23029, 23178, 23220, 23232, 23234, 23234, 23234, 23234, 23234, 23234, 23234, 23234],
        [0, 6591, 11539, 15377, 18345, 20611, 22254, 23473, 24301, 24776, 24967, 25039, 25061, 25066, 25067, 25067, 25067, 25067, 25067, 25067, 25067],
        [0, 6885, 12114, 16230, 19433, 21930, 23765, 25089, 26001, 26548, 26790, 26892, 26934, 26946, 26948, 26948, 26948, 26948, 26948, 26948, 26948],
        [0, 7139, 12641, 17015, 20461, 23150, 25230, 26694, 27700, 28324, 28629, 28769, 28836, 28861, 28867, 28867, 28867, 28867, 28867, 28867, 28867],
        [0, 7362, 13124, 17734, 21419, 24309, 26582, 28262, 29388, 30094, 30472, 30657, 30753, 30798, 30813, 30814, 30814, 30814, 30814, 30814, 30814],
        [0, 7555, 13564, 18394, 22310, 25409, 27851, 29743, 31037, 31849, 32300, 32547, 32676, 32745, 32775, 32781, 32781, 32781, 32781, 32781, 32781],
        [0, 7722, 13962, 19003, 23136, 26444, 29062, 31119, 32632, 33576, 34114, 34426, 34598, 34696, 34747, 34765, 34766, 34766, 34766, 34766, 34766],
        [0, 7867, 14320, 19572, 23905, 27414, 30216, 32431, 34127, 35259, 35904, 36286, 36515, 36646, 36720, 36757, 36765, 36765, 36765, 36765, 36765],
        [0, 7994, 14643, 20101, 24622, 28326, 31316, 33687, 35533, 36862, 37655, 38125, 38415, 38588, 38692, 38749, 38772, 38774, 38774, 38774, 38774],
        [0, 8104, 14934, 20589, 25290, 29183, 32351, 34887, 36881, 38367, 39350, 39928, 40290, 40516, 40653, 40735, 40779, 40791, 40791, 40791, 40791],
        [0, 8198, 15196, 21041, 25918, 29986, 33330, 36032, 38173, 39797, 40951, 41687, 42135, 42421, 42602, 42713, 42779, 42809, 42813, 42813, 42813],
        [0, 8280, 15432, 21456, 26506, 30741, 34256, 37120, 39410, 41176, 42465, 43371, 43941, 44300, 44532, 44679, 44770, 44822, 44838, 44839, 44839],
        [0, 8351, 15645, 21835, 27056, 31452, 35132, 38154, 40595, 42503, 43918, 44966, 45696, 46141, 46436, 46628, 46749, 46824, 46861, 46869, 46869]

    ],
    [
        // 17 SOLAIRE 
        [0, 3399, 6341, 8826, 10855, 12477, 13748, 14678, 15323, 15707, 15860, 15873, 15873, 446, 15873, 15873, 15873, 15873, 15873, 15873, 15873,],
        [0, 3399, 6341, 8826, 10855, 12477, 13748, 14678, 15323, 15707, 15860, 15873, 15873, 446, 15873, 15873, 15873, 15873, 15873, 15873, 15873,],
        [0, 3515, 6472, 8972, 11013, 12643, 13919, 14856, 15504, 15889, 16042, 16054, 16054, 5469, 16054, 16054, 16054, 16054, 16054, 16054, 16054,],
        [0, 4009, 7026, 9576, 11674, 13339, 14644, 15607, 16273, 16666, 16822, 16837, 16837, 6221, 16837, 16837, 16837, 16837, 16837, 16837, 16837,],
        [0, 4554, 7835, 10453, 12621, 14352, 15701, 16705, 17402, 17813, 17980, 17997, 17999, 7304, 17999, 17999, 17999, 17999, 17999, 17999, 17999,],
        [0, 5057, 8713, 11476, 13718, 15530, 16940, 17992, 18728, 19171, 19355, 19378, 19383, 8548, 19383, 19383, 19383, 19383, 19383, 19383, 19383,],
        [0, 5508, 9518, 12582, 14922, 16814, 18306, 19414, 20199, 20680, 20890, 20927, 20935, 9864, 20936, 20936, 20936, 20936, 20936, 20936, 20936,],
        [0, 5910, 10279, 13623, 16186, 18164, 19742, 20927, 21768, 22294, 22539, 22598, 22610, 11091, 22615, 22615, 22615, 22615, 22615, 22615, 22615,],
        [0, 6279, 10972, 14590, 17434, 19555, 21218, 22491, 23399, 23978, 24262, 24351, 24375, 12226, 24383, 24383, 24383, 24383, 24383, 24383, 24383,],
        [0, 6611, 11601, 15504, 18571, 20941, 22714, 24075, 25061, 25700, 26035, 26157, 26199, 13287, 26215, 26215, 26215, 26215, 26215, 26215, 26215,],
        [0, 6902, 12170, 16349, 19645, 22244, 24203, 25663, 26734, 27447, 27837, 27996, 28065, 14265, 28096, 28096, 28096, 28096, 28096, 28096, 28096,],
        [0, 7154, 12693, 17125, 20660, 23447, 25648, 27242, 28407, 29197, 29653, 29859, 29955, 15168, 30013, 30015, 30015, 30015, 30015, 30015, 30015,],
        [0, 7375, 13172, 17835, 21606, 24592, 26980, 28787, 30067, 30940, 31468, 31729, 31860, 15999, 31954, 31962, 31962, 31962, 31962, 31962, 31962,],
        [0, 7566, 13607, 18489, 22484, 25679, 28232, 30248, 31691, 32669, 33271, 33599, 33770, 16768, 33911, 33928, 33930, 33930, 33930, 33930, 33930,],
        [0, 7731, 14002, 19091, 23300, 26701, 29429, 31605, 33263, 34366, 35059, 35453, 35680, 17483, 35873, 35906, 35914, 35914, 35914, 35914, 35914,],
        [0, 7876, 14356, 19655, 24058, 27660, 30571, 32899, 34734, 36023, 36825, 37292, 37580, 18155, 37838, 37890, 37911, 37913, 37913, 37913, 37913,],
        [0, 8002, 14675, 20177, 24766, 28561, 31656, 34138, 36116, 37601, 38550, 39111, 39459, 18781, 39799, 39873, 39912, 39923, 39923, 39923, 39923,],
        [0, 8111, 14963, 20661, 25424, 29407, 32676, 35322, 37443, 39083, 40219, 40891, 41315, 19362, 41750, 41852, 41910, 41936, 41939, 41939, 41939,],
        [0, 8205, 15223, 21107, 26042, 30198, 33643, 36452, 38715, 40492, 41797, 42630, 43141, 19901, 43686, 43819, 43901, 43947, 43961, 43961, 43961,],
        [0, 8286, 15456, 21517, 26621, 30943, 34554, 37527, 39934, 41848, 43288, 44292, 44928, 20399, 45602, 45774, 45884, 45949, 45982, 45988, 45988,],
        [0, 8356, 15667, 21892, 27164, 31643, 35417, 38544, 41103, 43152, 44719, 45870, 46664, 20853, 47492, 47711, 47853, 47943, 47996, 48016, 48016,]

    ],
    [
        // 18 SOLAIRE 
        [0, 3438, 6447, 9017, 11160, 12898, 14297, 15362, 16136, 16654, 16940, 17020, 17023, 17023, 17023, 17023, 17023, 17023, 17023, 17023, 17023],
        [0, 3438, 6447, 9017, 11160, 12898, 14297, 15362, 16136, 16654, 16940, 17020, 17023, 17023, 17023, 17023, 17023, 17023, 17023, 17023, 17023],
        [0, 3554, 6575, 9160, 11316, 13062, 14466, 15537, 16316, 16836, 17121, 17202, 17205, 17205, 17205, 17205, 17205, 17205, 17205, 17205, 17205],
        [0, 4043, 7123, 9754, 11964, 13748, 15180, 16278, 17079, 17609, 17901, 17983, 17988, 17988, 17988, 17988, 17988, 17988, 17988, 17988, 17988],
        [0, 4585, 7922, 10618, 12894, 14746, 16224, 17359, 18194, 18749, 19055, 19141, 19149, 19150, 19150, 19150, 19150, 19150, 19150, 19150, 19150],
        [0, 5085, 8793, 11630, 13975, 15901, 17445, 18628, 19505, 20094, 20420, 20520, 20531, 20534, 20534, 20534, 20534, 20534, 20534, 20534, 20534],
        [0, 5533, 9592, 12723, 15163, 17166, 18787, 20030, 20958, 21584, 21943, 22064, 22081, 22087, 22087, 22087, 22087, 22087, 22087, 22087, 22087],
        [0, 5933, 10347, 13754, 16414, 18500, 20201, 21523, 22505, 23180, 23576, 23725, 23755, 23763, 23766, 23766, 23766, 23766, 23766, 23766, 23766],
        [0, 6300, 11033, 14713, 17646, 19871, 21655, 23060, 24113, 24842, 25281, 25464, 25514, 25528, 25533, 25533, 25533, 25533, 25533, 25533, 25533],
        [0, 6629, 11656, 15619, 18770, 21239, 23130, 24617, 25750, 26543, 27034, 27253, 27332, 27357, 27364, 27366, 27366, 27366, 27366, 27366, 27366],
        [0, 6918, 12222, 16455, 19832, 22528, 24600, 26182, 27399, 28266, 28809, 29078, 29186, 29228, 29243, 29247, 29247, 29247, 29247, 29247, 29247],
        [0, 7168, 12740, 17223, 20835, 23718, 26025, 27739, 29046, 29989, 30601, 30921, 31063, 31131, 31157, 31165, 31166, 31166, 31166, 31166, 31166],
        [0, 7386, 13215, 17927, 21771, 24851, 27343, 29265, 30680, 31706, 32391, 32772, 32955, 33049, 33094, 33110, 33113, 33113, 33113, 33113, 33113],
        [0, 7576, 13647, 18574, 22638, 25925, 28579, 30707, 32280, 33409, 34168, 34617, 34850, 34975, 35042, 35071, 35080, 35081, 35081, 35081, 35081],
        [0, 7740, 14038, 19170, 23444, 26936, 29761, 32046, 33828, 35079, 35931, 36448, 36742, 36902, 36995, 37043, 37062, 37065, 37065, 37065, 37065],
        [0, 7884, 14389, 19729, 24194, 27883, 30889, 33323, 35278, 36713, 37671, 38264, 38619, 38828, 38950, 39018, 39053, 39064, 39064, 39064, 39064],
        [0, 8009, 14704, 20246, 24891, 28773, 31959, 34545, 36642, 38269, 39370, 40059, 40477, 40744, 40898, 40993, 41047, 41069, 41073, 41073, 41073],
        [0, 8118, 14990, 20724, 25541, 29607, 32967, 35714, 37951, 39730, 41015, 41816, 42317, 42637, 42838, 42962, 43035, 43077, 43090, 43090, 43090],
        [0, 8211, 15247, 21166, 26151, 30389, 33920, 36834, 39206, 41116, 42569, 43532, 44122, 44508, 44760, 44919, 45019, 45078, 45107, 45112, 45112],
        [0, 8292, 15479, 21572, 26725, 31123, 34821, 37894, 40411, 42451, 44039, 45174, 45888, 46353, 46660, 46862, 46992, 47073, 47121, 47137, 47139],
        [0, 8361, 15687, 21943, 27263, 31814, 35673, 38900, 41565, 43736, 45449, 46730, 47604, 48162, 48537, 48787, 48952, 49059, 49125, 49161, 49168]
    ],
    [
        // 19 SOLAIRE             
        [0, 3475, 6544, 9189, 11436, 13284, 14792, 15989, 16887, 17531, 17940, 18141, 1000, 18176, 18176, 18176, 18176, 18176, 18176, 18176, 18176],
        [0, 3475, 6544, 9189, 11436, 13284, 14792, 15989, 16887, 17531, 17940, 18141, 1000, 18176, 18176, 18176, 18176, 18176, 18176, 18176, 18176],
        [0, 3590, 6670, 9329, 11591, 13446, 14960, 16161, 17065, 17712, 18122, 18322, 5469, 18357, 18357, 18357, 18357, 18357, 18357, 18357, 18357],
        [0, 4075, 7210, 9915, 12226, 14122, 15666, 16891, 17819, 18482, 18899, 19102, 6221, 19140, 19140, 19140, 19140, 19140, 19140, 19140, 19140],
        [0, 4614, 8001, 10768, 13140, 15104, 16694, 17959, 18920, 19611, 20045, 20259, 7304, 20303, 20303, 20303, 20303, 20303, 20303, 20303, 20303],
        [0, 5111, 8865, 11770, 14206, 16241, 17898, 19210, 20215, 20940, 21402, 21631, 8548, 21685, 21687, 21687, 21687, 21687, 21687, 21687, 21687],
        [0, 5556, 9658, 12852, 15381, 17487, 19221, 20596, 21649, 22414, 22911, 23164, 9864, 23236, 23240, 23240, 23240, 23240, 23240, 23240, 23240],
        [0, 5954, 10408, 13876, 16619, 18803, 20614, 22066, 23176, 23992, 24526, 24811, 11091, 24911, 24918, 24918, 24918, 24918, 24918, 24918, 24918],
        [0, 6319, 11089, 14826, 17837, 20159, 22046, 23577, 24762, 25632, 26213, 26534, 12226, 26675, 26683, 26686, 26686, 26686, 26686, 26686, 26686],
        [0, 6646, 11707, 15724, 18950, 21514, 23503, 25112, 26378, 27311, 27943, 28307, 13287, 28497, 28512, 28518, 28518, 28518, 28518, 28518, 28518],
        [0, 6932, 12269, 16552, 19999, 22790, 24957, 26656, 28001, 29007, 29700, 30112, 14265, 30363, 30389, 30398, 30400, 30400, 30400, 30400, 30400],
        [0, 7181, 12783, 17314, 20992, 23967, 26365, 28193, 29623, 30707, 31469, 31933, 15168, 32254, 32298, 32314, 32319, 32319, 32319, 32319, 32319],
        [0, 7397, 13255, 18012, 21918, 25088, 27668, 29701, 31233, 32400, 33233, 33760, 15999, 34159, 34227, 34254, 34264, 34266, 34266, 34266, 34266],
        [0, 7586, 13683, 18652, 22777, 26149, 28889, 31124, 32811, 34077, 34984, 35579, 16768, 36074, 36165, 36211, 36229, 36233, 36233, 36233, 36233],
        [0, 7749, 14070, 19243, 23574, 27148, 30058, 32446, 34341, 35725, 36723, 37387, 17483, 37988, 38108, 38175, 38206, 38217, 38218, 38218, 38218],
        [0, 7892, 14418, 19796, 24315, 28085, 31174, 33707, 35773, 37336, 38437, 39178, 18155, 39899, 40050, 40141, 40191, 40212, 40217, 40217, 40217],
        [0, 8017, 14731, 20308, 25005, 28963, 32231, 34917, 37120, 38870, 40111, 40951, 18781, 41793, 41988, 42107, 42176, 42213, 42226, 42226, 42226],
        [0, 8125, 15015, 20782, 25648, 29787, 33228, 36076, 38412, 40309, 41735, 42684, 19362, 43666, 43916, 44064, 44157, 44212, 44237, 44243, 44243],
        [0, 8217, 15269, 21219, 26252, 30559, 34171, 37181, 39652, 41675, 43268, 44376, 19901, 45520, 45821, 46011, 46131, 46205, 46249, 46264, 46265],
        [0, 8297, 15499, 21622, 26820, 31285, 35062, 38228, 40842, 42994, 44718, 45995, 20399, 47348, 47703, 47941, 48094, 48194, 48253, 48284, 48291],
        [0, 8365, 15705, 21989, 27354, 31966, 35904, 39222, 41984, 44261, 46106, 47530, 20853, 49137, 49564, 49851, 50044, 50170, 50251, 50300, 50319]
    ],
    [
        // 20 SOLAIRE             
        [0, 3511, 6632, 9349, 11687, 13638, 15250, 16568, 17585, 18346, 18876, 19193, 19319, 19331, 19331, 19331, 19331, 19331, 19331, 19331, 19331],
        [0, 3511, 6632, 9349, 11687, 13638, 15250, 16568, 17585, 18346, 18876, 19193, 19319, 19331, 19331, 19331, 19331, 19331, 19331, 19331, 19331],
        [0, 3625, 6755, 9486, 11839, 13798, 15417, 16737, 17760, 18525, 19057, 19375, 19501, 19512, 19512, 19512, 19512, 19512, 19512, 19512, 19512],
        [0, 4107, 7290, 10063, 12461, 14465, 16113, 17457, 18504, 19289, 19830, 20154, 20281, 20295, 20295, 20295, 20295, 20295, 20295, 20295, 20295],
        [0, 4643, 8072, 10907, 13362, 15431, 17128, 18512, 19592, 20406, 20970, 21306, 21439, 21455, 21458, 21458, 21458, 21458, 21458, 21458, 21458],
        [0, 5136, 8931, 11899, 14417, 16550, 18317, 19749, 20871, 21721, 22315, 22669, 22816, 22836, 22841, 22842, 22842, 22842, 22842, 22842, 22842],
        [0, 5580, 9720, 12972, 15580, 17780, 19618, 21117, 22285, 23181, 23807, 24192, 24357, 24386, 24393, 24395, 24395, 24395, 24395, 24395, 24395],
        [0, 5976, 10464, 13988, 16805, 19083, 20991, 22564, 23794, 24738, 25406, 25825, 26014, 26059, 26069, 26073, 26073, 26073, 26073, 26073, 26073],
        [0, 6339, 11140, 14930, 18008, 20424, 22407, 24053, 25360, 26358, 27075, 27530, 27752, 27816, 27833, 27840, 27841, 27841, 27841, 27841, 27841],
        [0, 6663, 11755, 15821, 19110, 21766, 23848, 25567, 26951, 28016, 28785, 29285, 29539, 29632, 29660, 29670, 29673, 29673, 29673, 29673, 29673],
        [0, 6947, 12312, 16643, 20150, 23028, 25283, 27092, 28550, 29690, 30520, 31067, 31364, 31485, 31531, 31548, 31554, 31555, 31555, 31555, 31555],
        [0, 7193, 12824, 17398, 21134, 24193, 26676, 28611, 30150, 31367, 32264, 32867, 33211, 33362, 33434, 33461, 33471, 33474, 33474, 33474, 33474],
        [0, 7409, 13292, 18089, 22052, 25300, 27964, 30100, 31740, 33035, 34004, 34672, 35065, 35256, 35352, 35397, 35414, 35420, 35421, 35421, 35421],
        [0, 7596, 13716, 18723, 22903, 26350, 29173, 31507, 33301, 34690, 35733, 36468, 36921, 37154, 37278, 37346, 37375, 37386, 37388, 37388, 37388],
        [0, 7758, 14101, 19309, 23692, 27340, 30331, 32816, 34813, 36315, 37448, 38252, 38767, 39053, 39211, 39300, 39348, 39367, 39373, 39373, 39373],
        [0, 7900, 14446, 19857, 24425, 28265, 31435, 34063, 36227, 37903, 39137, 40021, 40600, 40944, 41141, 41257, 41324, 41358, 41370, 41372, 41372],
        [0, 8024, 14757, 20366, 25109, 29133, 32482, 35259, 37558, 39417, 40790, 41769, 42416, 42818, 43067, 43211, 43301, 43352, 43375, 43381, 43381],
        [0, 8131, 15038, 20835, 25745, 29948, 33469, 36404, 38835, 40839, 42393, 43478, 44211, 44672, 44975, 45157, 45274, 45343, 45382, 45396, 45398],
        [0, 8223, 15290, 21268, 26344, 30711, 34403, 37496, 40060, 42190, 43906, 45146, 45974, 46509, 46861, 47094, 47237, 47329, 47385, 47412, 47420],
        [0, 8302, 15518, 21668, 26907, 31428, 35283, 38531, 41237, 43492, 45334, 46745, 47698, 48316, 48726, 49009, 49190, 49308, 49382, 49427, 49444],
        [0, 8370, 15721, 22031, 27435, 32101, 36114, 39514, 42369, 44744, 46705, 48259, 49372, 50086, 50572, 50902, 51127, 51275, 51373, 51433, 51467]
    ]
]

export const renewableAbacus = [
    [0, 1184, 2270, 2865, 3207, 3425, 3579, 3696, 3788, 3864, 3930, 3990, 4045, 4095, 4140, 4182, 4220, 4256, 4289, 4321, 4350],
    [2043, 3222, 4125, 4528, 4748, 4889, 4991, 5069, 5134, 5190, 5240, 5286, 5327, 5365, 5400, 5460, 5487, 5512, 5512, 5534, 5556],
    [3905, 4938, 5598, 5874, 6021, 6117, 6188, 6244, 6292, 6333, 6370, 6403, 6433, 6460, 6484, 6528, 6547, 6565, 6565, 6582, 6598],
    [5165, 6055, 6562, 6755, 6859, 6928, 6980, 7021, 7056, 7086, 7114, 7138, 7161, 7181, 7199, 7231, 7246, 7259, 7259, 7272, 7284],
    [6046, 6826, 7210, 7347, 7421, 7471, 7508, 7538, 7564, 7588, 7609, 7628, 7161, 7659, 7673, 7697, 7708, 7718, 7718, 7727, 7736],
    [6705, 7376, 7662, 7761, 7814, 7851, 7879, 7902, 7922, 7940, 7956, 7969, 7644, 7992, 8003, 8020, 8028, 8036, 8036, 8042, 8049],
    [7195, 7767, 7979, 8050, 8090, 8117, 8138, 8157, 8172, 8185, 8196, 8206, 7981, 8224, 8231, 8244, 8249, 8254, 8254, 8259, 8263],
    [7559, 8048, 8204, 8255, 8284, 8305, 8321, 8334, 8345, 8355, 8363, 8370, 8215, 8382, 8387, 8396, 8400, 8403, 8403, 8407, 8410],
    [7834, 8249, 8362, 8399, 8421, 8436, 8448, 8457, 8465, 8472, 8478, 8483, 8377, 8492, 8495, 8502, 8505, 8508, 8508, 8510, 8512],
    [8045, 8392, 8475, 8502, 8518, 8529, 8538, 8545, 8551, 8556, 8560, 8564, 8488, 8570, 8573, 8577, 8580, 8581, 8581, 8583, 8585],
    [8207, 8496, 8555, 8576, 8587, 8596, 8602, 8607, 8612, 8615, 8618, 8621, 8567, 8625, 8627, 8630, 8632, 8633, 8633, 8634, 8635],
    [8331, 8571, 8614, 8629, 8637, 8643, 8648, 8652, 8655, 8657, 8659, 8661, 8623, 8664, 8665, 8667, 8668, 8669, 8669, 8670, 8670],
    [8427, 8626, 8657, 8668, 8673, 8678, 8681, 8683, 8685, 8687, 8688, 8689, 8662, 8691, 8692, 8694, 8694, 8695, 8695, 8695, 8696],
    [8503, 8666, 8688, 8695, 8699, 8702, 8704, 8706, 8707, 8708, 8709, 8710, 8690, 8712, 8712, 8713, 8713, 8714, 8714, 8714, 8714],
    [8561, 8695, 8710, 8715, 8717, 8720, 8721, 8722, 8723, 8724, 8724, 8725, 8711, 8726, 8726, 8727, 8727, 8727, 8727, 8727, 8727],
    [8605, 8714, 8724, 8728, 8730, 8731, 8732, 8733, 8733, 8734, 8734, 8735, 8725, 8735, 8735, 8736, 8736, 8736, 8736, 8736, 8736],
    [8639, 8727, 8734, 8736, 8737, 8738, 8739, 8739, 8740, 8740, 8740, 8740, 8735, 8741, 8741, 8741, 8741, 8741, 8741, 8741, 8741],
    [8666, 8736, 8740, 8742, 8743, 8743, 8744, 8744, 8744, 8744, 8744, 8745, 8741, 8745, 8745, 8745, 8745, 8745, 8745, 8745, 8745],
    [8687, 8742, 8745, 8746, 8746, 8746, 8747, 8747, 8747, 8747, 8747, 8747, 8745, 8748, 8748, 8748, 8748, 8748, 8748, 8748, 8748],
    [8703, 8746, 8747, 8748, 8748, 8749, 8749, 8749, 8749, 8749, 8749, 8749, 8749, 8749, 8749, 8750, 8750, 8750, 8750, 8750, 8750],
    [8716, 8748, 8749, 8750, 8750, 8750, 8750, 8750, 8751, 8751, 8753, 8751, 8751, 8751, 8751, 8751, 8751, 8751, 8751, 8751, 8751]
]

