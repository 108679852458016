import React, { Component } from 'react'

export default class Hamburger extends Component {

    toggleMenu = () => {
        let burgerMenu = document.querySelector('#burgerId');

        if (burgerMenu.classList.contains("menu-open")) {
            burgerMenu.classList.remove("menu-open");
        }
        else {
            burgerMenu.classList.add("menu-open");
        }
    }

    render() {
        return (
            <div onClick={this.toggleMenu} >
                <div id="burgerId" className="menu-btn-burger">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
}
