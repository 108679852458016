import React from 'react'
import CurriculumDetails from '../components/CurriculumDetails'
import { useParams } from "react-router-dom";

import Preloader from '../components/Preloader'

const Curriculum = () => {

    const { id } = useParams();

    return (
        <>
            <CurriculumDetails curriculumId={id} />
        </>

    )
}

export default Curriculum
