import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import CoalGame from '../../components/CoalGame'

import Batterie from './images/batterie.jpg'
import StockageVsCharbon from './images/eolien-solaire-stockage-contre-charbon.jpg'
import EolienSolaireStockage from './images/eolien-solaire-stockage.jpg'
import FacteurDeChargeCharbon from './images/facteur-de-charge-charbon.jpg'
import Hydrogen from './images/hydrogene.jpg'
import Methane from './images/methane.jpg'
import HydrogenReformat from './images/reformatage-hydrogene.jpg'
import Step from './images/step.jpg'
import FullSystem from './images/systeme-complet-avec-stockage.jpg'
import PuissanceInstallee from './images/puissance-installee.jpg'

import stockageEnergieprincipe from './images/stockage-energie-principe.jpg'
import effacement from './images/effacement.jpg'

function StockerEnergie() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/stocker-energie");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Suivre la demande à partir de sources intermittentes, variables et fatales amène le besoin de pouvoir mettre de côté une partie de l’électricité produite en excès, lorsque les conditions météorologiques se montrent particulièrement favorables, pour pouvoir la réinjecter sur le réseau plus tard, quand à l’inverse celles-ci s’avèrent insuffisantes. Par conséquent, le stockage d’énergie, qui répond précisément à cette problématique, apparaît aujourd’hui comme une composante clé de la transition énergétique.</p>


                    <div className="image-source">
                        <img src={stockageEnergieprincipe} style={{ maxWidth: '53rem' }} />
                    </div>

                    <p>Les «&nbsp;stations de transfert d’énergie par pompage-turbinage&nbsp;» ou STEP demeurent, de très loin, la solution de stockage d'énergie la plus répandue. Celles-ci fonctionnent tels des barrages réversibles&nbsp;: le surplus d’électricité sert à pomper de l’eau vers un bassin en hauteur, ce stock d’énergie peut ensuite être consommé en ouvrant les vannes, comme sur n’importe quel barrage.</p>

                    <div className="image-source">
                        <img src={Step} style={{ maxWidth: '38rem' }} />
                    </div>


                    <p>Notons que, contrairement aux centrales hydroélectriques, la présence naturelle de courant n’est pas indispensable, seule compte la différence de hauteur entre deux réservoirs. Montagnes, collines, falaises ou à l’inverse gouffres, canyons : les possibilités sont multiples. S’ils restent inégalement répartis dans le monde, les emplacements potentiels existent en nombre suffisant pour couvrir une majeure partie des besoins mondiaux en stockage.

                        <Reference index="1" content="Australian National University’s, <a href='http://re100.eng.anu.edu.au/global/' target='_blank'>Global pumped hydro atlas</a>, April, 2019 (en) " />

                    </p>

                    <p>Un immense avantage de ce système vient du fait qu’il nécessite la construction du contenant, le barrage, mais pas du contenu, étant donné qu’il est composé d’une ressource elle-même renouvelable&#8239;: l’eau. </p>

                    <p>Ce n’est pas le cas des alternatives à base de batteries. Leur durée de vie reste limitée à une dizaine d’années et leur production repose sur des métaux qui sont encore assez peu recyclés. Un certain nombre de technologies différentes existent dans ce domaine, mais ce sont les batteries lithium-ion qui se sont largement imposées au cours des dernières années. Elles équipent notamment les ordinateurs, les smartphones et les véhicules électriques.</p>

                    <div className="image-source">
                        <img src={Batterie} style={{ maxWidth: '38rem' }} />
                    </div>

                    <p>En dehors de ces deux approches, nous entrons dans le domaine de technologies aux applications plus restreintes, ou encore à l’état de prototype. Dans cette catégorie, l’hydrogène se distingue par l’écho médiatique souvent sensationnaliste dont il a largement bénéficié.</p>

                    <p>Sa production à partir d’énergie renouvelable s'effectue grâce à l’électrolyse de l’eau. Faire passer un courant électrique dans de l’eau produit des petites bulles qui contiennent de l’hydrogène. Le gaz ainsi généré se voit capté, comprimé, puis stocké. L’hydrogène peut être consommé plus tard dans une pile à combustible pour, à l’inverse, restituer de l’électricité en ne rejetant au passage que de l’eau.</p>

                    <div className="image-source">
                        <img src={Hydrogen} style={{ maxWidth: '39rem' }} />
                    </div>

                    <p>Le principal problème posé par l’électrolyse provient de sa considérable consommation d’énergie, et donc de son coût. Dans les faits, pour des raisons économiques, 95&#8239;% de l’hydrogène reste aujourd’hui produit grâce aux énergies fossiles. Plusieurs approches coexistent actuellement, mais le procédé le plus répandu est le vaporeformage, qui consiste à produire de l’hydrogène à partir du méthane contenu dans le gaz naturel. Cette réaction chimique produit des gaz particulièrement polluants, au sein desquels on retrouvera du CO<sub>2</sub> en quantité.</p>

                    <div className="image-source">
                        <img src={HydrogenReformat} style={{ maxWidth: '39rem' }} />
                    </div>

                    <p>Pour distinguer les deux types de production, l’usage a consacré la notion d’hydrogène vert qui vise à préciser que sa production provient d’énergies bas carbone, renouvelables ou nucléaire, en opposition à l’hydrogène gris, produit à partir d’énergies fossiles.</p>

                    <p>L’hydrogène est l’élément chimique le plus petit et léger possible. Conséquence : il a tendance à se faufiler partout. Le stocker demande des moyens importants, et donc de l’énergie, pour le maintenir à haute pression ou à basse température. Maîtriser les fuites est primordial, car ce gaz s’enflamme ou explose très facilement. En&nbsp;1937, l’incendie du zeppelin Hindenburg, rempli d’hydrogène, a marqué la fin de l’exploitation des dirigeables commerciaux. Plus récemment, en&nbsp;2011, c’est l’explosion de poches d’hydrogène formées à l’intérieur de l’enceinte de confinement qui causent les principaux dommages à la centrale nucléaire de Fukushima.</p>

                    <p>L’hydrogène peut également être brûlé directement, pur ou mélangé à d’autres gaz, pour libérer de la chaleur, ou encore servir de réactif pour produire d’autres composés chimiques plus lourds tels que le méthane, qui peut alors être stocké et consommé de la même manière que le gaz naturel d'origine fossile. Ces solutions «&nbsp;Power-to-Gas&nbsp;»  permettent en particulier un stockage de longue durée, rendant possibles des transferts d’énergie d’une saison à une autre.</p>

                    <div className="image-source">
                        <img src={Methane} style={{ maxWidth: '39rem' }} />
                    </div>

                    <p>Le même principe «&nbsp;Power-to-Chemical&nbsp;» peut être utilisé pour produire d'autres produits chimiques bas carbone, par exemple de l'ammoniac, qui apparaît prometteur pour remplacer le fioul sur les navires.</p>

                    <p>Des solutions alternatives plus confidentielles existent&nbsp;: </p>

                    <ul>
                        <li>Les systèmes de stockage par air comprimé</li>
                        <li>Le stockage d'énergie thermique, par conservation de chaud, de froid ou d'un état particulier de la matière (liquide, glace)</li>
                        <li>Le stockage d'énergie cinétique par des volants d'inertie, soit des disques de métal tournant sur place à très grande vitesse grâce à l'apport d'énergie et qui peuvent la restituer en ralentissant</li>
                    </ul>

                    <h2>Gérer la variabilité</h2>

                    <p>Les dimensions d'un système de stockage dépendent 1) de sa puissance, c’est-à-dire de sa capacité à absorber ou produire de l’électricité à un instant <em>t</em>, soit la taille des tuyaux vers le stockage, et 2) de la durée d’autonomie attendue, soit la taille du réservoir d’énergie.</p>

                    <p>Dans tous les cas, quelle que soit la technologie choisie, stocker puis récupérer de l’énergie entraîne des pertes. STEP et batteries occasionnent des pertes relativement similaires, de l’ordre de 15 à 30%. L’hydrogène se distingue par son très faible rendement&nbsp;: entre la production de gaz, la compression, le stockage et sa consommation dans une pile à combustible, l’ensemble du processus engendre 75&#8239;% de pertes. L'hydrogène reste donc une solution de stockage notablement énergivore.</p>

                    <p>Autre élément à prendre en compte&nbsp;: la production de batteries nécessite de l’énergie et, par voie de conséquence, génère des émissions de CO<sub>2</sub>. Pour peu que cette option soit retenue, au moins en partie, il conviendra de revoir l’intensité carbone du système complet, production et stockage sur batterie, à la hausse.</p>

                    {/* 
                    <p>Ainsi, passer par une batterie multiplie l’impact climatique global de l’électricité éolienne par 8, dans le meilleur des cas. Bien entendu, cela ne vaut que pour la fraction de la production passée par le stockage pour lisser la production électrique. Celle qui a pu être consommée directement sur le réseau conserve son bilan carbone particulièrement avantageux.
                        <Reference index="2" content="<em>IVL Swedish Environmental Research Institute</em>, Mia Romare, Lisbeth Dahllöf, <a target='_blank' href='http://www.energimyndigheten.se/globalassets/forskning--innovation/transporter/c243-the-life-cycle-energy-consumption-and-co2-emissions-from-lithium-ion-batteries-.pdf'> The Life Cycle Energy Consumption and Greenhouse Gas Emissions from Lithium-Ion Batteries</a>, Mai 2017 (en).<br/><br/>
                    <em> Hans Eric Melin, Circular Energy Storage Commissioned by Transport &amp; Environment</em>, <a target='_blank' href='https://www.transportenvironment.org/sites/te/files/publications/2019_11_Analysis_CO<sub>2</sub>_footprint_lithium-ion_batteries.pdf'>Analysis of the climate impact of lithium-ion batteries and how to measure it </a>, July 2019 (en)"/>
                    </p>
                    */}

                    {/* 
                    <p>
                        <CoalGame displayedItems={["solar", "wind", "storage"]} source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="4" content="Nous prenons ici comme référence des éoliennes de 3MW et des panneaux solaires standards de surface 1,70m², avec une puissance de 300 Watts-Crête, soit 0,3 kWc.  <br/><br/>
                        La production éolienne et solaire provient des données détaillées, heure par heure, fournie par RTE pour l'année 2019. <br/><br/>

                        RTE, <a target='_blank' href='https://www.services-rte.com/fr/telechargez-les-donnees-publiees-par-rte.html?category=generation&type=actual_generations_per_production_type'>Production : Production réalisée aggrégée par filières</a>, 2019
                        <br/><br/>

                        GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br/> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />

                    </p>
                    */}

                    <p>Un levier d’action absolument incontournable se trouve du côté de la flexibilisation de la demande, en procédant en particulier à des «&#8239;effacements de consommation électrique&#8239;». Cette approche consiste à donner au réseau la capacité de différer massivement les consommations qui peuvent l'être comme le rechargement des véhicules électriques, la mise en route des chauffe-eaux ou des «&#8239;usages blancs&#8239;», lave-linge, lave-vaisselle et sèche-linge. Cela permet de lisser les pics de consommation et d'adapter les appels de charge à ce que le réseau est capable de fournir à un instant donné. Automatiser ces types de comportements à grande échelle est l’une des promesses apportées par les réseaux intelligents ou «&#8239;smart grids&#8239;».</p>


                    <div className="image-source">
                        <img src={effacement} style={{ maxWidth: '53rem' }} />
                    </div>


                    <p>Accroître la part des énergies renouvelables intermittentes est ainsi possible au prix d'efforts importants quant à la flexibilisation du réseau. Théoriquement, arriver à 100&#8239;% d'énergies renouvelables est également réalisable, mais cela demande de cumuler beaucoup d'hypothèses très optimistes, et donc risquées, ou d'accepter des contreparties substantielles en matière de baisse de la consommation énergétique.</p>

                    <p>En pratique, le changement d’échelle dans l'utilisation des énergies renouvelables intermittentes soulève toujours des interrogations majeures&#8239;:</p>

                    <ul>
                        <li>Les puissances installées pour le stockage stationnaire dans le monde ne couvrent pas plus de 3&#8239;% de la puissance électrique, et plus de 97&#8239;% de ces capacités de stockage restent assurées par des STEP.<Reference index="2" content="AIE, <a href='https://www.iea.org/data-and-statistics/charts/cumulative-installed-storage-capacity-2017-2023' target='_blank'>Cumulative installed storage capacity, 2017-2023</a>, 2020 (en) " /> </li>
                        <li>Si le vent et le soleil sont renouvelables, les machines pour les capter, elles, ne le sont pas. La durée de vie des éoliennes ne dépasse pas 20 à 25 ans. Les panneaux solaires, 30&nbsp;ans.</li>
                        <li>Construire des parcs éoliens ou solaires nécessite énormément de surface au sol, parfois aux dépens de terres agricoles ou d’écosystèmes naturels. </li>
                        <li>Sans réserve suffisante de sources pilotables, comment faire face aux cas les plus défavorables, avec un déficit de vent ou de soleil durable, sans se heurter à des pénuries d'énergie, voire à un black-out total du réseau&#8239;? </li>
                    </ul>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                            <a href="#0">Renouvelables</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="stocker-energie" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="5. Le gaz naturel" url="/gaz-naturel"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default StockerEnergie

