import React, { Component } from 'react'
import { FaAllergies } from 'react-icons/fa';

export default class Reference extends Component {

    componentDidMount() {

        let tooltipText = document.getElementById('tooltip-text-content-' + this.props.index);
        let rect = tooltipText.getBoundingClientRect();

        let total = rect.right;
    }

    handleMouseOver = () => {

        let cont = document.getElementById("tooltip-text-container-" + this.props.index);
        cont.style.display = 'block';

        const vw = document.documentElement.clientWidth;

        let containerId = "tooltip-text-content-" + this.props.index;
        let containerContent = document.getElementById(containerId);
        let rect = containerContent.getBoundingClientRect();

        let toolTipWidth = 500;

        if (vw < 600) {
            toolTipWidth = 450;
        }
        if (vw < 450) {
            toolTipWidth = vw - 40;
        }

        let spaceOnRight = vw - rect.right - toolTipWidth;

        if (spaceOnRight > -40) {
            spaceOnRight = -40;
        }

        let tooltipContext = document.getElementById("tooltip-text-content-id-" + this.props.index);
        tooltipContext.style.transform = 'translate(' + spaceOnRight + 'px)';
        tooltipContext.style.minWidth = toolTipWidth + 'px';

    }

    handleMouseOut = () => {
        let cont = document.getElementById("tooltip-text-container-" + this.props.index);
        cont.style.display = 'none';

    }

    render() {

        return (
            <span className="footnote-ref" id={"tooltip-text-content-" + this.props.index} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                <div className="note-bubble">{this.props.index}

                    <span className="container" id={"tooltip-text-container-" + this.props.index} >
                        <span className="tooltip-transparent-panel"></span>
                        <div id={"tooltip-text-content-id-" + this.props.index} dangerouslySetInnerHTML={{ __html: this.props.content }}>


                        </div>
                    </span>

                </div>
            </span >
        )
    }
}
