import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class SimulatorThumb extends Component {

    render() {

        return (

            <div class="cur-container" style={{ background: '#fff', display: "flex", flexDirection: 'column', padding: '1rem', height: "33  rem" }}>

                <div class="cur-description" style={{ display: 'flex', padding: '1rem', border: 'none' }}>

                    <div style={{ borderRadius: '10px', marginTop: "2rem" }}>
                        <div className="icon u-center" >
                            {this.props.image}
                        </div>
                        <h1 style={{ textAlign: 'center', margin: "0 auto", fontSize: "2.6rem" }}>
                            <Link to={this.props.url}>{this.props.title}</Link>
                        </h1>

                        <p style={{ textAlign: 'center' }}>
                            {this.props.description}
                        </p>
                    </div>

                </div>
            </div >
        );
    }
}


