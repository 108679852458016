import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import Agrocarburants from './images/agrocarburants.jpg'
import AgrocarburantsMobile from './images/agrocarburants-MOB.jpg'
import ConsommationPetrole from './images/consommation-petrole-mobilite.jpg'
import ConsommationPetroleMobile from './images/consommation-petrole-mobilite-MOB.jpg'
import Emissions from './images/emissions-des-vehicules.jpg'
import EmissionsMobile from './images/emissions-des-vehicules-MOB.jpg'
import StockageVueEnsemble from './images/overview-storage.jpg'
import StockageVueEnsembleMobile from './images/overview-storage-MOB.jpg'


function ApresPetrole() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();

    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/apres-petrole");
            history.push("/signup?returl=" + retURL);
        }
    }

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    return (

        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Le pétrole constitue une réserve d’énergie extrêmement concentrée, facile à transporter, et qui se libère très vite. Qui plus est, il présente l’immense avantage d’être liquide à température ambiante, ce qui le rend aisément manipulable, transportable et stockable. </p>

                    <p>Il n'est pas surprenant de constater que dans leur immense majorité les machines qui se déplacent utilisent différents dérivés du pétrole&#8239;: fioul lourd, kérosène, essence, diesel, etc. Seuls les trains, métros, tramways et autres trolleybus échappent à cette règle, grâce à l’utilisation de caténaires.</p>

                    <div className="image-source" >
                        <img src={ConsommationPetrole} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={ConsommationPetroleMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                        <div >AIE<Reference index="1" content="
                        Émissions de CO<sub>2</sub> pour le secteur des transports.<br/><br/>
                        
                        Céline Deluzarche, d’après les chiffres de l'Agence Internationale de l'Énergie pour l’année 2016, <a target='_blank' href='https://www.futura-sciences.com/planete/questions-reponses/pollution-transport-CO<sub>2</sub>-part-emissions-1017/'>Transport et CO<sub>2</sub> : quelle part des émissions ?</a>, 2016." /></div>
                    </div>

                    <p>Deux alternatives existent aujourd’hui : les batteries et l’hydrogène. Dans tous les cas, l’énergie proviendra du réseau électrique. Un véhicule « électrique » ou « à hydrogène » fonctionne donc au charbon, au gaz ou grâce à des énergies bas carbone, selon le réseau sur lequel il s’est chargé.</p>

                    <p>Ces options répondent à un besoin de mobilité, par conséquent les comparer demande de traiter deux questions&#8239;: «&#8239;combien ça pèse&#8239;?&#8239;» et «&#8239;combien de place ça prend&#8239;?&#8239;».</p>

                    <p>Les batteries les plus répandues aujourd’hui sont basées sur le lithium. Ce métal est le plus léger de tous les éléments solides, ce qui lui confère un avantage évident par rapport à d’autres technologies plus anciennes, comme les batteries au plomb. Malgré cela, elles sont très loin de pouvoir rivaliser avec le pétrole&nbsp;: à contenu énergétique efficace équivalent, une batterie pèsera près de 13 fois plus lourd.  <Reference index="2" content="Nous parlons ici de l'énergie restituée effectivement pour la propulsion. Le rendement du moteur est donc pris en compte." /></p>

                    <p>Sous sa forme liquide, l’hydrogène s'avère trois fois plus léger que le pétrole. Cette propriété lui vaut de servir dans l’industrie spatiale comme carburant principal des moteurs-fusées. Toutefois, conserver de l’hydrogène à l’état liquide demande de le garder à une température inférieure à -253&#8239;°C. Les réservoirs présents sur les lanceurs spatiaux sont l’aboutissement de prouesses techniques, et par voie de conséquence l’usage de l’hydrogène liquide reste aujourd'hui réservé à des applications de très haute technologie.</p>

                    <p>Dans le cadre d’un usage grand public, l’hydrogène à température ambiante est gazeux. Problème, en plus d'être hautement explosif, ce gaz a tendance à fuir, même à travers l’acier. Par conséquent, le stocker à haute pression demande des structures relativement épaisses, et donc lourdes&nbsp;: transporter 1&#8239;kg d’hydrogène sous pression requiert ainsi une bouteille d’environ 20&#8239;kg. En fin de compte, des bouteilles d'hydrogène sous pression pèseront 16 fois plus lourd qu'un réservoir rempli d'essence. </p>


                    <div className="image-source">
                        <img src={StockageVueEnsemble} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={StockageVueEnsembleMobile} style={{ maxWidth: '37rem' }} className="img-mobile"></img>

                    </div>

                    <p>Si l’on rajoute à toutes ces considérations les pertes en amont dues à l'électrolyse et à la compression, alors, à déplacements égaux, un véhicule à hydrogène consommera trois fois plus d’énergie qu’un véhicule électrique à batteries. Cela rend <em>de facto</em> un déploiement à grande échelle incompatible avec la volonté de réaliser d'importantes économies d'énergie.</p>

                    <h2>Le véhicule électrique</h2>

                    <p>La communication autour des véhicules «&#8239;propres&#8239;» porte essentiellement sur les émissions en «&#8239;sortie du pot d’échappement&#8239;». Pertinent quand on parle de pollution urbaine, ce cadre s’avère beaucoup trop réducteur si l’on souhaite mesurer l’impact global sur l’environnement et le climat.</p>

                    <p>L’analyse du cycle de vie impose de prendre en compte la construction du véhicule, l’empreinte carbone de l’électricité utilisée pour le recharger et enfin, le recyclage en fin de vie. Une fois ces éléments intégrés, la réduction des émissions de CO<sub>2</sub> permise par un véhicule électrique par rapport à un véhicule thermique montre de fortes disparités. Elle dépend fortement du paysage énergétique du lieu de production, ainsi que du pays où il se verra mis en circulation.</p>

                    <div className="image-source" >
                        <img src={Emissions} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={EmissionsMobile} style={{ maxWidth: '35rem' }} className="img-mobile"></img>
                        <div >France Stratégie <Reference index="3" content="Nicolas Meilhan pour <em>France Stratégie</em>, <a href='https://www.strategie.gouv.fr/sites/strategie.gouv.fr/files/atoms/files/fs-na78-2019-emissions-voitures-meilhan-20juin-bat.pdf#page=9' target='_blank'>Comment faire enfin baisser
                                les émissions de CO<sub>2</sub> des voitures</a>, Graphique 12, juin 2019." /></div>
                    </div>

                    <p>En fin de compte, solliciter des centrales à charbon chinoises ou polonaises pour charger son SUV électrique s’avère plus nocif pour l’environnement que de faire un plein d’essence sur un véhicule thermique exceptionnellement sobre. Reste l’avantage de délocaliser les nuisances liées à la pollution. Ainsi, la Chine mise massivement sur les véhicules électriques pour réduire le «&#8239;smog&#8239;», un brouillard de pollution extrêmement nocif pour la santé des habitants, dans ses grandes villes.<Reference index="4" content="World Economic Forum, <a href='https://fr.weforum.org/agenda/2018/05/chine-quand-les-voitures-electriques-augmentent-les-emissions-de-carbone/'>Chine : quand les voitures électriques augmentent les émissions de carbone</a>, mai 2018" /></p>

                    <p>La Norvège se place aujourd’hui en tête de l’adoption des véhicules électriques. Plébiscités par la population, ceux-ci roulent grâce à de l’énergie décarbonée produite par des barrages hydroélectriques. Ici quasi optimale, la réduction des émissions atteint alors 55 à 70% par rapport à un véhicule thermique.</p>

                    <p>La construction des batteries reste un poste qui pèse toujours très lourd dans le bilan carbone d’un véhicule électrique. Produire une nouvelle batterie, de l’extraction minière à son assemblage, génère en moyenne aujourd’hui autant de CO<sub>2</sub> que la construction d’un véhicule thermique neuf. Les émissions sont proportionnelles à la taille de la batterie, elle-même dimensionnée en fonction du poids du véhicule, ce qui, à contre-courant de la tendance actuelle, plaide en faveur d'une transition vers des véhicules plus légers. On estime aujourd'hui que la durée de vie théorique d'une batterie se situe autour de 10&nbsp;ans.</p>

                    <p>Bien qu’incapable de tenir la promesse du «&#8239;zéro émission&#8239;», le véhicule électrique n'en permet pas moins une réduction significative des émissions de CO<sub>2</sub>. Certes, il requiert un réseau électrique qui fournit de l’électricité suffisamment décarbonée, mais le remplacement d’un parc automobile se compte en dizaines d’années et initier la dynamique dès aujourd’hui ne saurait être vu d’un mauvais œil. </p>

                    <p>Ceci étant dit, le milliard de véhicules a été dépassé en&nbsp;2009, et si la progression actuelle se poursuit, on pourrait compter entre 2,5 et 3&nbsp;milliards de véhicules en circulation dans le monde en&nbsp;2050. Si ceux-ci devaient être tous électriques, cela représenterait une charge supplémentaire que ne pourra pas absorber un réseau électrique déjà à la peine pour effectuer sa propre transition.
                        <Reference index="5" content="<em>International Organization of Motor Vehicle Manufacturers (OICA)</em>, <a target='_blank' href='http://www.oica.net/wp-content/uploads//Total_in-use-All-Vehicles.pdf#page2'> World vehicles in use - All vehicles</a>, 2015 (PDF) (en)." />
                    </p>

                    <p>Le véhicule électrique ne nous dispensera donc pas d’une refonte profonde de notre relation à la mobilité, avec un plus grand recours, là où c’est possible, aux transports en commun ou aux mobilités douces, et une nécessaire réduction du poids des voitures personnelles. </p>

                    <h2>La marine marchande</h2>

                    <p>La marine marchande fonctionne aujourd’hui pour 99% avec du fioul lourd. Brûler ce carburant disperse dans l’atmosphère des particules fines extrêmement nocives pour la santé. Un seul cargo produit autant de particules fines qu’un million de voitures. En Europe, les conséquences de cette pollution se chiffrent en dizaines de milliers de maladies respiratoires, cardiovasculaires et autant de décès prématurés chaque année.<Reference index="6" content="<em>France Nature Environnement</em>, <a target='_blank' href='https://www.fne.asso.fr/dossiers/linsoutenable-pollution-de-lair-du-transport-maritime-navire-bateaux-croisi%C3%A8res'>L'insoutenable pollution de l'air du transport maritime</a>" />
                    </p>

                    <p>Pour parcourir un kilomètre, un cargo de fret maritime émettra autant de CO<sub>2</sub> qu’environ 5000&nbsp;voitures. Mais il transportera une bien plus grande quantité de marchandises. Par conséquent, une tonne de fret transportée par la mer émettra 3 à 9&nbsp;fois moins de CO<sub>2</sub> que par la route. Des marchandises acheminées en France d’Europe de l’Est par camion ou de Chine par fret maritime présenteront donc significativement la même empreinte carbone.<Reference index="7" content="<em>ADEME</em>, <a target='_blank' href='https://www.ademe.fr/sites/default/files/assets/documents/86275_7715-guide-information-co2-transporteurs.pdf#page=65'>Information CO<sub>2</sub> des prestations de transport</a>, Oct 2012" /> </p>

                    <p>Les croisiéristes commencent à construire des paquebots au gaz naturel liquéfié (GNL). Si cela permet assurément de protéger leurs passagers du danger des particules fines émises par le fioul, la baisse des émissions de CO<sub>2</sub> n’excédera pas 20% dans le meilleur des cas. Avec les problèmes posés par les rejets de méthane, l’impact global sur le climat reste à l’arrivée probablement inchangé.</p>

                    <p>En définitive, aucune technologie bas carbone n’existe aujourd’hui sur ce marché.</p>

                    <p>Après une prise de conscience tardive, le secteur maritime commence tout juste à financer des programmes de recherche et développement qui visent à développer des solutions moins nocives pour le climat. Pour l’instant, elles reposent sur des carburants issus de la production d’hydrogène vert, avec un assez solide consensus sur l’ammoniac.<Reference index="8" content="<a href='http://www.journalmarinemarchande.eu/filinfo/misc-samsung-heavy-industries-lloyds-register-et-man-priorisent-lammoniac' target='_blank'>MISC, Samsung Heavy Industries, Lloyd's Register et MAN priorisent l'ammoniac</a>, janvier 2020" /></p>

                    <h2>L’inévitable décroissance du transport aérien</h2>

                    <p>En matière d’émissions de CO<sub>2</sub>, prendre l’avion ou sa voiture personnelle revient à peu près au même par kilomètre parcouru. Est-ce suffisant pour conclure que l’avion ne mérite pas sa réputation de moyen de transport excessivement polluant&#8239;?</p>

                    <p>Pour commencer, rappelons qu'emprunter sa voiture émet de grandes quantités de CO<sub>2</sub>. Et l’avion implique le déplacement de beaucoup de passagers, beaucoup plus vite et beaucoup plus loin. Un aller-retour Paris-Bangkok en A350 permet de condenser 7,5&nbsp;millions de kilomètres-passagers en à peine quelques heures de vol. Un avion apparaît donc autant comme une formidable machine à voyager qu’à polluer, et c’est bien là tout le problème.</p>

                    <p>Plus encore que tout autre secteur, l’industrie aéronautique doit son entière existence au pétrole. Elle cherche aujourd’hui à lui survivre en explorant différentes pistes. La plus simple à mettre en place repose sur les mécanismes dits «&#8239;de compensation&#8239;», par exemple en plantant des arbres censés capturer du CO<sub>2</sub> dans des proportions équivalentes aux émissions générées par un trajet en avion. Air France arrivait ainsi à proposer des vols «&#8239;neutres en carbone&#8239;», là où effectuer le même trajet en train revenait, d’après la SNCF, à émettre une poignée de kilos de CO<sub>2</sub> !</p>

                    <p>Seulement, rejeter du carbone aujourd’hui dans l’atmosphère ne s’équilibre pas avec le fait de, peut-être, si tout se passe bien, le recapturer plus tard. Rappelons en outre que pour garder une chance de tenir les objectifs climatiques, l’augmentation des puits de carbone, en particulier les forêts, a un potentiel limité, et qu’ils doivent se développer en complément de la baisse des émissions, et non à la place de celles-ci.</p>

                    <p>Seconde piste : les agrocarburants issus de la production de colza, d’huile de palme, de déchets agricoles ou d’algues. Ils ne peuvent pas encore se substituer entièrement au kérosène, mais en rajouter une part variable, entre 10 et 50%&#8239;, dans les réservoirs permet de diminuer de manière significative, jusqu'à 55&#8239;%, les émissions des moteurs.</p>

                    <p>Le problème reste qu’aujourd’hui le mot «&#8239;agrocarburant&#8239;» se traduit toujours beaucoup trop par «&#8239;déforestation&#8239;». Un usage massif des agrocarburants dans le secteur aérien pourrait, à lui seul, conduire des millions d’hectares de forêt à partir en fumée.

                        <Reference index="9" content="<em>Rainforest Foundation Norway</em>, <a href='https://d5i6is0eze552.cloudfront.net/documents/Destination-deforestation_Oct2019.pdf' target='_blank'>Destination deforestation, Aviation biofuels, vegetable oil and land use change</a>, octobre 2019 (en)." />
                    </p>

                    <p>Que ça soit des forêts ou des prairies, détruire des écosystèmes qui historiquement stockent du CO<sub>2</sub> pour produire des agrocarburants peut, dans certains cas, s’avérer beaucoup plus néfaste que de consommer directement du pétrole. À titre d’exemple, consommer du biodiesel à base d’huile de palme revient à émettre trois fois plus de CO<sub>2</sub> que de brûler directement du gasoil. Si les agrocarburants les plus récents permettent d'envisager jusqu'à 90&#8239;% de réduction des émissions par rapport à leurs équivalents fossiles, prendre en compte les changements d’usage des sols reste tout à fait primordial pour arriver à évaluer leur impact réel sur le climat.<Reference index="10" content="<em>Transport &amp; Environment</em>, <a href='https://www.transportenvironment.org/sites/te/files/publications/2016_04_TE_Globiom_paper_FINAL_0.pdf#page=6' target='_blank'>Globiom: the basis for biofuel policy post-2020</a>, Figure 1, avril 2016 (en)." />  </p>

                    <p>Au-delà de ces considérations, deux limitations s’imposent à l’essor de ces «&nbsp;carburants durables&nbsp;». Premièrement, les agrocarburants coûtent beaucoup plus cher que leurs équivalents fossiles. Deuxièmement, le secteur aérien consomme à lui seul 10&#8239;% de la production mondiale de pétrole, avec 50&#8239;% d’agrocarburant par vol, c'est un sixième des terres agricoles, soit le quart de la superficie des États-Unis, qui se verrait entièrement réaffecté à cet usage. Dès lors, une telle concurrence pèserait fortement sur la production de produits agricoles et viendrait aggraver les risques portant sur la sécurité alimentaire mondiale, déjà menacée par les effets du réchauffement climatique.</p>

                    <div className="image-source" >
                        <img src={Agrocarburants} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={AgrocarburantsMobile} style={{ maxWidth: '35rem' }} className="img-mobile"></img>
                    </div>

                    <p>Dernière piste en date&#8239;: l’avion à hydrogène. Si c’est une technologie tout à fait maîtrisée pour les lanceurs spatiaux, pour ce qui est des avions de ligne, un seul avion expérimental construit par l'URSS, le Tupolev 155, a pu voler une vingtaine de minutes en 1988.</p>

                    <p>Les turboréacteurs actuellement en service sur les vols commerciaux pourraient théoriquement fonctionner avec de l'hydrogène à la place du kérosène. En revanche, les réservoirs aujourd'hui situés dans les ailes devraient être repensés pour conserver le carburant embarqué à -253&#8239;°C. Cela en toute sécurité, alors que l'hydrogène est hautement inflammable, et présente un risque d'explosion bien plus élevé que le kérosène. Par ailleurs, comme l'hydrogène liquide occupe quatre fois plus d'espace que le kérosène, une simple adaptation des appareils actuels ne permettrait pas d'embarquer suffisamment de carburant pour des vols long-courriers.</p>

                    <p>Moyennant un surplus de consommation d'énergie, l'hydrogène peut servir dans une seconde étape de transformation. Combiné avec du CO<sub>2</sub>, il permet de produire des carburants synthétiques PTL (Power-to-Liquid) dont l'avantage réside, comme pour les biocarburants, dans son aspect «&#8239;drop-in&#8239;», c’est-à-dire de ne nécessiter que peu ou pas d'évolutions sur les avions actuels. </p>

                    <p>Quoi qu’il en soit, même si une telle révolution technologique devait aboutir, elle resterait fondamentalement incompatible avec l’aviation de masse. Compte tenu des pertes inhérentes à la production d’hydrogène «&#8239;vert&#8239;», faire voler des aéronefs à l'hydrogène ou aux PTL demanderait au minimum deux fois plus d’énergie qu’avec du kérosène.</p>

                    <p> À titre d'exemple, couvrir les besoins en énergie du trafic aérien de 2019 en France nécessiterait d’y consacrer à plein temps 9&nbsp;nouveaux EPR, ou quelque 16&#8239;000&nbsp;éoliennes terrestres, recouvrant ainsi l’intégralité d'un département français.
                        <Reference index="11" content="D'après les émissions de CO2 de 2019 (23,4 MT CO2) et les propriétés du principal carburant aéronautique, le Jet A1, La quantité d'énergie consommée par le trafic aérien français était de 85 TWh en 2019. <br/><br/>
                    <a href='https://www.ecologie.gouv.fr/emissions-gazeuses-liees-au-trafic-aerien/'>Émissions gazeuses liées au trafic aérien</a>, 2019 <br/> <a href='https://fr.wikipedia.org/wiki/Carburant_aviation#cite_note-2/'>Caractéristiques du carburant Jet A1</a>" />
                    </p>

                    <p>En définitive, l'aviation entièrement décarbonée n'existe pas. Même avec des développements technologiques inédits, le secteur aérien ne pourra pas respecter ses engagements climatiques sans voir une baisse marquée du trafic.<Reference index="12" content="Référentiel ISAE–SUPAERO<br/><br/>
                    <a href='https://oatao.univ-toulouse.fr/28309/13/Synthese_Referentiel_ISAE-SUPAERO.pdf /'>AVIATION ET CLIMAT
                    SYNTHÈSE</a>, Septembre 2021." />
                    </p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }
                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="apres-petrole" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="10. Les métaux"
                        url="/metaux"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default ApresPetrole

