import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SimulatorContainer from '../components/SimulatorContainer'
import { useParams } from "react-router-dom";


const Simulator = () => {

    const { id } = useParams();

    return (

        <SimulatorContainer simulatorId={id} />

    )
}

export default Simulator
