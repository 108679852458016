export const questions = [
    {
        id: "gaz-naturel",
        text: "Le gaz naturel est :",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Une énergie renouvelable produite naturellement",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Une énergie propre mais non renouvelable",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Une énergie fossile non renouvelable",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Une énergie produite à partir de charbon",
                isCorrect: false,
                isSelected: false
            },
        ]
    },
    {
        text: "De quoi est principalement composé le gaz naturel ?",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "D'un mélange d'hydrogène et de monoxyde de carbone",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "De soufre",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "De méthane",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "De CO2",
                isCorrect: false,
                isSelected: false
            },
        ]
    },
    {
        text: "En quoi le gaz naturel est-il un danger ? ",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 2,
        answers: [{
            text: "Sa combustion émet du CO2",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Les fuites peuvent relâcher du méthane dans l'atmosphère",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Sa combustion émet beaucoup de particules fines",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Il détruit la couche d'ozone",
            isCorrect: false,
            isSelected: false
        }
        ]
    }
]