import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import CoalGame from '../../components/CoalGame'
import Map from './img/electricity-map.png'
import Hydro from './img/hydro.jpg'
import Nuclear from './img/nuclear.jpg'
import RenewablesGaz from './img/renewables-gaz.jpg'
import RenewablesCoal from './img/renewables-coal.jpg'
import Speed from './img/vitesse-de-transition.jpg'
import SpeedMobile from './img/vitesse-de-transition-MOB.jpg'
import Price from './img/electricity-prices.jpg'
import PriceMobile from './img/electricity-prices-MOB.jpg'
import PriceStock from './img/electricity-prices-stock.jpg'
import PriceStockMobile from './img/electricity-prices-stock-MOB.jpg'
import Accidents from './img/cout-accidents.jpg'
import AccidentsMobile from './img/cout-accidents-MOB.jpg'
import intensiteOverview from './img/intensite-overview.jpg'
import intensiteOverviewMOB from './img/intensite-overview-MOB.jpg'

function SimDecarbonerElec() {


    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }


    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });


    function openSimulator() {

        history.push("/simulator/decarboner-l-electricite");

    }


    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/decarboner-electricite");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">


                    <p>La production d'électricité repose encore majoritairement sur le charbon (38&#8239;%) et le gaz naturel (23&#8239;%)&#8239;; le pétrole (3&#8239;%) reste très peu utilisé pour cet usage.</p>

                    <div className="image-source">
                        <img src={intensiteOverview} style={{ maxWidth: '57rem' }} className="img-desktop"></img>
                        <img src={intensiteOverviewMOB} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                    </div>

                    <p>L'électricité ne pèse aujourd'hui que pour 19&#8239;% de la consommation mondiale d'énergie mais cette part est vouée à augmenter fortement car les efforts de décarbonisation reposent en grande partie sur une électrification des usages.</p>

                    <p>L'excellent site <a href='https://www.electricitymap.org/' target='_blank'>electricitymap.org</a> permet de voir, en temps réel et pour différents pays, quelle est l'intensité carbone du réseau électrique.</p>

                    <p>Bien qu’une infinité de combinaisons existe, les pays qui bénéficient déjà d’un mix énergétique décarboné, ou qui cherchent à avancer dans cette voie, peuvent être regroupés en 4 grandes approches.</p>

                    <h2>Hydroélectricité</h2>

                    <p>Les pays ou régions qui génèrent leur électricité à partir de barrages produisent une électricité 100&#8239;% bas carbone et 100% renouvelable.</p>


                    <div className="image-source">
                        <img src={Hydro} style={{ maxWidth: '40rem' }}></img>
                    </div>


                    <h2>Nucléaire et hydroélectricité</h2>

                    <p>70 à 75&#8239;% de l’électricité produite par la France provient de ses centrales nucléaires et 14% de ses barrages, ce qui permet à notre pays de produire une énergie parmi les plus décarbonées au monde. À l’étranger, Suède et Ontario présentent des caractéristiques similaires. </p>


                    <div className="image-source">
                        <img src={Nuclear} style={{ maxWidth: '40rem' }}></img>
                    </div>

                    <h2>Éolien, solaire et gaz</h2>

                    <p>En juin&nbsp;2020, l’Espagne a fermé la moitié de ses centrales à charbon. Elle se dirige vers un mix énergétique basé sur des énergies renouvelables intermittentes doublées par du gaz. 22&#8239;% de son électricité reste produite par du nucléaire, une énergie dont elle souhaite sortir d’ici 15 ans.</p>

                    <p>Le Portugal voisin suit une approche similaire, sans nucléaire.</p>


                    <div className="image-source">
                        <img src={RenewablesGaz} style={{ maxWidth: '40rem' }}></img>
                    </div>

                    <h2>Éolien, solaire et charbon</h2>

                    <p>Danemark et Allemagne sont deux pays engagés dans une transition massive vers les énergies renouvelables alors qu’ils ne disposent que peu, voire pas du tout, d’hydroélectricité. Le premier a investi massivement dans l’éolien offshore, le second dans l’éolien terrestre et un peu dans le solaire.</p>

                    <p>Pour compenser les variations, ces deux pays dépendent encore fortement du charbon.</p>

                    <div className="image-source">
                        <img src={RenewablesCoal} style={{ maxWidth: '40rem' }}></img>
                    </div>

                    <p>L’ouverture en Allemagne de Datteln 4, une centrale à charbon flambant neuve, en 2020, avait créé la polémique. En réalité, celle-ci viendra probablement remplacer des centrales vieillissantes et donc plus polluantes. Car le charbon constitue la base du réseau électrique allemand depuis des décennies, et ce bien avant que le pays ne décide, en&nbsp;2011, de programmer sa sortie du nucléaire. Fournissant encore 13&#8239;% de l’électricité du pays, les derniers réacteurs seront arrêtés en&nbsp;2022.</p>

                    <h2>Quelle approche est la moins coûteuse&#8239;?</h2>

                    <p>Pour les pays qui possèdent déjà un parc de centrales nucléaires, prolonger leur exploitation après travaux est statistiquement l'investissement le plus rentable. En France, l’estimation de ce coût tourne autour de 40&nbsp;milliards pour 75% de l'électricité fournie. Ce type d'opération reste possible jusqu'à ce que la cuve ait atteint la fin de sa durée de vie, après quoi le réacteur devra forcément être démantelé.</p>

                    <p>Le solaire résidentiel, installé par exemple sur le toit des particuliers ou d'usines, ne bénéfice pas d'économies d'échelle, et demeure donc assurément aujourd'hui le moyen le plus onéreux de produire de l'électricité bas carbone. Les promesses d'une énergie locale et décentralisée restent dans les faits associées à des coûts plus élevés.</p>

                    <div className="image-source">
                        <img src={Price} style={{ maxWidth: '57rem' }} className="img-desktop"></img>
                        <img src={PriceMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                        <div >AIE<Reference index="1" content=" <em>Agence Internationale de l'énergie</em>,  <a target='_blank' href='https://www.iea.org/reports/projected-costs-of-generating-electricity-2020'>Projected Costs of Generating Electricity 2020</a>, LCOE by technology, discount rate of 7%, Dec 2020 (en). " /></div>
                    </div>

                    <p>Le coût du mégawattheure est ici calculé en additionnant tous les coûts de production&#8239;: construction, alimentation en combustible, démantèlement futur, etc. Le terme anglais, dont l'emploi s'est généralisé, est «&#8239;levelized cost of energy&#8239;», ou LCOE. Ce mode de calcul peut s’avérer trompeur, car il compare des services rendus de natures très différentes. Que la production soit concentrée sur le milieu des journées d'été ou mobilisable à la demande toute l'année n'a ici aucune influence. Or la prise en compte de l'intermittence engendre un coût supplémentaire&nbsp;: l'investissement dans des systèmes de stockage ou d'effacements de consommation.</p>

                    <p>Introduire le coût du lissage de la production, ici réalisé hypothétiquement par des stations de pompage-turbinage, permet de mieux appréhender l’intérêt des productions qui présentent moins de variations, principalement le nucléaire et l’éolien offshore qui réduit l’écart par rapport à son équivalent terrestre, et se révèle alors moins coûteux que le solaire.</p>

                    <div className="image-source">
                        <img src={PriceStock} style={{ maxWidth: '57rem' }} className="img-desktop"></img>
                        <img src={PriceStockMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>

                        <div >AIE, Lazard<Reference index="2" content=" 
                        Pour le nucléaire et l'hydro, 5% de la production passe par le stockage.<br/><br/>
                        
                        Pour l'éolien terrestre 45% et 30% pour l'éolien en mer (fourchette haute).<br/>
                        Pour le solaire, 70%.<br/><br/>
                        Le coût du stockage est estimé à 140 $ / MWh -
                        <em>Lazard</em>, <a target='_blank' href='https://www.lazard.com/media/451418/lazards-levelized-cost-of-storage-version-60.pdf'>Levelized Cost of Storage Analysis</a>, oct 2020 (en). <br /><br />
                        <em>Agence Internationale de l'énergie</em>,  <a target='_blank' href='https://www.iea.org/reports/projected-costs-of-generating-electricity-2020'>Projected Costs of Generating Electricity 2020</a>, LCOE by technology, discount rate of 7%, Dec 2020 (en). " /></div>

                    </div>

                    <p>De plus, avec l’augmentation des sources intermittentes, le réseau doit pouvoir supporter des pics de production de plus en plus importants, ce qui suppose des travaux considérables. L’Allemagne investit ainsi près de 40&nbsp;milliards d'euros dans son réseau, avec des lignes haute tension allant du nord au sud, pour que celui-ci puisse s'adapter au développement de l’éolien.</p>

                    <p>Ce calcul ne prend pas non plus en compte le coût des accidents, dont une grande partie est assumée par les assurances, voire les contribuables, et ne se reflète donc pas dans les coûts actualisés de l'électricité.</p>

                    <p>Historiquement, les incidents nucléaires sont très peu fréquents, peu mortels mais particulièrement coûteux. Et cela même si l’on met de côté les deux grands accidents majeurs pour lesquels les conséquences économiques sont hors de toute proportion.</p>

                    <div className="image-source">
                        <img src={Accidents} style={{ maxWidth: '57rem' }} className="img-desktop"></img>
                        <img src={AccidentsMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                        <div ><Reference index="3" content=" 
                        Données remises à jour pour 2020 à partir des données de Sovacool et al..<br/><br/>
                        
                        Benjamin K. Sovacool et al. <a href='https://www.sciencedirect.com/science/abs/pii/S0959652615009877'>Balancing safety with sustainability: assessing the risk of accidents for modern low-carbon energy systems</a>, january 2016 (en)" /></div>

                    </div>

                    <p>En ce qui concerne les énergies renouvelables, les prix du solaire, de l’éolien et des batteries baissent chaque année. En dix ans, les coûts de l’éolien ont été divisés par 3,5 et ceux du solaire par 10. Cette chute des prix repose sur des progrès technologiques, mais aussi sur le dumping mis en place par la Chine, qui a eu pour conséquence fâcheuse de tuer toute concurrence étrangère.</p>

                    <h2>Quelle approche est la plus rapide&#8239;?</h2>

                    <p>Le déploiement le plus rapide d’énergies bas carbone revient aux programmes électronucléaires menés dans les années 70. La France fait toujours figure de référence en la matière, mais son succès ne doit pas faire oublier la Suède, la Belgique ou la Finlande qui, en proportion de leur population, ont conduit des transitions particulièrement remarquables.</p>

                    <p>Construire une centrale nucléaire prend, sur le papier, entre 5 et 7&nbsp;ans, mais la construction des nouvelles têtes de série pour les réacteurs de générations III et III+ a partout connu des retards importants. Les EPR français et les AP1000 américains construits en Chine auront ainsi été mis en service après 9 ans de travaux. Passer de ces prototypes à une production en série laisse toutefois envisager une réduction des délais et des coûts. </p>

                    <p>Répliquer partout dans le monde des programmes électronucléaires semblables à ceux des années&nbsp;70 reviendrait à mettre en service un réacteur moderne comme l’EPR tous les deux jours pendant 30 ans. Un tel ordre de grandeur ne fait que confirmer ce que nous savions déjà&#8239;: une solution intégralement basée sur le nucléaire n’est pas possible, et certainement pas dans le temps imparti.</p>

                    <p>Intuitivement, il est tentant de penser que déployer des énergies renouvelables comme le solaire et l’éolien permet d’aller beaucoup plus vite. Ce serait oublier un peu rapidement la quantité phénoménale d’énergie que produit un réacteur nucléaire. Arriver au même résultat avec des énergies plus diffuses demande un effort colossal.</p>

                    <p>À titre d’exemple, les côtes danoises bénéficient de conditions de vent parmi les meilleures au monde, mais le pays aura mis près de 20 ans pour construire un parc éolien capable de fournir chaque année autant d’électricité qu’une seule centrale nucléaire.</p>

                    <p>Ainsi, si l’on regarde les statistiques issues des programmes de transition énergétique les plus rapides de l’histoire, en Allemagne, au Danemark ou en Californie, il apparaît que celles effectuées vers le solaire ou l’éolien demandent un peu de patience, et pour des résultats encore perfectibles.</p>

                    <div className="image-source">
                        <img src={Speed} style={{ maxWidth: '57rem' }} className="img-desktop"></img>
                        <img src={SpeedMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                        <div >BP<Reference index="4" content="Calcul de l'auteur à partir des données BP, <a href='https://knoema.com/BPWES2017/bp-statistical-review-of-world-energy-main-indicators' target='_blank'>BP Statistical Review of World Energy - Main Indicators</a>, juin 2019 (en)." /></div>
                    </div>

                    <p>Toutefois, 2020 apparaît comme une charnière où, en matière de résultats financiers, les acteurs majeurs des énergies renouvelables commencent à rivaliser avec les multinationales de l’industrie fossile, frappées durement par la crise. L’attractivité du secteur pourrait pousser à une accélération notable de la montée en puissance des nouvelles énergies.</p>

                    <p>Même si à l'arrivée il est difficile de rivaliser avec la quantité d'énergie fournie par un réacteur nucléaire, le secteur ne peut produire qu'une quantité limitée de réacteurs d'une part, et d'autre part un réacteur ne produit rien avant sa mise en service. Or, entre le moment où la décision de construire une centrale nucléaire est prise et la mise en service commerciale, 10 à 15 ans peuvent s'écouler, dont plusieurs années avant même le début du chantier.</p>

                    <p>Ainsi, quelles que soient les ambitions en matière de nucléaire, la construction de multiples sources d'énergie renouvelables pouvant être mises en service progressivement se révèle absolument incontournable, avec toujours ce que cela implique en termes d'adaptation à la variabilité de la production.</p>

                    {!isQuizzOpened && <div className="article-separator" style={{ marginTop: '4rem', marginBottom: '2rem', borderBlockColor: 'var(--lightGrey)' }}></div>}

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <>

                                <div className="u-center">
                                    <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                                </div>
                            </>
                        }
                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="decarboner-electricite" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="9. L'après-pétrole" url="/apres-petrole"></RelatedArticles>

                </div>

            </div>

        </>
    );
}

export default SimDecarbonerElec

