import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FcReading, FcClock } from "react-icons/fc";

import { curriculums } from '../data/Curriculums'
import { Helmet } from "react-helmet";


export default class CurriculumThumb extends Component {

    render() {

        let currentCurriculum = curriculums[0];

        curriculums.map((item, index) => {

            if (this.props.curriculumId == item.page) {
                currentCurriculum = item;
            }

        });

        return (

            <div class="cur-container">
                {/*
                <div class="cur-image">
                    <img src={currentCurriculum.imageThumb} alt="Cheminées"></img>
                </div>
                */}
                <div class="cur-description">

                    <div>

                        <h1 style={{ fontSize: "2.6rem", margin: '0 auto' }}>
                            <Link to={"/formation/" + currentCurriculum.page}>Fondamentaux des enjeux énergie-climat</Link>
                        </h1>

                        <div style={{ padding: "0rem 1rem 2rem 2rem", margin: '0' }}>
                            <div class="description-footer" style={{ justifyContent: "center", margin: '0' }}>

                                <FcReading className="cur-description-icon" /> <div class="cur-icon-text" style={{ margin: '0 1rem' }}>11 sujets</div>
                                <FcClock className="cur-description-icon" /> <div class="cur-icon-text" style={{ margin: '0  1rem' }} >3 heures</div>

                            </div>
                        </div>

                        <p className="article-content" style={{ padding: "2rem 0" }}>
                            <ul>
                                <li style={{ marginBottom: "1rem" }}>Les 3 points clés à connaître sur le réchauffement climatique</li>
                                <li style={{ marginBottom: "1rem" }}>En quoi consiste vraiment la transition énergétique </li>
                                <li style={{ marginBottom: "1rem" }}>Les énergies renouvelables intermittentes&#8239;: promesses et limites</li>
                                <li style={{ marginBottom: "1rem" }}>Tchernobyl, Fukushima&#8239;: le véritable bilan du nucléaire</li>
                                <li style={{ marginBottom: "1rem" }}>Pourquoi l’hydrogène ne remplacera pas le pétrole</li>
                                <li style={{ marginBottom: "1rem" }}>Quelles limites à la croissance dans un monde à +2 °C</li>
                            </ul>
                        </p>

                        <Link to="/formation/fondamentaux-energie-climat" style={{ margin: 'auto', display: "block", maxWidth: '80%' }}>
                            <div className="btn green-btn" onClick={this.handleQuizzOnClick}>Commencer</div>
                        </Link>

                    </div>

                </div>
            </div>
        );
    }
}


