import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyA5t5SHtVuQP5aXTia5ukeCfFgbaKfYgf8",
    authDomain: "energie-climat-org.firebaseapp.com",
    databaseURL: "https://energie-climat-org-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "energie-climat-org",
    storageBucket: "energie-climat-org.appspot.com",
    messagingSenderId: "696734060156",
    appId: "1:696734060156:web:587abfa792eaca1ef9c3f9",
    measurementId: "G-ZHJ5PHD1MF"
};

firebase.initializeApp(config);
export const firebaseProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const firebaseAuth = firebase.auth();

export default firebase;