import React, { useContext, createContext, useEffect, useState, Component } from 'react'
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";
import Loader from 'react-loader-spinner';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import profile from './images/svg/profile.png';
import Cookies from './images/svg/cookie.svg';
import firebase, { firebaseAuth, firebaseProvider, facebookProvider } from './firebase.js';

import {
    BrowserRouter as Router,
    Routes,
    Outlet,
    Route,
    Link,
    Navigate,
    useNavigate,
    useLocation
} from "react-router-dom";


import Expenses from "./components/expenses";
import Invoices from "./components/invoice";

import Home from './pages/home';
import ContactUs from './pages/contactUs';
import Error from './pages/error';
import Curriculum from './pages/curriculum';
import Article from './pages/article';
import Simulator from './pages/simulator';
import SimulatorList from './pages/simulatorList';
import CGU from './pages/cgu';
import DataPrivacy from './pages/dataPrivacy';
import Credits from './pages/credits';

import logo_full_white from './images/logo_standalone.png';

import './css/loader.css';
import './style.css';
import Preloader from './components/Preloader';


export default class App extends Component {

    state = {
        isLoading: true,
        containerClass: "spinner-container"
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

    render() {

        return <Routes>

            <Route path="/" element={<Home />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route index element={<Home />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/formation/:id" element={<Curriculum />}> </Route>
            <Route path="/modeles" element={<SimulatorList />} />
            <Route path="/nous-contacter" element={<ContactUs />} />
            <Route path="/cgu" element={<CGU />} />
            <Route path="/data-privacy" element={<DataPrivacy />} />
            <Route path="/credits" element={<Credits />} />
            <Route exact path="/:id" element={<Article />} />
            <Route exact path="/simulator/:id" element={<Simulator />} />
            <Route path="*" element={<Error />} />

        </Routes>

    }
}

const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
        fakeAuth.isAuthenticated = true;
        setTimeout(cb, 300); // fake async
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        setTimeout(cb, 300);
    }
};


const authContext = createContext();

function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {

    let fetchedAuthContext = useContext(authContext);

    if (fetchedAuthContext && !fetchedAuthContext.user) {
        fetchedAuthContext.user = localStorage.getItem('UserInfo');
    }

    return fetchedAuthContext;
}

function useProvideAuth() {

    const [user, setUser] = useState(null);

    const signin = (cb, user) => {

        return fakeAuth.signin(() => {

            localStorage.setItem('UserInfo', JSON.stringify(user));
            setUser(user);
            cb();
        });
    };

    const signout = cb => {
        return fakeAuth.signout(() => {

            localStorage.removeItem('UserInfo');
            setUser(null);

            cb();
        });
    };

    return {
        user,
        signin,
        signout
    };
}

export function AuthButton() {

    let history = useNavigate();
    let auth = useAuth();
    let userName = '';
    let isAuthenticated;

    if (auth && auth.user) {

        let userInfo = localStorage.getItem("UserInfo");

        if (userInfo) {
            let newUser = JSON.parse(userInfo);

            if (newUser.user && newUser.user.displayName) {
                userName = newUser.user.displayName;
            }

            isAuthenticated = !newUser.user.isAnonymous;
        }
    }

    let retURL = encodeURIComponent("/formation/fondamentaux-energie-climat");
    let loginRUL = "/login?returl=" + retURL;

    return isAuthenticated ? (
        <p>
            <div className="profile-link">
                <img src={profile} style={{ fill: '#fff', height: '1.8rem', }}></img>
                <div className="logout-panel" onClick={() => {

                    firebase.auth().signOut().then(() => {

                        auth.signout(() => history.push("/"));
                        setTimeout(() => { document.location.reload(); }, 300);

                    }).catch((error) => {
                        // An error happened.
                    });

                }}><a href="#">Se déconnecter</a></div>
                &nbsp; {userName}
            </div>
        </p>
    ) : (
        <>
            <Link to={loginRUL}>
                <div className="btn green-btn login-btn" >
                    <img src={profile} style={{ fill: '#fff', height: '1.8rem', }}></img>
                    <span style={{ marginTop: "0.4rem", marginLeft: "0.5rem" }}>
                        Se connecter
                    </span><br />

                </div>
            </Link><br />
            <span style={{ marginTop: "0.4rem", marginLeft: "0.5rem", fontSize: '5px', color: 'var(--mainColor)' }} onClick={() => {

                firebase.auth().signOut().then(() => {

                    auth.signout(() => history.push("/"));
                    setTimeout(() => { document.location.reload(); }, 300);

                }).catch((error) => {
                    // An error happened.
                });

            }}>
            </span>
        </>
    );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {

    let auth = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    children
                ) : (
                    <Navigate
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

function handleNameChange(event) {
    name = event.target.value;
}

function handleEmailChange(event) {
    email = event.target.value;
}

function handlePasswordchange(event) {
    password = event.target.value;
}

let password = '';
let email = '';
let name = '';
let isLoading = false;

let fromURL = '';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress && <Loader type="TailSpin" color="#FFF" height="30" width="30" />
    );
}

function LoginPage() {

    let history = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let hashParam;

    let retURL = location.search;

    if (retURL) {
        retURL = decodeURIComponent(retURL);
        retURL = retURL.replace('?returl=', '');

        hashParam = retURL.substring(retURL.indexOf('#'));
    }
    else {
        retURL = "/formation/fondamentaux-energie-climat";
    }

    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState();

    let { from } = { from: { pathname: "/formation/fondamentaux-energie-climat" } };

    if (location.state) {
        from = location.state;
    }

    if (retURL) {
        from = { from: { pathname: retURL, hash: hashParam } };
    }

    let login = (event) => {

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((result) => {

                if (result && result.user) {

                    let queriedUser = JSON.parse(JSON.stringify(result.user));
                    let quizz;
                    var quizzList = firebase.database().ref('quizzes/' + result.user.uid + '/user');

                    // If there is no quizz results, instanciate
                    if (quizzList) {

                        quizzList.on('value', (snapshot) => {

                            quizz = snapshot.val();
                            queriedUser.displayName = quizz.name;

                            if (!quizz) {

                                firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                                    "name": result.user.displayName,
                                    "uid": result.user.uid
                                });
                            }
                        });
                    }
                    else {
                        firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                            "name": result.user.displayName,
                            "uid": result.user.uid
                        });
                    }

                    let { from } = { from: { pathname: "/formation/fondamentaux-energie-climat" } };

                    if (location.state) {
                        from = location.state;
                    }

                    if (retURL) {
                        from = { from: { pathname: retURL, hash: hashParam } };
                    }


                    auth.signin(() => {

                        history.replace(from);
                    }, { "user": queriedUser });
                }
            })
            .catch((error) => {
                setErrorMessage('Identifiant ou mot de passe invalide.');
            });

    }


    let AnonymousNavigate = (event) => {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged) {

            firebaseAuth.signInAnonymously()
                .then(() => {

                })
                .catch((error) => {

                    setErrorMessage(error.code + ' - ' + error.errorMessage);

                });
        }
        else {
            history.push("/formation/fondamentaux-energie-climat");
        }
    }

    firebaseAuth.onAuthStateChanged((user) => {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged && user && user.isAnonymous) {

            let queriedUser = JSON.parse(JSON.stringify(user));
            queriedUser.displayName = 'Anonyme';

            firebase.database().ref('quizzes/' + queriedUser.uid + '/user').set({
                "name": "Anonyme",
                "uid": user.uid,
                "email": "Inconnu",
                "registrationDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
            });

            auth.signin(() => {
                history.push(retURL + "#questionnaire");
            },
                {
                    "user": queriedUser
                });


            // ...
        } else {
            // User is signed out
            // ...
        }
    });


    let timeoutLogin;

    let FacebookAuthNavigate = (event) => {

        // Add the loading parameter
        if (location.search) {
            location.search += '&isLoading=true';
        }

        history.push(location.pathname + location.search);

        firebaseAuth.signInWithNavigate(facebookProvider)
            .then(function (result) {

            }, function (error) {
                setErrorMessage(error.errorMessage);
            });

    }

    let GoogleAuthNavigate = (event) => {

        // Add the loading parameter
        if (location.search) {
            location.search += '&isLoading=true';
        }

        history.push(location.pathname + location.search);

        firebaseAuth.signInWithNavigate(firebaseProvider)
            .then(function (result) {

                auth.signin(() => {

                },
                    {
                        "user": result.user
                    });

            }, function (error) {
                setErrorMessage(error.errorMessage);
            });
    }

    useEffect(() => {

        let retURL = location.search;

        if (retURL) {
            retURL = decodeURIComponent(retURL);

            if (retURL.includes('isLoading')) {
                setIsLoading(true);
                timeoutLogin = setTimeout(function () {
                    setIsLoading(false);
                },
                    3000
                );
            }
        }

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged) {


            firebase
                .auth()
                .getNavigateResult()
                .then(result => {

                    let hashParam;
                    let retURL = location.search;

                    if (retURL) {
                        retURL = decodeURIComponent(retURL);

                        if (retURL.includes('isLoading')) {
                            retURL = retURL.replace('&isLoading=true', '');
                        }

                        retURL = retURL.replace('?returl=', '');
                    }
                    else {
                        retURL = "/formation/fondamentaux-energie-climat";
                    }

                    if (result && result.user && result.user.uid) {

                        clearTimeout(timeoutLogin);

                        let quizz;
                        var quizzList = firebase.database().ref('quizzes/' + result.user.uid);

                        // If there is no quizz results, instanciate
                        if (quizzList) {

                            quizzList.on('value', (snapshot) => {

                                quizz = snapshot.val();

                                if (!quizz) {

                                    firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                                        "name": result.user.displayName,
                                        "uid": result.user.uid,
                                        "email": result.user.email,
                                        "registrationDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                    });
                                }
                            });
                        }
                        else {
                            firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                                "name": result.user.displayName,
                                "uid": result.user.uid,
                                "email": result.user.email,
                                "registrationDate": new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
                            });
                        }

                        auth.signin(() => {

                            history.push(retURL + "#questionnaire");

                        },
                            {
                                "user": result.user
                            });
                    }
                })
                .catch(error => {
                    setErrorMessage(error.message);
                });

        }



    }, []);

    return (

        <>
            <Preloader />
            <div className="login-body">


                <div className="login-wrapper">


                    <form onSubmit={login} className="login-form" style={{ height: '670px' }}>

                        <div className="form__header header__logo" >
                            <img className="nav-logo" src={logo_full_white} alt="Énergie-Climat"></img>
                        </div>

                        <div className="form__body">
                            <h1 className="title">
                                Connexion
                            </h1>

                            {isLoading && <Loader type="TailSpin" color="#56BB8E" height="35" width="35" />}

                            {!isLoading && <>

                                <p className="quizz-top-page-error" style={{ display: 'block', marginBottom: '0rem', marginTop: '1.5rem', }} >{errorMessage}</p>

                                <input type="email" placeholder="Email" onChange={handleEmailChange} />
                                <input type="password" placeholder="Mot de passe" onChange={handlePasswordchange} />
                                <div className="forgot-pasword">
                                    <Link to="/forgot-password">Mot de passe oublié ?</Link>
                                </div>

                                <div className="u-center" style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                                    <div className="btn green-btn" onClick={login}>Connexion </div>
                                    <LoadingIndicator />
                                </div>

                                <div className="submit-google" onClick={GoogleAuthNavigate}>
                                    <img src="https://developers.google.com/identity/images/g-logo.png" ></img>
                                    <div>Connexion avec Google</div>
                                    <LoadingIndicator />
                                </div>

                                <div className="submit-google" onClick={FacebookAuthNavigate}>
                                    <img src="https://www.facebook.com/images/fb_icon_325x325.png" ></img>
                                    <div>Connexion avec Facebook</div>

                                    <LoadingIndicator />
                                </div>

                                <div className="or-class" style={{ marginTop: '4rem' }}>
                                    <div className="or-panel">OU</div>
                                </div>

                                <div className="u-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                    <div className="btn green-btn" style={{ background: 'var(--mainColor)' }} onClick={login}>
                                        <Link to={{
                                            pathname: "/signup",
                                            state: { fromParam: from.pathname }
                                        }}>Créer un compte</Link>
                                    </div>
                                </div>
                                <div className="forgot-pasword" style={{ cursor: 'pointer' }} onClick={AnonymousNavigate}>
                                    Je préfère rester anonyme
                                </div>

                            </>}

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}


function CookieConsent() {

    const [areCookiesOk, setAreCookiesOk] = useState(localStorage.getItem('acceptCookies'));

    let acceptCookies = (event) => {
        setAreCookiesOk(true);
        localStorage.setItem('acceptCookies', "OK");
    }

    return (

        <>
            {!areCookiesOk && <div className="ecorg-cookie-panel">

                <div>
                    <img src={Cookies}></img>
                </div>
                <div>

                    <div>
                        <p> Nous utilisons des cookies pour améliorer votre expérience et analyser le trafic sur le site.</p>
                    </div>

                    <div>

                        <div>
                            <Link to="/data-privacy">En savoir plus ></Link>
                        </div>
                        <div>
                            <div className="btn dark-blue-btn" onClick={acceptCookies} >Ok</div>
                        </div>

                    </div>
                </div>
            </div>}

        </>
    );
}


function ForgotPasswordPage() {

    let history = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const [errorMessage, setErrorMessage] = useState();
    const [isSent, setiIsSent] = useState();

    let { from } = { from: { pathname: "/formation/fondamentaux-energie-climat" } };

    let goToHomePage = () => {
        history.push('/');
    }

    let resetPassword = (event) => {

        event.preventDefault();

        firebase.auth().sendPasswordResetEmail(email)
            .then(function () {

                setiIsSent("true");

                // Password reset email sent.
            })
            .catch(function (error) {

                if (error.code == 'auth/invalid-email') {
                    setErrorMessage('L\'adresse email est incorrecte');
                }
                else {
                    setErrorMessage(error.errorMessage);
                }
            });

    };

    return (

        <>
            <Preloader />
            <div className="login-body">

                <div className="login-wrapper">
                    <form onSubmit={resetPassword} className="login-form" style={{ height: '330px' }}>

                        <div className="form__header header__logo" >
                            <img className="nav-logo" src={logo_full_white} alt="Énergie-Climat"></img>
                        </div>

                        <div className="form__body">
                            <h1 className="title">
                                Récupération de compte
                            </h1>

                            {isSent && <>
                                <p style={{ fontSize: '1.6rem', margin: '3rem auto' }}>Email de récupération du mot de passe envoyé à {email}.</p>

                                <a href="/" style={{ fontSize: '1.6rem' }} >Retour à la page d'accueil</a>

                            </>}
                            {!isSent && <>
                                <p className="quizz-top-page-error" style={{ display: 'block', marginBottom: '0rem', marginTop: '1.5rem', }} >{errorMessage}</p>

                                <input type="email" placeholder="Email" onChange={handleEmailChange} />

                                <div className="u-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                    <div className="btn green-btn" onClick={resetPassword}>Envoyer email</div>
                                    <LoadingIndicator />
                                </div>
                            </>}

                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

function SignUpPage() {

    let history = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const [errorMessage, setErrorMessage] = useState();
    const [createAccount, setCreateAccount] = useState();
    const [isLoading, setIsLoading] = useState();

    let { from } = { from: { pathname: "/formation/fondamentaux-energie-climat" } };

    let createNewAccount = (event) => {

        setCreateAccount("true");
    }

    let login = (event) => {

        event.preventDefault();

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;

                firebase.database().ref('quizzes/' + user.uid + '/user').set({
                    "name": name,
                    "uid": user.uid,
                    "email": email
                });

            })
            .catch((error) => {
                setErrorMessage(error.code + ' ' + error.message);
            });
    };

    let AnonymousNavigate = (event) => {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged) {

            firebaseAuth.signInAnonymously()
                .then(() => {

                })
                .catch((error) => {

                    setErrorMessage(error.code + ' - ' + error.errorMessage);

                });
        }
        else {
            history.push("/formation/fondamentaux-energie-climat");
        }
    }

    firebaseAuth.onAuthStateChanged((user) => {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged && user && user.isAnonymous) {

            let retURL = location.search;

            if (retURL) {
                retURL = decodeURIComponent(retURL);
                retURL = retURL.replace('?returl=', '');

                if (retURL.includes('isLoading')) {
                    setIsLoading(true);
                    timeoutLogin = setTimeout(function () {
                        setIsLoading(false);
                    },
                        3000
                    );
                }
            }
            else {
                retURL = "/formation/fondamentaux-energie-climat";
            }

            let queriedUser = JSON.parse(JSON.stringify(user));
            queriedUser.displayName = 'Anonyme';

            firebase.database().ref('quizzes/' + queriedUser.uid + '/user').set({
                "name": "Anonyme",
                "uid": user.uid,
                "email": "Unknown,",
                "registrationDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
            });

            auth.signin(() => {

                history.push(retURL + "#questionnaire");
            },
                {
                    "user": queriedUser
                });


            // ...
        } else {
            // User is signed out
            // ...
        }
    });


    let timeoutLogin;

    let FacebookAuthNavigate = (event) => {

        // Add the loading parameter
        if (location.search) {
            location.search += '&isLoading=true';
        }

        history.push(location.pathname + location.search);

        firebaseAuth.signInWithNavigate(facebookProvider)
            .then(function (result) {

            }, function (error) {
                setErrorMessage(error.errorMessage);
            });

    }

    let GoogleAuthNavigate = (event) => {

        // Add the loading parameter
        if (location.search) {
            location.search += '&isLoading=true';
        }

        history.push(location.pathname + location.search);

        firebaseAuth.signInWithNavigate(firebaseProvider)
            .then(function (result) {

                auth.signin(() => {

                },
                    {
                        "user": result.user
                    });

            }, function (error) {
                setErrorMessage(error.errorMessage);
            });
    }

    useEffect(() => {

        let retURL = location.search;

        if (retURL) {
            retURL = decodeURIComponent(retURL);

            if (retURL.includes('isLoading')) {
                setIsLoading(true);
                timeoutLogin = setTimeout(function () {
                    setIsLoading(false);
                },
                    3000
                );
            }
        }

        firebase
            .auth()
            .getNavigateResult()
            .then(result => {

                let hashParam;
                let retURL = location.search;

                if (retURL) {
                    retURL = decodeURIComponent(retURL);

                    if (retURL.includes('isLoading')) {
                        retURL = retURL.replace('&isLoading=true', '');
                    }

                    retURL = retURL.replace('?returl=', '');
                }
                else {
                    retURL = "/formation/fondamentaux-energie-climat";
                }

                if (result && result.user && result.user.uid) {

                    clearTimeout(timeoutLogin);

                    let quizz;
                    var quizzList = firebase.database().ref('quizzes/' + result.user.uid);

                    // If there is no quizz results, instanciate
                    if (quizzList) {

                        quizzList.on('value', (snapshot) => {

                            quizz = snapshot.val();

                            if (!quizz) {

                                firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                                    "name": result.user.displayName,
                                    "uid": result.user.uid,
                                    "email": result.user.email,
                                    "registrationDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
                                });
                            }
                        });
                    }
                    else {
                        firebase.database().ref('quizzes/' + result.user.uid + '/user').set({
                            "name": result.user.displayName,
                            "uid": result.user.uid,
                            "email": result.user.email,
                            "registrationDate": new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        });
                    }

                    auth.signin(() => {

                        history.push(retURL + "#questionnaire");

                    },
                        {
                            "user": result.user
                        });
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            });

    }, []);


    return (

        <>
            <Preloader />
            <div className="login-body">

                {createAccount &&
                    <div className="login-wrapper">
                        <form onSubmit={login} className="login-form" >

                            <div className="form__header header__logo" >
                                <img className="nav-logo" src={logo_full_white} alt="Énergie-Climat"></img>
                            </div>

                            <div className="form__body">

                                {isLoading && <Loader type="TailSpin" color="#56BB8E" height="35" width="35" />}

                                {!isLoading && <>
                                    <h1 className="title"> Sauvegarder votre progression&#8239;?</h1>

                                    <div className="why-account">
                                        <p>S'authentifier permet de sauvegarder votre progression et votre historique de réponses. </p>
                                        <p>Conformément à <Link to="/data-privacy" target="_blank">notre politique de gestion des données</Link>, nous n'utilisons pas et ne partageons pas vos données personnelles.</p>
                                    </div>

                                    <div className="u-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                        <div className="btn green-btn" onClick={createNewAccount}>Créer un compte</div>
                                        <LoadingIndicator />
                                    </div>

                                    <div className="submit-google" onClick={GoogleAuthNavigate}>
                                        <img src="https://developers.google.com/identity/images/g-logo.png" ></img>
                                        <div>Connexion avec Google</div>
                                        <LoadingIndicator />
                                    </div>

                                    <div className="submit-google" onClick={FacebookAuthNavigate}>
                                        <img src="https://www.facebook.com/images/fb_icon_325x325.png" ></img>
                                        <div>Connexion avec Facebook</div>

                                        <LoadingIndicator />
                                    </div>

                                    <div className="or-class">
                                        <div className="or-panel">OU</div>
                                    </div>

                                    <div className="u-center" style={{ marginTop: '3rem', paddingBottom: '3rem' }}>

                                        <div className="no-account" style={{ cursor: 'pointer' }} onClick={AnonymousNavigate}>
                                            Je préfère rester anonyme
                                        </div>

                                    </div>
                                </>}
                            </div>



                        </form>

                        <div className="social">
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="https://dribbble.com/" target="_blank" rel="noopener noreferrer">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </div>

                    </div>
                }

                {!createAccount &&
                    <div className="login-wrapper">
                        <form onSubmit={login} className="login-form" >

                            <div className="form__header header__logo" >
                                <img className="nav-logo" src={logo_full_white} alt="Énergie-Climat"></img>
                            </div>

                            <div className="form__body">
                                <h1 className="title">
                                    Créer un compte
                                </h1>

                                <p className="quizz-top-page-error" style={{ display: 'block', marginBottom: '0rem', marginTop: '1.5rem', }} >{errorMessage}</p>

                                <input type="text" placeholder="Nom / Pseudo" onChange={handleNameChange} />
                                <input type="email" placeholder="Email" onChange={handleEmailChange} />
                                <input type="password" placeholder="Créer un mot de passe" onChange={handlePasswordchange} />
                                <div className="accept-conditions">
                                    En créant un compte, je confirme que j'ai lu et que j'accepte <Link to="/cgu" target="_blank">les conditions générales d'utilisation</Link>.
                                </div>

                                <div className="u-center" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                    <div className="btn green-btn" onClick={login}>Créer un compte</div>
                                    <LoadingIndicator />
                                </div>

                                <div className="or-class">
                                    <div className="or-panel">OU</div>
                                </div>

                                <div className="u-center" style={{ marginTop: '3rem', paddingBottom: '3rem' }}>

                                    <div className="btn green-btn" style={{ background: 'var(--mainColor)' }} onClick={login}>
                                        <Link to="/login">Se connecter</Link>
                                    </div>

                                </div>

                            </div>
                        </form>

                        <div className="social">
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a href="https://dribbble.com/" target="_blank" rel="noopener noreferrer">
                                <i class="fab fa-dribbble"></i>
                            </a>
                        </div>

                    </div>
                }

            </div>
        </>
    );
}




