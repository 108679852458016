import React, { Component } from 'react'
import Smokes from '../images/curriculums/smokes.jpg'
import { FcReading, FcClock } from "react-icons/fc";
import CurriculumDetailsThumb from './CurriculumDetailsThumb'
import { curriculums } from '../data/Curriculums'
import { Helmet } from "react-helmet";

import Navbar from './Navbar'
import ResultModal from './ResultModal'
import Footer from './Footer'
import Preloader from './Preloader';

import firebase, { firebaseAuth, firebaseProvider } from '../firebase.js';

export default class CurriculumDetails extends Component {

    state = {
        isLoading: false,
        isCertificateDispayed: false,
        completion: 0,
        isCompleted: false,
        navbarClassName: 'navbar-faded-in',
        isAuthenticated: false,
        thisCurriculum: curriculums[0]
    }

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (nextProps.curriculumId != this.props.curriculumId) {
            this.setState({
                isLoading: true,
            });
        }

        return true;
    }

    displayCertificate = () => {

        this.setState({
            isCertificateDispayed: "true",
        });
    }

    componentDidMount() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
        let totalCompleted = 0;
        let isCompleted = false;

        if (storedUSerInfo && storedUSerInfo.user) {

            var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/overview');

            if (overview) {

                overview.on('value', (snapshot) => {

                    let thisCurriculum = this.state.thisCurriculum;

                    const data = snapshot.val();

                    console.log('data : ' + JSON.stringify(data));

                    if (data && data.results && data.results.length) {

                        let i;
                        for (i = 0; i < data.results.length; i++) {

                            let articleIndex;

                            let goThroughQuestions = curriculums[0].articles.map((element, j) => {

                                if (data && data.results && data.results[i] && data.results[i].isPassed && element.url === data.results[i].articleId) {
                                    articleIndex = j;
                                }
                            });

                            if (articleIndex >= 0) {
                                thisCurriculum.articles[articleIndex].isPassed = true;
                                totalCompleted += 1;
                            }
                        }
                    }

                    if (totalCompleted == 11) {
                        isCompleted = 'true';
                    }

                    this.setState({
                        isCompleted: isCompleted,
                        completion: ((totalCompleted / 11) * 100).toFixed(0),
                        isAuthenticated: true,
                        thisCurriculum: thisCurriculum
                    });
                });
            }
        }

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);

    }

    render() {

        let thisCurriculum = this.state.thisCurriculum;

        let detailThumbs;

        // const found = array1.find(element => element > 10);

        detailThumbs = this.state.thisCurriculum.articles.map((item, index) => {

            let dots = <></>;
            /*
            if (index > 0) {
                dots = <div className="flex-column">
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            }
            */

            return <>

                {dots}

                <CurriculumDetailsThumb title={item.title}
                    description={item.description} index={index + 1} image={item.imageThumb} readTime={item.readTime} url={item.url} isPassed={item.isPassed} />

            </>
        });

        return (
            <>

                <Helmet>
                    <title>{this.state.thisCurriculum.title}</title>
                    <meta name="description" content={thisCurriculum.description} />
                    <meta property="og:image" content={thisCurriculum.image} />
                </Helmet>

                <Preloader isLoading={this.state.isLoading} />

                <Navbar />


                <section className="curriculum-header">

                    <h1>{this.state.thisCurriculum.title}</h1>
                    <p>{thisCurriculum.description}</p>

                    <div class="description-footer" style={{ justifyContent: 'flex-start' }} >

                        <div className="header-section">
                            <FcReading className="cur-description-icon" /> <div class="cur-icon-text">{thisCurriculum.articlesNbr} Sujets</div>
                            <FcClock className="cur-description-icon" /> <div class="cur-icon-text">{thisCurriculum.readTime}</div>
                        </div>
                        {this.state.isAuthenticated && <>
                            <div style={{ maxWidth: '25rem', padding: '2rem 0', display: 'flex', alignItems: 'center' }}>

                                <div style={{ marginRight: '5px', fontSize: '1.65rem', fontWeight: '600', fontFamily: 'Poppins' }}>
                                    {this.state.completion}%
                                </div>

                                <div class="progress" style={{ width: "25rem" }}>
                                    <div class="bar" style={{ width: this.state.completion + '%' }}>
                                    </div>

                                </div>

                            </div>

                            {this.state.isCompleted &&
                                <ResultModal articleId="la-double-contrainte-carbone" displayModal={false} displayCertificateAction={true} />
                            }

                        </>
                        }

                    </div>

                </section>

                <section className="curriculum-list-section">

                    <div className="detail-thumb-list">

                        {detailThumbs}

                    </div>
                </section>

                <Footer />

            </>
        )
    }
}
