import React, { Component } from 'react'
import logo_full_white from '../images/energie-climat-white.png'
import { Link } from 'react-router-dom'
import Hamburger from './Hamburger'
import { AuthButton } from '../App.js'

export default class Navbar extends Component {

    state = {
        isOpen: false,
    }

    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    componentDidMount() {
        let marker = document.querySelector('#marker');
        let items = document.querySelectorAll('.nav-links div:not(first-child)');

        let links = document.querySelectorAll('a');

        links.forEach(link => {
            link.addEventListener('click', (e) => {
                this.setState({ isOpen: false });
            })

        });

        items.forEach(link => {

            if (link.classList.contains("with-hover")) {
                link.addEventListener('mouseover', (e) => { this.indicator(e.target) });
                link.addEventListener('mouseout', (e) => { this.indicatorOut(e.target) });
            }
        });
    }

    toggleBurgerMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    indicator(e) {

        let marker = document.querySelector('#marker');
        if (!e.classList.contains("link-container")) {
            marker.style.opacity = 1;
            marker.style.left = e.offsetLeft + "px";
            marker.style.width = e.offsetWidth + "px";
        }
    }

    indicatorOut(e) {
        let marker = document.querySelector('#marker');
        marker.style.opacity = 0;
    }

    render() {

        let navbarClass = "navbar";

        if (this.props.isTransparent) {
            navbarClass += " nav-hero";
        }

        if (this.state.isOpen) {
            navbarClass += " navbar-fixed";
        }

        return (
            <nav className={navbarClass}>

                <div className={this.state.isOpen ? "nav-header nav-header-open" : "nav-header"}>

                    <Link to="/">
                        <img className="nav-logo" src={logo_full_white} alt="Énergie-Climat" style={{ paddingTop: '10px' }}></img>
                    </Link>

                    <div className="nav-links">
                        <div className="link-container">
                            <div id="marker"></div>
                            <div className="with-hover"><Link to="/"> Accueil</Link></div>
                            <div className="with-hover"><Link to="/formation/fondamentaux-energie-climat">Formation</Link></div>
                            <div className="with-hover"><Link to="/modeles">Modèles</Link></div>
                            <AuthButton />
                        </div>
                    </div>

                    <div className="burgermenu" onClick={this.toggleBurgerMenu}>
                        <Hamburger />
                    </div>

                </div>
            </nav>
        );
    }
}

Navbar.defaultProps = {
    navclass: "navbar"
}


