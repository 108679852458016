import React, { Component } from 'react'
import { curriculums } from '../data/Curriculums'
import { Link } from 'react-router-dom';


import firebase, { firebaseAuth, firebaseProvider } from '../firebase.js';
import Checked from './Checked';

export default class FinishedQuiz extends Component {

    state = {
        simple: this.props.simple
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
        let totalCompleted = 0;

        if (storedUSerInfo && storedUSerInfo.user) {

            var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/overview');

            if (overview) {

                overview.on('value', (snapshot) => {

                    let thisCurriculum = this.state.thisCurriculum;

                    const data = snapshot.val();

                    if (data && data.results && data.results.length) {

                        let i;
                        for (i = 0; i < data.results.length; i++) {

                            let articleIndex;

                            let goThroughQuestions = curriculums[0].articles.map((element, j) => {

                                if (data && data.results && data.results[i] && data.results[i].isPassed && element.url === data.results[i].articleId) {
                                    articleIndex = j;
                                }
                            });

                            if (articleIndex >= 0) {
                                totalCompleted += 1;
                            }
                        }
                    }

                    this.setState({
                        completion: ((totalCompleted / 12) * 100).toFixed(0),
                        isAuthenticated: true
                    });
                });
            }
        }

    }

    render() {

        let currentArticle = curriculums[0].articles[0];
        let articleIndex = 0;

        curriculums.map((item, index) => {
            if (item.articles) {
                item.articles.map((item, index) => {
                    if (this.props.articleId == item.url) {
                        currentArticle = item;
                        articleIndex = index;
                    }
                });
            }
        });

        let nextArticle;
        let nextArticleUrl;


        if (articleIndex < curriculums[0].articles.length - 1) {
            nextArticle = curriculums[0].articles[articleIndex + 1];
            nextArticleUrl = '/' + nextArticle.url;
        }

        return (
            <>

                <div className="modal-overview-container">

                    <h1>Quiz terminé</h1>

                    <div style={{ position: "relative", marginTop: '4rem' }}>
                        <img src={currentArticle.imageThumb}></img>

                        <div style={{ position: "absolute", top: "-2rem", right: "-2rem" }}>
                            <Checked />
                        </div>

                    </div>

                    <h2>{currentArticle.title}</h2>

                    {!this.props.simple &&
                        <>
                            <div className="modal-overview-detail">

                                <div>Fondamentaux des enjeux énergie-climat</div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ marginRight: '10px', fontFamily: 'Poppins', fontWeight: '600', fontSize: '1.8rem' }}>{this.state.completion}%</div>
                                    <div class="progress" style={{ width: "25rem" }}>

                                        <div class="bar" style={{ width: this.state.completion + '%' }}></div>

                                    </div>
                                </div>

                            </div>
                        </>
                    }



                </div>

            </>
        )
    }
}
