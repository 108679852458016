export const questions = [
    {
        id: "croissance-verte",
        text: "Le PIB prend en compte :",
        type: "Multiple",
        isCurrent: false,
        isSubmitted: false,
        correctAnswers: 3,
        isValid: false,
        answers: [
            {
                text: "Les services",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Les produits neufs",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Les produits d'occasion",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Les atteintes aux actifs naturels",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Le travail effectué par les fonctionnaires",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "Sur quels principes directeurs reposent la croissance verte ?",
        type: "Multiple",
        isCurrent: false,
        correctAnswers: 2,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "L'adaptation face à la crise climatique et à la raréfaction des ressources devient l'objectif principal",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La croissance économique reste l'objectif principal",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Le déploiement des énergies renouvelables permet de couvrir une demande en énergie toujours croissante",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Une amélioration suffisante de l'efficacité énergétique permet de combiner baisse des émissions de CO2 et hausse du PIB",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "Sur quels principes directeurs reposent les modèles de décroissance / post-croissance ?",
        type: "Multiple",
        isCurrent: false,
        isSubmitted: false,
        correctAnswers: 2,
        isValid: false,
        answers: [
            {
                text: "L'adaptation face à la crise climatique et à la raréfaction des ressources devient l'objectif principal",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "La croissance économique reste l'objectif principal",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Il faut abandonner les nouvelles technologies et le confort moderne",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Le bien-être des populations doit être découplé de la croissance du PIB grâce à des innovations culturelles, politiques et sociales",
                isCorrect: true,
                isSelected: false
            },
        ]
    },
    {
        text: "Quels leviers sont incontournables pour maintenir le réchauffement bien en dessous de 2 °C ?",
        type: "Multiple",
        isCurrent: false,
        isSubmitted: false,
        correctAnswers: 4,
        isValid: false,
        answers: [
            {
                text: "Développer toutes les énergies bas carbone sans exception",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Améliorer l'efficacité énergétique",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Adopter des mesures de sobriété énergétique",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Refroidir artificiellement le climat par l'envoi massif de dioxyde de soufre dans l'atmosphère",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Développer les puits de carbone",
                isCorrect: true,
                isSelected: false
            }
        ]
    }
]