export const questions = [{
    id: "csc",
    text: "La capture et la séquestration du carbone : ",
    type: "Multiple",
    isCurrent: true,
    isSubmitted: false,
    correctAnswers: 2,
    isValid: false,
    answers: [
        {
            text: "Consiste à planter des arbres pour compenser ses émissions",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Permet théoriquement d'enfouir 70 à 90 % du CO2 émis par une centrale à charbon ou à gaz",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Est une technologie existant depuis plusieurs décennies",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "S'est largement développée en Europe depuis les années 2010",
            isCorrect: false,
            isSelected: false
        },
    ]
},
{
    text: "Quels sont les principaux freins au développement de la CSC ?",
    type: "Multiple",
    isCurrent: true,
    isSubmitted: false,
    correctAnswers: 2,
    isValid: false,
    answers: [{
        text: "Elle n'a jamais été prouvée scientifiquement",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Elle nécessite de diminuer les rendements énergétiques de 20 à 30 %",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Elle pollue systématiquement les nappes phréatiques",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Elle demande un investissement financier important et encore considéré comme risqué",
        isCorrect: true,
        isSelected: false
    }
    ]
}
]