import React, { Component } from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default class DataPrivacy extends Component {

    state = {
        isLoading: true,
        themesSection: React.createRef()
    }

    constructor(props) {
        super(props);
    }

    handleScrollToCurriculums = () => {
        // window.scrollTo({ behavior: 'smooth', top: this.state.themesSection.current.offsetTop - 30 });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    render() {

        return <>

            <Helmet>
                <title>Politique de Protection des Données Personnelles</title>
                <meta name="description" content="Politique de Protection des Données Personnelles" />
            </Helmet>

            <Preloader isLoading={this.state.isLoading} />

            <Navbar />

            <article className="article-container" >

                <div class="curriculum-header">

                    <h1>Politique de Protection des Données Personnelles</h1>
                    <div class="article-content">


                        <h2>Pourquoi utilisons-nous des cookies ?</h2>
                        <p>Nous utilisons des cookies sur notre Site pour les besoins de votre navigation, l’optimisation, la personnalisation de nos Services sur notre plateforme et le suivi du trafic sur le site.</p>
                        <p>Vous pouvez à tout moment désactiver les cookies via les options de votre navigateur.</p>
                        <p>Un « cookie » est un fichier texte déposé sur votre ordinateur lors de la visite de notre plateforme. Dans votre ordinateur, les cookies sont gérés par votre navigateur internet.</p>
                        <p>Nous utilisons des cookies sur notre Site pour les besoins de votre navigation, l'optimisation et la personnalisation de nos Services sur notre plateforme en mémorisant vos préférences. Les cookies nous permettent aussi de voir comment notre plateforme est utilisée. Nous recueillons automatiquement votre adresse IP et des informations relatives à l'utilisation de notre Site. Notre plateforme peut ainsi se souvenir de votre identité lorsqu'une connexion a été établie entre le serveur et le navigateur web. Les informations fournies précédemment dans un formulaire web pourront ainsi être conservées.</p><p> Différents types de cookies sont utilisés sur notre Site :</p>
                        <ul>
                            <li>Des cookies qui sont strictement nécessaires au fonctionnement de notre plateforme (cookies obligatoires). Ils vous permettent d'utiliser les principales fonctionnalités de notre plateforme, notamment le maintien votre session. Sans ces cookies, vous ne pourrez pas utiliser notre plateforme normalement.</li>
                            <li>Des cookies fonctionnels, parmis lesquels des cookies dits "analytiques" : afin d'améliorer nos services, nous utilisons des cookies de mesures d'audience telles que le nombre de pages vues, le nombre de visites, l'activité des Utilisateurs et leur fréquence de retour, notamment grâce aux services de Google Analytics. Ces cookies permettent seulement l'établissement d'études statistiques sur le trafic des Utilisateurs sur notre plateforme, dont les résultats sont totalement anonymes pour nous permettre de connaître l'utilisation et les performances de notre plateforme et d'en améliorer le fonctionnement.</li>
                            <li>Nous n’utilisons pas de de cookie de ciblage (cookies publicitaires).</li>
                        </ul>

                        <p>Types de cookies utilisés. Les types de cookies suivants sont utilisés sur ce Site :</p>
                        <ul>
                            <li><strong>Cookies "temporaires"</strong> : ce type de cookie est actif dans votre navigateur jusqu'à ce que vous quittiez notre plateforme et expire si vous n'accédez pas au Site pendant une certaine période donnée.</li>
                            <li><strong>Cookies "permanents" ou "traceurs" </strong> : ce type de cookie reste dans le fichier de cookies de votre navigateur pendant une période plus longue, qui dépend des paramètres de votre navigateur web. Les cookies permanents sont également appelés cookies traceurs.</li>
                        </ul>
                        <p>Utilisation des cookies de tiers. Nous pouvons recourir à des partenaires tiers, tels que Google Analytics, pour suivre l'activité des visiteurs de notre plateforme ou afin d'identifier vos centres d'intérêt sur notre plateforme et personnaliser l'offre qui vous est adressée sur notre plateforme ou en dehors de notre plateforme. Les informations pouvant ainsi être collectées par des annonceurs tiers peuvent inclure des données telles que des données de géo-localisation ou des informations de contact, comme des adresses électroniques. Les politiques de confidentialité de ces annonceurs tiers fournissent des informations supplémentaires sur la manière dont les cookies sont utilisés.</p>

                        <p>Nous veillons à ce que les sociétés partenaires acceptent de traiter les informations collectées sur notre plateforme exclusivement pour nos besoins et conformément à nos instructions, dans le respect de la réglementation européenne et s'engagent à mettre en œuvre des mesures appropriées de sécurisation et de protection de la confidentialité des données.</p>
                        <p>Vous pouvez à tout moment désactiver les cookies en sélectionnant les paramètres appropriés de votre navigateur pour désactiver les cookies (la rubrique d'aide du navigateur utilisé précise la marche à suivre).</p>
                        <p>Nous attirons votre attention sur le fait que la désactivation des cookies peut réduire ou empêcher l'accessibilité à tout ou partie de certaines fonctions.</p>
                        <p>Nous collectons les informations que vous nous fournissez notamment quand :</p>
                        <ul>
                            <li>vous naviguez sur notre plateforme et applications</li>
                            <li>vous créez, modifiez et accédez à votre compte personnel</li>
                        </ul>


                        <h2>Quelles données personnelles sont collectées et pour quelles finalités ?</h2>

                        <p>Nous ne collectons vos données personnelles que pour le bon fonctionnement du site :</p>
                        <ul>
                            <li><strong>Email</strong> : pour la récupération de votre compte en cas d'oubli du mot de passe.</li>
                            <li><strong>Nom et prénom</strong> : pour la génération de votre certificat de complétion.</li>
                        </ul>

                        <p>Lorsque vous vous inscrivez sur la plateforme, vous pouvez vous inscrire grâce à Facebook ou votre compte Google. Si vous vous connectez à nos services en utilisant les fonctionnalités de réseaux sociaux mises à votre disposition et notamment Facebook, energie-climat.info aura accès à l’adresse email utilisée par votre compt, conformément aux conditions générales d'utilisation du réseau social concerné.</p>

                        <p>Nous ne récupérons pas d'autres informations personnelles telles que la date de naissance ou votre photo de profil.</p>

                        <p>Dans le cadre d'enquêtes de satisfaction, nous pourrions éventuellement utiliser votre adresse email pour solliciter votre avis..</p>

                        <p>Nous n’utilisons pas votre adresse email pour des communications publicitaires ou des newsletters.</p>

                        <p>Notre site est en conformité avec le Règlement général sur la protection des données du 27 avril 2016.</p>


                        <h2>Vos données sont-elles partagées avec des tiers ?</h2>
                        <h3>Non.</h3>

                        <p>Les données personnelles vous concernant collectées sur notre plateforme sont destinées pour la propre utilisation par energie-climat.info et peuvent être transmises aux sociétés sous-traitantes auxquelles energie-climat.info peut faire appel dans le cadre de l'exécution de ses services (OVH, Google).</p>
                        <p>energie-climat.info ne vend ni ne loue vos données personnelles à des tiers à des fins de marketing, en aucun cas.</p>
                        <h2>Comment sont protégées vos données personnelles ?</h2>
                        <p>Nous prenons toutes les dispositions nécessaires pour que vos données soient protégées. Nous vous demandons également de veiller à la confidentialité des données.</p>

                        <p>Energie-climat.org applique les mesures de sécurité technologiques et organisationnelles généralement reconnues afin que les données à caractère personnel recueillies ne soient, ni perdues, ni détournées, ni consultées, ni modifiées ni divulguées par des tiers non autorisés sauf si la communication de ces données est imposée par la réglementation en vigueur.</p>
                        <p>La sécurité des données personnelles dépend également des Utilisateurs. Les Utilisateurs qui sont membres d'energie-climat.info s'engagent à conserver la confidentialité de leur identifiant et de leur mot de passe. Les membres s'engagent également à ne pas partager leur compte et à déclarer à energie-climat.info toute utilisation non autorisée dudit compte dès lors qu'ils en ont connaissance.</p>
                        <h2>Quelle est la durée de conservation des données personnelles des utilisateurs d'energie-climat.info ?</h2>
                        <p>Vos données sont conservées pour une durée limitée afin de vous assurer une sécurité optimale.</p>
                        <p>Les données à caractère personnel communiquées par les Utilisateurs seront supprimées à l'issue d'une certaine période et selon les données traitées.</p>
                        <p>Afin de permettre aux membres de récupérer leur compte en cas d'oubli de mot de passe après une période prolongée sans connexion au Site, votre e-mail est conservé par sécurité. Il est néanmoins possible de le supprimer en contactant le site via <Link to="/nous-contacter"> le formulaire dédié</Link>.</p>
                        <p>Nous conservons vos données uniquement le temps nécessaire pour les finalités poursuivies, conformément aux prescriptions légales, et notamment :</p>
                        <ul>
                            <li>3 ans après votre dernière utilisation de notre plateforme, si vous n'avez pas fermé votre compte ;</li>
                            <li>Dans l'hypothèse où votre compte a été suspendu ou bloqué, vos données sont alors supprimées dans les 3 mois.</li>

                        </ul>

                        <h2>Quels sont vos droits ?</h2>
                        <p>En application de la réglementation européenne en vigueur, nous avons mis en place des procédures et mécanismes vous permettant d’exercer vos droits. Conformément à la réglementation en vigueur les Utilisateurs de notre plateforme disposent des droits suivants :</p>
                        <ul>
                            <li>droit d'accès et de rectification ;</li>
                            <li>de mise à jour, de complétude des données Utilisateurs ;</li>
                            <li>droit de verrouillage ou de suppression des données des Utilisateurs à caractère personnel, lorsqu'elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite ;</li>
                            <li>droit de retirer à tout moment un consentement ;</li>
                            <li>droit à la limitation du traitement des données des Utilisateurs ;</li>
                            <li>droit d'opposition aux traitement des données personnelles ;</li>
                            <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l'objet de traitements automatisés fondés sur leur consentement ou un contrat.</li>
                        </ul>

                        <p>Si vous souhaitez savoir comment nous utilisons ces données personnelles, demander à les rectifier ou s'opposer à un traitement vous pouvez envoyer un message via <Link to="/nous-contacter"> le formulaire dédié</Link>.</p>
                        <p>Vos requêtes seront traitées sous un mois. En cas de doute raisonnable quant à votre identité nous pouvons demander que nous soient fournies des informations supplémentaires nécessaires pour confirmer votre identité.</p>

                    </div>
                    <div class="article-separator"></div>
                </div>

            </article>

            <Footer />

        </>
    }
}

