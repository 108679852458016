import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Reference from './Reference'

import electron from '../images/svg/nuclear.jpg';
import solar from '../images/svg/solar-panel.svg';
import wind from '../images/svg/wind.jpg';
import gas from '../images/svg/gaz-plant.jpg';
import dam from '../images/svg/dam.jpg';
import smoke from '../images/svg/smoke.jpg';
import coalPlant from '../images/svg/coal-plant.png';
import battery from '../images/svg/battery.jpg';
import hydrogen from '../images/svg/hydrogen.svg';
import STEP from '../images/svg/STEP.jpg';

import { energyAbacus } from '../data/EnergyAbacus';
import { renewableAbacus } from '../data/EnergyAbacus';

export default class CoalGame extends Component {

    state = {
        usageInit: 30,
        energyInit: 30,
        reductionEmission: 100,
        increaseUsage: [30, 30],
        usageVar: 30,
        energyConsumption: [30, 30],
        energyVar: 0
    }

    easeOutExpo = (x) => {
        return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
    }

    handleReductionChange = (event, newValue, index) => {

        let energyConsumption = 100 * this.state.usageVar / this.state.usageInit;
        let reduction = newValue / 100;

        energyConsumption = [30, ((energyConsumption * reduction) / 100) * this.state.energyInit];


        this.setState({
            reductionEmission: newValue,
            energyConsumption: energyConsumption
        });
    }


    getSelectedValue(newValue, initialValue) {

        if (newValue[0] == newValue[1]) {
            return newValue[0];
        }
        else if (newValue[0] != initialValue) {
            return newValue[0];
        }
        else if (newValue[1] != initialValue) {
            return newValue[1];
        }
    }


    handleUsageChange = (event, newValue, index) => {

        if (newValue[0] == this.state.usageInit || newValue[1] == this.state.usageInit) {

            let selectedValue = this.getSelectedValue(newValue, this.state.usageInit);

            let energyConsumption = 100 * (selectedValue / this.state.usageInit);
            let reduction = (this.state.reductionEmission) / 100;

            energyConsumption = [30, ((energyConsumption * reduction) / 100) * this.state.energyInit];

            //energyConsumption = Math.floor(energyConsumption * (this.state.reductionEmission) / 100) - 100;

            this.setState({
                increaseUsage: newValue,
                usageVar: selectedValue,
                energyConsumption: energyConsumption
            });

        }
    }

    render() {

        return (

            <>

                <article class="game-container coal-game" >
                    <div class="game">

                        <h3 className="energy-label" style={{ textAlign: 'center' }}>
                            <span >Puissance installée &nbsp;  </span>
                        </h3>

                        <section>

                            <label className="energy-label">
                                <span>Économie d'énergie</span>&nbsp;
                                {(100 - this.state.reductionEmission) > 0 && <span className="diff-text-neutral">{this.state.reductionEmission - 100} %</span>}
                            </label>
                            <div>
                                <GreenSlider value={this.state.reductionEmission} defaultValue={this.state.reductionEmission} onChange={this.handleReductionChange} aria-labelledby="continuous-slider" />
                            </div>

                            <label className="energy-label">
                                <span>Variation des usages</span>&nbsp;

                                {(this.state.usageVar) > 0 && <span className="diff-text-neutral"> {this.state.usageVar > this.state.usageInit && '+'} {Math.floor((this.state.usageVar / this.state.usageInit) * 100 - 100)} %</span>}
                            </label>
                            <div>
                                <GreenSlider value={this.state.increaseUsage} defaultValue={this.state.increaseUsage} onChange={this.handleUsageChange} aria-labelledby="continuous-slider" />
                            </div>

                            <div className="energy-label tr-results" style={{ color: '#3373BA', width: '100%' }}>
                                <span className="c-intensity tr-results" style={{ color: '#3373BA', width: '50px' }}> {(this.state.energyConsumption[1] / this.state.usageInit) > 1 && "+"}{Math.floor((this.state.energyConsumption[1] / this.state.usageInit) * 100 - 100)}% </span>
                                <span className="tr-results-label">Énergie </span>
                            </div>

                            <div className="tr-results" >
                                <span className="result-blocker"></span>
                                <EnergySlider value={this.state.energyConsumption} defaultValue={this.state.energyConsumption} id="energy-total" aria-labelledby="continuous-slider" />
                            </div>


                        </section>
                    </div>

                    {/* 
                    <div class="game-icons">

                        <section >
                            <div className="coal-header">




                            </div>


                        </section>
                    </div>
                    */}
                </article>

                <div className="coal-game-source">
                    {this.props.source}
                </div>

            </>


        )
    }
}

const GreenSlider = withStyles({
    root: {
        color: '#09bc8a',
        height: 8,
        "& . Mui-disabled": {
            color: '#09bc8a',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgba(12, 238, 174, 0.301)',
        },
        '& $active': {
            boxShadow: '0px 0px 0px 14px rgba(12, 238, 174, 0.301)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
        color: '#09bc8a',
    },

})(Slider);


const EmissionSlider = withStyles({

    root: {
        color: '#595A5A',
        height: 8,
        "& . Mui-disabled": {
            color: '#595A5A',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#595A5A',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        marginLeft: '-10px',
        borderRadius: 4,
        color: '#595A5A',
    },

})(Slider);


const DarkSlider = withStyles({
    root: {
        color: '#ccc',
        height: 8,
        "& . Mui-disabled": {
            color: '#09bc8a',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgba(12, 238, 174, 0.301)',
        },
        '& $active': {
            boxShadow: '0px 0px 0px 14px rgba(12, 238, 174, 0.301)',
        },

    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4
    },

})(Slider);


const EnergySlider = withStyles({

    root: {
        color: '#3373BA',
        height: 8,
        "& . Mui-disabled": {
            color: '#3373BA',
        }
    },
    thumb: {
        marginLeft: '0',
        'z-index': 4,
        '&:hover': {
            boxShadow: '0px 0px 0px 9px rgb(89, 90, 90, 0)',
        },
        '& $active': {
            color: '#3373BA',
            boxShadow: '0px 0px 0px 14px rgb(89, 90, 90, 0)',
        },
        width: '4px',
        height: '14px',
        marginBottom: '3px',
        borderRadius: '2px',


    },
    track: {
        'z-index': 4,
        height: 4,
        marginTop: -1,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        marginLeft: '-10px',
        borderRadius: 4,
        color: '#3373BA',
    },

})(Slider);
