import React from 'react'

import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'

import transitionVsCible from './images/transition-ernegetique-vs-cible.jpg'
import VitesseTransition from './images/vitesse-de-transition.jpg'
import VueDensemble from './images/vue-ensemble-transition-energetique.jpg'
import CoalGame from '../../components/CoalGame'

function Temps() {

    const target = React.createRef();

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Sur la base des technologies maîtrisées actuellement, nous pouvons dresser une vue générale de la transition énergétique.</p>

                    <div className="image-source">
                        <img src={VueDensemble} style={{ maxWidth: '55rem' }}></img>
                        <div >GIEC <Reference index="1" content="GIEC, 2014: Climate Change 2014: Synthesis Report,  <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'> Table A.III.2 | Emissions of selected electricity supply technologies</a> (en)    " />, US EIA <Reference index="2" content="Les facteurs de charges pour le solaire et l'éolien sont ceux des États-Unis sur l'année 2018. <br/><br/>  <em>U.S. Energy Information Administration</em>,
                                <a href='https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=epmt_6_07_b' target='_blank'>Capacity Factors for Utility Scale Generators Primarily Using Non-Fossil Fuels</a>, 2018 (en)." />
                        </div>
                    </div>

                    <p>Est-ce que nous serons capable de remplacer 75% des énergies fossiles par des sources sobres en carbone d'ici 30 ans ?</p>

                    <p>Rien n'est moins sûr. Comparons donc quelques ordres de grandeur pour percevoir l'ampleur de l'effort attendu.</p>

                    <p>Le déploiement le plus rapide d'une production électrique sobre en carbone par habitant revient aux programmes électronucléaires menés dans les années 70. En France bien sûr, mais également en Suède et en Finlande. Si tous les pays du monde arrivaient, en proportion de leur population, à renouveller l'exploit aujourd'hui, l'objectif attendu pourrait théoriquement être atteint après 25 à 40 ans. Concrètement, cela revient à mettre en service une centrale nucléaire moderne &ndash; comme l'EPR français &ndash; en moyenne tous les deux jours.</p>

                    <p>Avec les nouvelles énergies renouvelables, si l'on en croit les statistiques passées, ce serait beaucoup plus long.</p>

                    <p>Si le monde entier arrivait à s'aligner sur le Danemark, qui bénéficie de conditions de vent parmi les plus favorables au monde, remplacer les trois quarts de la consommation actuelle d'énergies fossiles prendrait <strong>105 ans</strong>. On retrouve à peu près le même résultat en prenant comme référence <em>l'Energiewende</em> &ndash; la transition énergétique allemande vers les énergies renouvelables.</p>

                    <div className="image-source" style={{ maxWidth: '55rem' }}>
                        <img src={VitesseTransition}></img>
                        <div >BP Statistical Review
                            <Reference index="5" content="Calcul de l'auteur à partir des données <em>BP</em>,
                            <a href='https://knoema.com/BPWES2017/bp-statistical-review-of-world-energy-main-indicators' target='_blank'>BP Statistical Review of World Energy - Main Indicators</a>, juin 2019 (en)." />
                        </div>
                    </div>

                    <p>La généralisation du nucléaire est limité, le déploiement des énergies renouvelables est trop lent ... Au final, arriver à remplacer ne serait-ce que les trois quarts des énergies fossiles, en moins de 30 ans, est totalement hors de portée.</p>

                    <img src={transitionVsCible} style={{ maxWidth: '55rem' }} />

                    <p>Pour combler le fossé qui sépare notre trajectoire de l’objectif que nous nous sommes fixé, il ne nous reste plus à ce stade qu'une seule option : réduire notre consommation d'énergie.</p>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    <RelatedArticles nextArticleTitle="9. La descente énergétique" url="/la-descente-energetique"></RelatedArticles>

                </div>

            </div>

        </>
    );
}

export default Temps

