export const DoubleContrainteQuestions = [{
    id: "la-double-contrainte-carbone",
    text: "Quelle est la cause principale du réchauffement climatique ?",
    type: "Single",
    correctAnswers: 1,
    isCurrent: false,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "La variation de l'activité solaire",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "La variation naturelle de l'orbite terrestre",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "L'utilisation massive des énergies fossiles",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "La précession des équinoxes",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Le CO2 émis cette année sera épuré naturellement de l'atmosphère : ",
    type: "Single",
    isCurrent: false,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "D'ici quelques années",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Dans 100 ans",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Progressivement sur des milliers d'années",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Jamais",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Qu'est-ce qui caractérise le pic pétrolier ?",
    type: "Single",
    isCurrent: false,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "La date à partir de laquelle tous les gisements conventionnels auront été épuisés",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "La date à partir de laquelle tous les gisements, conventionnels et non conventionnels, auront été épuisés",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Le passage par un maximum de production",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Une limite physique qui limitera forcément le réchauffement climatique à 2 °C",
        isCorrect: false,
        isSelected: false
    }
    ]
},
]