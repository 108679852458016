import React, { Component } from 'react'
import { curriculums } from '../data/Curriculums'
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';

import firebase, { firebaseAuth, firebaseProvider } from '../firebase.js';
import Certification from './Certification';
import Checked from './Checked';
import FinishedQuiz from './FinishedQuiz';

export default class ResultModal extends Component {

    state = {
        isModalDisplayed: this.props.displayModal,
        isWon: false
    }

    constructor(props) {
        super(props);
    }

    goToNextArticle = () => {
        this.props.history.push('/');
    }

    displayModal = () => {

        this.setState(
            {
                isModalDisplayed: true
            }
        )
    }

    closeModal = () => {

        this.setState(
            {
                isModalDisplayed: false
            }
        )
    }

    displayCertificate = () => {
        this.setState(
            {
                isModalDisplayed: true
            }
        )
    }

    componentDidMount() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
        let totalCompleted = 0;

        if (storedUSerInfo && storedUSerInfo.user) {

            var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/overview');

            if (overview) {

                overview.on('value', (snapshot) => {

                    let thisCurriculum = this.state.thisCurriculum;

                    const data = snapshot.val();

                    if (data && data.results && data.results.length) {

                        let i;
                        for (i = 0; i < data.results.length; i++) {

                            let articleIndex;

                            let goThroughQuestions = curriculums[0].articles.map((element, j) => {

                                if (data && data.results && data.results[i] && data.results[i].isPassed && element.url === data.results[i].articleId) {
                                    articleIndex = j;
                                }
                            });

                            if (articleIndex >= 0) {
                                totalCompleted += 1;
                            }
                        }
                    }

                    this.setState({
                        completion: ((totalCompleted / 11) * 100).toFixed(0),
                        isAuthenticated: true
                    });
                });
            }
        }

        if (totalCompleted == 11) {

            this.setState({
                isWon: "true"
            });
        }

    }

    render() {

        let currentArticle = curriculums[0].articles[0];
        let articleIndex = 0;

        curriculums.map((item, index) => {
            if (item.articles) {
                item.articles.map((item, index) => {
                    if (this.props.articleId == item.url) {
                        currentArticle = item;
                        articleIndex = index;
                    }
                });
            }
        });

        let nextArticle;
        let nextArticleUrl;
        let nextArticleName;

        if (articleIndex < curriculums[0].articles.length - 1) {
            nextArticle = curriculums[0].articles[articleIndex + 1];
            nextArticleUrl = '/' + nextArticle.url;
        }

        return (
            <>

                {this.props.displayCertificateAction &&
                    <div style={{ marginLeft: '10px' }}>
                        <a onClick={this.displayCertificate} >Afficher le certificat de complétion</a>
                    </div>}

                {!this.state.isLoading &&
                    <>

                        {this.state.isModalDisplayed &&

                            <div div id="myModal" class="modal">
                                <div class="modal-content" style={{ padding: '0' }}>

                                    <span class="close" onClick={this.closeModal}>&times;</span>

                                    {this.state.isWon && <>
                                        <Certification />
                                        <div style={{ marginTop: '4rem' }}></div>
                                    </>}

                                    {!this.state.isWon && <>
                                        <FinishedQuiz articleId={this.props.articleId} />

                                        {nextArticle && <>
                                            <div style={{ width: '100%', border: '1px solid #e5e5e5' }} ></div>

                                            <div className="u-center" style={{ padding: '4rem' }}>
                                                <div className="btn green-btn" style={{ background: 'var(--mainColor)', color: "#fff" }} >

                                                    <h2>{nextArticleName}</h2>

                                                    <Link to={nextArticleUrl}>
                                                        Article suivant
                                                    </Link>
                                                </div>
                                            </div>
                                        </>}
                                    </>}

                                </div>
                            </div>
                        }
                    </>
                }

            </>
        )
    }
}
