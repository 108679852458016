export const mainQuestions = [{
    text: "Qu’est-ce qui est vrai à propos du réchauffement climatique ?",
    type: "Multiple",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "Les températures redescendront à partir du moment où nous commencerons à réduire significativement nos émissions de gaz à effet de serre",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "La canicule de 2003 pourrait devenir un été normal en France d’ici 30 ans",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Les phénomènes naturels comme la variation d’activité solaire jouent un rôle important",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "C’est une très grave menace pour la sécurité alimentaire et la prosperité de milliards d’êtres humains",
        isCorrect: true,
        isSelected: false
    }
    ]
},
{
    text: "Qu’appelle-t-on « la double contrainte carbone » ?",
    type: "Single",
    isCurrent: false,
    answers: [{
        text: "Le fait que les continents se réchauffent deux fois plus vite que le réchauffement global",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Le fait de subir en même temps les conséquences du réchauffement climatiques et de la raréfaction des ressources fossiles",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "La nécessité de diminuer simultanément nos émissions de CO2 et de méthane",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "La nécessité de diminuer nos émissons de CO2 et d’augmenter les puits de carbone, comme les forêts",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Qu'est-ce qui caractérise l'exploitation de ressources non renouvelables ?",
    type: "Multiple",
    isCurrent: false,
    answers: [
        {
            text: "Les gisements les plus accessibles sont exploités et donc épuisés en premier",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Les quantités produites chaque année passent forcément par un pic à partir duquel elles ne pourront tendanciellement que diminuer",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Les prix dépendent toujours des stocks restants",
            isCorrect: false,
            isSelected: false
        }
    ]
},
{
    text: "Quelle est la priorité n°1 pour lutter contre le réchauffement climatique ?",
    type: "Single",
    isCurrent: false,
    answers: [{
        text: "Installer des énergies renouvelables",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Sortir du charbon",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Sortir du nucléaire",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Planter des arbres",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Moins prendre l’avion",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Le gaz naturel :",
    type: "Multiple",
    isCurrent: false,
    answers: [{
        text: "Est une source d’énergie bas carbone",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Est un mode de production d’électricité pilotable",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Contient essentiellement du méthane, un puissant gaz à effet de serre",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Est moins polluant que le charbon, l’essence ou le diesel",
        isCorrect: true,
        isSelected: false
    }
    ]
},
{
    text: "Panneaux solaires et éoliennes :",
    type: "Multiple",
    isCurrent: false,
    answers: [{
        text: "Constituent une source d’énergie bas carbone",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Peuvent aider à réduire la part des énergies fossiles dans la production d’électricité",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Peuvent fonctionner pendant plus de 60 ans",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Sont sûrs et très peu polluants",
        isCorrect: true,
        isSelected: false
    }
    ]
},
{
    text: "Sur une centrale nucléaire, la fumée qui s’échappe de ses tours contient de manière significative :",
    type: "Multiple",
    isCurrent: false,
    answers: [
        {
            text: "Du CO<sub>2</sub>",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Des particules radioactives",
            isCorrect: false,
            isSelected: false
        }, {
            text: "De la vapeur d’eau",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Des particules fines",
            isCorrect: false,
            isSelected: false
        }
    ]
},
{
    text: "Remplacer du nucléaire par du solaire :",
    type: "Multiple",
    isCurrent: false,
    answers: [{
        text: "Réduit globalement les émissions de CO<sub>2</sub>",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Augmente globalement les émissions de CO<sub>2</sub>",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Permet de réduire la production de déchets nucléaires",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Augmente la production de déchets nucléaires",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Qu’est-ce qui constitue aujourd’hui l’immense majorité des capacités de stockage d’énergie ?",
    type: "Single",
    isCurrent: false,
    answers: [{
        text: "Les batteries",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Les retenues d'eau",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Les volants d’inertie",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "L’hydrogène",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Le méthane vert",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Quelles alternatives aux véhicules à essence peuvent aujourd'hui permettre de réduire les émissions de plus de 90% ?",
    type: "Multiple",
    isCurrent: false,
    answers: [{
        text: "Les voitures électriques",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Le vélo",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Les voitures à hydrogène",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Les transports en commun",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Les véhicules roulant au gaz naturel",
        isCorrect: false,
        isSelected: false
    },
    ]
},
{
    text: "L’hydrogène :",
    type: "Multiple",
    isCurrent: false,
    answers: [{
        text: "Est une source d’énergie primaire quasi infinie et très abondante",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Est aujourd’hui, dans l’immense majorité des cas, produit à base de gaz et de charbon",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Permet un stockage d’énergie sur de très longues durées",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Est une solution de stockage d'énergie avec un très bon rendement énergétique",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "En 2020, quels sont les deux sources d'énergies majoritaires dans les pays produisant l'électricité la plus bas carbone ?",
    type: "Multiple",
    isCurrent: false,
    answers: [
        {
            text: "Des panneaux solaires",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Des éoliennes offshore",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Des barrages hydroélectriques",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Des centrales à charbon propre",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Des centrales nucléaires",
            isCorrect: true,
            isSelected: false
        }
    ]
},
{
    text: "Citer un exemple d’effet rebond",
    type: "Single",
    isCurrent: false,
    answers: [
        {
            text: "Améliorer l’isolation des logements entraîne une augmentation de la température moyenne à l’intérieur de ceux-ci",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Installer trop de pistes cyclables provoque des bouchons qui finissent par augmenter la pollution",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Appliquer une taxe carbone trop élevée pousserait les consommateurs à se rabattre sur des produits étrangers moins chers mais plus polluants",
            isCorrect: false,
            isSelected: false
        }
    ]
},
{
    text: "À quoi correspond l’intensité énergétique du PIB ?",
    type: "Single",
    isCurrent: false,
    answers: [
        {
            text: "Le rapport entre population ayant accès à l’électricité et la population totale",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "La quantité moyenne d'énergie consommée chaque jour",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "La quantité d’énergie nécessaire à une économie pour produire une quantité de richesses donnée",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "L'intensité, en Ampères, du courant distribué par le réseau électrique ",
            isCorrect: false,
            isSelected: false
        }

    ]
},
{
    text: "Parmi les activités économiques suivantes, lesquelles sont comptées comme de la production de richesses par le PIB ?",
    type: "Multiple",
    isCurrent: false,
    answers: [
        {
            text: "La vente de produits neufs",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "La vente de produits d'occasion",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Les revenus issus de la spéculation financière",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "La rémunération des fonctionnaires",
            isCorrect: true,
            isSelected: false
        }
    ]
}
]