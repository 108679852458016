import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, Link, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import TransitionGame from '../../components/TransitionGame'

import PIB from './images/pib.jpg'
import PIBMobile from './images/pib-MOB.jpg'
import Intensite from './images/intensite-energetique-pib.jpg'
import valeurAjoutee from './images/valeur-ajoutee.jpg'
import valeurAjouteeMobile from './images/valeur-ajoutee-MOB.jpg'
import croissanceVerteEfficacite from './images/croissance-verte-efficacite.jpg'
import croissanceVerteServices from './images/croissance-verte-services.jpg'
import postCroissance from './images/post-croissance.jpg'
import MiracleTechnologique from './images/MiracleTechnologique.jpg'
import EffetRebond from './images/effet-rebond.jpg'
import Sobriete from './images/sobriete.jpg'
import PibTransitionEnergetique from './images/PibTransition.jpg'
import PibTransitionEnergetiqueMobile from './images/PibTransition-mobile.jpg'

import vueGenerale from './images/vue-generale.jpg'
import vueGeneraleMobile from './images/vue-generale-mobile.jpg'

import decouplageabsolu from './images/decouplage-absolu.jpg'
import decouplagrelatif from './images/decouplage-relatif.jpg'




function DescenteEnergetique() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/croissance-verte");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Une transition vers des énergies bas-carbone entraînera le déclin d'industries entières, mais aussi l'émergence de nouveaux besoins, produits et services. Quelle serait la résultante de ces changements à l'échelle globale&#8239;? Une telle transformation peut-elle constituer un relais de croissance ? Ou au contraire se traduire par une contraction de l'économie mondiale ? </p>

                    <h2>Le PIB</h2>

                    <p>Le PIB, ou produit intérieur brut, a été inventé en 1934 par l'économiste américain Simon Kuznets. Construit afin d'évaluer l'effet de la Grande Dépression sur l'économie, Kuznets précise déjà à l'époque que cet outil n'a pas vocation à mesurer seul le bien-être de la population.</p>

                    <p>Le PIB mesure la valeur économique des richesses nouvellement créées par un pays ou une zone donnée. Par convention, ce calcul se base sur celui de la valeur ajoutée. Pour une entité productive, comme une entreprise, elle se définit comme la différence entre les consommations intermédiaires, soit les matières premières et produits achetés par cette entreprise, et les ventes.</p>

                    <div className="image-source">
                        <img src={valeurAjoutee} alt="PIB" style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={valeurAjouteeMobile} alt="PIB" style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                    </div>

                    <p> C'est le travail qui produit de la valeur ajoutée. Quand celui-ci est mécanisé, il utilise de l’énergie pour transformer des matières premières en richesses d’un côté et en déchets, dont du CO<sub>2</sub>, de l’autre.</p>

                    <p>La pollution, les atteintes à l’environnement ou à la santé engendrent un coût supplémentaire, présent ou futur, que le calcul de la valeur ajoutée ignore&nbsp;: ce sont des externalités négatives.</p>

                    <div className="image-source">
                        <img src={PIB} alt="PIB" style={{ maxWidth: '55rem' }} className="img-desktop"></img>
                        <img src={PIBMobile} alt="PIB" style={{ maxWidth: '35rem' }} className="img-mobile"></img>
                    </div>

                    <p>Par ailleurs, une convention de l’ONU datant de 1968 impose de considérer les services publics comme productifs, leur coût doit dès lors être comptabilisé comme une composante non marchande du PIB.</p>

                    <p>Par définition, seules les richesses nouvellement produites sont comptabilisées, les ventes d’occasion ne comptent ainsi pas dans le PIB, pas plus que tout le patrimoine mobilier et immobilier déjà en circulation.</p>

                    <h2>La descente énergétique</h2>

                    <p>En ordre de grandeur, à l'échelle mondiale, remplacer l'énergie aujourd'hui fournie par les combustibles fossiles représente la construction de 10&nbsp;000 réacteurs nucléaires de type EPR ou l'électricité produite par plus de 25 millions d'éoliennes terrestres.</p>

                    <div className="image-source">

                        <img src={PibTransitionEnergetique} alt="PIB" style={{ maxWidth: '55rem' }} className="img-desktop"></img>
                        <img src={PibTransitionEnergetiqueMobile} alt="PIB" style={{ maxWidth: '37rem' }} className="img-mobile"></img>

                    </div>

                    <p>Construire de telles capacités de production en moins de 30 ans paraît aujourd'hui très largement hors de portée. Dès lors, sortir des énergies fossiles entrane nécessairement une réduction marquée de la consommation d'énergie.</p>

                    <p>Concernant le PIB, deux possibilités&nbsp;:</p>

                    <ul>
                        <li><strong>Découplage absolu</strong> — La production de richesses peut être totalement découplée de la consommation d'énergie, et donc continuer à croître.</li>
                        <img src={decouplageabsolu} alt="PIB" style={{ maxWidth: '35rem' }} ></img>

                        <li><strong>Découplage relatif</strong> — La production de richesses reste corrélée à la consommation d'énergie. Même si la dépendance se réduit avec le temps, le PIB va se contracter.</li>
                        <img src={decouplagrelatif} alt="PIB" style={{ maxWidth: '35rem' }} ></img>
                    </ul>

                    <h2>La délocalisation des émissions</h2>

                    <p>On observe dans les pays développés un découplage absolu entre PIB et consommation d'énergie. Cette amélioration de l'efficacité énergétique fonctionne en trompe-l'œil, car en réalité une part significative s'explique par la délocalisation des industries vers des pays à plus bas coût.</p>

                    <p>Seulement, ce transfert est loin d'être neutre sur un plan climatique. Déplacer des usines vers la Chine, où elles s'avèrent largement alimentées par du charbon, plutôt qu'en France ou en Grande-Bretagne, entraîne globalement des hausses significatives d'émissions de CO<sub>2</sub>.</p>

                    <p>Précisons en outre que la consommation énergétique des avions et des navires qui assurent les liaisons internationales constitue, pour reprendre la terminologie officielle, des <em>«&#8239;soutes énergétiques&#8239;»</em>. Elles ne sont comptabilisées ni dans le pays de départ ni dans le pays d’arrivée. Les dépenses énergétiques —&nbsp;et les émissions de CO<sub>2</sub> correspondantes&nbsp;— pour transporter vers l’Europe des dizaines de millions de touristes et toute la production venant d’Asie, sont en réalité affectées à deux organisations internationales&#8239;: <em>l’Organisation de l’aviation civile internationale</em> (OACI) et <em>l’Organisation maritime internationale</em> (OMI).</p>

                    <p>Par conséquent, il est important de raisonner sur l'empreinte carbone globale d'une économie donnée, et pas seulement en termes d'émissions par pays.</p>

                    <h2>La croissance verte</h2>

                    <p>La croissance «&nbsp;verte&nbsp;» vise une croissance durable capable de préserver les actifs naturels que sont le climat ou la biodiversité, en arrivant à découpler le PIB de la consommation de ressources. Pour produire plus en consommant moins d'énergie, de tels scénarios reposent sur une amélioration considérable de l'efficacité énergétique.</p>

                    <div className="image-source">
                        <img src={croissanceVerteEfficacite} alt="Intensité énergétique" style={{ maxWidth: '44rem' }}></img>
                    </div>

                    <p>Sur les quatre dernières décennies, l’efficacité énergétique de l’économie mondiale progresse chaque année de 1,1&#8239;%. Maintenir le PIB actuel dans un contexte de descente énergétique marquée demanderait d'accélérer le rythme d'un facteur deux à quatre.
                        <Reference index="1" content=" <em>Agence internationale de l'énergie</em> ,<a target='_blank' href='https://donnees.banquemondiale.org/indicator/NY.GDP.MKTP.KD?end=2018&start=1960&view=chart'>Total final consumption by source</a>, 1965 - 2018<br/><br/><em>Banque mondiale</em>,
                                     <a target='_blank' href='https://donnees.banquemondiale.org/indicator/NY.GDP.MKTP.KD?end=2018&start=1960&view=chart'>PIB ($ US constants de 2010)</a>, 1965 - 2018." />
                    </p>

                    <p>Pour y parvenir, deux leviers peuvent être activés&nbsp;:</p>
                    <ul>
                        <li>Déployer des innovations techniques</li>
                        <li>Faire progresser la part de l’économie qui n'est pas ou est peu mécanisée, et donc peu consommatrice d'énergie</li>
                    </ul>

                    <h2>L'effet rebond</h2>

                    <p>En matière d’innovations techniques pures, les principaux gains d’efficacité énergétique se trouvent très probablement derrière nous. On observe d’ailleurs, depuis le début des années&nbsp;2000, un certain ralentissement, preuve s’il en est que l’arrivée d’internet n’a pas dématérialisé l’économie.</p>

                    <p>Une plus grande marge d’amélioration réside en revanche dans le déploiement des innovations existantes. Ainsi, dans les pays développés, la rénovation énergétique des bâtiments arrive systématiquement en tête des propositions écologiques faisant consensus. Une telle mesure s’avère d'autant plus pertinente que les énergies fossiles, en particulier le fioul et le gaz, restent prédominantes dans le chauffage des habitations.</p>

                    <p>En la matière, l’Allemagne fait figure de bon élève&nbsp;: remplacement des fenêtres, installation de nouveaux systèmes de chauffage, isolement des façades… Depuis&nbsp;2010, le pays a investi plus de 340&nbsp;milliards d’euros dans des travaux colossaux.</p>

                    <p>Résultat des courses&#8239;: la consommation d’énergie des foyers est restée stable. Mais les ménages allemands qui avant se chauffaient en moyenne à 20&#8239;°C profitent désormais d’un intérieur à 22&#8239;°C.

                        <Reference index="2" content="<em>GdW</em>, <a target='_blank' href='https://www.gdw.de/media/2020/07/jpk2020-praesentation-1.6-mit-kmt-o-bs-.pdf#page=32'>Die Wohnungswirtschaft in Deutschland</a>, July 2020 (de)" />
                    </p>

                    <p>Cet exemple est loin d'être un cas isolé, après un déploiement d'améliorations techniques, les économies d’énergie observées ne dépassent pas, dans le meilleur des cas, 70&#8239;% de celles initialement escomptées.
                        <Reference index="3" content="UK Energy Research Centre, <em>UKERC</em>,
                                <a target='_blank' href='https://ukerc.ac.uk/publications/the-rebound-effect-an-assessment-of-the-evidence-for-economy-wide-energy-savings-from-improved-energy-efficiency/'>The Rebound Effect: An Assessment of the Evidence for Economy-wide Energy Savings from Improved Energy Efficiency</a>, Page 36, Table 3.1 «&nbsp;Estimates of the long-run direct rebound effect for consumer energy services in the OECD&nbsp;», October 2007 (en)" />
                    </p>

                    <p>Car l’efficacité énergétique entraîne un effet pervers&#8239;: celui de rendre l’usage visé moins cher, ou moins culpabilisant, et donc plus attractif. Le gain d’efficacité peut ainsi se voir annulé par des changements de comportement qui mènent à une augmentation des usages concernés&#8239;: c’est l’effet rebond.</p>

                    <div className="image-source">
                        <img src={EffetRebond} style={{ maxWidth: '35rem' }}></img>
                    </div>

                    <p>Dans l’automobile, la baisse continue de la consommation des moteurs ne s’est pas traduite par une réduction des émissions, bien au contraire. Partout dans le monde, la mode est désormais aux SUV : des véhicules plus hauts, plus larges et plus lourds. Sur la dernière décennie, la mise en circulation de ces 4x4 urbains constitue le deuxième poste d’augmentation des émissions, derrière le charbon.
                        <Reference index="4" content="Agence Internationale de l'énergie (AIE) <a target='_blank' href='https://www.iea.org/commentaries/growing-preference-for-suvs-challenges-emissions-reductions-in-passenger-car-market'>Growing preference for SUVs challenges emissions reductions in passenger car market</a>, Octobre 2019 (en)." />

                    </p>

                    <p>En outre, lorsqu’il s’agit d’argent, «&nbsp;économiser&nbsp;» permet d’éviter une dépense à un instant donné pour mettre de côté, et donc dépenser plus tard. Mais dans le cas de l’énergie, cela ne fonctionne pas de cette manière. Si les économies réalisées sur des postes de dépense énergétique, par exemple le chauffage, se reportent après coup sur d’autres secteurs encore plus énergivores, comme acheter un second véhicule, ou voyager en avion, alors le gain initial se voit annulé. Ce cas de figure relève aussi d’une forme d’effet rebond, cette fois-ci indirect.</p>

                    <p>Pour finir, diminuer légèrement l’impact écologique d’une pratique, en recourant par exemple à des mécanismes dits de «&#8239;compensation&#8239;», a tendance à générer un «&#8239;effet indulgence&#8239;». Déculpabiliser le consommateur revient de fait à favoriser l’essor du problème contre lequel on prétend lutter.</p>

                    <h2>Vers une société de services&#8239;?</h2>

                    <p>Si la production de biens matériels dépend étroitement des dépenses énergétiques, nous pouvons espérer que les services échappent à cette contrainte.</p>

                    <div className="image-source">
                        <img src={croissanceVerteServices} alt="Intensité énergétique" style={{ maxWidth: '46rem' }}></img>
                    </div>

                    <p>Mais la tertiarisation des économies développées ne doit pas faire oublier à quel point l’économie mondiale reste dépendante de mouvements de marchandises on ne peut plus physiques. Sans énergie, pas de production, pas de déplacements et pas de livraisons.</p>

                    <p>Cette remarque vaut particulièrement pour une grande partie de l’économie numérique. Que ça soit en jouant le rôle de distributeur, d’intermédiaire ou par l’affichage de publicités, les grandes entreprises du numérique tirent bien l’essentiel de leurs revenus de marchandises physiques. Sans compter le fait que la croissance de ces services passe par l’installation de centres de données et d’infrastructures qui consomment eux-mêmes de l’énergie.</p>

                    <p>Néanmoins, l’impact principal du numérique reste celui engendré par tous les appareils électroniques&#8239;: smartphones, ordinateurs, tablettes, objets connectés, etc. Leur production requiert de grandes quantités d’énergie et surtout des métaux rares qui s’épuisent progressivement. Le numérique est une ressource précieuse et aujourd’hui non renouvelable.</p>

                    <p>La crise du Covid-19 a accéléré le déploiement et l’utilisation du numérique partout. Mais les confinements mis en place dans les différents pays, les perturbations sur les approvisionnements et les pénuries qui ont suivi lors de la reprise, ont aussi montré les limites d’un modèle économique censé s’affranchir des contraintes du monde physique.</p>

                    <h2>Développement sans croissance économique </h2>

                    <p>Les acteurs économiques, les gouvernements et les grandes institutions internationales se sont régulièrement fixés pour objectif d'arriver à combiner baisse des émissions de CO<sub>2</sub> et croissance économique grâce à une amélioration continue de l'efficacité énergétique.</p>

                    <p>En pratique, les plus de 900 publications scientifiques consacrées au sujet convergent vers la même conclusion :</p>
                    <p>
                        <ul>
                            <li>Il n’existe aujourd’hui aucun exemple passé ou présent de croissance durable du PIB sans croissance de la consommation d’énergie. Tout découplage apparent cache en réalité un recouplage dans un autre pays ou un autre secteur</li>
                            <li>Même avec un degré d'innovation conséquent, réaliser un découplage durable, et dans le temps imparti, reste très probablement illusoire
                                <Reference index="5" content=" <em>Parrique T. et al</em>, <a target='_blank' href='https://mk0eeborgicuypctuf7e.kinstacdn.com/wp-content/uploads/2019/07/Decoupling-Debunked.pdf'>Decoupling Debunked, Evidence and arguments against green growth as a sole strategy for sustainability</a>, juillet 2019 (en) <br><br>
                        
                        <em>Jason Hickel, Giorgos Kallis </em>, <a target='_blank' href='https://www.tandfonline.com/doi/abs/10.1080/13563467.2019.1598964'>Is Green Growth Possible?</a>, Avril 2019 (en) <br><br>

                        <em>Thomas Wiedmann, Manfred Lenzen, Lorenz T. Keyßer &amp; Julia K. Steinberger </em>, <a target='_blank' href='https://www.nature.com/articles/s41467-020-16941-y'>Scientists’ warning on affluence</a>, Juin 2020 (en)" /></li>
                        </ul>
                    </p>

                    <p>Ainsi, plutôt que de tout miser sur la croissance verte, un pari technologique qui tarde toujours à faire la preuve de sa faisabilité, dans son rapport d'avril 2022, le GIEC invite les décideurs à envisager sérieusement des modèles de développement alternatifs basés sur la décroissance. C'est une première depuis la publication du premier rapport en 1990.</p>

                    <p> Le GIEC consacre un chapitre entier à la baisse de la demande&nbsp;: moins d'avions, moins de voitures individuelles, moins de viande... Les experts estiment dès lors que <em>«&nbsp;l'atténuation du côté de la demande peut réduire les émissions mondiales de gaz à effet de serre de 40 à 70&nbsp;% d'ici 2050&nbsp;»</em>, tout en étant <em>«&nbsp;compatible avec l'amélioration du bien-être de base pour tous&nbsp;»</em>.<Reference index="6"

                        content=" <em>IPCC</em>, <a target='_blank' href='https://www.ipcc.ch/2022/04/04/ipcc-ar6-wgiii-pressrelease/?utm_campaign=FR+-+IPCC+expert+quotes&utm_medium=email&utm_source=autopilot'>The evidence is clear: the time for action is now. We can halve emissions by 2030</a>, avril 2022 (en)
                        <br/><br/>
                        <em>Agence européenne pour l'environnement</em>, <a target='_blank' href='https://www.eea.europa.eu/publications/growth-without-economic-growth'>Growth without economic growth</a>, janvier 2021 (en)" />

                    </p>

                    <p>Car les modèles économiques basés sur la décroissance n'ont pas pour seul objectif de ramener l'économie vers un respect des limites planétaires, mais aussi d'organiser le découplage entre croissance économique et bien-être des populations. Si ce dernier se caractérise en partie par le revenu disponible, il reste en théorie possible de mettre l'accent sur d'autres aspects que le PIB ne sait pas vraiment quantifier comme la santé, la restauration des écosystèmes, la réduction des inégalités, l'éducation, le niveau de précarité, le temps libre etc.</p>

                    <div className="image-source">
                        <img src={postCroissance} alt="Intensité énergétique" style={{ maxWidth: '46rem' }}></img>
                    </div>

                    <p>Dans ce cadre, il est précieux de savoir mesurer et suivre d'autres indicateurs que le PIB. Le plus utilisé actuellement est l'IDH (indice de développement humain). Mais une multitude d'alternatives existent telles que le <em>Happy Planet Index</em> ou encore le <em>Wellbeing Budget</em>, introduit depuis 2019 en Nouvelle-Zélande, avec la particularité de valoriser les besoins à long terme des générations futures dans les arbitrages économiques d'aujourd’hui. </p>

                    <h2>En conclusion</h2>

                    <p>Relever le défi climatique nécessite de combiner de manière efficace toutes les solutions technologiques possibles, chacune présentant des points forts et des limites. Mais même dans les cas optimistes, l'innovation technologique seule ne suffit pas à réduire la consommation d'énergies fossiles dans les ordres de grandeurs requis. La mise en place d'une forme plus ou moins avancée de sobriété énergétique reste, avec ce que cela induit en termes d'efforts d'adaptation individuels et collectifs, incontournable.</p>

                    <p>Ainsi, limiter les émissions de CO<sub>2</sub> implique de cumuler les actions suivantes&nbsp;:  </p>
                    <ul>
                        <li>Sortir de toutes les énergies fossiles, dont le gaz naturel.</li>
                        <li>Développer les énergies renouvelables, conjointement à des solutions de stockage et de flexibilisation de la demande.</li>
                        <li>Développer l'énergie nucléaire, avec un nécessaire développement de la génération IV pour limiter la dépendance aux ressources d'uranium.</li>
                        <li>Améliorer considérablement l'efficacité énergétique, par la technologie <strong>et</strong> par des changements d'habitude de consommation.</li>
                        <li>Compenser ou capturer les émissions résiduelles.</li>
                        <li>Adopter les mesures de sobriété rendues indispensables pour couvrir l'effort restant.</li>
                    </ul>

                    <div className="image-source">

                        <img src={vueGenerale} alt="PIB" style={{ maxWidth: '55rem' }} ></img>
                        {/* <img src={vueGeneraleMobile} alt="PIB" style={{ maxWidth: '35rem' }} className="img-mobile"></img> */}

                    </div>

                    {/* 
                    <p>En complément, la réduction des émissions de méthane (CH<sub>4</sub>) requiert de limiter les fuites liées à l'extraction de gaz naturel, de pétrole et de charbon, de limiter la consommation de viande de bœuf, de produits laitiers, d'améliorer le tri et le traitement des déchets biodégradables.
                    </p>
                    */}

                    {!isQuizzOpened && <div className="article-separator" style={{ marginTop: '4rem', marginBottom: '2rem', borderBlockColor: 'var(--lightGrey)' }}></div>}

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <>

                                <div className="u-center">
                                    <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                                </div>
                            </>
                        }
                    </div>


                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="croissance-verte" displayModal={true} />}

                </div>

            </div>

        </>
    );
}

export default DescenteEnergetique

