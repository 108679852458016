import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const Error = () => {
    return (
        <>
            <Navbar />

            <div className="cu-overall-container">

                <div className="cu-form-container">

                    <h1>404 Page Not Found</h1>

                </div>
            </div>

            <Footer />

        </>

    )
}

export default Error
