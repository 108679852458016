export const questions = [{
    id: "energies-renouvelables",
    text: "La production d'électricité par les barrages :",
    type: "Multiple",
    isCurrent: true,
    correctAnswers: 2,
    isSubmitted: false,
    isValid: false,
    answers: [{
        text: "Est pilotable pour les installations disposant d'une retenue d'eau",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Est pilotable pour les centrales au fil de l'eau",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Est bas carbone et renouvelable",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "N'a quasiment pas d'impact écologique",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Le facteur de charge se définit comme :",
    type: "Single",
    isCurrent: true,
    isSubmitted: false,
    isValid: false,
    answers: [
        {
            text: "Le voltage en sortie d'installation",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "La vitesse avec laquelle une installation électrique peut démarrer",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Le rapport entre production réelle et production théorique maximale sur une période donnée ",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Le rapport entre jours de fonctionnement et nombre total de jours sur une période donnée ",
            isCorrect: false,
            isSelected: false
        }
    ]
},
{
    text: "Les éoliennes :",
    type: "Multiple",
    isCurrent: true,
    isSubmitted: false,
    correctAnswers: 2,
    isValid: false,
    answers: [{
        text: "Ont une empreinte carbone extrêmement basse",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Ont un facteur de charge d'environ 25 % pour les installations terrestres",
        isCorrect: true,
        isSelected: false
    },
    {
        text: "Ne tournent que 25 % du temps pour les installations terrestres",
        isCorrect: false,
        isSelected: false
    },
    {
        text: "Produisent de l'électricité en permanence quand elles sont installées en mer",
        isCorrect: false,
        isSelected: false
    }
    ]
},
{
    text: "Les panneaux photovoltaïques :",
    type: "Multiple",
    isCurrent: false,
    isSubmitted: false,
    correctAnswers: 3,
    isValid: false,
    answers: [
        {
            text: "Sont en moyenne 4 fois plus émissifs que les éoliennes",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Ne fonctionnent pas s'il y a des nuages",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Ont un facteur de charge relativement faible",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Ont une production d'énergie fatale",
            isCorrect: true,
            isSelected: false
        },
    ]
}
]