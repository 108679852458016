export const questions = [
    {
        id: "nucleaire",
        text: "Quel événement est responsable du bilan humain le plus lourd ?",
        type: "Single",
        isCurrent: false,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "L'explosion de la centrale nucléaire de Tchernobyl en 1986",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La radioactivité issue des déchets nucléaires sur les 10 dernières années",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La pollution atmosphérique causée en 2021 par les centrales au charbon.",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "L'ensemble des accidents du travail relatifs à la maintenance des parcs éoliens",
                isCorrect: false,
                isSelected: false
            }


        ]
    },
    {
        text: "L'énergie nucléaire :",
        type: "Multiple",
        correctAnswers: 2,
        isCurrent: false,
        isSubmitted: false,
        isValid: false,
        answers: [

            {
                text: "Est pilotable",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est renouvelable",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Est bas carbone",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est fatale",
                isCorrect: false,
                isSelected: false
            },
        ]
    },
    {
        text: "Les déchets radioactifs :",
        type: "Multiple",
        isCurrent: false,
        correctAnswers: 2,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Peuvent être entreposés dans des sites de stockage géologique",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Sont produits exclusivement par l'industrie nucléaire",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Peuvent être réutilisés de manière quasi renouvelable par des réacteurs à neutrons rapides",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Contribuent au réchauffement climatique",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        text: "Pourquoi l'énergie nucléaire ne saurait-elle  constituer seule une alternative aux énergies fossiles ?",
        type: "Multiple",
        isCurrent: false,
        correctAnswers: 2,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "On ne saurait pas gérer tous les déchets nucléaires",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "L'uranium 235 utilisé par la filière actuelle risque d'être épuisé en quelques décennies",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Les centrales nucléaires ne peuvent être construites qu'en bord de mer",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Les compétences requises pour construire et opérer des centrales nucléaires sont limitées",
                isCorrect: true,
                isSelected: false
            }
        ]
    }
]