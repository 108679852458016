import React, { Component } from 'react'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Benefit from '../components/Benefit'
import CurriculumThumb from '../components/CurriculumThumb'
import QuizzSection from '../components/QuizzSection'
import Models from '../components/SimulatorList'


import windTurbines from '../images/home/wind-turbines-comment.jpg'
import coal from '../images/home/coal.jpg'

import cheminees from '../images/home/cheminees.jpg'
import cityimage from '../images/home/grandeville.jpg'
import bike from '../images/home/bike.jpg'
import Preloader from '../components/Preloader'
import TransitionGame from '../components/TransitionGame'
import CoalGame from '../components/CoalGame'
import Factory from '../components/Factory'
import CurriculumDetailsThumb from '../components/CurriculumDetailsThumb'
import SimulatorThumb from '../components/SimulatorThumb'
import globeMobile from '../images/home/globe-mobile.jpg'

import transition2050 from '../images/home/transition-2050.jpg'
import coalGame from '../images/home/coalGame.jpg'

import BeforeAfterSlider from 'react-before-after-slider'

import { FcGlobe, FcBarChart, FcLineChart, FcApproval, FcIdea, FcCollect, FcShare, FcElectricity } from "react-icons/fc"

export default class Home extends Component {

    state = {
        isLoading: true,
        themesSection: React.createRef()
    }

    constructor(props) {
        super(props);
    }

    handleScrollToCurriculums = () => {


        // window.scrollTo({ behavior: 'smooth', top: this.state.themesSection.current.offsetTop - 30 });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    render() {
        return <>

            <Preloader isLoading={this.state.isLoading} />

            <Navbar isTransparent="true" />

            <Hero callbackFromHome={this.handleScrollToCurriculums} />

            <section className="details-section-container u-center" id="presentation">
                <div className="details-section">
                    <div className="details-text">
                        <h1 className="title">Un effort de compréhension nécessaire</h1>
                        <p>Toujours plus de citoyens, de collectivités et d'organisations souhaitent s'engager pour l'environnement, mais agir sur un problème demande d'abord de le comprendre.</p>
                        <p>Tout le monde, et pas uniquement une poignée de spécialistes, doit désormais connaître les grands enjeux qui s'articulent autour de l'énergie et du climat.</p>
                    </div>
                    <div className="details-img" >
                        <img src={windTurbines} />

                    </div>
                </div>
            </section>

            <section className="system-text u-center">
                <div>
                    <Benefit />
                </div>
            </section>


            <section className="simulation-section prez-container u-center" >
                <div className="prez" ref={this.state.themesSection}>

                    <div class="prez-curriculums" >
                        <div>
                            <CurriculumThumb curriculumId="fondamentaux-energie-climat" />
                        </div>

                    </div>

                </div>

            </section>

            <Models />


            <Footer />

        </>
    }
}

