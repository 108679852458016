export const questions = [
    {
        id: "stocker-energie",
        text: "Actuellement, les capacités de stockage couplées aux réseaux électriques couvrent globalement :",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "3 % de la puissance installée",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "5 % de la puissance installée",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "13 % de la puissance installée",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "25 % de la puissance installée",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        id: "stocker-energie",
        text: "Les stations de transfert d’énergie par pompage-turbinage :",
        type: "Multiple",
        isCurrent: true,
        correctAnswers: 2,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Représentent 97% des capacités de stockage d'énergie disponibles dans le monde",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Fonctionnent grâce à l'électrolyse de l'eau",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Occasionnent des pertes énergétiques entre 15 et 30 %",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Occasionnent des pertes énergétiques autour de 75 %",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        text: "Les batteries :",
        type: "Multiple",
        isCurrent: true,
        correctAnswers: 2,
        isSubmitted: false,
        isValid: false,
        answers: [
            {
                text: "Représentent 97 % des capacités de stockage d'énergie disponibles dans le monde",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Sont recyclées à 96 %",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Occasionnent des pertes énergétiques entre 15 et 30 %",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Génèrent des émissions de CO2 importantes lors de leur fabrication",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "L’hydrogène :",
        type: "Multiple",
        correctAnswers: 3,
        isCurrent: false,
        answers: [{
            text: "Occasionne des pertes énergétiques autour de 75 %",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Est aujourd’hui produit à 95 % par de l'électricité issue de sources d'énergies renouvelables",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Peut-être utilisé pour restituer de l'énergie en brûlant (Power-to-Gaz)",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Peut-être utilisé pour restituer de l'électricité dans une pile à combustible (Power-to-Power)",
            isCorrect: true,
            isSelected: false
        }
        ]
    },
    {
        text: "Qu'est-ce que l'effacement de consommation électrique ? ",
        type: "Single",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        answers: [{
            text: "Réaliser d'importantes économies d'énergie de manière à réduire la demande en électricité",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Importer de l'électricité de l'étranger",
            isCorrect: false,
            isSelected: false
        },
        {
            text: "Reporter des usages pouvant être différés de manière à s'adapter à la production électrique disponible",
            isCorrect: true,
            isSelected: false
        },
        {
            text: "Réduire la demande sur le réseau par un recours plus important à l'autoconsommation",
            isCorrect: false,
            isSelected: false
        }
        ]
    }
]