export const questions = [
    {
        id: "metaux",
        text: "Les métaux rares constituent :",
        type: "Single",
        isCurrent: false,
        answers: [
            {
                text: "Une ressource renouvelable produite par la géothermie",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Une ressource non renouvelable, mais quasi inépuisable",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Une ressource non renouvelable",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Une ressource non renouvelable, mais systématiquement recyclable",
                isCorrect: false,
                isSelected: false
            }
        ]
    },
    {
        text: "Le cuivre :",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 3,
        answers: [
            {
                text: "Est incontournable pour les réseaux électriques",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est incontournable pour les générateurs d'éoliennes",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est incontournable pour les véhicules électriques",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est encore très éloigné de son pic de production",
                isCorrect: false,
                isSelected: false
            },
        ]
    },
    {
        text: "Le néodyme, qui permet de faire des aimants permanents :",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 2,
        answers: [
            {
                text: "Peut faciliter la maintenance des générateurs d'éoliennes offshore",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Est incontournable pour les moteurs de véhicules électriques",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Est extrêmement rare sur Terre",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "Appartient à la famille des terres rares",
                isCorrect: true,
                isSelected: false
            }
        ]
    },
    {
        text: "Quels sont les freins au recyclage des métaux ?",
        type: "Multiple",
        isCurrent: true,
        isSubmitted: false,
        isValid: false,
        correctAnswers: 3,
        answers: [
            {
                text: "Le taux de collecte des équipements en fin de vie",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Les propriétés intrinsèques de certains métaux",
                isCorrect: false,
                isSelected: false
            },
            {
                text: "La présence de quantités infimes mélangées dans des composants complexes",
                isCorrect: true,
                isSelected: false
            },
            {
                text: "Le coût de l'opération",
                isCorrect: true,
                isSelected: false
            }
        ]
    }
]