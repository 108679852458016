import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import cscImage from './images/csc.jpg'
import cscImageMobile from './images/csc-MOB.jpg'
import beccs from './images/beccs.jpg'
import beccsMobile from './images/beccs-MOB.jpg'

function CSC() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/csc");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">


                    <p>Les technologies de «&#8239;capture et séquestration du carbone&#8239;», ou CSC, ont pour objet de capter le CO<sub>2</sub> émis par des centrales fossiles, à gaz ou au charbon pour l’enfouir sous terre plutôt que de le relâcher dans l’atmosphère. </p>

                    <p>Existant depuis plusieurs décennies et pleines de promesses, ces innovations n’ont encore jamais été déployées à une échelle industrielle. </p>

                    <p>En 2020, les centrales thermiques en service dans le monde équipées d’un système de CSC ne représentaient que 0,1% du parc installé. À de rares exceptions près, aucune des installations récentes ou en cours de construction n’intègre cette technologie.<Reference index="1" content="<em>Thibault LACONDE</em>, Climate Chance, <a target='_blank' href='https://www.climate-chance.org/wp-content/uploads/2019/03/new-capture-et-sequestration-du-carbone-une-solution-qui-peine-a-se-concretiser.pdf'>Capture et séquestration du carbone : une solution qui peine à se concrétiser</a>, 2018 (fr)" /></p>

                    <p>Techniquement, la première étape de «&#8239;capture&#8239;» consiste à capter le CO2 en sortie de chaudière en le faisant réagir avec des composants chimiques spécifiques. Cela permet de diminuer les émissions de 70 à 90&#8239;%. C’est à ce niveau que se situe la principale limitation&#8239;: les procédés actuels consomment à eux seuls 20 à 30&#8239;% de l’énergie produite par la centrale, entraînant une diminution considérable de son rendement.<Reference index="2" content="<em>GIEC</em> 2005, Davison 2005, IEA GHG 2004, IEA GHG 2003; IEA GHG, 2000b; Dillon et al. 2005, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/03/srccs_wholereport-1.pdf#page=130'>Carbon Dioxide Capture and Storage Special Report</a>, Figure 3.6, 2005 (en)" /></p>

                    <p>Le CO<sub>2</sub> ainsi capturé doit ensuite être acheminé vers les sites de stockage. Si ceux-ci ne se trouvent pas à proximité immédiate, disposer d’un réseau de pipelines se révèle indispensable pour effectuer le transport. Pour diminuer les coûts, préférer un transport par bateau reste une option envisageable, mais au prix d’émissions supplémentaires.</p>


                    <div className="image-source">
                        <img src={cscImage} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={cscImageMobile} style={{ maxWidth: '39rem' }} className="img-mobile"></img>
                    </div>

                    <p>Le stockage géologique profond bénéficie d’un savoir-faire déjà développé depuis des décennies par l’industrie pétrolière et gazière. Plusieurs solutions existent&nbsp;: utiliser d’anciennes poches de gaz ou de pétrole vidées de leurs précieuses ressources, ou des mines de charbon profondes. Mais l’approche qui semble la plus prometteuse, car la plus facilement accessible, consiste à diluer le gaz dans l’eau contenue dans des roches poreuses appelées aquifères salins. Ces derniers sont situés bien plus en profondeur que les nappes phréatiques d’où est prélevée l’eau potable, et ils contiennent de l’eau salée et donc non potable.</p>

                    <p>Concrètement, la mise en œuvre de projets CSC se révèle très souvent complexe, et plusieurs d’entre eux se sont déjà soldés par des échecs.</p>

                    <p>Dans une directive européenne de 2009, la technologie était présentée comme «&#8239;clé pour répondre aux objectifs climatiques européens&#8239;», avec l'engagement de construire une douzaine de centrales d’ici 2015. À l’arrivée, les investissements se comptent en millions d’euros pour des projets qui n’auront jamais vu le jour.<Reference index="3" content="« Les dirigeants européens se sont engagés à établir, dans l'UE, un réseau qui comprendrait jusqu'à 12 centrales de démonstration du CSC en 2015. » <br/><br/>  <a target='_blank' href='https://ec.europa.eu/commission/presscorner/detail/fr/IP_09_1022'>Captage et stockage du carbone (CSC) dans les pays en développement à économie émergente: financement du projet UE-Chine de centrale au charbon à émissions quasi nulles</a>, 2009" /></p>

                    <p>De plus, des incertitudes pèsent toujours quant à l’étanchéité à long terme de certains sites de stockage, mais aussi quant à l’acceptation par les populations locales. Ces risques, et les potentiels surcoûts associés, conduisent en règle générale les investisseurs à se montrer frileux.</p>

                    <p>L’équation économique reste ainsi le principal frein au développement de la CSC. Complexe, très coûteuse en investissement et en énergie, en dehors des bénéfices climatiques la CSC n’apporte aucun avantage sur son équivalent classique. La tonne de CO2 émise n’étant encore nulle part associée à un prix significatif, sans subventions, de telles opérations se feraient entièrement à perte.</p>

                    <p>Même si elles commençaient à faire leurs preuves à une échelle significative, les technologies CSC posent toujours deux problèmes de taille. Le premier est qu’elles consomment beaucoup d’énergie, leur usage tend donc à accélérer l’épuisement des ressources fossiles, et en attendant tirent leur prix à la hausse. Le second est qu’elles légitiment la place des énergies fossiles dans la transition. D’une part cela accroît le risque de financer sous ce couvert les activités traditionnelles de cette industrie, et d’autre part cela rend plus tentant de repousser sans cesse des efforts de transition pourtant nécessaires.</p>

                    <p>En conclusion, en l’état actuel des choses, les technologies de capture et séquestration du carbone n’ont pas encore montré une maturité suffisante. Des progrès techniques et législatifs, tout particulièrement le prix de la tonne de CO<sub>2</sub>, pourraient leur permettre de jouer un rôle plus marqué. Leur utilisation sera alors déterminante partout où, pour des raisons physiques ou économiques, l’abandon des énergies fossiles n’est pour l'heure pas envisageable.</p>

                    <h2>Les émissions négatives</h2>

                    <p>Si dans un futur proche les mécanismes de capture et séquestration étaient parfaitement maîtrisés, pourquoi ne pas les mettre à contribution pour aller plus loin et retirer du CO<sub>2</sub> de l’atmosphère terrestre&#8239;?</p>

                    <p>Le problème principal réside dans le fait que la concentration atmosphérique en CO<sub>2</sub> est très faible, d'environ 0,04&#8239;%. Utiliser une machine pour capturer du CO<sub>2</sub> après que celui-ci a été dilué dans l'atmosphère, plutôt que dans les rejets d'une centrale fossile, où il est très concentré, s'avère particulièrement inefficace sur le plan énergétique.</p>

                    <p>Pour extraire le CO<sub>2</sub> présent dans l’air sans passer par des machines, l’approche la plus simple reste de cultiver des végétaux. Ainsi, les technologies de «&nbsp;bioénergie avec captage et stockage du carbone&nbsp;» (aussi connues sous son abréviation anglaise BECCS) reposent sur la culture de plantes comme le colza ou la canne à sucre, qui sont ensuite brûlées pour produire de l’énergie d’un côté, capturer et stocker le CO<sub>2</sub> de l’autre.</p>

                    <div className="image-source">
                        <img src={beccs} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={beccsMobile} style={{ maxWidth: '39rem' }} className="img-mobile"></img>
                    </div>


                    <p>Les limitations liées aux technologies de CSC s’appliquent toujours ici, soit une baisse du rendement énergétique et des surcoûts liés au transport et au stockage, mais un autre obstacle de taille vient s’y ajouter&nbsp;: le besoin en terres cultivables. De fait, absorber 10&#8239;% des émissions de CO2 annuelles nécessiterait d'utiliser 25&#8239;% des terres cultivables de la planète. Une telle utilisation des terres semble difficilement envisageable dans un contexte où le réchauffement du climat entraîne déjà une baisse de rendement des récoltes, mettant en péril la sécurité alimentaire mondiale. </p>

                    <p>Ainsi, les technologies visant à retirer du carbone de l'atmosphère ne pourraient éventuellement jouer un rôle significatif que dans un second temps, pour  stabiliser la concentration atmosphérique en CO<sub>2</sub> dans la seconde moitié du XXIème siècle, seulement après que les objectifs de réduction des émissions ont déjà été atteints par d'autres moyens beaucoup plus efficients.</p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="csc" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="7. Le nucléaire" url="/nucleaire"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default CSC

