import React, { Component } from 'react'
import { findAllByPlaceholderText } from '@testing-library/react';
import Navbar from './Navbar';

export default class Preloader extends Component {

    state = {
        isLoading: true,
        containerClass: "spinner-container"
    }

    componentDidMount() {

        if (this.state.isLoading != this.props.isLoading) {

            if (this.props.isLoading) {
                this.displayLoader();
            }
            else {
                this.hideLoader();
            }
        }
    }

    hideLoader = () => {

        setTimeout(() => {
            this.setState({
                containerClass: "spinner-container spinner-hidden",
                isLoading: false
            });

        }, 300);

        setTimeout(() => {
            this.setState({
                containerClass: "spinner-container spinner-hidden spinner-disappear"
            });
        }, 800);

        setTimeout(() => {
            this.setState({
                containerClass: "spinner-container spinner-none"
            });
        }, 1300);
    }

    displayLoader = () => {

        {/* Starts Loading */ }
        this.setState({
            containerClass: "spinner-container",
            isLoading: true
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.isLoading != prevProps.isLoading) {

            {/* Was loading but is not anymore */ }
            if (prevProps.isLoading && !this.props.isLoading) {
                this.hideLoader();
            }
            else if (!prevProps.isLoading && this.props.isLoading) {
                this.displayLoader();
            }
        }
    }

    render() {

        return (

            <div className={this.state.containerClass}>

                <div className="loadingio-spinner-dual-ball-3g9rtblom18">

                    <div className="ldio-ry3swy91qe8">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>

                </div>
            </div>
        )
    }
}
