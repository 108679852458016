import React, { Component } from 'react'
import globe from '../images/home/globe.jpg'
import globeMobile from '../images/home/globe-mobile.jpg'

import QuizzModal from './QuizzModal'

export default class QuizzSection extends Component {

    constructor(props) {
        super(props);
        this.handleQuizzOnClick = this.handleQuizzOnClick.bind(this);
    }

    quizzCallback = (dataFromChild) => {

        this.setState(
            {
                isModalDisplayed: false
            }
        )
    }

    handleQuizzOnClick() {

        this.setState(
            {
                isModalDisplayed: true
            }
        )
    }

    render() {
        return (
            <section className="home-quizz-container u-center">

                <div className="prez test-header">

                    <h1 className="u-text-center">Testez vos connaissances</h1>
                    <p style={{ margin: 'calc( var(--regularHomePadding)) auto calc( var(--regularHomePadding)) auto' }}>Aurez-vous 15/15 à ce test de connaissances sur l'énergie et le climat ?</p>
                    <div className="btn green-btn" onClick={this.handleQuizzOnClick}>Tester mes connaissances</div>

                </div>

                {this.state.isModalDisplayed && <QuizzModal callbackFromHero={this.quizzCallback} secondInstance="true" />}


            </section>
        )
    }

    state = {
        isModalDisplayed: false
    }
}
