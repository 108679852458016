import React, { useRef, useEffect, useState } from 'react';

import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import WaterCycle from '../../components/WaterCycle'
import Factory from '../../components/Factory'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import QuizzModal from '../../components/QuizzModal'


import mapGiec from './images/map-GIEC.jpg'
import effetDeSerre from './images/effet-de-serre.jpg'

import GES from './images/GES.jpg'
import GesMobile from './images/GES-mobile.jpg'

import earthStructure from './images/earth-structure.png'
import fossils from './images/fossils.jpg'
import fossilsMobile from './images/fossils-MOB.jpg'
import renewables from './images/renouvelables.jpg'
import renewablesMobile from './images/renouvelables-MOB.jpg'
import compareFossils from './images/comparison-fossils.jpg'
import compareFossilsMobile from './images/comparison-fossils-MOB.jpg'

import { useAuth } from '../../App.js';
import { DoubleContrainteQuestions } from './quizz';

import { useNavigate, useLocation } from "react-router-dom";

function DoubleContrainte() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        setIsQuizzOpened(true);

        if (location.hash) {

            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/la-double-contrainte-carbone");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (

        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>En août 2021, le GIEC sortait la première partie du 6e rapport d'évaluation (AR6). Celui-ci confirme qu'avec un réchauffement de 1,2 °C depuis le début de l'ère industrielle (1880), l'évolution du climat est conforme aux prédictions partagées par les scientifiques depuis 30 ans.<Reference index="1" content="<em>GIEC</em>, <a target='_blank' href='https://www.ipcc.ch/report/ar6/wg1/'>AR6 Climate Change 2021: The Physical Science Basis</a>, juin 2021 (en)." /></p>

                    <p>L'origine de ce phénomène est solidement établie&nbsp;: l'augmentation de la concentration atmosphérique en gaz à effet de serre. Ces gaz sont transparents pour la lumière visible, et laissent donc passer les rayons du soleil, mais bloquent les rayonnements infrarouges, invisibles à l'œil nu, émis en retour par la Terre. Une partie de la chaleur normalement renvoyée dans l'espace reste ainsi piégée dans le système climatique terrestre, provoquant l'élévation observée des températures.</p>

                    <div className="image-source">
                        <img src={effetDeSerre} style={{ width: '40rem' }} />
                    </div >

                    <p>Les principaux gaz à effet de serre dus aux activités humaines sont&#8239;:</p>

                    <ul>
                        <li>Le gaz carbonique, ou CO<sub>2</sub></li>
                        <li>Le méthane, émis en particulier par le bétail</li>
                        <li>Le protoxyde d'azote qui est notamment produit lors de la production d'engrais pour l'agriculture</li>
                    </ul>

                    <p>Aucune des causes naturelles étudiées, comme le volcanisme ou les variations de l’activité solaire, ne contribue de manière significative au phénomène observé.</p>

                    <div className="image-source">
                        <img src={GES} style={{ width: '58rem' }} className="img-desktop" />
                        <img src={GesMobile} style={{ width: '40rem' }} className="img-mobile" />
                        <div >GIEC<Reference index="2" content=" <em>GIEC</em>,  <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/05/ar4-wg1-ts-fr.pdf#page=14'>Changements climatiques 2014, rapport de synthèse</a>, Figure RT.5." /></div>
                    </div >

                    <p>Le changement climatique est un phénomène global. Pour autant, il n'est pas uniforme. Ainsi, lorsque des publications scientifiques parlent d'un réchauffement de «&#8239;2 °C&#8239;», cette valeur correspond à une moyenne pour le monde pris dans sa globalité, ce qui cache de fortes disparités au niveau local. Le réchauffement sera en moyenne deux fois plus important, donc de l’ordre de 4°C, au niveau des continents. Inversement, la variation s'avérera plus faible au niveau des océans, en raison de leur plus grande inertie thermique. </p>

                    <p>En France, l'augmentation de la température moyenne a atteint 2,3&#8239;°C en 2020. Avec l'accentuation de ce phénomène, notre pays fera face à des canicules plus fréquentes, plus longues et plus intenses. La sécheresse s’y installera de manière durable, favorisant l'émergence de feux de forêt et touchant durement l’agriculture. La perturbation des cycles de l'eau entraînera également des inondations plus récurrentes et plus destructrices.</p>

                    <p>Certaines zones proches de l’équateur pourraient devenir, bien avant la fin du siècle, littéralement invivables. Les effets combinés de la chaleur et de l’humidité se révéleraient mortels pour quiconque resterait exposé à l’air libre.</p>

                    <p>Les répercussions déjà observées aujourd'hui ne permettent malheureusement pas réellement d'appréhender l'ampleur réelle des bouleversements à venir, car les effets du dérèglement climatique ne sont pas linéaires. Pour une hausse de 3&#8239;°C, ceux-ci ne seraient pas seulement deux fois plus importants que pour un réchauffement de 1,5&#8239;°C, mais plutôt de l'ordre de 10 fois plus. Cela sans compter le risque d'un d'emballement spontané du climat, qui pourrait alors continuer à dériver de manière totalement incontrôlable.</p>

                    <p>Dans tous les cas, la dérive climatique n'est pas réversible. Entre 15 et 40&#8239;% d'un surplus de CO<sub>2</sub> émis aujourd'hui seront toujours présents dans 1&#8239;000 ans, et la fraction restante mettra plus de 10&#8239;000 ans à quitter l'atmosphère. </p>

                    <p>Le dernier rapport du GIEC confirme ces éléments, mais apporte toutefois une bonne nouvelle&#8239;: l'inertie du système climatique est moins forte que prévu. Agir maintenant permettrait encore de limiter de manière importante le réchauffement pour les décennies à venir. </p>

                    <h2>Les énergies fossiles</h2>

                    <p>Le surplus de CO<sub>2</sub> à l'origine du réchauffement climatique provient dans la grande majorité de la consommation d'énergies fossiles, c'est-à-dire de pétrole, de gaz naturel et de charbon, et en second lieu de la déforestation. Dans les deux cas, le carbone qui se voit relâché dans l'atmosphère provient de stocks constitués lors de la croissance de végétaux.</p>

                    <p> Le soleil permet en effet aux plantes, aux algues et à certains micro-organismes de se développer en effectuant la photosynthèse. L’énergie solaire s’accumule alors dans ces organismes sous forme de liaisons chimiques carbonées. En brûlant, ces liaisons sont détruites&#8239;: l’énergie stockée se voit ainsi libérée sous forme de chaleur et le carbone relâché dans les fumées issues de la combustion.</p>

                    <p>Passer de végétaux et du bois, la «&#8239;biomasse&#8239;», à un combustible fossile nécessite une transformation beaucoup plus longue&#8239;:&nbsp;la fossilisation. Les mouvements de l’écorce terrestre amènent lentement les restes de végétaux déposés en surface vers les profondeurs, là où s’exercent des températures et des pressions extrêmes. Comprimés et chauffés de la sorte, ils se transforment alors en des substances beaucoup plus denses en énergie. Ainsi, selon les variations propres à chaque emplacement, se forment d’immenses poches de pétrole, de gaz ou de charbon.</p>

                    <div className="image-source">
                        <img src={fossils} style={{ width: '58rem' }} className="img-desktop" />
                        <img src={fossilsMobile} style={{ width: '40rem' }} className="img-mobile" />
                    </div >

                    <p>Les énergies fossiles constituent en quelque sorte des réserves très condensées en énergies solaire et géothermique émises dans le passé. Ces deux énergies renouvelables, qui alimentent tout le système Terre, trouvent elles-mêmes leur source dans des réactions nucléaires&#8239;: le Soleil, qui tire sa puissance de la fusion d'atomes d'hydrogène en son cœur, et la Terre de la fission d'atomes d'uranium et de thorium dans ses couches profondes. </p>

                    <p>Le processus de fossilisation prenant plusieurs centaines de millions d’années, à l’échelle humaine, toute quantité prélevée l’est donc de manière définitive. Cela n'est pas sans conséquences, car en dépit de leur impact sur le climat les combustibles fossiles constituent des ressources énergétiques pratiques, très concentrées et aujourd'hui incontournables. Pour se faire une idée, un kilo de bois contient une quantité d’énergie équivalente à une grosse journée de travail manuel. Un kilo de charbon fournira deux fois plus d’énergie et un kilo de pétrole trois fois plus. </p>

                    <div className="image-source">
                        <img src={compareFossils} style={{ width: '58rem' }} className="img-desktop" />
                        <img src={compareFossilsMobile} style={{ width: '40rem' }} className="img-mobile" />

                    </div >

                    <h2>Le pic pétrolier</h2>

                    <p>Voitures, camions, bus, avions, machines agricoles, engins de chantier, navires marchands... Plus de 98% des transports fonctionnent aujourd’hui avec des dérivés du pétrole. Par conséquent, manquer de pétrole revient à ralentir considérablement l'économie mondiale.</p>

                    <p>Certes, en matière de stocks, le pétrole s'avère encore suffisamment abondant pour couvrir les besoins sur plusieurs décennies, mais à court terme la menace concerne les flux. Comme les sites les plus accessibles, et les plus rentables sont exploités en premier, la productivité des nouveaux gisements se révèle plus faible que ceux déjà épuisés. À l'échelle de plusieurs décennies, cela augmente le risque de voir l'offre incapable de suivre la demande. <em>De facto</em>, il en résultera une situation de pénurie structurelle qui n'ira qu'en s'aggravant.</p>

                    <p>Cette évolution est d’ores et déjà en cours. La production de pétrole dit «&#8239;conventionnel&#8239;», c’est-à-dire facile d’accès, a atteint son maximum en 2008. Depuis, elle décroît lentement d’année en année, et ce déclin est voué à se poursuivre jusqu’à l’épuisement total de ces gisements.</p>

                    <p>Pour compenser, des gisements «&#8239;non conventionnels&#8239;» ont pris la relève, et ce en dépit du fait qu’ils sont, par définition, beaucoup plus difficiles à exploiter. Ainsi, plus le temps passe, plus nous devrons chercher du pétrole dans des terres reculées, en profondeur, en fissurant des roches ou en purifiant des poches de sable. Nous pouvons aussi en produire à partir de charbon ou de gaz naturel, mais au prix de lourds procédés de transformation. Se procurer du pétrole nécessitera dès lors des installations toujours plus coûteuses et consommera toujours plus d’énergie pour extraire des volumes de plus en plus faibles.</p>

                    <p>Ce qui nous menace n’est donc pas la fin totale du pétrole, mais la raréfaction des gisements aisément exploitables. Autrement dit&nbsp;: nous allons tôt ou tard nous retrouver définitivement à court de pétrole abondant et bon marché. L'amorce d'un déclin de la production mondiale de pétrole est aujourd'hui attendue avant 2030. Certains analystes avancent même que le pic «&nbsp;tous pétroles&nbsp;» est déjà derrière nous, et que les niveaux de production de 2018, avant la crise du Covid-19, ne seront jamais à nouveau atteints.<Reference index="3" content="<em>Agence internationale de l'énergie</em>,  <a target='_blank' href='https://www.iea.org/reports/world-energy-outlook-2018'>World Energy Outlook 2018</a>, 2018 (en)<br/><br/>

                        <em>« The risk of a supply crunch
                        looms largest in oil. The average level of new conventional crude oil project approvals over
                        the last three years is only half the amount necessary to balance the market out to 2025,
                        given the demand outlook in the New Policies Scenario. US tight oil is unlikely to pick up
                        the slack on its own.»</em>
                        " />
                    </p>

                    <p>Particulièrement préoccupant pour le pétrole, l'épuisement des réserves fossiles concerne aussi le gaz naturel qui connaîtra, selon la tendance actuelle, son pic au cours dans la décennie 2020. Le pic gazier ayant été atteint pour les gisements européens, notamment en mer du Nord, le continent se repose pour son approvisionnement sur la Russie et les pays du Golfe, en concurrence avec la demande asiatique, provoquant déjà des chocs énergétiques majeurs.</p>

                    <p>Pour autant, le déclin programmé des énergies fossiles ne constitue pas une solution au problème du réchauffement climatique&nbsp;: pour espérer contenir la dérive climatique, un tiers des réserves de pétrole restantes, la moitié de celles de gaz et 80&#8239;% de celles de charbon devront rester volontairement inexploitées.<Reference index="4" content="McGlade, C., Ekins, P. <a target='_blank' href='https://www.nature.com/articles/nature14016'>The geographical distribution of fossil fuels unused when limiting global warming to 2&#8239;°C</a>. Nature 517, 187–190 (2015) (en)" />
                    </p>

                    <p>La fin des énergies fossiles est une fatalité. Elle sera soit anticipée, soit subie.</p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={DoubleContrainteQuestions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="croissante-verte" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="2. La transition énergétique" url="/la-transition-energetique"></RelatedArticles>
                </div>

            </div>

        </>
    );
}

export default DoubleContrainte

