import React, { Component } from 'react'
import doublecontrainte from '../images/curriculums/double-contrainte.jpg'
import { FcReading, FcClock } from "react-icons/fc"
import { Link } from 'react-router-dom'

import Checked from './Checked'

export default class CurriculumDetailsThumb extends Component {
    render() {
        return (

            <div className="curr-thumb-container">

                <div>
                    <img src={this.props.image}></img>
                </div>


                <div >
                    <div>
                        <h2>

                            <Link to={"/" + this.props.url}>
                                {this.props.index}. {this.props.title}
                            </Link>

                        </h2>
                        <p>{this.props.description}</p>
                    </div>
                    <span class="description-footer footer-article" style={{ justifyContent: "space-between" }}>


                        <div className="timing">
                            <FcClock className="cur-description-icon" />
                            <div class="cur-icon-text">{this.props.readTime}</div>
                        </div>

                        {this.props.isPassed && <div>
                            <Checked />
                        </div>}

                    </span>

                </div>


            </div>
        )
    }
}
