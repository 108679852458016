import React, { Component } from 'react'

import cheminees from '../images/home/cheminees.jpg'
import cityimage from '../images/home/grandeville.jpg'

import smokes from '../images/curriculums/smokes.jpg'
import climateChange from '../images/svg/climate-change.svg'
import growth from '../images/svg/growth.svg'
import doubleContrainte from '../images/curriculums/double-contrainte.jpg'
import coal from '../images/curriculums/coal.jpg'
import gaz from '../images/curriculums/gaz.jpg'
import csc from '../images/curriculums/csc.jpg'
import windTurbines from '../images/curriculums/wind-turbines.jpg'
import hydro from '../images/curriculums/hydro.jpg'
import nuclear from '../images/curriculums/nuclear.jpg'
import petrol from '../images/curriculums/petrol.jpg'
import hourglass from '../images/curriculums/hourglass.jpg'
import nightShift from '../images/curriculums/night-shift.jpg'
import lightBulb from '../images/curriculums/light-bulb.jpg'
import metals from '../images/curriculums/metals.jpg'
import croissanceVerte from '../images/curriculums/croissance-verte.jpg'

import DoubleContrainte from '../Content/DoubleContrainte/DoubleContrainte'
import TransitionStrategy from '../Content/TransitionStrategy/TransitionStrategy'
import GazNaturel from '../Content/GazNaturel/GazNaturel'
import CSC from '../Content/CSC/CSC'
import EnergiesRenouvelables from '../Content/EnergiesRenouvelables/EnergiesRenouvelables'
import StockerEnergie from '../Content/StockerEnergie/StockerEnergie'
import LeNucleaire from '../Content/LeNucleaire/LeNucleaire'
import ApresPetrole from '../Content/ApresPetrole/ApresPetrole'
import Temps from '../Content/Temps/Temps'
import DescenteEnergetique from '../Content/DescenteEnergetique/DescenteEnergetique'
import SimDecarbonerElec from '../Content/SimDecarbonerElec/SimDecarbonerElec'
import Metaux from '../Content/Metaux/Metaux'
import CroissanceVerte from '../Content/CroissanceVerte/CroissanceVerte'

import { DoubleContrainteQuestions } from '../Content/DoubleContrainte/quizz'

import DoubleContrainteH from '../images/articles/article-headers/double-contrainte-carbone.jpg'
import CharboneH from '../images/articles/article-headers/charbon.jpg'
import GazH from '../images/articles/article-headers/gaz.jpg'
import cscH from '../images/articles/article-headers/cscH.jpg'
import BarrageH from '../images/articles/article-headers/barrage.jpg'
import RenouvelablesH from '../images/articles/article-headers/eoliennes.jpg'
import NucleaireH from '../images/articles/article-headers/effet-cerenkov.jpg'
import ApresPetroleH from '../images/articles/article-headers/apres-petrole.jpg'
import sablierH from '../images/articles/article-headers/sablier.jpg'
import DescenteH from '../images/articles/article-headers/descente-energetique.jpg'
import lightBulbH from '../images/articles/article-headers/light-bulb.jpg'
import metalsH from '../images/articles/article-headers/metalsH.jpg'
import croissanteVerteH from '../images/articles/article-headers/autoroute-shangai.jpg'

export const curriculums = [{
    title: "Fondamentaux des enjeux énergie-climat",
    description: "",
    image: smokes,
    imageThumb: climateChange,
    page: "fondamentaux-energie-climat",
    articlesNbr: 11,
    readTime: "2 heures",
    articles: [
        {
            title: "La double contrainte énergie-climat",
            subtitle: "La transition énergétique — 1/11",
            isPassed: false,
            imageThumb: doubleContrainte,
            imageHeader: DoubleContrainteH,
            url: "la-double-contrainte-carbone",
            description: "Réchauffement climatique et pic pétrolier.",
            readTime: "9 min",
            content: <DoubleContrainte />,
            quizz: DoubleContrainteQuestions
        },
        {
            title: "La transition énergétique",
            subtitle: "La transition énergétique — 2/11",
            isPassed: false,
            imageThumb: coal,
            imageHeader: CharboneH,
            url: "la-transition-energetique",
            description: "Comment sortir des énergies fossiles ?",
            readTime: "7 min",
            content: <TransitionStrategy />
        },
        {
            title: "Les énergies renouvelables",
            subtitle: "La transition énergétique — 3/11",
            isPassed: false,
            url: 'energies-renouvelables',
            imageThumb: windTurbines,
            imageHeader: RenouvelablesH,
            description: "Avantages et limitations des barrages, des éoliennes et des panneaux solaires ",
            readTime: "10 min",
            content: <EnergiesRenouvelables />
        },
        {
            title: "Stocker l'énergie",
            subtitle: "La transition énergétique — 4/11",
            isPassed: false,
            url: 'stocker-energie',
            imageThumb: hydro,
            imageHeader: BarrageH,
            description: "Stocker l'énergie, flexibiliser la demande en énergie : enjeux majeurs pour accroître la part des énergies renouvelables.",
            readTime: "12 min",
            content: <StockerEnergie />
        },
        {
            title: "Le gaz naturel",
            subtitle: "La transition énergétique — 5/11",
            isPassed: false,
            url: "gaz-naturel",
            imageThumb: gaz,
            imageHeader: GazH,
            description: "Quel rôle pour le gaz naturel dans la transition ?",
            readTime: "4 min",
            content: <GazNaturel />
        },
        {
            title: "Capturer le carbone",
            subtitle: "La transition énergétique — 6/11",
            isPassed: false,
            url: "csc",
            imageThumb: csc,
            imageHeader: cscH,
            description: "Entre promesses et désillusions, où en sont les technologies de capture du carbone ?",
            readTime: "5 min",
            content: <CSC />
        },
        {
            title: "Le nucléaire",
            subtitle: "La transition énergétique — 7/11",
            isPassed: false,
            url: 'nucleaire',
            imageThumb: nuclear,
            imageHeader: NucleaireH,
            description: "Cible des luttes écologistes depuis la guerre froide, quel regard rationnel porter aujourd'hui sur le nucléaire civil ?",
            readTime: "9 min",
            content: <LeNucleaire />
        },
        {
            title: "Décarboner l'électricité",
            subtitle: "La transition énergétique — 8/11",
            isPassed: false,
            url: 'decarboner-electricite',
            imageThumb: lightBulb,
            imageHeader: lightBulbH,
            description: "Efficacité, rapidité et coût des approches existantes.",
            readTime: "9 minutes",
            content: <SimDecarbonerElec />
        },
        {
            title: "L'après-pétrole",
            subtitle: "La transition énergétique — 9/11",
            isPassed: false,
            url: 'apres-petrole',
            imageThumb: petrol,
            imageHeader: ApresPetroleH,
            description: "Quelle mobilité dans un monde post-pétrole ?",
            readTime: "6 min",
            content: <ApresPetrole />
        },
        {
            title: "Les métaux",
            subtitle: "La transition énergétique — 10/11",
            isPassed: false,
            url: 'metaux',
            imageThumb: metals,
            imageHeader: metalsH,
            description: "La disponibilité des métaux peut-elle constituer un facteur limitant dans la transition vers un monde bas-carbone ?",
            readTime: "8 min",
            content: <Metaux />
        },
        {
            title: "Énergie et PIB",
            subtitle: "La transition énergétique — 11/11",
            isPassed: false,
            url: 'croissance-verte',
            imageThumb: croissanceVerte,
            imageHeader: croissanteVerteH,
            description: "Quel modèle de croissance dans un monde aux ressources finies ?",
            readTime: "8 min",
            content: <CroissanceVerte />
        }]
},
{
    title: "Croissance et développement",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque blandit luctus justo, ut semper augue ornare in. Fusce tincidunt est ipsum, a volutpat nulla hendrerit in.",
    image: smokes,
    imageThumb: growth,
    page: "croissance-et-developpement",
    articlesNbr: 5,
    readTime: "2 heures"
}]