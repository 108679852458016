import React from 'react'

import { Link } from 'react-router-dom'
import logo_full_white from '../images/energie-climat-white.png'
import { FaRegCopyright, FaLinkedinIn, FaEnvelope } from "react-icons/fa";

import { AuthButton } from '../App.js'

export default function Footer() {
    return (
        <footer className="footer-section">
            <div>
                <img className="footer-logo" src={logo_full_white} alt="Énergie-Climat" ></img>
            </div>
            <div>
                <ul>
                    <li> <Link to="/nous-contacter"><FaEnvelope /> &nbsp; Nous contacter</Link></li>
                    <li>
                        <Link to="/cgu">Conditions générales d'utilisation</Link>
                    </li>
                    <li>
                        <Link to="/data-privacy">Politique de Protection des données personnelles</Link>
                    </li>
                    <li>
                        <Link to="/credits">Crédits</Link>
                    </li>
                </ul>
            </div>

            <div >
                {/* <img className="footer-logo" src={logo_full_white} alt="Énergie-Climat" style={{ paddingTop: '10px' }}></img>*/}
                <p>Suivez-nous sur :</p>
                <p style={{ height: '3rem', padding: '1rem 0' }}>
                    <a href="https://www.linkedin.com/company/85393908"><FaLinkedinIn size={25} /></a>
                </p>
            </div>

            <div>
                <FaRegCopyright />  Copyright 2022 - energie-climat.info
            </div>

        </footer>
    )
}
