import React, { Component } from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Preloader from '../components/Preloader'
import { Helmet } from "react-helmet";

export default class Home extends Component {

    state = {
        isLoading: true,
        themesSection: React.createRef()
    }

    constructor(props) {
        super(props);
    }

    handleScrollToCurriculums = () => {


        // window.scrollTo({ behavior: 'smooth', top: this.state.themesSection.current.offsetTop - 30 });
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        setTimeout(() => {
            this.setState({
                isLoading: false
            })
        }, 300);
    }

    logSubmit = (event) => {

        event.preventDefault();
    }

    submitForm = () => {

        let submittedForm = document.forms['myform'];
        submittedForm.addEventListener("submit", function (event) {

            event.preventDefault()
        });

    }

    render() {

        let isSubmitted = false;
        let url = this.props.location;

        if (url && url.includes('is-submitted')) {
            isSubmitted = "true";
        }
        else {
            url = window.location.href;

            if (url && url.includes('is-submitted')) {
                isSubmitted = "true";
            }
            else {

            }

        }

        return <>

            <Helmet>
                <title>Nous contacter</title>
                <meta name="description" content="Nous contacter" />
            </Helmet>


            <Preloader isLoading={this.state.isLoading} />

            <Navbar />

            <div className="cu-overall-container">

                <div className="cu-form-container">

                    <h1>Nous contacter</h1>

                    <meta HTTP-EQUIV="Content-type" CONTENT="text/html; charset=UTF-8" />

                    {isSubmitted && <>
                        <p>Merci de votre message !</p>
                        <p>Nous vous répondrons le plus rapidement possible directement à votre adresse mail.</p>
                    </>}

                    {!isSubmitted && <form name="myform" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST" className="contact-us-form">

                        <input type="hidden " name="oid" value="00D090000045CmE" style={{ height: '0px', border: 'none', color: '#fff' }} />
                        <input type="hidden" name="retURL" value="https://energie-climat.org/#/nous-contacter?is-submitted=true" />

                        <div>
                            <label for="first_name">Prénom</label>
                            <input id="first_name" maxlength="40" name="first_name" size="20" type="text" required="required" />
                        </div>

                        <div>
                            <label for="last_name">Nom</label>
                            <input id="last_name" maxlength="80" name="last_name" size="20" type="text" required="required" />
                        </div>

                        <div>
                            <label for="email">Email</label>
                            <input id="email" maxlength="80" name="email" size="20" type="text" required="required" />
                        </div>

                        <div>
                            <label for="description" required="required">Votre message</label>
                            <textarea rows="10" name="description"></textarea>
                        </div>

                        <div className="u-center" style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                            <input type="submit" name="Envoyer" value="Envoyer" />
                        </div>

                    </form>}


                </div>
            </div>

            <Footer />

        </>
    }
}

