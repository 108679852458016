import React, { useContext, createContext, Component } from 'react'
import { Link } from 'react-router-dom';
import { mainQuestions } from '../data/MainQuizz';
import { curriculums } from '../data/Curriculums';
import Loader from 'react-loader-spinner';
import Preloader from './Preloader';
import FinishedQuiz from './FinishedQuiz';
import Checked from './Checked';

import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import firebase, { firebaseAuth, firebaseProvider, facebookProvider } from '../firebase.js';

export default class QuizzModal extends Component {

    state = {
        errorMessageDisplayClass: 'quizz-top-page-error',
        checkboxType: "radio",
        checkErrors: false,
        isFinished: false,
        displayModalClass: "inline-quizz",
        modalContentClass: "quizz-content-wrapper",
        finalScore: 0,
        examMode: true,
        currentQuestion: {},
        numberOfQuestions: 0,
        currentIndex: 0,
        isLoaded: null,
        questionId: null,
        topPageError: "Vous devez sélectionner au moins une réponse",
        questions: mainQuestions
    }

    constructor(props) {
        super(props);
        this.handleCheckClick = this.handleCheckClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleRetry = this.handleRetry.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleCheckAndNextClick = this.handleCheckAndNextClick.bind(this);
        this.handleRetake = this.handleRetake.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);

    }

    testAuth() {

        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
    }

    authStateListerner() {

        firebaseAuth.onAuthStateChanged(user => {

            if (user && user.isAnonymous) {

                if (!localStorage.getItem('UserInfo')) {

                    let queriedUser = JSON.parse(JSON.stringify(user));
                    queriedUser.displayName = 'Anonyme';

                    firebase.database().ref('quizzes/' + queriedUser.uid + '/user').set({
                        "name": "Anonyme",
                        "uid": user.uid,
                        "email": "Inconnu",
                        "registrationDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    });

                    localStorage.setItem('UserInfo', JSON.stringify({ "user": queriedUser }));
                }
                else {

                }
            }
        });
    }

    componentWillMount() {
        this.authStateListerner();
    }

    componentDidUpdate() {
        this.authStateListerner();
    }


    componentDidMount() {


        // Set the questions, either to the main quizz or article-related quizz.
        let questions;
        let examMode;
        let questionId;
        let isLoaded;

        if (this.props.articleQuestions) {

            questions = this.props.articleQuestions;
            questionId = questions[0].id;

        }
        else {
            isLoaded = "Loaded";
            questions = mainQuestions;
        }

        if (this.props.examMode) {
            examMode = this.props.examMode;
        }

        this.setState({
            isLoaded: "Loaded",
            questions: questions,
            questionId: questionId,
            currentQuestion: questions[0],
            examMode: examMode
        });

        if (questions) {

            let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

            if (storedUSerInfo && storedUSerInfo.user) {

                var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/answers/' + questions[0].id);

                overview.on('value', (snapshot) => {

                    let queriedQuestions = snapshot.val();

                    if (queriedQuestions) {

                        let questionList = JSON.parse(queriedQuestions.questions);

                        this.setState({
                            isFinished: queriedQuestions.isFinished,
                            finalScore: queriedQuestions.finalScore,
                            questions: questionList,
                            currentQuestion: questionList[0],
                            isLoaded: "Loaded"

                        });
                    }
                }
                )

            }
        }
    }

    AnonymousRedirect() {

        firebaseAuth.signInAnonymously()
            .then(() => {
            })
            .catch((error) => {

            });

    }

    componentDidUpdate(prevProps) {

        if (this.props.displayModalClass != prevProps.displayModalClass) {

            this.setState(
                {
                    displayModalClass: this.props.displayModalClass
                }
            );
        }
    }

    handleRetry(event) {

        let allQuestions = this.state.questions;
        allQuestions[this.state.currentIndex] = this.state.currentQuestion;

        this.setState({
            modalContentClass: 'quizz-content-wrapper animate-fadeout'
        })

        allQuestions.map((item, index) => {

            if (!item.isValid) {

                item.isSubmitted = false;
                item.errorMessageDisplayClass = 'quizz-top-page-error u-display';
                item.errorMessage = 'Réponse incorrecte';

                item.answers.map((item, index) => {

                    item.errorMessage = '';
                    item.className = 'quizz-result-message';
                });
            }
            else {
                item.errorMessage = '';
                item.errorMessageDisplayClass = 'quizz-top-page-error';
                item.answers.map((item, index) => {
                    item.className = item.className.replace(' u-hide', '');
                });
            }

        });


        setTimeout(() => {
            this.setState({
                isFinished: false,
                currentIndex: 0,
                questions: allQuestions,
                currentQuestion: allQuestions[0],
                modalContentClass: 'quizz-content-wrapper animate-fadein',
            });
        }, 300);

    }


    handlePrevious() {

        let allQuestions = this.state.questions;
        allQuestions[this.state.currentIndex] = this.state.currentQuestion;

        this.setState({
            modalContentClass: 'quizz-content-wrapper animate-fadeout'
        })

        setTimeout(() => {
            this.setState({
                checkErrors: false,
                currentIndex: this.state.currentIndex - 1,
                questions: allQuestions,
                currentQuestion: this.state.questions[this.state.currentIndex - 1],
                modalContentClass: 'quizz-content-wrapper animate-fadein'
            });
        }, 300);
    }


    handleRetake() {

        let allQuestions = this.state.questions;
        this.state.currentQuestion = this.state.questions[0];

        allQuestions.map((item, index) => {

            item.isSubmitted = false;
            item.errorMessage = '';


            item.answers.map((answer, i) => {
                answer.isSelected = false;
                item.isValid = false;
            });

        });

        this.setState({
            isFinished: false,
            questions: allQuestions,
            currentIndex: 0
        });

    }

    handleNextClick() {

        // Log as anonymous if User is not logged
        let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));

        let isLogged = false;

        if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {
            isLogged = "true";
        }

        if (!isLogged) {
            this.AnonymousRedirect();
        }

        let allQuestions = this.state.questions;
        allQuestions[this.state.currentIndex] = this.state.currentQuestion;

        this.setState({
            modalContentClass: 'quizz-content-wrapper animate-fadeout'
        })

        setTimeout(() => {
            this.setState({
                checkErrors: false,
                currentIndex: this.state.currentIndex + 1,
                questions: allQuestions,
                currentQuestion: this.state.questions[this.state.currentIndex + 1],
                modalContentClass: 'quizz-content-wrapper animate-fadein'
            });
        }, 300);
    }

    handleCloseClick = () => {
        this.props.callbackFromHero("modal u-hide");
    }

    handleCheckClick = () => {

        let selectedQuestion = this.state.currentQuestion;
        // selectedQuestion.isSubmitted = true;
        let noSelection = true;
        let checkErrors = true;
        let errorMessageDisplayClass = "quizz-top-page-error";

        if (selectedQuestion && selectedQuestion.answers) {

            selectedQuestion.answers.map((item, index) => {

                item.errorMessage = '';
                item.isValid = false;

                if (item.isSelected) {
                    noSelection = false;
                }
            });

            if (!noSelection) {

                let isValid = true;

                errorMessageDisplayClass = "quizz-top-page-error";
                selectedQuestion.errorMessageDisplayClass = "u-hide";

                selectedQuestion.answers.map((item, index) => {

                    item.isValid = false;

                    if (item.isCorrect && !item.isSelected) {

                        selectedQuestion.isValid = false;
                        isValid = false;
                        checkErrors = false;
                        item.errorMessage = 'Réponse manquante';

                        selectedQuestion.isSubmitted = false;
                        selectedQuestion.errorMessage = 'Réponse incorrecte';
                        selectedQuestion.errorMessageDisplayClass = "quizz-top-page-error u-display";

                    }
                    else if (!item.isCorrect && item.isSelected) {

                        selectedQuestion.isValid = false;
                        isValid = false;
                        checkErrors = false;

                        item.errorMessage = 'Réponse incorrecte';

                        selectedQuestion.isSubmitted = false;
                        selectedQuestion.errorMessage = 'Réponse incorrecte';
                        selectedQuestion.errorMessageDisplayClass = "quizz-top-page-error u-display";
                    }
                    else if (item.isCorrect && item.isSelected) {

                        item.errorMessage = 'Bonne réponse !';
                        item.isValid = true;

                    }

                    if (item.isValid) {
                        item.className = 'quizz-result-message quizz-valid-answer';
                    }
                    else {
                        // item.className = 'quizz-result-message quizz-wrong-answer';
                    }
                });

                selectedQuestion.isValid = isValid;
            }
            else {

                checkErrors = false;
                selectedQuestion.isSubmitted = false;
                selectedQuestion.errorMessageDisplayClass = "u-hide";

                errorMessageDisplayClass = "quizz-top-page-error u-display";

            }
        }

        selectedQuestion.errorMessage = 'Réponse incorrecte';

        this.setState({
            checkErrors: checkErrors,
            currentQuestion: selectedQuestion,
            errorMessageDisplayClass: errorMessageDisplayClass
        });

        return checkErrors;
    }

    handleSubmit(event) {

        event.preventDefault();

        let score = 0;
        let isPassed = false;
        let isValid = this.handleCheckClick();

        if (isValid) {
            // Number of validated questions
            let passedQuestion = 0;

            let questionList = this.state.questions.map((item, index) => {
                if (item.isValid) {
                    score++;
                }
            });

            this.setState({
                isFinished: true,
                finalScore: score
            });

            let storedUSerInfo = JSON.parse(localStorage.getItem("UserInfo"));
            let questions = this.state.questions;

            isPassed = questions.length == score;

            if (storedUSerInfo && storedUSerInfo.user && storedUSerInfo.user.uid) {

                firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + "/answers/" + this.state.questionId).set({
                    "id": this.state.questionId,
                    "isFinished": true,
                    "isPassed": isPassed,
                    "finalScore": score,
                    "questions": JSON.stringify(questions)
                });

                var overview = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/overview');

                if (overview) {

                    overview.on('value', (snapshot) => {

                        const data = snapshot.val();

                        if (data && data.results) {

                            let i;
                            let isExisting = false;

                            for (i = 0; i < data.results.length; i++) {

                                if (data.results[i]) {

                                    if (data.results[i].articleId === this.state.questionId) {
                                        isExisting = true;
                                        data.results[i] = {
                                            "articleId": this.state.questionId,
                                            "isPassed": isPassed
                                        }
                                    }

                                    if (data.results[i].isPassed) {
                                        passedQuestion++;
                                    }
                                }
                            }

                            if (isExisting) {
                                firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + "/overview").set(data);
                            }
                            else {

                                data.results.push({
                                    "articleId": this.state.questionId,
                                    "isPassed": isPassed
                                });

                                firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + "/overview").set({
                                    "results": data.results
                                });
                            }
                        }
                        else {
                            firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + "/overview").set({
                                "results": [{
                                    "articleId": this.state.questionId,
                                    "isPassed": isPassed
                                }]
                            });
                        }
                    });
                }

                if (passedQuestion >= 11) {
                    let existingCertificate;

                    var certificate = firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + '/certificate');

                    if (certificate) {

                        certificate.on('value', (snapshot) => {
                            existingCertificate = snapshot.val();
                        });
                    }

                    if (!existingCertificate) {

                        firebase.database().ref('quizzes/' + storedUSerInfo.user.uid + "/certificate").set({
                            "date": new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
                            "title": "Fondamentaux des enjeux énergie-climat",
                            "firstName": "Jean",
                            "fastName": "TOTO",
                            "id": Math.floor(Math.random() * 1000000000)
                        });
                    }
                }
            }

            if (isPassed) {
                this.props.callbackFromHero();
            }
        }
    }

    handleCheckAndNextClick(event) {

        let isValid = this.handleCheckClick();

        // remove styling for correct / incorrect response        
        let selectedQuestion = this.state.currentQuestion;

        selectedQuestion.answers.map((item, index) => {
            item.className = 'quizz-result-message quizz-valid-answer';
            item.className = item.className + ' u-hide';
        });

        if (isValid) {
            this.handleNextClick();
        }
        else {

        }
    }

    handleOnChange(event) {

        let selectedQuestion = this.state.currentQuestion;
        let id = event.target.id;

        if (this.props.secondInstance) {
            id = id - 10;
        }

        let isSelected = selectedQuestion.answers[id].isSelected;

        if (selectedQuestion.type == "Single") {

            selectedQuestion.answers.map((item) => {
                item.isSelected = false;
            });

            selectedQuestion.answers[id].isSelected = true;

        }
        else {
            selectedQuestion.answers[id].isSelected = !isSelected;
        }

        this.setState({
            currentQuestion: selectedQuestion
        });
    }

    render() {

        let LoadingIndicator;
        if (!this.state.isLoaded) {
            LoadingIndicator =
                <div className="u-center">
                    <Loader type="TailSpin" color="#00A77B" height="30" width="30" />
                </div>
                ;
        }

        let answerList;
        if (this.state.currentQuestion.answers) {
            answerList = this.state.currentQuestion.answers.map((item, index) => {

                let checkBoxClass = "quizz-checkbox";
                if (this.state.checkErrors && !item.isValid && !this.state.examMode) {
                    checkBoxClass = "quizz-checkbox quizz-checkbox-error";
                }

                return <>
                    <div className={checkBoxClass} key={index}>

                        <div className="u-center pos-relative">
                            <input className={this.state.currentQuestion.type == "Single" ? "rounded-checkbox" : ""} type="checkbox" name={"question-index-" - index} id={this.props.secondInstance ? index + 10 : index} onChange={this.handleOnChange} disabled={this.state.currentQuestion.isSubmitted} checked={item.isSelected} />
                        </div>
                        <div>
                            <label for={this.props.secondInstance ? index + 10 : index} dangerouslySetInnerHTML={{ __html: item.text }}></label>
                        </div>
                    </div>
                    {item.errorMessage &&
                        <div className="quizz-checkbox quizz-error-panel" >
                            <div className="u-center pos-relative"></div>
                            <div class={item.className}>{item.errorMessage}</div>
                        </div>
                    }
                    <div class="quizz-separator"></div>

                </>
            })

        } else {
            answerList = "";
        }

        let modalContent;

        if (!this.state.isFinished) {
            modalContent = <div className="modal-content">
                <h1 style={{ fontSize: "2rem", marginBottom: '1.5rem' }} onClick={this.AnonymousRedirect}>Questionnaire</h1>

                <div class="close" onClick={this.handleCloseClick}>&times;</div>

                <div className={this.state.modalContentClass}>

                    <div className="quizz-number">{this.state.currentIndex + 1} / {this.state.questions.length}</div>
                    <div className="quizz-question">{this.state.currentQuestion.text} </div>

                    {this.state.currentQuestion.type == "Multiple"
                        &&
                        <div className="number-of-answers"><em>Choisissez {this.state.currentQuestion.correctAnswers} réponses</em></div>
                    }

                    {this.state.currentQuestion.type == "Single"
                        &&
                        <div className="number-of-answers">&nbsp;</div>
                    }

                    <div className={this.state.errorMessageDisplayClass}>{this.state.topPageError}</div>
                    <div className={this.state.currentQuestion.errorMessageDisplayClass}>{this.state.currentQuestion.errorMessage}</div>

                    <div className="quizz-answer">
                        {answerList}
                    </div>
                    <div className="u-align-right">

                        {this.state.currentIndex > 0 && this.state.examMode &&
                            <div className="btn green-btn" onClick={this.handlePrevious}><span>Précédente</span></div>
                        }

                        {this.state.currentIndex + 1 < this.state.questions.length &&
                            this.state.currentQuestion.isSubmitted && !this.state.examMode &&
                            <div className="btn green-btn" onClick={this.handleCheckAndNextClick}><span>Suivante</span></div>
                        }

                        {this.state.currentIndex + 1 < this.state.questions.length && this.state.examMode &&
                            <div className="btn green-btn" onClick={this.handleCheckAndNextClick}><span>Suivante</span></div>
                        }

                        {this.state.currentIndex + 1 == this.state.questions.length &&
                            (this.state.currentQuestion.isSubmitted || this.state.examMode) &&
                            <div className="btn green-btn" onClick={this.handleSubmit}><span>Terminer</span></div>
                        }

                        {!this.state.currentQuestion.isSubmitted && !this.state.examMode &&
                            <div className="btn green-btn" onClick={this.handleCheckClick}>Valider</div>
                        }

                    </div>
                </div>
            </div>
        }
        else {

            if (this.state.examMode) {

                if (this.state.finalScore == this.state.questions.length) {

                    modalContent = <div className="modal-content modal-content-results">

                        <h1>Quiz terminé !</h1>
                        <div style={{ margin: '2rem 0 4rem 0' }}>

                            <Checked />

                        </div>

                        <div className="u-center" onClick={this.handleRetake}>
                            <div className="btn green-btn" style={{ background: 'var(--mainColor)' }}>Refaire le quiz</div>
                        </div>
                    </div >


                }
                else {

                    let wrongAnswers = this.state.questions.length - this.state.finalScore;
                    let errorMessage;
                    if (wrongAnswers == 1) {
                        errorMessage = "mauvaise réponse";
                    }
                    else {
                        errorMessage = "mauvaises réponses";
                    }

                    modalContent = <div className="modal-content modal-content-results">
                        <div class="close" onClick={this.handleCloseClick}>&times;</div>
                        <div className="quizz-question">Incorrect</div>

                        <p>Vous avez {wrongAnswers} {errorMessage}.</p>

                        <div className="btn blue-btn" onClick={this.handleRetry}>Réessayer</div>

                    </div>
                }

            }
            else {
                modalContent = <div className="modal-content modal-content-results">
                    <div class="close" onClick={this.handleCloseClick}>&times;</div>

                    <p>Votre score est de</p>
                    <p>Votre score est de</p>
                    <p>Votre score est de</p>

                    <div className="quizz-number">{this.state.finalScore}/{this.state.questions.length}</div>

                    <div className="btn blue-btn" onClick={this.handleCloseClick}>Fermer</div>

                </div>
            }
        }

        return (
            <>
                <TransitionGroup component={null}>
                    <CSSTransition in={true} appear={true} classNames="fade" timeout={2000}>

                        <div className={this.state.displayModalClass}>
                            <form onSubmit={this.handleSubmit}>
                                {!this.state.isLoaded && LoadingIndicator}
                                {this.state.isLoaded && modalContent}
                            </form>
                        </div>

                    </CSSTransition>
                </TransitionGroup>
            </>
        )
    }
}