import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
import App from './App';

import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Expenses from "./components/expenses";
import Invoices from "./components/invoice";


import Home from './pages/home';
import ContactUs from './pages/contactUs';
import Error from './pages/error';
import Curriculum from './pages/curriculum';
import Article from './pages/article';
import Simulator from './pages/simulator';
import SimulatorList from './pages/simulatorList';
import CGU from './pages/cgu';
import DataPrivacy from './pages/dataPrivacy';
import Credits from './pages/credits';

import * as serviceWorker from './serviceWorker';


render(

    <BrowserRouter>

        <App />

    </BrowserRouter>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
