import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'


import Factory from '../../components/Factory'

import moteurCuivre from './images/moteur-alternateur-cuivre.jpg'
import moteurCuivreMobile from './images/moteur-alternateur-cuivre-MOB.jpg'
import extraction from './images/extraction.jpg'
import extractionMobile from './images/extraction-MOB.jpg'
import moteurTerreRare from './images/moteur-alternateur-terre-rare.jpg'
import moteurTerreRareMobile from './images/moteur-alternateur-terre-rare-MOB.jpg'
import metauxTransition from './images/metaux-transition.jpg'
import metauxConsommation from './images/metaux-consommation.jpg'
import metauxConsommationMobile from './images/metaux-consommation-MOB.jpg'


function Metaux() {


    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }


    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/metaux");
            history.push("/signup?returl=" + retURL);
        }
    }

    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Véhicules électriques, panneaux photovoltaïques, éoliennes ou batteries&nbps;: les technologies de la transition énergétique qui visent à se défaire de notre dépendance aux énergies fossiles reposent elles-mêmes sur une grande consommation de métaux. </p>

                    <div className="image-source" >
                        <img src={metauxConsommation} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={metauxConsommationMobile} style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                        <div >AIE   <Reference index="1" content="La quantité d'électricité produite est comptée sur toute la durée de vie de la machine ou de la centrale électrique, pour des facteurs de charges moyens. <br/>                        
                        <em>AIE </em>, <a href='https://iea.blob.core.windows.net/assets/24d5dfbb-a77a-4647-abcc-667867207f74/TheRoleofCriticalMineralsinCleanEnergyTransitions.pdf'>The Role of Critical Minerals in Clean Energy Transitions</a>, page 133, 2021 (en) <br/><br/>" /></div>

                    </div>

                    <p>Même pour les technologies les plus avancées, l’enjeu principal reste de conduire de l’électricité. Et le cuivre est justement le métal qui possède la meilleure conductivité, derrière l'or et l'argent qui sont beaucoup moins abondants et donc plus chers, ce qui en fait un élément central de la transition énergétique. Il sert dans les moteurs électriques, les alternateurs et les câbles utilisés sur le réseau.</p>

                    <p>Faire passer un courant électrique dans un bobinage de cuivre permet de créer un champ magnétique. Ajuster la puissance en entrée permet de contrôler la force, et donc la vitesse de sortie d’un tel moteur. Un alternateur fonctionne de même, mais dans l’autre sens&#8239;: appliquer une force mécanique —&nbsp;vent, chute d’eau, vapeur sous pression&nbsp;— génère un courant électrique en sortie. Éoliennes, barrages hydroélectriques ou centrales nucléaires produisent toujours de l’électricité selon ce principe.</p>

                    <div className="image-source" >
                        <img src={moteurCuivre} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={moteurCuivreMobile} style={{ maxWidth: '37rem' }} className="img-mobile"></img>
                    </div>

                    <p>Une éolienne terrestre moyenne contient environ 17&nbsp;tonnes de cuivre, une voiture électrique 80&nbsp;kilos et un hectare de panneaux photovoltaïques 4,4&nbsp;tonnes. Le renforcement du réseau, la construction de transformateurs et de bornes de recharge demandent également de grandes quantités de ce métal.
                        <Reference index="2" content="Aveq, <a href='https://www.aveq.ca/actualiteacutes/de-combien-de-cuivre-les-vehicules-electriques-ont-ils-besoin' target='_blank'>De combien de cuivre les véhicules électriques ont-ils besoin?</a>, février 2020 (fr) <br/><br/> 
                    
                    <em>Wood Mackenzie </em>, <a href='https://www.woodmac.com/press-releases/global-wind-turbine-fleet-to-consume-over-5.5mt-of-copper-by-2028/#:~:text=Over%20650%20GW%20of%20new,recent%20analysis%20by%20Wood%20Mackenzie'>Global wind turbine fleet to consume over 5.5Mt of copper by 2028</a>, October 2019 (en)<br/><br/>

                    <em>Jesse Broehl, Dexter Gauntlett</em>, <a href='https://www.copper.org/publications/pub_list/pdf/a6198-na-wind-energy-analysis.pdf'>North American Wind Energy Copper Content Analysis</a>, 2018 (en)" />
                    </p>

                    <p>Comme pour toute ressource non renouvelable&#8239;: l’exploitation se focalise d’abord sur les filons les plus concentrés en minerais, puis, tandis que la production augmente, des filons moins riches et plus difficiles d’accès se voient progressivement mis à contribution. La production devenant plus complexe, les volumes finissent par décliner&#8239;: le «&#8239;pic cuivre&#8239;» est alors passé.</p>


                    <div className="image-source" >
                        <img src={extraction} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={extractionMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                    </div>


                    <p>La production annuelle de cuivre atteint 25&nbsp;millions de tonnes par an&nbsp;: pour couvrir les besoins de la transition énergétique, celle-ci devrait tourner autour de 35 à 50 millions de tonnes par an. Un défi majeur qui paraît d'autant moins atteignable que d'après l'Agence Internationale de l'Énergie <em>«&nbsp;les mines en opération se rapprochent de leur pic du fait de la diminution de la qualité des minerais et de l'épuisement des réserves &nbsp;»</em>.
                        <Reference index="3" content="<em>AIE </em>, <a href='https://iea.blob.core.windows.net/assets/24d5dfbb-a77a-4647-abcc-667867207f74/TheRoleofCriticalMineralsinCleanEnergyTransitions.pdf'>The Role of Critical Minerals in Clean Energy Transitions</a>, page 133, 2021 (en)" />

                    </p>

                    <p>Malgré sa valeur élevée sur le marché, seulement 31% du cuivre est aujourd’hui recyclé alors qu’il pourrait théoriquement l’être jusqu’à presque 100%. La mise en place de filières de recyclage efficaces apparaît donc bien comme un enjeu majeur.</p>

                    <p>Pour d’autres métaux, le recyclage se révèle beaucoup plus compliqué, voire impossible. Au sein des composants électroniques, ils sont présents en très petites quantités, et mélangés. Les extraire et les séparer demanderait une quantité phénoménale d’énergie. Dans les faits, arrivés en fin de vie, les métaux précieux contenus dans un appareil électronique sont perdus.</p>

                    <h2>Terres rares et métaux rares</h2>

                    <p>Que cela soit volontaire ou non, de nombreuses publications et reportages entretiennent une certaine confusion entre les «&#8239;métaux rares&#8239;» et les «&#8239;terres rares&#8239;». La première définition est relative, donc subjective, et recouvre tous les métaux peu abondants, ou dont l’extraction est limitée par des contraintes géologiques.</p>

                    <p>Les terres rares constituent en revanche une classification scientifique très précise qui regroupe 17&nbsp;métaux aux propriétés voisines, mais à l’abondance variable. La plupart des terres rares sont donc des métaux rares, mais l’inverse n’est pas vrai.</p>

                    <p>Le néodyme, le dysprosium ou le samarium font partie des terres rares ; ils présentent l’immense avantage de former des aimants permanents. Malgré leur appellation, les terres «&#8239;rares&#8239;» sont en réalité partout&nbps;: à titre d'exemple, les magnets que l’on colle sur son réfrigérateur utilisent du néodyme. Cette même technologie d'aimant sert dans les éoliennes ou les moteurs électriques en remplacement des électroaimants en cuivre. Les performances sont meilleures, et les besoins de maintenance plus faibles&#8239;: un avantage crucial pour des éoliennes offshore, installées loin des côtes.</p>

                    <div className="image-source" >
                        <img src={moteurTerreRare} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={moteurTerreRareMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                    </div>

                    <p>Les terres rares reviennent régulièrement sur le devant de la scène pour deux raisons. Premièrement, car la Chine conserve le quasi-monopole de l’extraction de ces éléments, ce qui soulève d’épineuses questions géopolitiques. Deuxièmement, car leur production cause un véritable désastre environnemental.</p>

                    <p>Extraire d’infimes particules métalliques requiert des quantités astronomiques d’eau mélangée à des produits chimiques nocifs. Les déchets produits présentent une très forte toxicité, et sont suffisamment radioactifs pour, en théorie, nécessiter un stockage sécurisé sur plusieurs centaines d’années. Mais les régions productrices ne s’embarrassent pas de ces considérations sanitaires et environnementales : tous les résidus sont rejetés directement dans la nature, formant d’immenses lacs de rejets toxiques.</p>

                    <p>Aucun organisme international n’a pu produire de chiffres précis sur le sujet. Mais les cas de maladies ou d’intoxications déjà répertoriés dans les «&#8239;villes du cancer&#8239;» laissent penser que le bilan environnemental et humain s'avère extrêmement lourd. Cas particulièrement marquant, l’extraction des terres rares n’épuise pas à elle seule la vaste question des impacts sanitaires et écologiques de l’industrie minière.</p>

                    <p>Toutefois, souvent pointé du doigt pour sa consommation de terres rares, l’éolien n'y a en réalité recours que dans une minorité de cas. Seules 5&#8239;% des éoliennes en sont aujourd’hui équipées, et elles se situent quasiment exclusivement dans des parcs offshore. Pour le reste, le bobinage de cuivre demeure toujours la norme.</p>

                    <p>Les panneaux photovoltaïques sont, pour 90&#8239;% d’entre eux, basés sur le silicium. Ce dernier est l’élément le plus abondant de la croûte terrestre, suivi de l’aluminium et du fer. Ces panneaux nécessitent aussi de l’argent, dont les stocks connus ne dépassent pas une vingtaine d’années. Comme pour le cuivre, améliorer le recyclage est une nécessité pour prévenir toute rupture d’approvisionnement.</p>

                    <p>Enfin, sauf exception, les batteries ne contiennent pas de terres rares. En revanche, elles se voient confrontées au problème que pose leur dépendance au cobalt. Les risques sur l’approvisionnement sont aujourd’hui confirmés, et les constructeurs automobiles cherchent des alternatives pour éviter de faire face aux conséquences de pénuries de cobalt dans les années à venir.</p>

                    <h2>L’épuisement des métaux</h2>

                    <p>Les problèmes liés à la surconsommation de métaux rares ne se rattachent pas spécifiquement aux énergies renouvelables ou aux véhicules électriques, mais plutôt à toutes les hautes technologies. Ces dernières doivent leur existence même aux propriétés de dizaines de métaux disponibles en très petite quantité sur Terre, mais incontournables pour produire lasers, matériaux légers, composants électroniques, batteries, aimants permanents, écrans, etc.</p>

                    <p>À titre d’exemple, l’essor fulgurant des écrans plats repose sur l’utilisation de l’indium, un métal qui en alliage permet de conduire l’électricité et en même temps de laisser passer la lumière. Présent en quantités infimes dans nos écrans, il n’est pas recyclable. Les gisements connus seront épuisés d’ici une dizaine d’années au maximum. À ce jour, les chercheurs n’ont pas encore trouvé de technologie alternative. En plus de l’indium, plus d’une dizaine de ces métaux critiques sont aujourd’hui identifiés comme portant un risque de pénurie, passagère ou définitive, sur les années à venir.<Reference index="4" content="<em>United States Geophysical Survey (USGS)</em>, <a href='https://pubs.usgs.gov/of/2004/1300/2004-1300.pdf' target='_blank'>Indium</a>, 2019 (en)" /></p>

                    <p>En poursuivant de la sorte, nous courons le risque de déplacer notre dépendance aux énergies fossiles vers une dépendance aux métaux. À la suite de ce constat, ces dernières années ont vu se populariser les concepts de basses technologies, ou low-techs, et d’innovation frugale. L'innovation serait tournée vers l'amélioration continue de la résilience et de la sobriété, en métal et en énergie, davantage que vers l'augmentation des performances et l'ajout de nouvelles fonctionnalités.</p>

                    <p>Quoi qu’il en soit, dans un objectif de développement et d'amélioration de la qualité de vie des populations, la nécessité pour la médecine ou d’autres domaines de pointe de continuer à bénéficier des avancées technologiques les plus innovantes reste indéniable. High-tech et low-tech ont dès lors vocation à se compléter pour permettre un progrès technique plus durable.</p>

                    {/* 
                    <p>Les contraintes pesant sur l'approvisionnement en métaux ne sauraient constitue, dans l'absolu, un argument contre les technologies de la transition énergétique  —&nbsp;éoliennes, photovoltaïques, véhicules électriques &nbsp;— et donc en faveur du <em>statu quo</em>, favorable aux énergies fossile. Il s'agit ici seulement de mesurer à quelles limites ces alternatives vont se heurter pour pouvoir dessiner des perspectives qui soient réalistes. </p>
                    */}

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="metaux" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="11. Énergie et PIB"
                        url="/croissance-verte"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default Metaux

