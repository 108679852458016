import React, { Component } from 'react'
import { GrLinkNext } from "react-icons/gr"
import { IconContext } from "react-icons"

import { Link } from 'react-router-dom'
import '../css/arrows.css'


export default class RelatedArticles extends Component {

    render() {
        return (

            <div className="related-articles">

                <div className="previous-article"></div>
                <div className="next-article">
                    <div className="next-article-title">
                        <Link to={this.props.url}>
                            {this.props.nextArticleTitle}
                        </Link>
                    </div>
                    <div className="arrow-icon">
                        <Link to={this.props.url}>
                            <span class="nav-arrov-right"></span>
                        </Link>
                    </div>
                </div>
            </div>

        )
    }
}
