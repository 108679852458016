import React, { Component } from 'react'
import { GrLinkNext } from "react-icons/gr"
import { IconContext } from "react-icons"

import coalGame from '../images/home/coalGame.jpg'
import coal from '../images/home/coal.jpg'
import transition2050 from '../images/home/transition-2050.jpg'

import SimulatorThumb from '../components/SimulatorThumb'

import { Link } from 'react-router-dom'
import '../css/arrows.css'

import { FcGlobe, FcBarChart, FcLineChart, FcApproval, FcIdea, FcCollect, FcShare, FcElectricity } from "react-icons/fc"


export default class Checked extends Component {

    render() {
        return (

            <section className="prez-container u-center" >
                <div className="prez" >

                    <h1 className="u-text-center" style={{ marginBottom: '2rem' }}>Modèles</h1>

                    <p style={{ marginBottom: '4rem', lineHeight: "1.5" }}>Construisez des simulations à partir de données réelles à jour.</p>

                    <div class="prez-curriculums">
                        <div>
                            <SimulatorThumb url="/simulator/decarboner-l-electricite" image={<FcElectricity size={35} />} title="Décarboner l'électricité" description="Nucléaire, renouvelables ou gaz ? Choisissez votre mix énergétique pour diminuer l'intensité carbone dans la production d'électricité." imageThumb={coalGame} />
                        </div>
                        <div>
                            <SimulatorThumb url="/simulator/objectif-2C" title="Objectif 2 °C" image={<FcGlobe size={35} />} description="Construisez votre scénario à horizon 2050 pour maintenir le réchauffement climatique sous les 2 °C " imageThumb={transition2050} />
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}
