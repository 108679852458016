import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../App.js';
import { questions } from './quizz';
import QuizzModal from '../../components/QuizzModal'
import { useNavigate, useLocation } from "react-router-dom";
import { ReadingProgress } from '../../components/ReadingProgress'
import RelatedArticles from '../../components/RelatedArticles'
import Reference from '../../components/Reference'
import ResultModal from '../../components/ResultModal'

import CoalGame from '../../components/CoalGame'

import Fission from './images/fission.jpg'
import Fonctionnement from './images/fonctionnement-centrale-nucleaire.jpg'
import FonctionnementMobile from './images/fonctionnement-centrale-nucleaire-MOB.jpg'
import IntensiteNucleaire from './images/intensite-nucleaire.jpg'
import IntensiteNucleaireMob from './images/intensite-nucleaire-MOB.jpg'
import Risques from './images/comparaison-risques.jpg'
import RisquesMobile from './images/comparaison-risques-MOB.jpg'

function LeNucleaire() {

    const target = React.createRef();
    let auth = useAuth();
    let history = useNavigate();
    let location = useLocation();
    let [isQuizzOpened, setIsQuizzOpened] = useState(true);
    let [isModalDisplayed, setIsModalDisplayed] = useState(false);

    function quizzCallback() {
        setIsModalDisplayed("true");
    }

    const divRef = useRef(null);

    useEffect(() => {

        if (location.hash) {
            setIsQuizzOpened(true);
            setTimeout(divRef.current.scrollIntoView({ behavior: 'instant', block: 'center' }), 1);
        }
    });

    function openQuizz() {

        setIsQuizzOpened(true);

        if (!auth.user) {
            let retURL = encodeURIComponent("/nucleaire");
            history.push("/signup?returl=" + retURL);
        }
    }


    return (
        <>
            <ReadingProgress target={target} />
            <div className={`post`} ref={target}>
                <div className="article-content">

                    <p>Une centrale nucléaire fonctionne sur le même principe qu'une centrale thermique à gaz ou à charbon&nbsp;: chauffer de l’eau pour produire de la vapeur qui entraîne ensuite un alternateur.</p>

                    <div className="image-source">
                        <img src={Fonctionnement} style={{ maxWidth: '58rem' }} className="img-desktop" />
                        <img src={FonctionnementMobile} style={{ maxWidth: '39rem' }} className="img-mobile" />
                    </div>

                    <p>L'enjeu principal consiste à entretenir une réaction nucléaire stable permettant de contrôler la libération de la chaleur dans le temps, ce qui repose sur des compétences scientifiques et techniques de très haut niveau.</p>

                    <p>Lors de la fission, un atome d'uranium est scindé en deux atomes plus petits. La masse de ces deux atomes est plus faible que celle du noyau d'uranium, ce qui signifie que de la matière a disparu au cours de l'opération. En réalité, la masse correspondante a été transformée en énergie&nbsp;: c'est de là que provient la chaleur du réacteur.  </p>

                    <div className="image-source">
                        <img src={Fission} style={{ maxWidth: '39rem' }} />
                    </div>

                    <p>La correspondance entre matière consommée et énergie produite est donnée par la fameuse équation d'Einstein <em>E&#8239;=&#8239;mc<sup>2</sup></em> soit <em>Énergie produite = Masse consommée &times; la vitesse de la lumière au carré</em>. Ce ratio est exceptionnellement élevé, on observe 80 millions de milliards de fois plus d'énergie générée que de matière consommée, un kilo d'uranium enrichi fournira ainsi autant d'énergie que près de 3&#8239;000 tonnes de charbon. Cette information a son importance car cela explique pourquoi en matière de nucléaire tout est très concentré&#8239;: combustible, déchets, surface au sol, risques...</p>

                    <p>Les réacteurs peuvent être démarrés ou arrêtés à la demande. À titre d’exemple, les réacteurs français peuvent ajuster leur production de plus ou moins 80&#8239;% en une trentaine de minutes. Les centrales nucléaires sont donc pilotables. Toutefois, dans les faits, leur utilisation principale reste l'injection d'une production de base sur le réseau. </p>

                    <p>Avec 12 grammes de CO<sub>2</sub> par kilowatt-heure, soit 70 fois moins que le charbon, l'énergie nucléaire est sans aucun doute possible l'une des plus vertueuses sur le plan climatique.  </p>

                    <div className="image-source">
                        <img src={IntensiteNucleaire} style={{ maxWidth: '57rem' }} className="img-desktop" />
                        <img src={IntensiteNucleaireMob} style={{ maxWidth: '38rem' }} className="img-mobile" />
                    </div>

                    <p>Le facteur de charge d’une centrale nucléaire est de l’ordre de 75 à 95&#8239;%. Les arrêts pour maintenance sont récurrents, afin de recharger le combustible nucléaire, mais surtout de garantir à tout instant le niveau de sécurité qui s’impose. Ils ont également pour effet de prolonger leur durée de vie.</p>

                    <p>Car les systèmes d’une centrale nucléaire ne sont pas figés dans le temps&nbsp;: même construite dans les années 70, une centrale en activité sera équipée de toute l’informatique moderne. Certaines centrales aux États-Unis ont ainsi reçu des autorisations pour continuer à fonctionner jusqu’à 80 ans. À terme, c’est l’état de la cuve du réacteur qui limite sa durée de vie.</p>

                    <p>Avec l'hydroélectricité, le nucléaire constitue une solution éprouvée pour produire massivement de l'énergie bas carbone pilotable.</p>

                    {/*
                                        <p>
                        <CoalGame displayedItems={["nuclear"]} source={<span style={{ zIndex: "5" }}><span>Source : EDF, GIEC</span><Reference index="7" content="Les 4 derniers réacteurs mis en service en France dans les années 90 avait une puissance de 1450 MW. Pour l'intensité carbone, la référence est la moyenne mondiale de 12 gCO₂eq/kWh donnée par le GIEC et non les 6 gCO₂eq/kWh français.<br/><br/>

                    GIEC, <a target='_blank' href='https://www.ipcc.ch/site/assets/uploads/2018/02/ipcc_wg3_ar5_annex-iii.pdf#page=7'>2014: Climate Change 2014: Synthesis Report</a>,<br/> Table A.III.2 | Emissions of selected electricity supply technologies (en) " /></span>} />
                    </p>
                    */}


                    <p>Pour autant, le nucléaire seul ne sauvera pas le climat. Cette technologie de pointe requiert un savoir-faire maîtrisé par une petite élite, ce qui implique un goulot d’étranglement sur les compétences. Même la France, qui s’est illustrée par le passé dans cette filière, semble repartir de très loin.</p>

                    <p>Par ailleurs, certains territoires ne disposent pas d’un réseau électrique suffisamment solide ou de la stabilité politique requise pour que l’on puisse envisager d’y bâtir une installation nucléaire. L’étanchéité entre nucléaire civil et militaire, et les risques de prolifération associés restent également un point d’attention important. </p>

                    <h2>Les déchets nucléaires</h2>

                    <p>Toute activité humaine génère des déchets. La recherche scientifique, l'industrie et la médecine produisent également des déchets radioactifs devant être stockés sur de longues durées. Les plastiques et certains autres sous-produits industriels ont des durées de vie qui se comptent en milliers d'années.</p>

                    <p>Si les déchets radioactifs issus du combustible nucléaire se caracérisent par leur haut niveau d'activité et leur longue durée de vie, ils gardent l’avantage de rester très denses. Ainsi, couvrir 70% des besoins en électricité d’un pays comme la France produit chaque année un volume de déchets qui tient dans le bassin d’une piscine olympique.</p>

                    <p>Nous disposons aujourd'hui de solutions éprouvées pour gérer ce type de déchet&nbsp;: le stockage géologique profond ou leur recyclage dans des réacteurs spéciaux appelés supergénérateurs.</p>

                    <h2>Le nucléaire de 4e génération</h2>

                    <p>Concernant les réserves d’uranium, le sujet est le même que pour le pétrole&nbsp;: s’agissant d’une matière première non renouvelable, sa production passera par un pic puis deviendra progressivement de plus en plus difficile, jusqu’à son épuisement complet.</p>

                    <p>Les réserves prouvées permettraient de faire fonctionner plus de 100 ans l'équivalent des 450 réacteurs aujourd'hui en activité.<Reference index="1" content="<em>Agence Internationalee de l'énergie atomique</em>, <em>OCDE</em>, <a target='_blank' href='https://www.iaea.org/newscenter/pressreleases/global-uranium-supply-ensured-long-term-new-report-shows'>Global Uranium Supply Ensured for Long Term, New Report Shows</a>, juillet 2012 (en)." /> Or ces derniers ne couvrent que 3% de la demande énergétique mondiale. Par voie de conséquence, l’uranium n’est pas présent en quantité suffisante pour que la filière actuelle puisse prétendre répondre entièrement au problème posé par le Réchauffement climatique.</p>

                    <p>Toutefois, une alternative existe&#8239;: la surgénération. De tels réacteurs «&nbsp;à neutrons rapides&nbsp;» permettent d’utiliser l’uranium d’une manière quasi renouvelable&#8239;; les déchets nucléaires actuellement stockés en France suffiraient pour produire toute l’électricité du pays durant plus de mille ans.</p>

                    <p>Cette filière est une piste parmi d’autres pour la prochaine génération de réacteurs nucléaires, la génération IV, dont le déploiement à grande échelle n’est pas envisagé avant le milieu du XXI<sup>e</sup> siècle. </p>

                    <p>La Chine prend de l’avance en développant un programme nucléaire ambitieux comprenant une vision de long terme, qui inclut notamment la construction de réacteurs à neutrons rapides. L’Inde s’est également lancée dans la course. Mais c’est en Russie que l’on trouve aujourd’hui le surgénérateur opérationnel le plus puissant au monde.</p>

                    <p>Quant à la France, elle abandonne le projet de réacteurs à neutrons rapides ASTRID en 2019, le présentant comme insuffisamment rentable à ce jour, dans un contexte où l’uranium est encore abondant et donc peu cher.</p>

                    <h2>Les catastrophes nucléaires</h2>

                    <p>Les énergies fossiles et la pollution atmosphérique qu'elles engendrent sont responsables d'un excès de mortalité estimé entre 8 et 10 millions de personnes chaque année, ce qui constitue la première cause de mortalité dans le monde, devant le tabac.<Reference index="2" content="<em>Environmental Research</em>, <a target='_blank' href='https://www.sciencedirect.com/science/abs/pii/S0013935121000487'>Global mortality from outdoor fine particle pollution generated by fossil fuel combustion: Results from GEOS-Chem</a>, avril 2021 (en)." /></p>

                    <p>Le charbon en particulier émet des particules fines particulièrement nocives qui, portées par le vent, peuvent provoquer des cancers et des maladies cardiovasculaires graves chez des personnes habitant pourtant loin des centrales. Si l’Asie connaît d’immenses problèmes liés à la pollution de l’air par le charbon, l’Europe n’est pas épargnée par le phénomène avec des dizaines de milliers de victimes chaque année. Ce triste bilan repose essentiellement sur deux pays&#8239;: la Pologne et l’Allemagne.

                        <Reference index="3" content="Le charbon entrainerait le décès prématuré de 23 000 personnes et occasionnerait des dizaines de milliers de cas de maladies cardiaques et pulmonaires en Europe chaque année.<br/><br/><em>Health and Environment Alliance (HEAL)</em>, <em>Greenpeace</em>, <em>Climate Action Network (CAN) Europe</em>, <em>WWF European Policy Office</em>,  <a target='_blank' href='http://awsassets.wwfffr.panda.org/downloads/dark_cloud_full_report.pdf#page=6'>Le nuage noir de l'Europe: comment les pays utilisant du charbon rendent leurs voisins malades</a>, juin 2016 (PDF) (en). <br><br>
   <em>Environment International</em>, <a target='_blank' href='https://www.sciencedirect.com/science/pii/S0160412018313369?via%3Dihub#p0200'>The impact of power generation emissions on ambient PM2.5 pollution and human health in China and India</a>, décembre 2018 (en). 
   " />
                    </p>

                    <p>Ainsi, parce qu’elles préservent la qualité de l’air, les énergies bas carbone causent à production équivalente 99,8&#8239;% de morts en moins que le charbon et 99% en moins que le gaz naturel.</p>

                    <p>Restent les accidents. Se référer aux statistiques passées revient plus à intenter le procès des différentes sources d’énergie à travers l’histoire qu’à donner une vue pertinente de leur réelle dangerosité. Ceci étant dit, selon ce mode de comptage rétrospectif, l’électricité bas carbone la plus mortelle par kilowatt-heure d’électricité produite serait… l’énergie éolienne !</p>

                    <p>Bien que rarement mortels, les accidents du travail sont fréquents dans cette industrie. Lors de l’installation, mais aussi de la maintenance : détachement de pales, incendies, basculement de grues, effondrement des mâts, chutes, naufrages, collisions au cours du transport, crashs d’hélicoptère, etc. Suivent le nucléaire, l’hydroélectricité et pour finir, le solaire qui compte notamment toutes les chutes lors de l'installation de panneaux sur des toitures.</p>

                    <div className="image-source" >
                        <img src={Risques} style={{ maxWidth: '58rem' }} className="img-desktop"></img>
                        <img src={RisquesMobile} style={{ maxWidth: '38rem' }} className="img-mobile"></img>
                        <div >Sources <Reference index="4" content="
   Pour le nucléaire, sont comptés : les morts confirmés, les prévisions de décès jusqu'à 2065, cf section dédiée à Tchernobyl, mais aussi les suicides consécutifs à la catastrophe de Fukushima.<br/><br/>
   Pour l'éolien : seuls les décès parmi les ouvriers de cette industrie et les aéronefs entrés en collision avec des éoliennes sont comptabilisés. 
   <br/><br/>
   · Anil Markandya, Paul Wilkinson <a href='https://www.thelancet.com/S0140-6736(07)61253-7/fulltext' target='_blank'>Electricity generation and health</a> septembre 2017 (en)                     
   <br/>
   · Benjamin K. Sovacool et al. <a href='https://www.sciencedirect.com/science/abs/pii/S0959652615009877' target='_blank'>Balancing safety with sustainability: assessing the risk of accidents for modern low-carbon energy systems</a>, january 2016 (en)<br/>
   · <em>Caithness Windfarm Information Forum</em>, <a href='http://www.caithnesswindfarms.co.uk/fullaccidents.pdf' target='_blank'>Summary of Wind Turbine Accident data to 30 September 2020</a>"
                        /></div>
                    </div>

                    <p>La quasi-intégralité du bilan en vies humaines du nucléaire civil se concentre sur un événement unique&#8239;: la catastrophe de Tchernobyl.</p>

                    <p>L’ONU dispose d’un équivalent du GIEC pour le nucléaire, l’UNSCEAR, qui regroupe des experts mis à disposition par ses 27 pays membres, et dont la mission porte sur l’étude des radiations et de leurs effets.</p>

                    <p>Publié en&nbsp;2008, le rapport sur Tchernobyl décompte, pour les travailleurs intervenus sur le site, 47&nbsp;morts et une recrudescence notable des leucémies. Les jeunes enfants, et notamment ceux qui avaient consommé du lait contaminé, vivent avec une probabilité plus élevée de développer un cancer de la thyroïde. Les enquêtes recensent à ce jour un peu plus de 6000&nbsp;cas. Le taux de survie observé pour ces patients est de l’ordre de 99&#8239;%.
                        <Reference index="5" content="<em>United Nations Scientific Committee on the Effects of Atomic Radiation (UNSCEAR)</em>, <a target='_blank' href='https://www.unscear.org/docs/reports/2008/11-80076_Report_2008_Annex_D.pdf#page=24'>Sources and effects of ionizing radiation 2008 Report</a>, section VII.A.99. «&nbsp;Health Risk attributable to radiation&nbsp;», 2008 (en). " />
                    </p>

                    <p>Ce bilan n’est pas figé, car certains cancers ne se déclareront pas avant plusieurs années. Le centre international de recherche sur le cancer (CIRC), rattaché à l’OMS, a estimé qu’à l’horizon&nbsp;2065 le bilan médian total, pour les deux types de populations exposées, pourrait tourner autour de 30&#8239;000&nbsp;cancers, dont 16&#8239;000&nbsp;mortels.
                        <Reference index="6" content="<em>International Journal of Cancer</em>, <a href='https://onlinelibrary.wiley.com/doi/full/10.1002/ijc.22037'>Estimates of the cancer burden in Europe from radioactive fallout from the Chernobyl accident</a>, septembre 2006 (en)." />
                    </p>

                    <p>En dehors de ces deux catégories, liquidateurs et jeunes enfants exposés, aucune recrudescence statistiquement significative de cancers dans la population générale n’a pu être établie. Cependant, le rapport insiste sur le fait que la peur des radiations a sûrement causé bien plus de malheur que les radiations elles-mêmes&#8239;: dépression, anxiété, stress post-traumatique, alcoolisme, suicides… Ce qui s’est passé après Tchernobyl reste pour les millions de personnes qui l’ont vécu un drame dont on peine encore à mesurer toute la gravité.</p>

                    <p>À Fukushima, <em>«&#8239;aucun décès, aucune maladie grave ayant un lien avec les radiations n’a été observé parmi les travailleurs et l’ensemble de la population&#8239;»</em>. L’UNSCEAR précise toutefois que les enfants exposés présentent là aussi un risque plus élevé que la moyenne de développer plus tard des cancers de la thyroïde. Avec plusieurs centaines de suicides recensés à la suite de la catastrophe, les traumatismes de l’évacuation et la terreur causée par les radiations constituent, ici aussi, une immense tragédie.
                        <Reference index="7" content="<em>United Nations Scientific Committee on the Effects of Atomic Radiation (UNSCEAR)</em>, <a target='_blank' href='https://www.unscear.org/docs/reports/2013/13-85418_Report_2013_Annex_A.pdf#page=17'>Sources and effects of ionizing radiation 2013 Report</a>, section III.A.3.38. «&nbsp;Health implications&nbsp;», 2013 (PDF) (en)." />
                    </p>

                    <p>Du strict point de vue des radiations, la limite à un décompte rigoureux reste la difficulté à tracer toutes les personnes exposées d’une part et de démontrer un lien de cause à effet entre exposition aux radiations et cancer d’autre part. Cela s’avère d’autant plus problématique dans dans les pays de l’ex-URSS où les données médicales manquent de fiabilité, et où la dégradation de la santé publique fut une constante observée dans toute la région à cette époque.</p>

                    <h2>Le risque d’accident majeur</h2>

                    <p>Les conséquences d’une catastrophe nucléaire portent essentiellement sur les centaines de kilomètres carrés de territoires contaminés rendus inhabitables pour plusieurs décennies. Même sans tuer directement personne, cela constitue une immense tragédie pour les populations déplacées, et un désastre économique pour les pays concernés. La situation à Fukushima n’est à ce jour toujours pas réglée, plus de dix ans après le tsunami, et le coût final de la catastrophe dépassera sans doute les 120&nbsp;milliards d’euros.</p>

                    <p>Pourtant, lors du séisme qui a frappé le Japon, la centrale japonaise d’Onagawa, opérée par la <em>Tohoku Electric Power Company</em>, était celle qui se situait au plus proche de l’épicentre. Elle fut endommagée, mais ses trois réacteurs s’y sont arrêtés conformément aux procédures prévues, en toute sécurité. Une vague de 15&nbsp;mètres a submergé les villages alentour et plusieurs centaines de sans-abri ont trouvé refuge dans la centrale, seul bâtiment encore debout à des kilomètres à la ronde.</p>

                    <p>Les conclusions de l’enquête indépendante, menée par le parlement japonais, insistent sur le fait que ce qui s’est passé à la centrale de Fukushima ne saurait être vu comme une fatalité. <em>«&#8239;Ce fut un désastre profondément causé par l’homme, qui aurait pu et aurait dû être prévu et prévenu&#8239;»</em>, détaille ainsi le rapport final remis en&nbsp;2012.
                        <Reference index="8" content="<em>The National Diet of Japan</em>, <a target='_blank' href='http://large.stanford.edu/courses/2013/ph241/mori1/docs/NAIIC_report_hi_res10.pdf#page=9'>The official report of The Fukushima Nuclear Accident Independent Investigation Commission</a>, juillet 2012 (en)." />
                    </p>

                    <p>La région du Tohoku, la côte nord-est du Japon où se trouve Fukushima, fait face à un tsunami de grande ampleur environ une fois par siècle. Les dernières catastrophes remontaient à 1896 et 1933, et la possibilité qu’un tel événement se reproduise au début du XXIe siècle faisait l’objet d’un consensus scientifique établi. Mais, confrontée aux demandes des autorités de sûreté, l’opérateur de la centrale de Fukushima, la <em>Tokyo Electric Power Company</em> (TEPCO), déjà coutumière dans la falsification de rapports internes, a sciemment procédé à des man&oelig;uvres d’obstruction pour éviter d’avoir à assumer les coûts relatifs à la sécurisation de ses réacteurs &nbsp;: rehausser les digues, mettre les systèmes de secours en hauteur. Le gouvernement japonais est également pointé du doigt pour avoir permis à de tels dysfonctionnements de perdurer pendant des années.</p>

                    <p>Quant à Tchernobyl, les contrôles de sûreté par les autorités publiques se sont révélés inexistants. Plus grave encore, les failles de sûreté identifiées dans la conception même des réacteurs relevaient du secret d’État. Enfin, pour compléter des phases de test dans la précipitation, le soir du 26&nbsp;avril&nbsp;1986, les opérateurs ont délibérément enfreint les quelques procédures de sûreté qui pouvaient encore les protéger du drame.</p>

                    <p>En outre, la situation aurait pu dégénérer en suraccident. L’eau déversée par les pompiers pour éteindre l’incendie s’était répandue dans le sous-sol. Sans l’intervention héroïque de trois ingénieurs pour ouvrir les vannes d’évacuation, le cœur fondu du réacteur serait rentré en contact avec les 20&#8239;000&nbsp;litres d’eau accumulée. Une grande partie se serait évaporée quasi instantanément, produisant une terrible explosion de vapeur. Kiev aurait été rayée de la carte et tout le nord de l’Ukraine serait devenu un champ de friches radioactives inhabitables pendant plus d’un siècle.</p>

                    <p>De tels scénarios catastrophe, par leur ampleur, mais surtout par la persistance de leurs effets, distinguent clairement le nucléaire des autres risques industriels. Pour autant, la menace d’une hypothétique catastrophe nucléaire ne dispense pas de garder une approche rationnelle des risques. </p>

                    <p>Ce que l’histoire nous apprend est que l’excès de confiance dans la technologie constitue bien la cause première des dysfonctionnements menant, une négligence après l’autre, à la catastrophe. La sûreté repose donc avant tout sur la prudence des décisions prises par les acteurs qui en ont la responsabilité.</p>

                    <p>Cette exigence de prudence vaut toujours pour le nucléaire civil mais aussi quand il s'agit de recourir à des alternatives. Miser déraisonnablement sur des technologies qui n'ont pas encore fait la preuve de leur efficacité à grande échelle nous expose à une multitude de risques concernant la sécurité de l'approvisionnement électrique.</p>

                    <p>À l'échelle planétaire, le GIEC met en gade&nbsp;: faire l'impasse sur l'énergie nucléaire se traduirait très probablement par une incapacité à respecter les objectifs de réduction d'émissions. Et en matière de risque climatique, nous n’aurons pas le droit à une deuxième chance.</p>

                    <div ref={divRef} className="quizz-article-inline">

                        {isQuizzOpened && <QuizzModal articleQuestions={questions} examMode={true} callbackFromHero={quizzCallback} />}

                        {!isQuizzOpened &&
                            <div className="u-center">
                                <div className="btn green-btn" onClick={openQuizz}>Questionnaire</div>
                            </div>
                        }

                    </div>

                    <p class="entry__tags">
                        <span>Tags</span>

                        <span class="entry__tag-list">
                            <a href="#0">Énergie</a>
                            <a href="#0">Climat</a>
                            <a href="#0">Nucléaire</a>
                        </span>
                    </p>

                    {isModalDisplayed && <ResultModal articleId="nucleaire" displayModal={true} />}

                    <RelatedArticles nextArticleTitle="8. Décarboner l'électricité"
                        url="/decarboner-electricite"></RelatedArticles>

                </div>
            </div>
        </>
    );
}

export default LeNucleaire

