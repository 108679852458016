import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ArticleContent from '../components/ArticleContent'
import { useParams } from "react-router-dom";

const Article = () => {

    const { id } = useParams();

    return (

        <ArticleContent articleId={id} />

    )
}

export default Article
